package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.domain.labodoc.partner.PartnerName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object PartnerCodeAsStringSerializer : KSerializer<PartnerCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PartnerCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(code: String): Either<Errors.Partner.Code.Invalid, PartnerCode> =
    PartnerCode(code)

  override fun deserialize(decoder: Decoder): PartnerCode {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(code: PartnerCode): String = code.value

  override fun serialize(encoder: Encoder, value: PartnerCode) {
    return encoder.encodeString(serialize(value))
  }
}

typealias PartnerCodeAsString = @Serializable(PartnerCodeAsStringSerializer::class) PartnerCode


object PartnerNameAsStringSerializer : KSerializer<PartnerName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PartnerNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.Partner.Name.Invalid, PartnerName> =
    PartnerName(name)

  override fun deserialize(decoder: Decoder): PartnerName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(name: PartnerName): String = name.value

  override fun serialize(encoder: Encoder, value: PartnerName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias PartnerNameAsString = @Serializable(PartnerNameAsStringSerializer::class) PartnerName
