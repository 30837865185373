package fr.labodoc.webapp.components

import io.kvision.core.*
import io.kvision.html.TagMarker
import io.kvision.html.image
import io.kvision.panel.SimplePanel
import io.kvision.snabbdom.VNode

@TagMarker
open class Picture(
  src: ResString?, alt: String? = null, className: String? = null, init: (Picture.() -> Unit)? = null
) : SimplePanel(className) {

  class Source(
    media: String,
    srcset: ResString
  ) : Widget() {

    init {
      setAttribute("media", media)
      setAttribute("srcset", srcset)
    }

    override fun render(): VNode {
      return render("source")
    }
  }

  init {
    @Suppress("LeakingThis")
    init?.invoke(this)

    add(image(src, alt))
  }

  override fun render(): VNode {
    return render("picture", childrenVNodes())
  }

  fun addSource(media: String, srcset: ResString) {
    add(Source(media, srcset))
  }
}

/**
 * DSL builder extension function.
 *
 * It takes the same parameters as the constructor of the built component.
 */
fun Container.picture(
  src: ResString?, alt: String? = null, className: String? = null, init: (Picture.() -> Unit)? = null
): Picture {
  val picture = Picture(src, alt, className, init)
  this.add(picture)
  return picture
}
