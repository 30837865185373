package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreatePartnershipRequest
import fr.labodoc.api.payloads.requests.UpdatePartnershipRequest
import fr.labodoc.api.payloads.responses.PartnershipCreatedResponse
import fr.labodoc.api.payloads.responses.PartnershipResponse
import fr.labodoc.api.payloads.responses.PartnershipsResponse
import fr.labodoc.app.data.admin.model.PartnershipModel
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite

class PartnershipsRepositoryImpl(
  private val apiClient: AdminApiClient
) : PartnershipsRepository {
  override suspend fun getPartnerships(
  ): ApiResponse<Set<PartnershipModel>> =
    apiClient.partnerships
      .getPartnerships()
      .map { partnershipsResponse: PartnershipsResponse ->
        partnershipsResponse
          .partnerships
          .map { partnership: PartnershipsResponse.Partnership ->
            PartnershipModel(
              id = partnership.id,
              name = partnership.name,
              logo = partnership.logo,
              website = partnership.website
            )
          }
          .toSet()
      }

  override suspend fun createPartnership(
    name: PartnershipName,
    logo: InputFile,
    website: PartnershipWebsite
  ): ApiResponse<PartnershipId> =
    apiClient.partnerships
      .createPartnership(
        payload = CreatePartnershipRequest(
          name = name,
          website = website
        ),
        logo = logo
      )
      .map { partnershipCreatedResponse: PartnershipCreatedResponse ->
        partnershipCreatedResponse.id
      }

  override suspend fun getPartnership(
    id: PartnershipId
  ): ApiResponse<PartnershipModel> =
    apiClient.partnerships
      .getPartnership(id)
      .map { partnershipResponse: PartnershipResponse ->
        PartnershipModel(
          id = partnershipResponse.id,
          name = partnershipResponse.name,
          logo = partnershipResponse.logo,
          website = partnershipResponse.website
        )
      }

  override suspend fun updatePartnership(
    id: PartnershipId,
    name: PartnershipName,
    logo: InputFile?,
    website: PartnershipWebsite
  ): ApiResponse<Unit> =
    apiClient.partnerships
      .updatePartnership(
        id = id,
        payload = UpdatePartnershipRequest(
          name = name,
          website = website
        ),
        logo = logo
      )

  override suspend fun deletePartnership(
    id: PartnershipId
  ): ApiResponse<Unit> =
    apiClient.partnerships
      .deletePartnership(
        id = id
      )
}
