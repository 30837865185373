package fr.labodoc.webapp.pages.admin.laboratories

import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZipOrAccumulate
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.laboratories.components.AdminLaboratoryForm
import fr.labodoc.webapp.utils.toInputFile
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.html.h2
import io.kvision.panel.SimplePanel
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminLaboratoryCreatePage : SimplePanel() {
  private class ViewModel : KoinComponent {
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))

    fun createLaboratoryAndContacts(
      laboratory: AdminLaboratoryForm.Data,
    ) {
      App.scope.launch {
        either<Nel<String>, Unit> {
          parZipOrAccumulate(
            {
              ensureNotNull(laboratory.name) { "Le nom du laboratoire est obligatoire" }
              LaboratoryName(laboratory.name)
                .mapLeft { error: Errors.Laboratory.Name.Invalid ->
                  when (error) {
                    Errors.Laboratory.Name.Invalid.Blank -> "Le nom du laboratoire est vide"
                    Errors.Laboratory.Name.Invalid.TooLong -> "Le nom du laboratoire est trop long"
                  }
                }
                .bind()
            },
            {
              ensureNotNull(laboratory.website) { "Le site du laboratoire est obligatoire" }
              LaboratoryWebsite(Url(laboratory.website))
            },
            {
              laboratory.pharmacovigilance?.let {
                LaboratoryPharmacovigilance(Url(laboratory.pharmacovigilance))
              }
            },
            {
              laboratory.logo?.firstOrNull()?.toInputFile()
            }
          ) { name, website, pharmacovigilance, logo ->
            laboratoriesRepository
              .createLaboratory(
                name,
                website,
                pharmacovigilance,
                logo
              )
              .onLeft {
                Toast.danger("Une erreur est survenue")
              }
              .onRight { laboratoryId: LaboratoryId ->
                App.routing.navigate(Page.AdminBackOfficeLaboratorySheet(laboratoryId))
              }
          }
        }.onLeft { errorMessages: Nel<String> ->
          errorMessages.forEach { errorMessage ->
            Toast.danger(errorMessage)
          }
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModel()

  init {
    id = "page-admin-laboratory-create"
    require("./css/pages/admin/laboratories/create.css")

    div(className = "page-width") {
      h1 {
        content = "Créer un laboratoire"
      }

      val laboratoryForm = AdminLaboratoryForm()
      div(className = "laboratory-form-container") {
        h2("Informations")
        add(laboratoryForm)
      }

      labodocButton("Sauvegarder", className = "save") {
        onClickLaunch {
          if (laboratoryForm.validate())
            viewModel.createLaboratoryAndContacts(laboratoryForm.getDataWithFileContent())
        }
      }
    }
  }
}

fun Container.adminLaboratoryCreatePage(): AdminLaboratoryCreatePage {
  val adminLaboratoryCreatePage = AdminLaboratoryCreatePage()
  this.add(adminLaboratoryCreatePage)
  return adminLaboratoryCreatePage
}
