package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.UrlAsString
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalMedicinesSummaryResponse(
  val medicines: Set<Medicine>,
  val laboratories: Map<String, Laboratory>,
  val atcClassifications: Map<String, AtcClassification>,
  val thematics: Map<String, Thematic>,
) {
  @Serializable
  data class Medicine(
    val id: String,
    val laboratoryId: String?,
    val name: String,
    val atcClassificationCode: String,
    val logoUrl: UrlAsString,
    val isPartner: Boolean
  )

  @Serializable
  data class Laboratory(
    val id: String,
    val name: String
  )

  @Serializable
  data class AtcClassification(
    val code: String,
    val name: String,
    val shortName: String?,
    val parentCode: String?,
    val numberOfMedicines: Int
  )

  @Serializable
  data class Thematic(
    val id: String,
    val name: String,
    val atcClassificationCodes: Set<String>,
    val numberOfMedicines: Int
  )
}
