package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.html.Span

class LabodocSpinner : Span(className = "labodoc-spinner") {
  init {
    require("./css/components/labodoc-spinner.css")
  }
}

fun Container.labodocSpinner(): LabodocSpinner {
  val spinner = LabodocSpinner()
  this.add(spinner)
  return spinner
}
