package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.CisCodeAsString
import fr.labodoc.api.payloads.serializers.DosageNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class UpdateDosagesRequest(
  val dosages: Set<Dosage>
) {
  @Serializable
  data class Dosage(
    val cisCode: CisCodeAsString,
    val name: DosageNameAsString,
  )
}
