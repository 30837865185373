package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class SegmentationTemplateResponse(
  val id: String,
  val name: String,
  val medicalProfessions: Set<MedicalProfessionIdAsString>?,
  val medicalSpecialities: Set<MedicalSpecialityIdAsString>?,
  val medicalInterests: Set<MedicalInterestIdAsString>?,
  val professionalCategories: Set<ProfessionalCategoryCodeAsString>?,
  val professionalStatuses: Set<ProfessionalStatusCodeAsString>?,
  val medicalCardTypes: Set<MedicalCardTypeCodeAsString>?,
  val departmentCodes: Set<DepartmentCodeAsString>?
)
