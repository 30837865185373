package fr.labodoc.domain.healthdirectory

import fr.labodoc.domain.models.AggregateRoot
import kotlin.jvm.JvmInline

class PharmacistTableSection(
  val code: Code,
  val label: Label
) : AggregateRoot<PharmacistTableSection.Code>(code) {
  @JvmInline
  value class Code(val value: String)

  @JvmInline
  value class Label(val value: String)
}
