package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.AtcCategoryWithCodesResponse
import fr.labodoc.app.data.admin.model.AtcCategoryModel

class AtcCodesRepositoryImpl(
  private val apiClient: AdminApiClient
) : AtcCodesRepository {
  override suspend fun getAtcCategoriesWithCodes(
  ): ApiResponse<Set<AtcCategoryModel>> =
    apiClient.atcCodes
      .getAtcCodes()
      .map { atcCategories: Set<AtcCategoryWithCodesResponse> ->
        atcCategories
          .map { atcCategory: AtcCategoryWithCodesResponse ->
            AtcCategoryModel(
              code = atcCategory.code,
              name = atcCategory.name,
              codes = atcCategory.codes
                .map { atc ->
                  AtcCategoryModel.Atc(
                    code = atc.code,
                    name = atc.name
                  )
                }
                .toSet()
            )
          }
          .toSet()
      }
}
