package fr.labodoc.webapp.pages.admin.medicines

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.LaboratoryModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.utils.toInputFile
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.html.*
import io.kvision.i18n.I18n
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicineForm(
  private val laboratoryOptions: Set<Pair<LaboratoryId, LaboratoryName>>,
  private val medicalProfessions: Set<MedicalProfessionModel>,
  private val medicineLogoUrl: Url? = null,
) : FormPanel<AdminMedicineForm.Data>(serializer = Data.serializer(), className = "medicine-form") {
  private interface ViewModel {
    val selectedLaboratory: ObservableState<LaboratoryModel?>

    fun updateSelectedLaboratory(selectedLaboratoryId: String?)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))

    override val selectedLaboratory: ObservableValue<LaboratoryModel?> =
      ObservableValue(null)

    override fun updateSelectedLaboratory(selectedLaboratoryId: String?) {
      App.scope.launch {
        val laboratory = selectedLaboratoryId?.let {
          laboratoriesRepository
            .getLaboratory(LaboratoryId(uuidFrom(selectedLaboratoryId)))
            .fold({ null }, { it })
        }

        selectedLaboratory.setState(laboratory)
      }
    }
  }

  @Serializable
  data class Data(
    val laboratory: String? = null,
    val name: String? = null,
    val atcClassificationCode: String? = null,
    val logo: List<KFile>? = null,
    val segmentation: SegmentationAsString? = null
  ) {
    companion object {
      fun validateLaboratory(laboratory: String?): Either<String, LaboratoryId?> = either {
        laboratory?.let {
          LaboratoryId(uuidFrom(laboratory))
        }
      }

      fun validateName(name: String?): Either<String, MedicineName> = either {
        ensureNotNull(name) {
          I18n.tr("Field.Required")
        }

        MedicineName(name)
          .mapLeft { error ->
            when (error) {
              Errors.Medicine.Name.Invalid.Blank -> "Ne peut pas être vide"
              Errors.Medicine.Name.Invalid.TooLong -> "Trop long"
            }
          }
          .bind()
      }

      fun validateAtcClassificationCode(atcClassificationCode: String?): Either<String, AtcClassificationCode> = either {
        ensureNotNull(atcClassificationCode) {
          I18n.tr("Field.Required")
        }

        AtcClassificationCode(atcClassificationCode)
          .mapLeft { error ->
            when (error) {
              Errors.AtcClassification.Code.Invalid.Format -> "Format invalide"
            }
          }
          .bind()
      }

      fun validateLogo(logo: List<KFile>?): Either<String, InputFile?> = either {
        logo?.firstOrNull()?.toInputFile()
      }

      fun validateSegmentation(segmentation: Segmentation?): Either<String, Segmentation> = either {
        ensureNotNull(segmentation) {
          I18n.tr("Field.Required")
        }

        ensure(!segmentation.medicalProfessions.isNullOrEmpty() && !segmentation.medicalSpecialities.isNullOrEmpty()) {
          raise("Aucune spécialité renseignée")
        }

        segmentation
      }
    }
  }

  data class ValidatedData(
    val laboratory: LaboratoryId?,
    val name: MedicineName,
    val atcClassificationCode: AtcClassificationCode,
    val logo: InputFile?,
    val segmentation: Segmentation
  )

  private val viewModel: ViewModel = ViewModelImpl()

  val laboratoryInput = LabodocSelect {
    label = "Laboratoire"
    options = laboratoryOptions.map { it.first.value.toString() to it.second.value }

    subscribe(viewModel::updateSelectedLaboratory)

    viewModel.selectedLaboratory.subscribe { selectedLaboratory ->
      value = selectedLaboratory?.id?.value?.toString()
    }
  }.bindCustom(
    key = Data::laboratory,
    required = false,
    requiredMessage = I18n.tr("Field.Required")
  )

  val nameInput = LabodocText {
    label = "Nom"
  }.bindCustom(
    key = Data::name,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateName(it.value).leftOrNull() == null },
    validatorMessage = { Data.validateName(it.value).leftOrNull() }
  )

  val atcClassificationInput = LabodocText {
    label = "Code ATC"
  }.bindCustom(
    key = Data::atcClassificationCode,
    required = true,
    requiredMessage = I18n.tr("Field.Required")
  )

  val segmentationInput = LabodocSegmentation(medicalProfessions, null, null, null, null) {
    label = "Segmentation"
  }.bindCustom(
    key = Data::segmentation,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateSegmentation(it.data).leftOrNull() == null },
    validatorMessage = { Data.validateSegmentation(it.data).leftOrNull() }
  )

  val logoInput = LabodocUpload(label = "Logo", accept = listOf("image/*"), multiple = false) {
    subscribe { files ->
      App.scope.launch {
        logoPreview.src =
          files?.firstOrNull()?.let { getNativeFile(it)?.getContent() } ?: medicineLogoUrl?.toString()
      }
    }
  }.bind(
    Data::logo,
    required = false,
    requiredMessage = I18n.tr("Field.Required"),
    validator = null,
    validatorMessage = null
  )

  private val logoPreview = Image(src = medicineLogoUrl?.toString())

  init {
    require("./css/pages/admin/medicines/components/medicine-form.css")

    div(className = "laboratory") {
      h3("Informations du laboratoire associé : ")

      div(className = "fields") {
        div(className = "laboratory-details") {
          div(className = "laboratory-information") {
            image(null, "Logo du laboratoire").bind(viewModel.selectedLaboratory) { selectedLaboratory ->
              val logoUrl = selectedLaboratory?.logoUrl?.toString()

              src = logoUrl
              if (logoUrl != null) show() else hide()
            }

            add(laboratoryInput)

            button("", icon = "fa-solid fa-close", className = "remove") {
              onClick {
                viewModel.updateSelectedLaboratory(null)
              }
            }.bind(viewModel.selectedLaboratory) { selectedLaboratory ->
              if (selectedLaboratory != null) show() else hide()
            }
          }
        }
      }
    }

    div(className = "medicine") {
      h3("Information du médicament :")

      div(className = "fields") {
        add(nameInput)

        add(atcClassificationInput)

        add(segmentationInput)
      }

      div(className = "logo") {
        add(logoPreview)

        add(logoInput)
      }
    }
  }

  suspend fun getValidatedData(
  ): Either<Map<String, String>, ValidatedData> = either<Nel<StringPair>, ValidatedData> {
    val formData = getDataWithFileContent()

    zipOrAccumulate(
      {
        Data.validateLaboratory(formData.laboratory)
          .onLeft { laboratoryInput.validatorError = it }
          .mapLeft { Data::laboratory.name to it }
          .bind()
      },
      {
        Data.validateName(formData.name)
          .onLeft { nameInput.validatorError = it }
          .mapLeft { Data::name.name to it }
          .bind()
      },
      {
        Data.validateAtcClassificationCode(formData.atcClassificationCode)
          .onLeft { atcClassificationInput.validatorError = it }
          .mapLeft { Data::atcClassificationCode.name to it }
          .bind()
      },
      {
        Data.validateLogo(formData.logo)
          .onLeft { logoInput.validatorError = it }
          .mapLeft { Data::logo.name to it }
          .bind()
      },
      {
        Data.validateSegmentation(formData.segmentation)
          .onLeft { segmentationInput.validatorError = it }
          .mapLeft { Data::segmentation.name to it }
          .bind()
      }
    ) { laboratory, name, atcClassificationCode, logo, segmentation ->
      ValidatedData(
        laboratory = laboratory,
        name = name,
        atcClassificationCode = atcClassificationCode,
        logo = logo,
        segmentation = segmentation
      )
    }
  }.mapLeft { errors ->
    errors.toMap()
  }
}

fun Container.adminMedicineForm(
  laboratoryOptions: Set<Pair<LaboratoryId, LaboratoryName>>,
  medicalProfessions: Set<MedicalProfessionModel>,
  medicineLogoUrl: Url? = null
): AdminMedicineForm {
  val adminMedicineForm = AdminMedicineForm(
    laboratoryOptions,
    medicalProfessions,
    medicineLogoUrl
  )

  this.add(adminMedicineForm)
  return adminMedicineForm
}
