package fr.labodoc.app.data.healthprofessional.source.remote

import arrow.core.Either
import arrow.core.raise.catch
import arrow.core.raise.either
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.LaboratoriesResponse
import fr.labodoc.app.data.error.RemoteDataSourceError
import fr.labodoc.app.data.healthprofessional.model.LaboratoryModel
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId

class LaboratoriesRemoteDataSourceImpl(
  private val apiClient: HealthProfessionalApiClient
) : LaboratoriesRemoteDataSource {
  override suspend fun getLaboratories(
  ): Either<RemoteDataSourceError, Set<LaboratoryModel>> =
    either {
      catch({
        apiClient.laboratories
          .getLaboratories()
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map(LaboratoriesResponse::toModel)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun favoriteLaboratory(
    id: LaboratoryId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.laboratories
          .favoriteLaboratory(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun unfavoriteLaboratory(
    id: LaboratoryId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.laboratories
          .unfavoriteLaboratory(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }
}

private fun LaboratoriesResponse.toModel(): Set<LaboratoryModel> =
  this.laboratories
    .map { laboratory: LaboratoriesResponse.Laboratory ->
      LaboratoryModel(
        id = laboratory.id,
        name = laboratory.name,
        logoUrl = laboratory.logoUrl,
        website = laboratory.website,
        favorite = laboratory.favorite
      )
    }
    .toSet()
