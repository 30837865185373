package fr.labodoc.webapp.pages.admin.laboratories

import arrow.core.Either
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.LaboratorySummaryModel
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.navigate
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminLaboratoriesPage : SimplePanel() {
  private class ViewModel: KoinComponent {
    private val laboratoryRepository: LaboratoriesRepository by inject(named("admin"))

    private val _laboratories: ObservableValue<ApiResponse<Set<LaboratorySummaryModel>>?> by lazy {
      val observableValue: ObservableValue<ApiResponse<Set<LaboratorySummaryModel>>?> =
        ObservableValue(null)

      App.scope.launch {
        laboratoryRepository.getLaboratories()
          .also { _laboratories.setState(it) }
      }

      observableValue
    }
    val laboratories: ObservableState<ApiResponse<Set<LaboratorySummaryModel>>?>
      get() = _laboratories
  }

  private class Card(laboratory: LaboratorySummaryModel) : SimplePanel(className = "card") {
    private class ViewModel(private val laboratory: LaboratorySummaryModel): KoinComponent {
      private val laboratoryRepository: LaboratoriesRepository by inject(named("admin"))

      private val _laboratoryActivated: ObservableValue<Boolean> = ObservableValue(laboratory.activated)
      val laboratoryActivated: ObservableState<Boolean>
        get() = _laboratoryActivated

      var activateDialogContainer = SimplePanel {}
      var activateDialogContent = LabodocDialog(SimplePanel {}, "")
      fun openActivateDialog() {
        activateDialogContainer.add(activateDialogContent)
        activateDialogContent.showModal()
      }

      fun closeActivateDialog() {
        activateDialogContent.closeModal()
      }


      fun activateLaboratory() =
        App.scope.launch {
          laboratoryRepository.activateLaboratory(laboratory.id)
            .onRight { _laboratoryActivated.setState(true); activateDialogContent.closeModal() }
        }

      fun deactivateLaboratory() =
        App.scope.launch {
          laboratoryRepository.deactivateLaboratory(laboratory.id)
            .onRight { _laboratoryActivated.setState(false); activateDialogContent.closeModal() }
        }
    }

    private val viewModel: ViewModel = ViewModel(laboratory)

    init {
      viewModel.activateDialogContainer = div {}

      viewModel.activateDialogContent = LabodocDialog(SimplePanel() {
        div(className = "dialog-inner").bind(viewModel.laboratoryActivated) {
          h3 {
            content = if (viewModel.laboratoryActivated.getState()) {
              "Voulez vous vraiment désactiver le laboratoire ${laboratory.name.value} ? "
            } else {
              "Voulez vous vraiment activer le laboratoire ${laboratory.name.value} ? "
            }
          }
          div {
            if (viewModel.laboratoryActivated.getState()) {
              labodocButton("") {
                text = "Désactiver";
                addCssClass("deactivate-btn")
                onClick {
                  viewModel.deactivateLaboratory(); viewModel.closeActivateDialog(); Toast.success("Laboratoire désactivé avec succés")
                }
              }
            } else {
              labodocButton("") {
                text = "Activer";
                addCssClass("activate-btn")
                onClick {
                  viewModel.activateLaboratory(); viewModel.closeActivateDialog(); Toast.success("Laboratoire activé avec succés")
                }
              }
            }
            labodocButton("") {
              text = "Annuler";
              addCssClass("cancel-btn")
              onClick {
                viewModel.closeActivateDialog()
              }
            }
          }
        }
      }, "Activation d'un laboratoire")

      header {
        if (laboratory.logoUrl != null) {
          image(laboratory.logoUrl.toString()){
            onClick { App.routing.navigate(Page.AdminBackOfficeLaboratorySheet(laboratory.id)) }
          }
        }
        navigoLink(
          laboratory.name.value,
          Page.AdminBackOfficeLaboratorySheet(laboratory.id),
          className = "name"
        )
      }

      hr()

      div(className = "actions") {
        navigoLink("", Page.AdminBackOfficeLaboratoryUpdate(laboratory.id)) {
          labodocButton("Mettre à jour", className = "edit")
        }
        labodocButton("").bind(viewModel.laboratoryActivated) { activated ->
          removeEventListeners()
          when (activated) {
            true -> {
              text = "Activé"
              icon = "fa-solid fa-check"
              removeCssClass("activate")
              addCssClass("deactivate")
              onClick { viewModel.openActivateDialog() }
            }

            false -> {
              text ="Désactivé"
              icon = "fa-solid fa-ban"
              removeCssClass("deactivate")
              addCssClass("activate")
              onClick { viewModel.openActivateDialog() }
            }
          }
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModel()

  init {
    id = "page-admin-laboratories"
    require("./css/pages/admin/laboratories/laboratories.css")


    div(className = "page-width") {
      header {
        h1("Liste des laboratoires")

        navigoLink("", Page.AdminBackOfficeLaboratoryCreate()) {
          labodocButton("Ajouter un laboratoire", "fa-solid fa-plus")
        }
      }

      div(className = "cards").bind(viewModel.laboratories) { laboratories ->
        when (laboratories) {
          is Either.Left -> {
            Toast.danger("Impossible de récupérer la liste des laboratoires")
            window.history.back()
          }

          is Either.Right -> {
            laboratories.value.forEach { add(Card(it)) }
          }

          null -> labodocSpinner()
        }
      }
    }
  }
}

fun Container.adminLaboratoriesPage(): AdminLaboratoriesPage {
  val adminLaboratoriesPage = AdminLaboratoriesPage()
  this.add(adminLaboratoriesPage)
  return adminLaboratoriesPage
}
