package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class UpdateMedicineRequest(
  val laboratory: LaboratoryIdAsString?,
  val name: MedicineNameAsString,
  val atcClassificationCode: AtcClassificationCodeAsString,
  val segmentation: Segmentation
) {
  @Serializable
  data class Segmentation(
    val medicalProfessions: Set<MedicalProfessionIdAsString>?,
    val medicalSpecialities: Set<MedicalSpecialityIdAsString>?,
    val medicalInterests: Set<MedicalInterestIdAsString>?
  )
}
