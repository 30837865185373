package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.RPPSNumber
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object RPPSNumberAsStringSerializer : KSerializer<RPPSNumber> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("RPPSNumberAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(rppsNumber: String): Either<Errors.RPPSNumber.Invalid, RPPSNumber> = RPPSNumber(rppsNumber)

  override fun deserialize(decoder: Decoder): RPPSNumber {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(rppsNumber: RPPSNumber): String = rppsNumber.value

  override fun serialize(encoder: Encoder, value: RPPSNumber) {
    return encoder.encodeString(serialize(value))
  }
}

typealias RPPSNumberAsString = @Serializable(RPPSNumberAsStringSerializer::class) RPPSNumber
