package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class LoginResponse(
  val token: String,
  val user: User
) {
  @Serializable
  sealed class User {
    abstract val emailAddress: EmailAddressAsString
    abstract val lastLoginDate: Instant?

    @Serializable
    data class HealthProfessional(
      override val emailAddress: EmailAddressAsString,
      override val lastLoginDate: Instant?,
      val rppsNumber: RPPSNumberAsString?,
      val firstName: FirstNameAsString,
      val lastName: LastNameAsString,
      val department: Department,
      val medicalProfession: MedicalProfession,
      val medicalSpeciality: MedicalSpeciality,
      val medicalInterests: Set<MedicalInterest>,
      val canHaveMedicalInterests: Boolean,
      val professionalCategory: ProfessionalCategory,
      val professionalStatus: ProfessionalStatus,
      val dailyNotificationEnabled: Boolean,
      val weeklyNotificaitonEnabled: Boolean
    ) : User() {
      @Serializable
      data class Department(
        val code: DepartmentCodeAsString,
        val name: DepartmentNameAsString
      )

      @Serializable
      data class MedicalProfession(
        val id: MedicalProfessionIdAsString,
        val name: MedicalProfessionNameAsString
      )

      @Serializable
      data class MedicalSpeciality(
        val id: MedicalSpecialityIdAsString,
        val name: MedicalSpecialityNameAsString,
        val partnership: Partnership?
      ) {
        @Serializable
        data class Partnership(
          val name: PartnershipNameAsString,
          val logo: UrlAsString,
          val website: PartnershipWebsiteAsString
        )
      }

      @Serializable
      data class MedicalInterest(
        val id: MedicalInterestIdAsString,
        val name: MedicalInterestNameAsString
      )

      @Serializable
      data class ProfessionalCategory(
        val code: ProfessionalCategoryCodeAsString,
        val name: ProfessionalCategoryNameAsString
      )

      @Serializable
      data class ProfessionalStatus(
        val code: ProfessionalStatusCodeAsString,
        val name: ProfessionalStatusNameAsString
      )
    }

    @Serializable
    data class Admin(
      override val emailAddress: EmailAddressAsString,
      override val lastLoginDate: Instant?
    ) : User()
  }
}
