package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.PartnershipNameAsString
import fr.labodoc.api.payloads.serializers.PartnershipWebsiteAsString
import kotlinx.serialization.Serializable

@Serializable
data class UpdatePartnershipRequest(
  val name: PartnershipNameAsString,
  val website: PartnershipWebsiteAsString
)
