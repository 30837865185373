package fr.labodoc.webapp.pages.admin.healthDirectory

import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.HealthDirectoryHealthProfessionalResponse
import fr.labodoc.api.payloads.responses.PaginatedEntities
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.DepartmentsRepository
import fr.labodoc.app.data.admin.repository.HealthDirectoryRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.webapp.App
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

sealed interface AdminHealthDirectoryPageViewModel {
  data class PageData(
    val healthRepositoryProfessions: Map<ProfessionCode, ProfessionName>,
    val healthRepositoryProfessionalCategories: Map<ProfessionalCategoryCode, ProfessionalCategoryName>,
    val healthRepositoryProfessionalStatus: Map<ProfessionalStatusCode, ProfessionalStatusName>,
    val healthRepositoryPharmacistTableSectionCodes: Map<PharmacistTableSection.Code, PharmacistTableSection.Label>,
    val healthRepositoryExpertises: Map<ExpertiseCode, ExpertiseName>,
    val medicalCardTypes: Map<MedicalCardTypeCode, MedicalCardTypeLabel>,
    val labodocMedicalProfessions: Map<MedicalProfessionId, MedicalProfessionModel>,
    val departments: Map<DepartmentCode, DepartmentName>
  )

  data class RequestParameters(
    val pageSize: Int,
    val pageNumber: Int,
    val nationalIdentifier: NationalIdentifier? = null,
    val name: String? = null,
    val healthRepositoryProfessionsCodes: Set<ProfessionCode>? = null,
    val healthRepositoryProfessionalCategoryCodes: Set<ProfessionalCategoryCode>? = null,
    val healthRepositoryProfessionalStatusCodes: Set<ProfessionalStatusCode>? = null,
    val healthRepositoryPharmacistTableSectionCodes: Set<PharmacistTableSection.Code>? = null,
    val healthRepositoryExpertiseCodes: Set<ExpertiseCode>? = null,
    val medicalCardNumber: MedicalCardNumber? = null,
    val medicalCardTypeCodes: Set<MedicalCardTypeCode?>? = null,
    val labodocMedicalProfessionsIds: Set<MedicalProfessionId>? = null,
    val labodocMedicalSpecialitiesIds: Set<MedicalSpecialityId>? = null,
    val departments: Set<DepartmentCode>? = null,
  )

  val pageDataResponse: ObservableState<ApiResponse<PageData>?>

  val requestParameters: ObservableState<RequestParameters>

  val healthProfessionalsResponse: ObservableState<ApiResponse<PaginatedEntities<HealthDirectoryHealthProfessionalResponse>>?>

  fun changeFilters(filters: FormFilters.Filters)

  fun changePage(pageNumber: Int)
}

class AdminHealthDirectoryPageViewModelImpl : AdminHealthDirectoryPageViewModel, KoinComponent {
  private val healthDirectoryRepository: HealthDirectoryRepository by inject(named("admin"))
  private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))
  private val departmentsRepository: DepartmentsRepository by inject(named("admin"))

  override val pageDataResponse: ObservableValue<ApiResponse<AdminHealthDirectoryPageViewModel.PageData>?> by lazy {
    val observableValue: ObservableValue<ApiResponse<AdminHealthDirectoryPageViewModel.PageData>?> =
      ObservableValue(null)

    App.scope.launch {
      either {
        parZip(
          { healthDirectoryRepository.getProfessions().bind() },
          { healthDirectoryRepository.getExpertises().bind() },
          { medicalProfessionsRepository.getMedicalProfessions().bind() },
          { departmentsRepository.getDepartments().bind() },
        ) { healthRepositoryProfessions, healthRepositoryExpertises, labodocMedicalProfessions, departments ->
          AdminHealthDirectoryPageViewModel.PageData(
            healthRepositoryProfessions.associate { it.code to it.name },
            mapOf(
              ProfessionalCategoryCode("C") to ProfessionalCategoryName("Civil"),
              ProfessionalCategoryCode("M") to ProfessionalCategoryName("Militaire"),
              ProfessionalCategoryCode("E") to ProfessionalCategoryName("Étudiant")
            ),
            mapOf(
              ProfessionalStatusCode("S") to ProfessionalStatusName("Salarié"),
              ProfessionalStatusCode("L") to ProfessionalStatusName("Libéral"),
              ProfessionalStatusCode("B") to ProfessionalStatusName("Bénévole")
            ),
            mapOf(),
            healthRepositoryExpertises.associate { it.code to it.name },
            mapOf(
              MedicalCardTypeCode("CPS") to MedicalCardTypeLabel("Carte de Professionnel de Santé"),
              MedicalCardTypeCode("CPF") to MedicalCardTypeLabel("Carte de Professionnel de Santé en Formation")
            ),
            labodocMedicalProfessions.associateBy { it.id },
            departments.associate { it.code to it.name }
          )
        }
      }.also { pageDataResponse.setState(it) }
    }

    observableValue
  }

  override val requestParameters: ObservableValue<AdminHealthDirectoryPageViewModel.RequestParameters> =
    ObservableValue(AdminHealthDirectoryPageViewModel.RequestParameters(10, 1))

  override val healthProfessionalsResponse: ObservableValue<ApiResponse<PaginatedEntities<HealthDirectoryHealthProfessionalResponse>>?> =
    ObservableValue(null)

  init {
    requestParameters.subscribe { requestParameters ->
      App.scope.launch {
        healthProfessionalsResponse.setState(null)
        healthDirectoryRepository.getHealthProfessionals(
          requestParameters.pageSize,
          requestParameters.pageNumber,
          requestParameters.nationalIdentifier,
          requestParameters.name,
          requestParameters.healthRepositoryProfessionsCodes,
          requestParameters.healthRepositoryProfessionalCategoryCodes,
          requestParameters.healthRepositoryProfessionalStatusCodes,
          requestParameters.healthRepositoryPharmacistTableSectionCodes,
          requestParameters.healthRepositoryExpertiseCodes,
          requestParameters.medicalCardNumber,
          requestParameters.medicalCardTypeCodes,
          requestParameters.labodocMedicalProfessionsIds,
          requestParameters.labodocMedicalSpecialitiesIds,
          requestParameters.departments
        ).also { healthProfessionalsResponse.setState(it) }
      }
    }
  }

  override fun changeFilters(filters: FormFilters.Filters) {
    requestParameters.setState(
      requestParameters.getState().copy(
        nationalIdentifier = filters.nationalIdentifier,
        name = filters.name,
        healthRepositoryProfessionsCodes = filters.healthRepositoryProfessionCodes,
        healthRepositoryProfessionalCategoryCodes = filters.healthRepositoryProfessionalCategoryCodes,
        healthRepositoryProfessionalStatusCodes = filters.healthRepositoryProfessionalStatusCodes,
        healthRepositoryPharmacistTableSectionCodes = filters.healthRepositoryPharmacistTableSectionCodes,
        healthRepositoryExpertiseCodes = filters.healthRepositoryExpertiseCodes,
        medicalCardNumber = filters.medicalCardNumber,
        medicalCardTypeCodes = filters.medicalCardTypeCodes,
        labodocMedicalProfessionsIds = filters.labodocMedicalProfessionIds,
        labodocMedicalSpecialitiesIds = filters.labodocMedicalSpecialitiesIds,
        departments = filters.departmentCodes
      )
    )
  }

  override fun changePage(pageNumber: Int) {
    requestParameters.setState(
      requestParameters.getState().copy(pageNumber = pageNumber)
    )
  }
}
