package fr.labodoc.webapp.components

import io.kvision.core.Container
import io.kvision.panel.SimplePanel
import io.kvision.snabbdom.VNode

class SwiperContainer(
  className: String? = null,
  init: (SwiperContainer.() -> Unit)? = null
) : SimplePanel(className) {

  class SwiperSlide(className: String? = null, init: (SwiperSlide.() -> Unit)? = null) : SimplePanel(className) {
    init {
      init?.invoke(this)
    }

    override fun render(): VNode {
      return render("swiper-slide", childrenVNodes())
    }
  }

  init {
    init?.invoke(this)
  }

  override fun render(): VNode {
    return render("swiper-container", childrenVNodes())
  }

  fun slide(className: String? = null, slideContent: Container.() -> Unit) {
    val slide = SwiperSlide(className) {
      slideContent(this)
    }
    add(slide)
  }
}

fun Container.swiperContainer(
  className: String? = null,
  init: (SwiperContainer.() -> Unit)? = null
): SwiperContainer {
  val swiperContainer = SwiperContainer(className, init)
  this.add(swiperContainer)
  return swiperContainer
}
