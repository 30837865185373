package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import kotlinx.serialization.Serializable

@Serializable
data class MedicalProfessionResponse(
  val id: MedicalProfessionIdAsString,
  val name: MedicalProfessionNameAsString,
  val medicalSpecialities: Set<MedicalSpeciality>
) {
  @Serializable
  data class MedicalSpeciality(
    val id: MedicalSpecialityIdAsString,
    val name: MedicalSpecialityNameAsString,
    val partnership: PartnershipIdAsString?,
    val medicalInterests: Set<MedicalInterest>
  ) {
    @Serializable
    data class MedicalInterest(
      val id: MedicalInterestIdAsString,
      val name: MedicalInterestNameAsString
    )
  }
}
