package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.*
import io.kvision.html.*
import io.kvision.panel.Root
import io.kvision.panel.SimplePanel
import io.kvision.panel.Root.Companion.addModal
import io.kvision.panel.Root.Companion.removeModal

class LabodocPopup(
  closeButton: Boolean = false,
  icon: String? = null,
  image: ResString? = null,
  className: String? = null,
  content: (Container.(LabodocPopup) -> Unit),
  beforeClose: (() -> Unit)? = null,
  init: (LabodocPopup.() -> Unit)? = null
) : SimplePanel("labodoc-popup") {
  override var parent: Container? = Root.getFirstRoot()

  override fun clearParent() {
    this.parent = null
  }

  override fun getRoot(): Root? {
    return this.parent?.getRoot()
  }

  override fun dispose() {
    super.dispose()
    removeModal(this)
  }

  init {
    this.hide()

    require("./css/components/labodoc-popup.css")

    className?.let { addCssClass(it) }

    div(className = "labodoc-popup-container") {
      header {
        if (image != null) {
          this.background = Background(
            image = image,
            repeat = BgRepeat.NOREPEAT,
            size = BgSize.COVER
          )
          this@LabodocPopup.addCssClass("with-image")
        }

        if (closeButton) {
          span("&#10006;", rich = true, className = "close-button") {
            onClick {
              beforeClose?.invoke()
              this@LabodocPopup.hide()
            }
          }
          this@LabodocPopup.addCssClass("with-close-button")
        }

        if (icon != null) {
          this@LabodocPopup.addCssClass("with-icon")
          i(className = "icon $icon")
        }
      }

      val contentContainer = div(className = "labodoc-popup-container-content")
      content.invoke(contentContainer, this@LabodocPopup)
    }

    addModal(this)
    init?.invoke(this)
  }
}
