package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.PartnershipIdAsString
import fr.labodoc.api.payloads.serializers.PartnershipNameAsString
import fr.labodoc.api.payloads.serializers.PartnershipWebsiteAsString
import fr.labodoc.api.payloads.serializers.UrlAsString
import kotlinx.serialization.Serializable

@Serializable
data class PartnershipResponse(
  val id: PartnershipIdAsString,
  val name: PartnershipNameAsString,
  val logo: UrlAsString,
  val website: PartnershipWebsiteAsString
)

