package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateUniversityHospitalRequest
import fr.labodoc.api.payloads.requests.UpdateUniversityHospitalRequest
import fr.labodoc.api.payloads.responses.UniversityHospitalIdResponse
import fr.labodoc.api.payloads.responses.UniversityHospitalResponse
import fr.labodoc.app.data.admin.model.UniversityHospitalModel
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalName

class UniversityHospitalsRepositoryImpl(
  private val apiClient: AdminApiClient
) : UniversityHospitalsRepository {
  override suspend fun getUniversityHospitals(): ApiResponse<Set<UniversityHospitalModel>> =
    apiClient.universityHospitals
      .getUniversityHospitals()
      .map { universityHospitals: Set<UniversityHospitalResponse> ->
        universityHospitals
          .map { universityHospital ->
            UniversityHospitalModel(
              id = universityHospital.id,
              name = universityHospital.name,
              department = UniversityHospitalModel.Department(
                code = universityHospital.department.code,
                name = universityHospital.department.name
              )
            )
          }
          .toSet()
      }

  override suspend fun createUniversityHospital(
    name: UniversityHospitalName,
    departmentCode: DepartmentCode
  ): ApiResponse<UniversityHospitalId> =
    apiClient.universityHospitals
      .createUniversityHospital(
        payload = CreateUniversityHospitalRequest(
          name = name,
          departmentCode = departmentCode
        )
      )
      .map { universityHospitalIdResponse: UniversityHospitalIdResponse ->
        universityHospitalIdResponse.id
      }

  override suspend fun updateUniversityHospital(
    id: UniversityHospitalId,
    name: UniversityHospitalName,
    departmentCode: DepartmentCode
  ): ApiResponse<Unit> =
    apiClient.universityHospitals
      .updateUniversityHospital(
        id = id,
        payload = UpdateUniversityHospitalRequest(
          name = name,
          departmentCode = departmentCode
        )
      )

  override suspend fun deleteUniversityHospital(id: UniversityHospitalId): ApiResponse<Unit> =
    apiClient.universityHospitals
      .deleteUniversityHospital(id)
}
