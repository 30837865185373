package fr.labodoc.webapp.pages.admin.users.section

import fr.labodoc.app.data.admin.model.HealthProfessionalUserModel
import fr.labodoc.app.data.admin.repository.UsersRepository
import fr.labodoc.app.utils.datetimeFormat
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class UsersList : SimplePanel(className = "users-list") {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val users: ObservableList<Pair<Boolean, HealthProfessionalUserModel>>,
        val successMessage: ObservableState<String?>,
        val errorMessage: ObservableState<String?>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun refresh()

    fun enableUser(user: HealthProfessionalUserModel)

    fun disableUser(user: HealthProfessionalUserModel)

    fun deleteUser(user: HealthProfessionalUserModel)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val usersRepository: UsersRepository by inject(named("admin"))

    private val users: ObservableListWrapper<Pair<Boolean, HealthProfessionalUserModel>> =
      ObservableListWrapper()

    private val successMessage: ObservableValue<String?> =
      ObservableValue(null)

    private val errorMessage: ObservableValue<String?> =
      ObservableValue(null)

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      refresh()

      ObservableValue(ViewModel.UiState.Loading)
    }

    override fun refresh() {
      App.scope.launch {
        uiState.value = ViewModel.UiState.Loading
        users.clear()
        successMessage.value = null
        errorMessage.value = null

        val newUiState = usersRepository
          .getUsers()
          .fold(
            {
              ViewModel.UiState.Error
            },
            { remoteUsers ->

              users.addAll(
                remoteUsers
                  .filterIsInstance<HealthProfessionalUserModel>()
                  .sortedByDescending { it.lastLoginDate }
                  .map { false to it }
              )

              ViewModel.UiState.Loaded(
                users = users,
                successMessage = successMessage,
                errorMessage = errorMessage
              )
            }
          )

        uiState.value = newUiState
      }
    }

    override fun enableUser(user: HealthProfessionalUserModel) {
      App.scope.launch {
        users
          .find { it.second.id == user.id }
          ?.takeIf { !it.first }
          ?.let { users.indexOf(it) }
          ?.let { index ->
            users[index] = true to user

            usersRepository.enableUser(user.id)
              .onLeft {
                errorMessage.value = "Impossible d'activer l'utilisateur"
                users[index] = false to user
              }
              .onRight {
                successMessage.value = "Utilisateur activé"
                users[index] = false to user.copy(disabled = false)
              }
          }
      }
    }

    override fun disableUser(user: HealthProfessionalUserModel) {
      App.scope.launch {
        users
          .find { it.second.id == user.id }
          ?.takeIf { !it.first }
          ?.let { users.indexOf(it) }
          ?.let { index ->
            users[index] = true to user

            usersRepository.disableUser(user.id)
              .onLeft {
                errorMessage.value = "Impossible de désactiver l'utilisateur"
                users[index] = false to user
              }
              .onRight {
                successMessage.value = "Utilisateur désactivé"
                users[index] = false to user.copy(disabled = true)
              }
          }
      }
    }

    override fun deleteUser(user: HealthProfessionalUserModel) {
      App.scope.launch {
        users
          .find { it.second.id == user.id }
          ?.takeIf { !it.first }
          ?.let { users.indexOf(it) }
          ?.let { index ->
            users[index] = true to user

            usersRepository.deleteUser(user.id)
              .onLeft {
                errorMessage.value = "Impossible de supprimer l'utilisateur"
                users[index] = false to user
              }
              .onRight {
                successMessage.value = "Utilisateur supprimé"
                users.removeAt(index)
              }
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    this.bind(viewModel.uiState) { uiState ->
      when (uiState) {
        ViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        ViewModel.UiState.Error -> {
          p("Impossible de récupérer la liste des utilisateurs")
        }

        is ViewModel.UiState.Loaded -> {
          uiState.successMessage.subscribe { successMessage ->
            successMessage?.let { Toast.success(it) }
          }

          uiState.errorMessage.subscribe { errorMessage ->
            errorMessage?.let { Toast.danger(it) }
          }

          table {
            thead {
              tr {
                th("RPPS")
                th("Email")
                th("Nom")
                th("Département")
                th("Type de carte")
                th("Profession")
                th("Spécialité")
                th("Catégorie")
                th("Statut")
                th("Dernière connexion")
                th("Actions")
              }
            }
            tbody().bindEach(uiState.users) { (processing, user) ->
              tr {
                td(user.rppsNumber?.value)
                td(user.emailAddress.value)
                td("${user.firstName.value} ${user.lastName.value}")
                td(user.department.code.value)
                td(user.medicalCardType?.value?.takeIf { it != "NONE" })
                td(user.medicalProfession.name.value)
                td(user.medicalSpeciality.name.value)
                td(user.professionalCategory.name.value)
                td(user.professionalStatus.name.value)
                td(user.lastLoginDate?.datetimeFormat)
                td(className = "actions") {
                  if (!processing) {
                    labodocButton("", className = "action") {
                      if (user.disabled) {
                        addCssClass("enable")
                        text = "Activer"
                        icon = "fa-solid fa-check"

                        onClick {
                          LabodocPopup(
                            closeButton = true,
                            icon = "fa-solid fa-warning",
                            image = null,
                            className = "admin-user-enable-confirmation-modal",
                            beforeClose = null,
                            content = { modal ->
                              p(className = "title") {
                                content = "Êtes-vous sûr de vouloir activer l'utilisateur \"${user.firstName.value} ${user.lastName.value}\" ?"
                              }

                              div(className = "choices") {
                                labodocButton("Activer", icon = "fa-solid fa-check", className = "enable") {
                                  onClick {
                                    modal.hide()
                                    viewModel.enableUser(user)
                                  }
                                }

                                labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
                                  onClick {
                                    modal.hide()
                                  }
                                }
                              }
                            }
                          ).show()
                        }
                      } else {
                        addCssClass("disable")
                        text = "Désactiver"
                        icon = "fa-solid fa-ban"

                        onClick {
                          LabodocPopup(
                            closeButton = true,
                            icon = "fa-solid fa-warning",
                            image = null,
                            className = "admin-user-disable-confirmation-modal",
                            beforeClose = null,
                            content = { modal ->
                              p(className = "title") {
                                content = "Êtes-vous sûr de vouloir désactiver l'utilisateur \"${user.firstName.value} ${user.lastName.value}\" ?"
                              }

                              div(className = "choices") {
                                labodocButton("Désactiver", icon = "fa-solid fa-check", className = "disable") {
                                  onClick {
                                    modal.hide()
                                    viewModel.disableUser(user)
                                  }
                                }

                                labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
                                  onClick {
                                    modal.hide()
                                  }
                                }
                              }
                            }
                          ).show()
                        }
                      }
                    }

                    labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "action delete") {
                      onClick {
                        LabodocPopup(
                          closeButton = true,
                          icon = "fa-solid fa-warning",
                          image = null,
                          className = "admin-user-delete-confirmation-modal",
                          beforeClose = null,
                          content = { modal ->
                            p(className = "title") {
                              content = "Êtes-vous sûr de vouloir supprimer l'utilisateur \"${user.firstName.value} ${user.lastName.value}\" ?"
                            }

                            div(className = "choices") {
                              labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "delete") {
                                onClick {
                                  modal.hide()
                                  viewModel.deleteUser(user)
                                }
                              }

                              labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
                                onClick {
                                  modal.hide()
                                }
                              }
                            }
                          }
                        ).show()
                      }
                    }
                  } else {
                    icon("fa fa-spinner fa-spin")
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.usersList(
): UsersList {
  val usersList = UsersList(
  )

  this.add(usersList)
  return usersList
}

