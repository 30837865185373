package fr.labodoc.api.resources

import io.ktor.resources.*

@Resource("segmentation-templates")
class SegmentationTemplates(
  val filterName: String? = null
) {
  @Resource("{segmentationTemplateId}")
  class SegmentationTemplate(
    val segmentationTemplateId: String,
    val segmentationTemplate: SegmentationTemplates = SegmentationTemplates()
  )
}
