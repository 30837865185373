package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.common.FirstName
import fr.labodoc.domain.labodoc.common.LastName
import fr.labodoc.domain.labodoc.department.DepartmentCode

data class HealthDirectoryHealthProfessionalModel(
  val nationalIdentifier: NationalIdentifier,
  val medicalCard: MedicalCard,
  val practices: Set<Practice>
) {
  data class MedicalCard(
    val number: MedicalCardNumber,
    val typeCode: MedicalCardTypeCode,
    val typeLabel: MedicalCardTypeLabel
  )

  data class Practice(
    val practiceFirstName: FirstName,
    val practiceLastName: LastName,
    val professionCode: ProfessionCode,
    val professionName: ProfessionName,
    val professionalCategoryCode: ProfessionalCategoryCode,
    val professionalCategoryName: ProfessionalCategoryName,
    val expertises: Set<Expertise>,
    val situations: Set<Situation>
  ) {
    data class Situation(
      val professionalStatusCode: ProfessionalStatusCode,
      val professionalStatusName: ProfessionalStatusName,
      val pharmacistTableSectionCode: PharmacistTableSection.Code?,
      val facility: Facility?
    ) {
      data class Facility(
        val name: String?,
        val officeCedex: String,
        val departmentCode: DepartmentCode
      )
    }

    data class Expertise(
      val code: ExpertiseCode,
      val name: ExpertiseName
    )
  }
}
