package fr.labodoc.webapp.layouts

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.panel.SimplePanel

class HealthProfessionalLayout(init: (HealthProfessionalLayout.() -> Unit)? = null) : SimplePanel() {
  init {
    id = "layout-health-professional"
    require("./css/layouts/health-professional.css")

    add(LabodocHeader())
    init?.invoke(this)
    add(LabodocFooter())
  }
}

fun Container.healthProfessionalLayout(init: (HealthProfessionalLayout.() -> Unit)? = null): HealthProfessionalLayout {
  val healthProfessionalLayout = HealthProfessionalLayout(init)
  this.add(healthProfessionalLayout)
  return healthProfessionalLayout
}
