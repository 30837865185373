package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicalProfessionIdAsStringSerializer : KSerializer<MedicalProfessionId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalProfessionIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.MedicalProfession.Id.Invalid, MedicalProfessionId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.MedicalProfession.Id.Invalid.Format }
      .map { MedicalProfessionId(it) }

  override fun deserialize(decoder: Decoder): MedicalProfessionId =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalProfessionId: MedicalProfessionId): String =
    UuidAsStringSerializer.serialize(medicalProfessionId.value)

  override fun serialize(encoder: Encoder, value: MedicalProfessionId) =
    encoder.encodeString(serialize(value))
}

typealias MedicalProfessionIdAsString = @Serializable(MedicalProfessionIdAsStringSerializer::class) MedicalProfessionId

object MedicalProfessionNameAsStringSerializer : KSerializer<MedicalProfessionName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalProfessionNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.MedicalProfession.Name.Invalid, MedicalProfessionName> =
    MedicalProfessionName(name)

  override fun deserialize(decoder: Decoder): MedicalProfessionName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalProfessionName: MedicalProfessionName): String =
    medicalProfessionName.value

  override fun serialize(encoder: Encoder, value: MedicalProfessionName) =
    encoder.encodeString(serialize(value))
}

typealias MedicalProfessionNameAsString = @Serializable(MedicalProfessionNameAsStringSerializer::class) MedicalProfessionName
