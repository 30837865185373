package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.AtcCategoryCodeAsString
import fr.labodoc.api.payloads.serializers.AtcCategoryNameAsString
import fr.labodoc.api.payloads.serializers.AtcCodeAsString
import fr.labodoc.api.payloads.serializers.AtcNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class AtcCategoryWithCodesResponse(
  val code: AtcCategoryCodeAsString,
  val name: AtcCategoryNameAsString,
  val codes: Set<Atc>
) {
  @Serializable
  data class Atc(
    val code: AtcCodeAsString,
    val name: AtcNameAsString
  )
}
