package fr.labodoc.webapp.pages.aboutUs

import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import org.koin.core.component.KoinComponent
import io.kvision.i18n.I18n.tr as translation

class AboutUsPage() : SimplePanel(), KoinComponent {

  init {
    id = "page-about-us"
    require("./css/pages/aboutUs/about-us.css")
    add(LabodocHeader())
    div(className = "labodoc-background-beige d-flex flex-row align-center pb-xs-6") {
      id = "page-about-header"

      div(className = "labodoc-background-beige col-xl-6 col-lg-6 col-12") {
        id = "page-about-presentation"

        div(className = "page-width d-flex flex-column pt-5 pt-lg-10 pt-xl-10 align-xs-center") {
          id = "page-about-presentation-content"

          h1 (className = "mb-8 text-xs-center mb-xs-4"){
            content = "A propos de Labodoc"
          }

          p (className= "mb-8 mb-xs-4") {
            content = "Née de l'expertise et de la passion d'une équipe à la croisée du médical et du digital, LaboDoc a pour objectif d’apporter une réponse pragmatique au besoin d'information permanent et grandissant qui rythme le quotidien des professionnels de santé."
          }
        }
      }

      div (className = "col-xl-3 col-lg-3 hidden-sm-and-down") {
        div(className = "page-width") {
          image(require("./img/page-about-header.png"))
        }
      }
    }

    div(className = "labodoc-background-white py-lg-14 py-xl-16") {
      id = "page-about-pros"

      div(className = "page-width mx-lg-14 mx-xl-16 hidden-xs hidden-sm") {
        h1 {
          content = "Nos engagements envers la transparence et l'indépendance"
        }
        h4 {
          content = "Chez LaboDoc, nous accordons une importance primordiale à la fiabilité des informations qui vous sont présentées. Tous les documents respectent scrupuleusement les dispositions du Code de la Santé Publique, incluant un contrôle par l’ANSM avant leur diffusion."
        }

        div(className = "d-flex justify-center hidden-sm-and-down") {
          div(className = "bubble col-4 labodoc-background-white") {
            div (className = "d-flex align-center justify-center") {
              div (className = "pros-point-icon mr-3") {  }
              h2 {
                content = "Indépendance"
              }
            }

            div (className = "d-flex align-center justify-center") {
              p {
                content =
                  "Nous n’éditons aucun contenu et n'avons pas de liens juridiques ou capitalistiques avec l’industrie pharmaceutique."
              }
            }
          }

          div(className = "bubble col-4 labodoc-background-white") {
            div (className = "d-flex align-center justify-center") {
              div (className = "pros-point-icon mr-3") {  }
              h2 {
                content = "Neutralité"
              }
            }

            div (className = "d-flex align-center justify-center") {
              p {
                content = "Nous ne sélectionnons pas nos partenaires et agissons en toute équité et transparence."
              }
            }
          }

          div(className = "bubble col-4 labodoc-background-white") {
            div (className = "d-flex align-center justify-center") {
              div (className = "pros-point-icon mr-3") {  }
              h2 {
                content = "Diversité de l'information"
              }
            }

            div (className = "d-flex align-center justify-center") {
              p {
                content =
                  "LaboDoc permet d'accéder à des ressources complémentaires telles que celles de la HAS, de la Base de données publique des médicaments et des Sociétés Savantes."
              }
            }
          }
        }
      }

      div(className = "mobile swiper") {
        swiperContainer(className = "swiper-wrapper mb-6") {
          setAttribute("slides-per-view", "auto")
          setAttribute("centered-slides", "true")
          setAttribute("pagination", "true")
          setAttribute("pagination-dynamic-bullets", "true")

          slide(className = "bubble labodoc-background-white swiper-slide") {
            div (className = "d-flex align-center justify-center") {
              div (className = "pros-point-icon mr-3") {  }
              h2 {
                content = "Indépendence"
              }
            }
            div (className = "d-flex align-center justify-center") {
              p {
                content =
                  "Nous n’éditons aucun contenu et n'avons pas de liens juridiques ou capitalistiques avec l’industrie pharmaceutique."
              }
            }
          }

          slide(className = "bubble labodoc-background-white") {
            div (className = "d-flex align-center justify-center") {
              div (className = "pros-point-icon mr-3") {  }
              h2 {
                content = "Neutralité"
              }
            }

            div (className = "d-flex align-center justify-center") {
              p {
                content = "Nous ne sélectionnons pas nos partenaires et agissons en toute équité et transparence."
              }
            }
          }

          slide(className = "bubble labodoc-background-white swiper-slide") {
            div (className = "d-flex align-center justify-center") {
              div (className = "pros-point-icon mr-3") {  }
              h2 {
                content = "Diversité de l'information"
              }
            }
            div (className = "d-flex align-center justify-center") {
              p {
                content =
                  "LaboDoc permet d'accéder à des ressources complémentaires telles que celles de la HAS, l'ANSM et des Sociétés Savantes."
              }
            }
          }
        }
      }
    }

    div(className = "labodoc-background-light-blue") {
      id = "page-about-features"

      div(className = "page-width") {
        div (className = "order-md-last d-flex justify-center align-center") {
          image(require("./img/page-about-doctor.png"))
        }
        div {
          h1 {
            content = "Pourquoi choisir LaboDoc ?"
          }
          hr (className = "mb-xl-10 mb-lg-8") {}

          span (className = "mt-xl-10 mt-lg-8") {

            b (className = "mb-0 pb-0") { content = "Simplicité et Efficacité :" }
            p { content = "Notre plateforme est conçue pour faciliter la veille informationnelle des professionnels de santé grâce à une interface intuitive pensée avec eux. Deux clics suffisent pour consulter les communications sur les médicaments ou se connecter à de nombreuses ressources internet complémentaires (site internet des industriels, HAS, Base de données publique des médicaments, Sociétés Savantes)." }
            br {  }
            b { content = "Collaboration et Innovation :" }
            p { content = "Nous collaborons étroitement avec des équipes de professionnels de santé pour faire évoluer notre offre et mieux répondre à leurs besoins." }
            br {  }
            b { content = "Sécurité et Confidentialité :" }
            p { content = "Les données et l'anonymat de nos utilisateurs sont protégés selon les standards les plus élevés, leur navigation est privée et sans cookie." }
          }
        }
      }
    }

    div(className = "labodoc-background-white px-lg-12 px-xl-16 py-10") {
      id = "page-about-mission"

      div(className = "page-width mx-lg-12 mx-xl-16") {
        h1 (className = "mx-5") {
          content = "Rejoignez la communauté LaboDoc !"
        }
        div {
          p {
            rich = true
            content = "Chaque professionnel de santé doit pouvoir disposer facilement de toutes les informations utiles à sa pratique, quel que soit son rôle au sein du parcours de soins des patients. LaboDoc n'est pas seulement une application, c’est une communauté engagée qui souhaite mieux partager l’information et faciliter la mise en relation."
          }
        }
        navigoLink("", Page.Register()) {
          labodocButton("Je m'inscris gratuitement", className = "labodoc-background-middle-blue mb-xl-6 mb-lg-4")
        }
      }
    }

    val swiper = require("swiper/element/bundle")

    swiper.register()
  }
}

fun Container.aboutUsPage() {
  add(AboutUsPage())
  add(LabodocFooter())
}
