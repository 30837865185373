package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.Password
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object PasswordAsStringSerializer : KSerializer<Password> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PasswordAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(Password: String): Either<Errors.Password.Invalid, Password> = Password(Password)

  override fun deserialize(decoder: Decoder): Password {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(Password: Password): String = Password.value

  override fun serialize(encoder: Encoder, value: Password) {
    return encoder.encodeString(serialize(value))
  }
}

typealias PasswordAsString = @Serializable(PasswordAsStringSerializer::class) Password
