package fr.labodoc.webapp.pages.admin.medicines.view

import fr.labodoc.app.data.admin.model.MedicalCardTypeModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.ProfessionalStatusModel
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.medicines.*
import io.kvision.core.Container
import io.kvision.core.onEvent
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.browser.window
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.parameter.parametersOf

class AdminMedicinePage(
  medicineId: MedicineId
) : SimplePanel(), KoinComponent {
  private val viewModel: AdminMedicinePageViewModel by inject { parametersOf(medicineId) }

  init {
    id = "page-admin-medicine"
    require("./css/pages/admin/medicines/medicine.css")

    viewModel.errorMessage.subscribe { errorMessage ->
      errorMessage?.let { Toast.danger(it) }
    }

    viewModel.successMessage.subscribe { successMessage ->
      successMessage?.let { Toast.success(it) }
    }

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      when (uiState) {
        is AdminMedicinePageViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        is AdminMedicinePageViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is AdminMedicinePageViewModel.UiState.Medicine -> {
          val medicineDeleteConfirmationModal = MedicineDeleteConfirmationModal(
            name = uiState.medicine.name,
            onDeleteConfirmed = { viewModel.deleteMedicine() },
            processing = uiState.medicine.processing
          )

          uiState.medicine.deleteModalOpen.subscribe { open ->
            if (open)
              medicineDeleteConfirmationModal.show()
            else
              medicineDeleteConfirmationModal.hide()
          }

          div(className = "medicine") {
            div(className = "information") {
              image(uiState.medicine.logoUrl.toString(), className = "logo")

              div(className = "data") {
                uiState.laboratory?.let { laboratory ->
                  navigoLink(
                    "Laboratoire ${laboratory.name.value}",
                    Page.AdminBackOfficeLaboratorySheet(laboratory.id),
                    className = "laboratory"
                  )
                }

                p(uiState.medicine.name.value, className = "name")

                div(className = "atc") {
                  p(className = "title") {
                    content = "Classification ATC"
                  }

                  uiState.medicine.atcClassification.firstLevel?.let {
                    p(className = "category first") {
                      content = "${it.code.value} - ${it.name.value}"
                    }
                  }

                  uiState.medicine.atcClassification.secondLevel?.let {
                    p(className = "category second") {
                      content = "${it.code.value} - ${it.name.value}"
                    }
                  }

                  uiState.medicine.atcClassification.thirdLevel?.let {
                    p(className = "category third") {
                      content = "${it.code.value} - ${it.name.value}"
                    }
                  }

                  uiState.medicine.atcClassification.fourthLevel?.let {
                    p(className = "category fourth") {
                      content = "${it.code.value} - ${it.name.value}"
                    }
                  }

                  uiState.medicine.atcClassification.fifthLevel?.let {
                    p(className = "category fifth") {
                      content = "${it.code.value} - ${it.name.value}"
                    }
                  }
                }

                uiState.laboratory?.pharmacovigilance?.let { pharmacovigilance ->
                  link(
                    "Pharmacovigilance",
                    pharmacovigilance.value.toString(),
                    "fa-solid fa-warning",
                    target = "_blank",
                    className = "pharmacovigilence"
                  )
                }
              }
            }

            div {
              add(
                MarketingDocumentList(
                  marketingDocuments = uiState.marketingDocuments.document,
                  medicalProfessions = uiState.medicalProfessions,
                  professionalStatuses = uiState.professionalStatuses,
                  medicalCardTypes = uiState.medicalCardTypes,
                  addMarketingDocument = viewModel::addMarketingDocument,
                  updateMarketingDocument = viewModel::updateMarketingDocument,
                  deleteMarketingDocument = viewModel::deleteMarketingDocument,
                  processing = uiState.marketingDocuments.processing,
                  createModalOpen = uiState.marketingDocuments.createModalOpen
                )
              )

              add(
                DosageList(
                  dosages = uiState.dosages.dosages,
                  addDosage = viewModel::addDosage,
                  deleteDosage = viewModel::deleteDosage,
                  moveDosage = viewModel::moveDosage,
                  processing = uiState.dosages.processing,
                  createModalOpen = uiState.dosages.createModalOpen,
                )
              )
            }
          }

          div(className = "actions") {
            navigoLink("", Page.AdminBackOfficeMedicineUpdate(uiState.medicine.id)) {
              labodocButton("Mettre à jour", className = "action edit")
            }

            labodocButton(className = "action delete") {
              onClick {
                uiState.medicine.deleteModalOpen.value = true
              }
            }.bind(uiState.medicine.processing) { processing ->
              if (processing) {
                disabled = true
                text = "Traitement"
                icon = "fa fa-spinner fa-spin"
              } else {
                disabled = false
                text = "Supprimer"
                icon = "fa-solid fa-trash"
              }
            }
          }
        }

        is AdminMedicinePageViewModel.UiState.MedicineDeleted -> {
          App.routing.navigate(Page.AdminBackOfficeMedicinesList())
        }
      }
    }
  }
}

private class MarketingDocumentList(
  marketingDocuments: ObservableList<AdminMedicinePageViewModel.MarketingDocument>,
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalStatuses: Set<ProfessionalStatusModel>,
  medicalCardTypes: Set<MedicalCardTypeModel>,
  addMarketingDocument: (MarketingDocumentForm) -> Unit,
  updateMarketingDocument: (AdminMedicinePageViewModel.MarketingDocument, MarketingDocumentForm) -> Unit,
  deleteMarketingDocument: (AdminMedicinePageViewModel.MarketingDocument) -> Unit,
  processing: ObservableState<Boolean>,
  createModalOpen: ObservableValue<Boolean>
): SimplePanel(className = "marketing-document-list") {
  val marketingDocumentsCreateModal = MarketingDocumentFormModal(
    medicalProfessions = medicalProfessions,
    professionalStatuses = professionalStatuses.map { it.code to it.name }.toSet(),
    medicalCardTypes = medicalCardTypes.map { it.code to it.label }.toSet(),
    marketingDocument = null,
    onSubmit = addMarketingDocument,
    processing = processing
  )

  init {
    createModalOpen.subscribe { open ->
      if (open)
        marketingDocumentsCreateModal.show()
      else
        marketingDocumentsCreateModal.hide()
    }

    header {
      h4("Documents marketing")

      button("", "fa-solid fa-folder-plus") {
        onClick {
          createModalOpen.value = true
        }
      }.bind(processing) { processing ->
        if (processing) {
          disabled = true
          icon = "fa fa-spinner fa-spin"
        } else {
          disabled = false
          icon = "fa-solid fa-folder-plus"
        }
      }
    }

    ul(className = "documents").bindEach(marketingDocuments) { marketingDocument ->
      val editModal = MarketingDocumentFormModal(
        medicalProfessions = medicalProfessions,
        professionalStatuses = professionalStatuses.map { it.code to it.name }.toSet(),
        medicalCardTypes = medicalCardTypes.map { it.code to it.label }.toSet(),
        marketingDocument = marketingDocument,
        onSubmit = { updateMarketingDocument(marketingDocument, it) },
        processing = marketingDocument.processing
      )

      val deleteConfirmationModal = MarketingDocumentDeleteConfirmationModal(
        name = marketingDocument.name,
        onDeleteConfirmed = { deleteMarketingDocument(marketingDocument) },
        processing = marketingDocument.processing
      )

      marketingDocument.editModalOpen.subscribe { open ->
        if (open)
          editModal.show()
        else
          editModal.hide()
      }

      marketingDocument.deleteModalOpen.subscribe { open ->
        if (open)
          deleteConfirmationModal.show()
        else
          deleteConfirmationModal.hide()
      }

      li(className = "document") {
        p(marketingDocument.name.value, className = "value")

        div(className = "actions").bind(marketingDocument.processing) { processing ->
          if (processing) {
            button("", icon = "fa fa-spinner fa-spin")
          } else {
            button("", "fa-solid fa-file-arrow-down") {
              onClick { window.open(marketingDocument.documentUrl.toString(), target = "_blank") }
            }

            button("", "fa-solid fa-pencil") {
              onClick {
                marketingDocument.editModalOpen.value = true
              }
            }

            button("", "fa-solid fa-trash") {
              onClick {
                marketingDocument.deleteModalOpen.value = true
              }
            }
          }
        }
      }
    }
  }
}

private class DosageList(
  dosages: ObservableList<AdminMedicinePageViewModel.Dosage>,
  addDosage: (DosageForm) -> Unit,
  deleteDosage: (AdminMedicinePageViewModel.Dosage) -> Unit,
  moveDosage: (Int, Int) -> Unit,
  processing: ObservableState<Boolean>,
  createModalOpen: ObservableValue<Boolean>
): SimplePanel(className = "dosage-list") {
  val dosageCreateModal = DosageFormModal(
    dosage = null,
    onSubmit = addDosage,
    processing = processing
  )

  init {
    createModalOpen.subscribe { open ->
      if (open)
        dosageCreateModal.show()
      else
        dosageCreateModal.hide()
    }

    header {
      h4("Dosages")

      button("", "fa-solid fa-plus") {
        onClick {
          createModalOpen.value = true
        }
      }.bind(processing) { processing ->
        if (processing) {
          disabled = true
          icon = "fa fa-spinner fa-spin"
        } else {
          disabled = false
          icon = "fa-solid fa-plus"
        }
      }
    }

    ul(className = "dosages").bindEach(dosages) { dosage ->
      val deleteConfirmationModal = DosageDeleteConfirmationModal(
        name = dosage.name,
        onDeleteConfirmed = { deleteDosage(dosage) },
        processing = dosage.processing
      )

      dosage.deleteModalOpen.subscribe { open ->
        if (open)
          deleteConfirmationModal.show()
        else
          deleteConfirmationModal.hide()
      }

      li(className = "dosage") {
        setDragDropData("text/plain", dosage.index.toString())
        setDropTargetData("text/plain") { data ->
          val previousOrder = data!!.toInt()
          val newOrder = dosage.index
          moveDosage(previousOrder, newOrder)
        }

        onEvent {
          dragstart = {
            this@li.opacity = 0.4
          }

          dragend = {
            this@li.opacity = 1.0
          }

          dragenter = {
            this@li.addCssClass("over")
          }

          dragleave = {
            this@li.removeCssClass("over")
          }
        }

        p("${dosage.cisCode.value} - ${dosage.name.value}", className = "name")

        div(className = "actions").bind(dosage.processing) { processing ->
          if (processing) {
            button("", icon = "fa fa-spinner fa-spin")
          } else {
            button("", "fa-solid fa-link") {
              onClick { window.open("https://base-donnees-publique.medicaments.gouv.fr/extrait.php?specid=${dosage.cisCode.value}", target = "_blank") }
            }

            button("", "fa-solid fa-trash") {
              onClick {
                dosage.deleteModalOpen.value = true
              }
            }
          }
        }
      }
    }
  }
}

fun Container.adminMedicinePage(
  medicineId: MedicineId
): AdminMedicinePage {
  val adminMedicinePage = AdminMedicinePage(
    medicineId
  )

  this.add(adminMedicinePage)
  return adminMedicinePage
}
