package fr.labodoc.domain.labodoc.medicine

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class MedicineGovernmentPublicDatabaseId private constructor(val value: String) {
  companion object {
    val regex: Regex = Regex("^\\d{8}\$")

    fun isFormatValid(value: String): Boolean = regex.matches(value)

    operator fun invoke(value: String): Either<Errors.Medicine.GovernmentPublicDatabaseId.Invalid, MedicineGovernmentPublicDatabaseId> =
      either {
        ensure(isFormatValid(value)) { Errors.Medicine.GovernmentPublicDatabaseId.Invalid.Format }

        MedicineGovernmentPublicDatabaseId(value)
      }
  }
}
