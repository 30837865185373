package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicalSpecialityIdAsStringSerializer : KSerializer<MedicalSpecialityId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalSpecialityIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.MedicalSpeciality.Id.Invalid, MedicalSpecialityId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.MedicalSpeciality.Id.Invalid.Format }
      .map { MedicalSpecialityId(it) }

  override fun deserialize(decoder: Decoder): MedicalSpecialityId =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalSpecialityId: MedicalSpecialityId): String =
    UuidAsStringSerializer.serialize(medicalSpecialityId.value)

  override fun serialize(encoder: Encoder, value: MedicalSpecialityId) =
    encoder.encodeString(serialize(value))
}

typealias MedicalSpecialityIdAsString = @Serializable(MedicalSpecialityIdAsStringSerializer::class) MedicalSpecialityId

object MedicalSpecialityNameAsStringSerializer : KSerializer<MedicalSpecialityName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalSpecialityNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Nothing, MedicalSpecialityName> =
    MedicalSpecialityName(name).right()

  override fun deserialize(decoder: Decoder): MedicalSpecialityName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalSpecialityName: MedicalSpecialityName): String =
    medicalSpecialityName.value

  override fun serialize(encoder: Encoder, value: MedicalSpecialityName) =
    encoder.encodeString(serialize(value))
}

typealias MedicalSpecialityNameAsString = @Serializable(MedicalSpecialityNameAsStringSerializer::class) MedicalSpecialityName
