package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Component
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.html.*
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.panel.simplePanel

class LabodocDialog(dialogContent: Component, dialogTitle: String?) : SimplePanel() {
  var modalStatus = false;
  fun showModal() {
    addCssClass("labodoc-dialog-open")
    removeCssClass("labodoc-dialog-closed")
    modalStatus = true
  }

  fun closeModal() {
    removeCssClass("labodoc-dialog-open")
    addCssClass("labodoc-dialog-closed")
    modalStatus = false
  }

  init {
    require("./css/components/labodoc-dialog.css")
    addCssClass("labodoc-dialog-open")
    div (className = "modal-background") { onClick { closeModal() }}
    div (className = "modal-container") {
      div (className = "modal-header") {
        h2 {
          content = dialogTitle
        }
        button("", className = "labodoc-dialog-close-btn", icon = "fa-solid fa-close") {
          onClick {
            closeModal()
          }
        }
      }
      div (className = "modal-content") {
        add(dialogContent)
      }
    }
  }
}

fun Container.labodocDialog(dialogContent: Component, dialogTitle: String?) {
  val dialog = LabodocDialog(dialogContent, dialogTitle)

}
