package fr.labodoc.app.data.admin.repository

import arrow.core.toNonEmptySetOrNull
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.app.data.admin.model.AtcClassificationModel
import fr.labodoc.app.data.admin.model.MarketingDocumentModel
import fr.labodoc.app.data.admin.model.MedicineModel
import fr.labodoc.app.data.admin.model.MedicineSummaryModel
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentName
import fr.labodoc.domain.labodoc.medicine.CisCode
import fr.labodoc.domain.labodoc.medicine.DosageName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import kotlinx.datetime.Instant

class MedicinesRepositoryImpl(
  private val apiClient: AdminApiClient
) : MedicinesRepository {
  override suspend fun getMedicines(
  ): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getMedicines()
      .map { medicinesResponse ->
        medicinesResponse.medicines
          .map { medicine ->
            MedicineSummaryModel(
              id = medicine.id,
              name = medicine.name,
              atcClassificationCode = medicine.atcClassificationCode,
              logoUrl = medicine.logoUrl,
              activated = medicine.activated
            )
          }
          .toSet()
      }

  override suspend fun getMedicinesForLaboratory(
    laboratoryId: LaboratoryId
  ): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getMedicines(laboratoryId)
      .map { medicinesResponse ->
        medicinesResponse.medicines
          .map { medicine ->
            MedicineSummaryModel(
              id = medicine.id,
              name = medicine.name,
              atcClassificationCode = medicine.atcClassificationCode,
              logoUrl = medicine.logoUrl,
              activated = medicine.activated
            )
          }
          .toSet()
      }

  override suspend fun createMedicine(
    laboratory: LaboratoryId?,
    name: MedicineName,
    atcClassificationCode: AtcClassificationCode,
    logo: InputFile?
  ): ApiResponse<MedicineId> =
    apiClient.medicines
      .createMedicine(
        payload = CreateMedicineRequest(
          laboratory = laboratory,
          name = name,
          atcClassificationCode = atcClassificationCode
        ),
        logo = logo
      )
      .map { medicineCreatedResponse: MedicineCreatedResponse ->
        medicineCreatedResponse.id
      }

  override suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<MedicineModel> =
    apiClient.medicines
      .getMedicine(id)
      .map { medicineResponse: AdminMedicineResponse ->
        val medicine = medicineResponse.medicine

        MedicineModel(
          id = medicine.id,
          laboratory = medicine.laboratory,
          name = medicine.name,
          atcClassification = medicine.atcClassification.toModel(),
          logoUrl = medicine.logoUrl,
          dosages = medicine.dosages
            .map { dosage ->
              MedicineModel.Dosage(
                cisCode = dosage.cisCode,
                name = dosage.name
              )
            }
            .toSet(),
          activated = medicine.activated
        )
      }

  override suspend fun updateMedicine(
    id: MedicineId,
    laboratory: LaboratoryId?,
    name: MedicineName,
    atcClassificationCode: AtcClassificationCode,
    logo: InputFile?
  ): ApiResponse<Unit> =
    apiClient.medicines
      .updateMedicine(
        id = id,
        payload = UpdateMedicineRequest(
          laboratory = laboratory,
          name = name,
          atcClassificationCode = atcClassificationCode
        ),
        logo = logo
      )

  override suspend fun deleteMedicine(
    id: MedicineId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deleteMedicine(id)

  override suspend fun getMarketingDocuments(
    forMedicineId: MedicineId
  ): ApiResponse<Set<MarketingDocumentModel>> =
    apiClient.medicines
      .getMarketingDocuments(forMedicineId)
      .map { marketingDocumentsResponse: AdminMarketingDocumentsResponse ->
        marketingDocumentsResponse.marketingDocuments
          .map { marketingDocument ->
            MarketingDocumentModel(
              id = marketingDocument.id,
              name = marketingDocument.name,
              segmentation = Segmentation(
                medicalProfessions = marketingDocument.segmentation.medicalProfessions?.toNonEmptySetOrNull(),
                medicalSpecialities = marketingDocument.segmentation.medicalSpecialities?.toNonEmptySetOrNull(),
                medicalInterests = marketingDocument.segmentation.medicalInterests?.toNonEmptySetOrNull(),
                professionalCategories = null,
                professionalStatuses = marketingDocument.segmentation.professionalStatuses?.toNonEmptySetOrNull(),
                medicalCardTypes = marketingDocument.segmentation.medicalCardTypes?.toNonEmptySetOrNull(),
                departments = null
              ),
              documentUrl = marketingDocument.documentUrl,
              expiresAt = marketingDocument.expiresAt
            )
          }
          .toSet()
      }

  override suspend fun addMarketingDocument(
    medicine: MedicineId,
    name: MarketingDocumentName,
    segmentation: Segmentation,
    document: InputFile,
    expiresAt: Instant?
  ): ApiResponse<MarketingDocumentId> =
    apiClient.medicines
      .addMarketingDocument(
        forMedicine = medicine,
        payload = CreateMarketingDocumentRequest(
          name = name,
          segmentation = CreateMarketingDocumentRequest.Segmentation(
            medicalProfessions = segmentation.medicalProfessions,
            medicalSpecialities = segmentation.medicalSpecialities,
            medicalInterests = segmentation.medicalInterests,
            professionalStatuses = segmentation.professionalStatuses,
            medicalCardTypes = segmentation.medicalCardTypes
          ),
          expiresAt = expiresAt
        ),
        document = document
      )
      .map { marketingDocumentCreatedResponse: MarketingDocumentCreatedResponse ->
        marketingDocumentCreatedResponse.id
      }

  override suspend fun getMarketingDocument(
    forMedicineId: MedicineId,
    id: MarketingDocumentId
  ): ApiResponse<MarketingDocumentModel> =
    apiClient.medicines
      .getMarketingDocument(forMedicineId, id)
      .map { marketingDocumentResponse: AdminMarketingDocumentResponse ->
        MarketingDocumentModel(
          id = marketingDocumentResponse.marketingDocument.id,
          name = marketingDocumentResponse.marketingDocument.name,
          segmentation = Segmentation(
            medicalProfessions = marketingDocumentResponse.marketingDocument.segmentation.medicalProfessions?.toNonEmptySetOrNull(),
            medicalSpecialities = marketingDocumentResponse.marketingDocument.segmentation.medicalSpecialities?.toNonEmptySetOrNull(),
            medicalInterests = marketingDocumentResponse.marketingDocument.segmentation.medicalInterests?.toNonEmptySetOrNull(),
            professionalCategories = null,
            professionalStatuses = marketingDocumentResponse.marketingDocument.segmentation.professionalStatuses?.toNonEmptySetOrNull(),
            medicalCardTypes = marketingDocumentResponse.marketingDocument.segmentation.medicalCardTypes?.toNonEmptySetOrNull(),
            departments = null
          ),
          documentUrl = marketingDocumentResponse.marketingDocument.documentUrl,
          expiresAt = marketingDocumentResponse.marketingDocument.expiresAt
        )
      }

  override suspend fun updateMarketingDocument(
    medicine: MedicineId,
    id: MarketingDocumentId,
    name: MarketingDocumentName,
    segmentation: Segmentation,
    document: InputFile?,
    expiresAt: Instant?
  ): ApiResponse<Unit> =
    apiClient.medicines
      .updateMarketingDocument(
        forMedicine = medicine,
        id = id,
        payload = UpdateMarketingDocumentRequest(
          name = name,
          segmentation = UpdateMarketingDocumentRequest.Segmentation(
            medicalProfessions = segmentation.medicalProfessions,
            medicalSpecialities = segmentation.medicalSpecialities,
            medicalInterests = segmentation.medicalInterests,
            professionalStatuses = segmentation.professionalStatuses,
            medicalCardTypes = segmentation.medicalCardTypes
          ),
          expiresAt = expiresAt
        ),
        document = document
      )

  override suspend fun deleteMarketingDocument(
    medicine: MedicineId,
    id: MarketingDocumentId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deleteMarketingDocument(medicine, id)

  override suspend fun updateDosages(
    medicine: MedicineId,
    dosages: Set<Pair<CisCode, DosageName>>
  ): ApiResponse<Unit> =
    apiClient.medicines
      .updateDosages(
        forMedicine = medicine,
        payload = UpdateDosagesRequest(
          dosages = dosages
            .map { dosage ->
              UpdateDosagesRequest.Dosage(
                cisCode = dosage.first,
                name = dosage.second
              )
            }
            .toSet()
        )
      )

  override suspend fun addDosage(
    medicine: MedicineId,
    cisCode: CisCode,
    name: DosageName
  ): ApiResponse<Unit> =
    apiClient.medicines
      .addDosage(
        forMedicine = medicine,
        payload = CreateDosageRequest(
          cisCode = cisCode,
          name = name
        )
      )

  override suspend fun deleteDosage(
    medicine: MedicineId,
    cisCode: CisCode
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deleteDosage(
        forMedicine = medicine,
        cisCode = cisCode
      )

  override suspend fun activateMedicine(
    id: MedicineId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .activate(id)

  override suspend fun deactivateMedicine(
    id: MedicineId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deactivate(id)
}

private fun AdminMedicineResponse.Medicine.AtcClassification.toModel(
): AtcClassificationModel {
  // 1) Collect the chain from bottom to top
  val chainBottomToTop = generateSequence(this) { current -> current.parent }.toList()

  // 2) Reverse it so that index 0 is the top node,
  //    and the last item is the bottom node
  val chainTopToBottom = chainBottomToTop.asReversed()

  // 3) Convert each DTO item into an empty AtcClassificationModel
  //    (we will fill parent/children relationships below)
  val models = chainTopToBottom.map {
    AtcClassificationModel(
      code = it.code,
      name = it.name,
      shortName = it.shortName,
      parent = null,
      children = emptySet()
    )
  }.toMutableList()

  // 4) Link each parent to its child
  for (i in 0 until (models.size - 1)) {
    val parentModel = models[i]
    val childModel = models[i + 1]

    // Set child's parent
    val updatedChild = childModel.copy(parent = parentModel)
    models[i + 1] = updatedChild

    // Add child into parent's children
    val updatedParent = parentModel.copy(children = parentModel.children + updatedChild)
    models[i] = updatedParent
  }

  // The first item in "models" is now the topmost classification
  // with fully linked descendants down to the bottom node.
  return models.last()
}
