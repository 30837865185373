package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.Error
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.message.MessageContent
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MessageIdAsStringSerializer : KSerializer<MessageId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MessageIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.Message.Id.Invalid, MessageId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.Message.Id.Invalid.Format }
      .map { MessageId(it) }

  override fun deserialize(decoder: Decoder): MessageId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: MessageId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: MessageId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MessageIdAsString = @Serializable(MessageIdAsStringSerializer::class) MessageId


object MessageTitleAsStringSerializer : KSerializer<MessageTitle> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MessageTitleAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(title: String): Either<Errors.Message.Title.Invalid, MessageTitle> =
    MessageTitle(title)

  override fun deserialize(decoder: Decoder): MessageTitle {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(title: MessageTitle): String =
    title.value

  override fun serialize(encoder: Encoder, value: MessageTitle) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MessageTitleAsString = @Serializable(MessageTitleAsStringSerializer::class) MessageTitle


object MessageContentAsStringSerializer : KSerializer<MessageContent> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MessageContentAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(content: String): Either<Error, MessageContent> =
    MessageContent(content).right()

  override fun deserialize(decoder: Decoder): MessageContent {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(content: MessageContent): String =
    content.value

  override fun serialize(encoder: Encoder, value: MessageContent) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MessageContentAsString = @Serializable(MessageContentAsStringSerializer::class) MessageContent
