package fr.labodoc.domain.labodoc.medicine

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class MedicineMainComposition private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 50

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH
    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.Medicine.MainComposition.Invalid, MedicineMainComposition> =
      either {
        ensure(isNotTooLong(value)) { Errors.Medicine.MainComposition.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.Medicine.MainComposition.Invalid.Blank }

        MedicineMainComposition(value)
      }
  }
}
