package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.LaboratoryModel
import fr.labodoc.app.data.healthprofessional.source.remote.LaboratoriesRemoteDataSource
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId

class LaboratoriesRepositoryImpl(
  private val laboratoriesRemoteDataSource: LaboratoriesRemoteDataSource
) : LaboratoriesRepository {
  override suspend fun getLaboratories(
  ): Either<RepositoryError.DataSource, Set<LaboratoryModel>> =
    laboratoriesRemoteDataSource
      .getLaboratories()
      .mapLeft(RepositoryError::DataSource)

  override suspend fun favorite(
    id: LaboratoryId
  ): Either<RepositoryError, Unit> =
    laboratoriesRemoteDataSource
      .favoriteLaboratory(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)

  override suspend fun unfavorite(
    id: LaboratoryId
  ): Either<RepositoryError, Unit> =
    laboratoriesRemoteDataSource
      .unfavoriteLaboratory(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)
}
