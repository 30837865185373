package fr.labodoc.webapp.pages.admin.learnedSocieties

import arrow.core.Either
import arrow.core.NonEmptySet
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.core.raise.ensureNotNull
import arrow.core.toNonEmptySetOrNull
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.LearnedSocietiesRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyWebsite
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.learnedSocieties.components.adminLearnedSocietyForm
import fr.labodoc.webapp.utils.byteArray
import fr.labodoc.webapp.utils.toInputFile
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import io.kvision.types.KFile
import io.kvision.types.contentType
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminLearnedSocietyCreatePage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data class Error(
        val errorMessage: String
      ) : UiState()

      data class Loaded(
        val medicalProfessions: Set<MedicalProfessionModel>
      ) : UiState()

      data class LearnedSocietyCreated(
        val id: LearnedSocietyId
      ) : UiState()
    }

    val uiState: ObservableState<UiState>
    val createProcessing: ObservableState<Boolean>

    fun createLearnedSociety(
      name: LearnedSocietyName,
      acronym: LearnedSocietyAcronym?,
      website: LearnedSocietyWebsite,
      medicalSpecialities: NonEmptySet<MedicalSpecialityId>,
      logo: InputFile
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val learnedSocietyRepository: LearnedSocietiesRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        medicalProfessionsRepository
          .getMedicalProfessions()
          .map { medicalProfessions ->
            ViewModel.UiState.Loaded(
              medicalProfessions = medicalProfessions
            )
          }
          .fold(
            { ViewModel.UiState.Error("Impossible de charger la liste des spécialités") },
            { it }
          )
          .also { observableValue.setState(it) }
      }

      observableValue
    }

    override val createProcessing: ObservableValue<Boolean> =
      ObservableValue(false)

    override fun createLearnedSociety(
      name: LearnedSocietyName,
      acronym: LearnedSocietyAcronym?,
      website: LearnedSocietyWebsite,
      medicalSpecialities: NonEmptySet<MedicalSpecialityId>,
      logo: InputFile
    ) {
      App.scope.launch {
        createProcessing.setState(true)

        learnedSocietyRepository
          .createLearnSociety(
            name = name,
            acronym = acronym,
            website = website,
            medicalSpecialities = medicalSpecialities,
            logo = logo
          )
          .onLeft {
            Toast.danger("Une erreur est survenue lors de la création de la société savante")
          }
          .onRight { id: LearnedSocietyId ->
            Toast.info("Société savante correctement créée")
            uiState.setState(ViewModel.UiState.LearnedSocietyCreated(id))
          }

        createProcessing.setState(false)
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-learned-society-create"
    require("./css/pages/admin/learnedSocieties/create.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1 {
        content = "Créer une société savante"
      }

      when (uiState) {
        is ViewModel.UiState.Loading -> labodocSpinner()

        is ViewModel.UiState.Error -> Toast.danger(uiState.errorMessage)

        is ViewModel.UiState.Loaded -> {
          val learnedSocietyForm = adminLearnedSocietyForm(
            medicalProfessions = uiState.medicalProfessions
          )

          labodocButton("", className = "save").bind(viewModel.createProcessing) { createProcessing ->
            if (createProcessing) {
              disabled = true
              text = "Traitement"
              icon = "fa fa-spinner fa-spin"
            } else {
              disabled = false
              text = "Sauvegarder"
              icon = null

              onClickLaunch {
                either {
                  ensure(learnedSocietyForm.validate()) { "Le formulaire n'est pas valide" }
                  val learnedSocietyFormData = learnedSocietyForm.getDataWithFileContent()

                  val name = when (val name = learnedSocietyFormData.name) {
                    null -> raise("Le nom est manquant")
                    is Either.Left -> {
                      val errorMessage = when (name.value) {
                        is Errors.LearnedSociety.Name.Invalid.Blank -> "Le nom ne peut pas être vide"
                        is Errors.LearnedSociety.Name.Invalid.TooLong -> "Le nom est trop long"
                      }

                      raise(errorMessage)
                    }

                    is Either.Right -> name.value
                  }
                  val acronym = when (val acronym = learnedSocietyFormData.acronym) {
                    null -> null

                    is Either.Left -> {
                      val errorMessage = when (acronym.value) {
                        is Errors.LearnedSociety.Acronym.Invalid.Blank -> "Le sigle ne peut pas être vide si renseigné"
                        is Errors.LearnedSociety.Acronym.Invalid.TooLong -> "Le sigle est trop long"
                      }

                      raise(errorMessage)
                    }

                    is Either.Right -> acronym.value
                  }
                  val website = ensureNotNull(learnedSocietyFormData.website) { "Site internet manquant" }
                  val medicalSpecialities = ensureNotNull(learnedSocietyFormData.medicalSpecialities?.toNonEmptySetOrNull()) { "Spécialités manquantes" }
                  val logo = ensureNotNull(learnedSocietyFormData.logo) { "Logo manquant" }

                  viewModel.createLearnedSociety(
                    name = name,
                    acronym = acronym,
                    website = website,
                    medicalSpecialities = medicalSpecialities,
                    logo = logo.toInputFile()
                  )
                }.onLeft { errorMessage: String ->
                  Toast.danger(errorMessage)
                }
              }
            }
          }
        }

        is ViewModel.UiState.LearnedSocietyCreated -> App.routing.navigate(Page.AdminBackOfficeLearnedSocietiesList())
      }
    }
  }
}

fun Container.adminLearnedSocietyCreatePage(): AdminLearnedSocietyCreatePage {
  val adminLearnedSocietyCreatePage = AdminLearnedSocietyCreatePage()

  this.add(adminLearnedSocietyCreatePage)
  return adminLearnedSocietyCreatePage
}
