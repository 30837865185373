package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partnership.PartnershipId

data class MedicalSpecialityModel(
  val id: MedicalSpecialityId,
  val name: MedicalSpecialityName,
  val partnership: PartnershipId?,
  val medicalInterests: Set<MedicalInterestModel>
)
