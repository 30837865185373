package fr.labodoc.webapp.pages.admin.medicalProfessions.form

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import com.benasher44.uuid.uuidFrom
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.webapp.components.LabodocSelect
import fr.labodoc.webapp.components.LabodocText
import io.kvision.core.StringPair
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class AdminMedicalSpecialityForm(
  partnershipOptions: List<StringPair>
) : FormPanel<AdminMedicalSpecialityForm.Data>(
  serializer = Data.serializer(),
  className = "admin-medical-speciality-form"
) {
  @Serializable
  data class Data(
    val name: String? = null,
    val partnership: String? = null
  )

  data class ValidatedData(
    val name: MedicalSpecialityName,
    val partnership: PartnershipId?
  )

  private val nameInput = LabodocText {
    label = "Nom"
  }.bind(
    key = Data::name,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validateName(it.value).isRight() },
    validatorMessage = { validateName(it.value).leftOrNull() }
  )

  private fun validateName(input: String?): Either<String, MedicalSpecialityName> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      MedicalSpecialityName(input)
    }

  private val partnershipInput = LabodocSelect {
    label = "Partenaire"
    options = listOf("none" to "Aucun") + partnershipOptions
    value = "none"
  }.bind(
    key = Data::partnership,
    required = false,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validatePartnership(it.value).isRight() },
    validatorMessage = { validatePartnership(it.value).leftOrNull() }
  )

  private fun validatePartnership(input: String?): Either<String, PartnershipId?> =
    either {
      input?.let {
        if (input != "none")
          PartnershipId(uuidFrom(input))
        else
          null
      }
    }

  init {
    add(nameInput)
    add(partnershipInput)
  }

  suspend fun getValidatedData(): Either<Nel<String>, ValidatedData> =
    either {
      val inputs = getDataWithFileContent()

      zipOrAccumulate(
        {
          validateName(inputs.name)
            .onLeft { nameInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${nameInput.label}: $it" }
            .bind()
        },
        {
          validatePartnership(inputs.partnership)
            .onLeft { partnershipInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${partnershipInput.label}: $it" }
            .bind()
        }
      ) { name, partnership ->
        ValidatedData(
          name = name,
          partnership = partnership
        )
      }
    }
}
