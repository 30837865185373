package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.DepartmentCodeAsString
import fr.labodoc.api.payloads.serializers.DepartmentNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class DepartmentResponse(
  val code: DepartmentCodeAsString,
  val name: DepartmentNameAsString
)
