package fr.labodoc.app.data.healthprofessional.source.remote

import arrow.core.Either
import arrow.core.raise.catch
import arrow.core.raise.either
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.HealthProfessionalMedicineWithInformationResponse
import fr.labodoc.api.payloads.responses.HealthProfessionalMedicinesSummaryResponse
import fr.labodoc.app.data.error.RemoteDataSourceError
import fr.labodoc.app.data.healthprofessional.model.MedicineModel
import fr.labodoc.app.data.healthprofessional.model.MedicinesSummaryModel
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId

class MedicinesRemoteDataSourceImpl(
  private val apiClient: HealthProfessionalApiClient
) : MedicinesRemoteDataSource {
  override suspend fun getMedicines(
  ): Either<RemoteDataSourceError, MedicinesSummaryModel> =
    either {
      catch({
        apiClient.medicines
          .getMedicines()
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map(HealthProfessionalMedicinesSummaryResponse::toModel)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun getMedicine(
    id: MedicineId
  ): Either<RemoteDataSourceError, MedicineModel> =
    either {
      catch({
        apiClient.medicines
          .getMedicine(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map(HealthProfessionalMedicineWithInformationResponse::toModel)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun tagDocumentAsSeen(
    id: MarketingDocumentId,
    forMedicine: MedicineId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.medicines
          .getMarketingDocument(
            id = id,
            forMedicine = forMedicine
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }
}

private fun HealthProfessionalMedicinesSummaryResponse.toModel(): MedicinesSummaryModel {
  fun Map<String, HealthProfessionalMedicinesSummaryResponse.AtcClassification>.toModel(
  ): Map<String, MedicinesSummaryModel.AtcClassification> {
    val modelMap = mutableMapOf<String, MedicinesSummaryModel.AtcClassification>()

    this
      .forEach { (atcClassificationCode, atcClassification) ->
        modelMap[atcClassificationCode] = MedicinesSummaryModel.AtcClassification(
          code = atcClassificationCode,
          name = atcClassification.name,
          shortName = atcClassification.shortName,
          numberOfMedicines = atcClassification.numberOfMedicines,
          parent = null
        )
      }

    modelMap
      .values
      .forEach { atcClassification ->
        val parentCode = this[atcClassification.code]?.parentCode

        atcClassification.parent = modelMap[parentCode]
      }

    return modelMap
  }

  val atcClassificationsMap: Map<String, MedicinesSummaryModel.AtcClassification> = atcClassifications
    .toModel()

  val atcClassifications: Set<MedicinesSummaryModel.AtcClassification> = atcClassificationsMap
    .values
    .toSet()

  val thematics: Set<MedicinesSummaryModel.Thematic> = thematics
    .map { (_, thematic) ->
      MedicinesSummaryModel.Thematic(
        id = thematic.id,
        name = thematic.name,
        atcClassificationsCodes = thematic.atcClassificationCodes,
        numberOfMedicines = thematic.numberOfMedicines
      )
    }
    .toSet()

  val medicines = medicines
    .map { medicine ->
      MedicinesSummaryModel.Medicine(
        id = medicine.id,
        laboratory = medicine.laboratoryId?.let { laboratoryId ->
          laboratories[laboratoryId]?.let { laboratory ->
            MedicinesSummaryModel.Medicine.Laboratory(
              id = laboratory.id,
              name = laboratory.name,
            )
          }
        },
        name = medicine.name,
        logoUrl = medicine.logoUrl,
        atcClassification = atcClassificationsMap[medicine.atcClassificationCode]!!, // TODO : handle error
        isPartner = medicine.isPartner
      )
    }
    .toSet()

  return MedicinesSummaryModel(
    medicines = medicines,
    atcClassifications = atcClassifications,
    thematics = thematics
  )
}

private fun HealthProfessionalMedicineWithInformationResponse.toModel(): MedicineModel {
  fun HealthProfessionalMedicineWithInformationResponse.AtcClassification.toModel(): MedicineModel.AtcClassification =
    MedicineModel.AtcClassification(
      code = this.code,
      name = this.name,
      shortName = this.shortName,
      parent = this.parent?.toModel()
    )

  return MedicineModel(
    id = this.medicine.id,
    laboratory = this.laboratory?.let { laboratory ->
      MedicineModel.Laboratory(
        id = laboratory.id,
        name = laboratory.name,
        website = laboratory.website,
        pharmacovigilance = laboratory.pharmacovigilance
      )
    },
    name = medicine.name,
    atcClassification = medicine.atcClassification.toModel(),
    dosages = this.dosages
      .map { dosage ->
        MedicineModel.Dosage(
          cisCode = dosage.cisCode,
          name = dosage.name
        )
      }
      .toSet(),
    logoUrl = medicine.logoURL,
    marketingDocuments = this.marketingDocuments
      .map { marketingDocument ->
        MedicineModel.MarketingDocument(
          id = marketingDocument.id,
          name = marketingDocument.name,
          url = marketingDocument.documentUrl,
          version = marketingDocument.version,
          latestVersionSeen = marketingDocument.lastVersionSeen
        )
      }
      .toSet(),
  )
}
