package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MarketingDocumentIdAsStringSerializer : KSerializer<MarketingDocumentId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MarketingDocumentIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.Document.Id.Invalid, MarketingDocumentId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.Document.Id.Invalid.Format }
      .map { MarketingDocumentId(it) }

  override fun deserialize(decoder: Decoder): MarketingDocumentId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: MarketingDocumentId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: MarketingDocumentId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MarketingDocumentIdAsString = @Serializable(MarketingDocumentIdAsStringSerializer::class) MarketingDocumentId

object MarketingDocumentNameAsStringSerializer : KSerializer<MarketingDocumentName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MarketingDocumentNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.MarketingDocument.Name.Invalid, MarketingDocumentName> =
    MarketingDocumentName(name)

  override fun deserialize(decoder: Decoder): MarketingDocumentName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: MarketingDocumentName): String = id.value

  override fun serialize(encoder: Encoder, value: MarketingDocumentName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MarketingDocumentNameAsString = @Serializable(MarketingDocumentNameAsStringSerializer::class) MarketingDocumentName
