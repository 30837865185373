package fr.labodoc.webapp.pages.healthProfessional.medicines

import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.app.data.healthprofessional.model.MedicineFlashInfoSummaryModel
import fr.labodoc.app.data.healthprofessional.model.MedicineInvitationSummaryModel
import fr.labodoc.app.data.healthprofessional.model.MedicineMessageSummaryModel
import fr.labodoc.app.data.healthprofessional.model.MedicineModel
import fr.labodoc.app.data.healthprofessional.repository.MedicinesRepository
import fr.labodoc.app.data.healthprofessional.repository.MessagesRepository
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.healthProfessional.messages.components.MessageModal
import fr.labodoc.app.utils.dateFormat
import io.kvision.core.Container
import io.kvision.core.onChange
import io.kvision.core.onClick
import io.kvision.core.onEvent
import io.kvision.form.check.checkBox
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import io.kvision.utils.event
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.w3c.dom.Node

class HealthProfessionalMedicinePage(medicineId: MedicineId) : SimplePanel() {
  private sealed interface ViewModel {
    sealed class UIState {
      data object Loading : UIState()

      data object Error : UIState()

      data class Loaded(
        val medicine: MedicineModel,
        val messages: ObservableList<MedicineMessageSummaryModel>
      ) : UIState()
    }

    val uiState: ObservableState<UIState>
    val messageModal: ObservableState<MessageModal?>

    fun setMedicineFavoriteStatus(favoriteStatus: Boolean)
    fun setMessageFavoriteStatus(message: MedicineMessageSummaryModel, favoriteStatus: Boolean)
    fun tagDocumentAsSeen(id: MarketingDocumentId)
    fun getMessage(message: MedicineMessageSummaryModel)
  }

  private class ViewModelImpl(
    private val medicineId: MedicineId
  ) : ViewModel, KoinComponent {
    private val medicineRepository: MedicinesRepository by inject()
    private val messageRepository: MessagesRepository by inject()

    override val uiState: ObservableValue<ViewModel.UIState> by lazy {
      val observableValue: ObservableValue<ViewModel.UIState> = ObservableValue(ViewModel.UIState.Loading)

      App.scope.launch {
        either {
          parZip(
            { medicineRepository.getMedicine(medicineId).bind() },
            { messageRepository.getMessagesForMedicine(medicineId).bind() }
          ) { medicine, messages ->
            this@ViewModelImpl.messages.clear()
            this@ViewModelImpl.messages.addAll(messages)

            ViewModel.UIState.Loaded(
              medicine = medicine,
              messages = this@ViewModelImpl.messages
            )
          }
        }
          .fold(
            { ViewModel.UIState.Error },
            { it }
          )
          .also { observableValue.setState(it) }
      }

      observableValue
    }

    override val messageModal: ObservableValue<MessageModal?> =
      ObservableValue(null)

    private val messages: ObservableListWrapper<MedicineMessageSummaryModel> =
      ObservableListWrapper()

    private fun updateMessage(messageId: MessageId, block: (MedicineMessageSummaryModel) -> MedicineMessageSummaryModel) {
      messages.apply {
        find { it.id == messageId }?.let { message ->
          set(indexOf(message), block(message))
        }
      }
    }

    override fun setMedicineFavoriteStatus(favoriteStatus: Boolean) {
      App.scope.launch {
        val response = if (favoriteStatus) {
          medicineRepository.favoriteMedicine(medicineId)
        } else {
          medicineRepository.unfavoriteMedicine(medicineId)
        }

        response
          .onLeft { Toast.danger("Une erreur est survenue lors de la mise à jour du favori") }
          .onRight {
            when (val currentUiState = uiState.getState()) {
              is ViewModel.UIState.Loaded -> {
                val newUiState = ViewModel.UIState.Loaded(
                  medicine = currentUiState.medicine.copy(favorite = favoriteStatus),
                  messages = currentUiState.messages
                )

                uiState.setState(newUiState)
              }

              else -> {}
            }
          }
      }
    }

    override fun setMessageFavoriteStatus(message: MedicineMessageSummaryModel, favoriteStatus: Boolean) {
      App.scope.launch {
        val response = if (favoriteStatus) {
          messageRepository.favorite(message.id)
        } else {
          messageRepository.unfavorite(message.id)
        }

        response
          .onLeft { Toast.danger("Une erreur est survenue lors de la mise à jour du favori") }
          .onRight { updateMessage(message.id) { it.setFavorite(favoriteStatus) } }
      }
    }

    override fun tagDocumentAsSeen(id: MarketingDocumentId) {
      App.scope.launch {
        medicineRepository
          .tagDocumentAsSeen(id, medicineId)
          .onRight {
            when (val currentUiState = uiState.getState()) {
              is ViewModel.UIState.Loaded -> {
                val newUiState = ViewModel.UIState.Loaded(
                  medicine = currentUiState.medicine.copy(
                    marketingDocuments = currentUiState.medicine.marketingDocuments
                      .map { marketingDocument ->
                        if (marketingDocument.id == id)
                          marketingDocument.copy(
                            seenAt = Clock.System.now(),
                            seenLatestVersion = true
                          )
                        else
                          marketingDocument
                      }
                      .toSet()
                  ),
                  messages = currentUiState.messages
                )

                uiState.setState(newUiState)
              }

              else -> {}
            }
          }
      }
    }

    override fun getMessage(message: MedicineMessageSummaryModel) {
      App.scope.launch {
        messageRepository.getMessage(message.id)
          .onRight {
            updateMessage(message.id) { it.setSeen(true) }

            messageModal
              .setState(
                MessageModal(
                  message = it,
                  onFavoriteClicked = { setMessageFavoriteStatus(message, it) }
                )
              )
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(medicineId)

  private class MessageRow(
    message: MedicineMessageSummaryModel,
    onCardClicked: () -> Unit,
    onFavoriteClicked: (Boolean) -> Unit,
    onContactSubmit: () -> Unit
  ) : SimplePanel("message") {
    init {
      if (!message.seen)
        addCssClass("new")

      onClick {
        onCardClicked()
      }

      p(className = "type") {
        rich = true
        content = when (message) {
          is MedicineFlashInfoSummaryModel -> """<i class="fa-solid fa-bell"></i> Flash Info"""
          is MedicineInvitationSummaryModel -> """<i class="fa-solid fa-envelope"></i> Invitation"""
        }

        if (!message.seen)
          + """ <i class="fa-solid fa-exclamation"></i>"""
      }

      div {
        val favoriteCheckbox = checkBox {
          addCssClass("favorite")
          value = message.favorite

          onChange { event ->
            event.stopPropagation()
            onFavoriteClicked(value)
          }
        }

        p(message.title.value, className = "title")

        onClick { event ->
          val target = event.target

          if (target is Node && favoriteCheckbox.getElement()!!.contains(target))
            event.stopPropagation()
        }
      }

      p(message.publishedAt.dateFormat, className = "created-date")
    }
  }

  init {
    id = "page-health-professional-medicine-page"
    require("./css/pages/healthProfessional/medicines/medicine.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      when (uiState) {
        is ViewModel.UIState.Loading -> labodocSpinner()

        is ViewModel.UIState.Error -> {
          Toast.danger("Ce médicament n'existe pas")
          App.routing.navigate(Page.HealthProfessionalDashboard())
        }

        is ViewModel.UIState.Loaded -> {
          div(className = "medicine") {
            image(uiState.medicine.logoUrl.toString(), className = "logo")

            hr()

            div(className = "information") {
              div(className = "data") {
                p(uiState.medicine.name.value, className = "name")

                p(uiState.medicine.mainComposition.value, className = "main-composition")

                p(uiState.medicine.laboratory.name.value, className = "laboratory")

                p("${uiState.medicine.atc.code.value} - ${uiState.medicine.atc.name.value}", className = "atc")
              }

              div(className = "actions") {
                link(
                  "Consulter le site du Laboratoire",
                  uiState.medicine.laboratory.website.value.toString(),
                  target = "_blank"
                ) {
                  val leavingModal = LabodocPopup(
                    closeButton = false,
                    className = "leaving",
                    content = { popup ->
                      p(className = "title") {
                        content = "Visiter le site du Laboratoire"
                      }

                      p(className = "text") {
                        rich = true
                        content = """
                      En poursuivant vous quittez le site LaboDoc.<br>
                      Consultez nos <b><a href="${Page.TermsOfService().url}" target="_blank">conditions générales d’utilisation</a><b>
                    """.trimIndent()
                      }

                      div(className = "buttons") {
                        labodocButton("Annuler", className = "cancel") {
                          onClick {
                            popup.hide()
                          }
                        }

                        labodocButton("Continuer", className = "continue") {
                          onClick {
                            window.open(this@link.url ?: "", this@link.target ?: "")
                            popup.hide()
                          }
                        }
                      }
                    }
                  )

                  onClick {
                    it.preventDefault()
                    leavingModal.show()
                  }
                }
              }
            }

            ul(className = "documents") {
              uiState.medicine.marketingDocuments.forEach { marketingDocument ->
                li(className = "document") {
                  if (!marketingDocument.seenLatestVersion)
                    addCssClass("new")

                  link(
                    marketingDocument.name.value,
                    marketingDocument.url.toString(),
                    "fa-solid fa-file-arrow-down",
                    target = "_blank"
                  ) {
                    onEvent {
                      event("auxclick") {
                        viewModel.tagDocumentAsSeen(marketingDocument.id)
                      }

                      click = {
                        viewModel.tagDocumentAsSeen(marketingDocument.id)
                      }
                    }
                  }
                }
              }

              uiState.medicine.governmentPublicDatabaseId?.let { governmentPublicDatabaseId ->
                li(className = "document") {
                  link(
                    "Documents réglementaires (avis CT et RCP)",
                    "https://base-donnees-publique.medicaments.gouv.fr/extrait.php?specid=${governmentPublicDatabaseId.value}",
                    "fa-solid fa-arrow-up-right-from-square",
                    target = "_blank"
                  )
                }
              }
            }
          }

          div(className = "messages").bind(uiState.messages) { messages ->
            if (messages.isEmpty())
              addCssClass("empty")

            header {
              val newMessagesNumber = messages.count { !it.seen }

              icon("fa-solid fa-envelope") {
                if (newMessagesNumber > 0)
                  span(newMessagesNumber.toString(), className = "message-number")
              }

              div {
                if (messages.isNotEmpty())
                  p("L'actualité sur ce médicament", className = "title")
                else {
                  p("Actuellement, il n’y a pas d'actualité", className = "title")
                  p("Vous serez alerté en cas de nouveaux messages", className = "tip")
                }
              }
            }

            div(className = "list").bindEach(uiState.messages) { message ->
              add(
                MessageRow(
                  message = message,
                  onCardClicked = { viewModel.getMessage(message) },
                  onFavoriteClicked = { viewModel.setMessageFavoriteStatus(message, it) },
                  onContactSubmit = {  }
                )
              )
            }
          }
        }
      }

      viewModel.messageModal.subscribe {
        it?.show()
      }
    }
  }
}

fun Container.healthProfessionalMedicinePage(medicineId: MedicineId): HealthProfessionalMedicinePage {
  val healthProfessionalPage = HealthProfessionalMedicinePage(medicineId)

  this.add(healthProfessionalPage)
  return healthProfessionalPage
}
