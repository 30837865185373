package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.domain.labodoc.message.MessageContent
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import io.ktor.http.*
import kotlinx.datetime.Instant

sealed class MessageModel {
  abstract val id: MessageId
  abstract val title: MessageTitle
  abstract val content: MessageContent
  abstract val publishedAt: Instant
  abstract val seen: Boolean
  abstract val favorite: Boolean
  abstract val bannerUrl: Url
  abstract val documentUrl: Url?

  abstract fun setSeen(seen: Boolean): MessageModel
  abstract fun setFavorite(favorite: Boolean): MessageModel
}

sealed class LabodocMessageModel : MessageModel() {
  abstract override fun setSeen(seen: Boolean): LabodocMessageModel
  abstract override fun setFavorite(favorite: Boolean): LabodocMessageModel
}

sealed class LaboratoryMessageModel : MessageModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  abstract val laboratory: Laboratory

  abstract override fun setSeen(seen: Boolean): LaboratoryMessageModel
  abstract override fun setFavorite(favorite: Boolean): LaboratoryMessageModel
}

sealed class MedicineMessageModel : MessageModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  data class Medicine(
    val id: MedicineId,
    val name: MedicineName
  )

  abstract val laboratory: Laboratory
  abstract val medicine: Medicine

  abstract override fun setSeen(seen: Boolean): MedicineMessageModel
  abstract override fun setFavorite(favorite: Boolean): MedicineMessageModel
}

sealed class LearnedSocietyMessageModel : MessageModel() {
  data class LearnedSociety(
    val id: LearnedSocietyId,
    val name: LearnedSocietyName
  )

  abstract val learnedSociety: LearnedSociety

  abstract override fun setSeen(seen: Boolean): LearnedSocietyMessageModel
  abstract override fun setFavorite(favorite: Boolean): LearnedSocietyMessageModel
}

sealed interface FlashInfoMessageModel

sealed interface InvitationMessageModel {
  val eventAt: Instant
  val formUrl: Url?
}


data class LabodocFlashInfoModel(
  override val id: MessageId,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LabodocMessageModel(), FlashInfoMessageModel {
  override fun setSeen(seen: Boolean): LabodocFlashInfoModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LabodocFlashInfoModel =
    this.copy(favorite = favorite)
}

data class LaboratoryFlashInfoModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LaboratoryMessageModel(), FlashInfoMessageModel {
  override fun setSeen(seen: Boolean): LaboratoryFlashInfoModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LaboratoryFlashInfoModel =
    this.copy(favorite = favorite)
}

data class LaboratoryInvitationModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val eventAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val formUrl: Url?,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LaboratoryMessageModel(), InvitationMessageModel {
  override fun setSeen(seen: Boolean): LaboratoryInvitationModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LaboratoryInvitationModel =
    this.copy(favorite = favorite)
}

data class MedicineFlashInfoModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : MedicineMessageModel(), FlashInfoMessageModel {
  override fun setSeen(seen: Boolean): MedicineFlashInfoModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): MedicineFlashInfoModel =
    this.copy(favorite = favorite)
}

data class MedicineInvitationModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val medicine: Medicine,
  override val publishedAt: Instant,
  override val eventAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val formUrl: Url?,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : MedicineMessageModel(), InvitationMessageModel {
  override fun setSeen(seen: Boolean): MedicineInvitationModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): MedicineInvitationModel =
    this.copy(favorite = favorite)
}

data class LearnedSocietyFlashInfoModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LearnedSocietyMessageModel(), FlashInfoMessageModel {
  override fun setSeen(seen: Boolean): LearnedSocietyFlashInfoModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LearnedSocietyFlashInfoModel =
    this.copy(favorite = favorite)
}

data class LearnedSocietyInvitationModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val eventAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val formUrl: Url?,
  override val seen: Boolean,
  override val favorite: Boolean,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LearnedSocietyMessageModel(), InvitationMessageModel {
  override fun setSeen(seen: Boolean): LearnedSocietyInvitationModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LearnedSocietyInvitationModel =
    this.copy(favorite = favorite)
}
