package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.medicine.AtcCode
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineMainComposition
import fr.labodoc.domain.labodoc.medicine.MedicineName
import io.ktor.http.*

data class MedicineSummaryModel(
  val id: MedicineId,
  val laboratory: Laboratory,
  val name: MedicineName,
  val mainComposition: MedicineMainComposition,
  val logoUrl: Url,
  val atcCode: AtcCode.Code,
  val favorite: Boolean,
  val unreadDocuments: Int
) {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )
}
