package fr.labodoc.domain.labodoc

import fr.labodoc.Error
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationName
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationShortName
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentName
import fr.labodoc.domain.labodoc.medicine.DosageName
import fr.labodoc.domain.labodoc.partner.PartnerCode

object Errors {
  sealed class UUID(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String) : UUID(Type.Validation, code, "Invalid UUID", details) {
      class Format(details: String? = null) : Invalid("UUID.Invalid.Format",details ?: "Invalid format")
    }
  }

  sealed class Forbidden(code:String, title: String? = null, details: String? = null) : Error(Type.Forbidden, code, title, details) {
    data object NotAllowed : Forbidden ("Forbidden.NotAllowed", "Not allowed", "This action must be explicitly allowed and it has not been")
    data object NotValidated : Forbidden ("Forbidden.NotValidated","Not validated", "This action has validations prerequisites that have not been performed")
    data object InvalidContext : Forbidden("Forbidden.InvalidContext","Invalid context", "This action is invalid in this context")
  }

  sealed class Authentication(type: Type, code: String,) : Error(type, code) {
    data object InvalidCredentials : Authentication(Type.Unauthorized, "Authentication.InvalidCredentials")
    data object Unauthenticated : Authentication(Type.Unauthorized, "Authentication.Unauthenticated")
    data object AccountDisabled : Authentication(Type.Unauthorized, "Authentication.AccountDisabled")
  }

  sealed class HttpStatusCode(code: String, title: String? = null, details: String? = null) : Error(Type.Validation, code, title, details) {
    sealed class Invalid(code: String, details: String) : HttpStatusCode(code, "Invalid HTTP status code", details) {
      class Unknown(details: String? = null) : Invalid("HttpStatusCode.Invalid.Unknown",details ?: "Unknown value")
    }
  }

  sealed class AtcClassification(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : AtcClassification(Type.NotFound, "AtcClassification.NotFound", title = "ATC classification not found", details = details)

    sealed class Code(type: Type, code: String, title: String? = null, details: String? = null) : AtcClassification(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Code(Type.Validation, code, "Invalid ATC classification's code", details) {
        data object Format : Invalid("AtcClassification.Code.Invalid.Format", "Must conform to the following regex ${AtcClassificationCode.regex.pattern}")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : AtcClassification(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid ATC classification's name", details) {
        data object Blank : Invalid("AtcClassification.Name.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("AtcClassification.Name.Invalid.TooLong", "Must must be less than or equals to ${AtcClassificationName.MAX_LENGTH} characters")
      }
    }

    sealed class ShortName(type: Type, code: String, title: String?, details: String?) : AtcClassification(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : ShortName(Type.Validation, code, "Invalid ATC classification's short name", details) {
        data object Blank : Invalid("AtcClassification.ShortName.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("AtcClassification.ShortName.Invalid.TooLong", "Must must be less than or equals to ${AtcClassificationShortName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class User(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    data object NotFound: User(Type.NotFound, "User.NotFound")

    data object EmailAlreadyUsed : User(Type.Conflict, "User.EmailAlreadyUsed")
    sealed class Id(type: Type, code: String, title: String?, details: String?) : User(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid user's id", details) {
        data object Format : Invalid("User.Id.Invalid.Format","Invalid format")
      }
    }
  }

  sealed class Admin(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    data object NotFound: Admin(Type.NotFound, "Admin.NotFound")

    sealed class Id(type: Type, code: String, title: String?, details: String?) : Admin(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid admin's id", details) {
        data object Format : Invalid("Admin.Id.Invalid.Format","Invalid format")
      }
    }
  }

  sealed class HealthProfessional(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    data object NotFound: HealthProfessional(Type.NotFound, "HealthProfessional.NotFound")

    sealed class Id(type: Type, code: String, title: String?, details: String?) : HealthProfessional(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid health professional's id", details) {
        data object Format : Invalid("HealthProfessional.Id.Invalid.Format","Invalid format")
      }
    }
  }

  sealed class HealthDirectoryMatchingRule(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Id(type: Type, code: String, title: String?, details: String?) : HealthDirectoryMatchingRule(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid matching rule's id", details) {
        data object Format : Invalid("HealthDirectoryMatchingRule.Id.Invalid.Format", "Invalid format")
      }
    }

    data object NoMatch: HealthDirectoryMatchingRule(Type.NotFound, "HealthDirectoryMatchingRule.NoMatch", title = "No match for criteria")
  }

  sealed class HealthDirectoryStudentMatchingRule(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Id(type: Type, code: String, title: String?, details: String?) : HealthDirectoryStudentMatchingRule(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid student matching rule's id", details) {
        data object Format : Invalid("HealthDirectoryStudentMatchingRule.Id.Invalid.Format", "Invalid format")
      }
    }
  }

  sealed class LearnedSociety(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : LearnedSociety(Type.NotFound, "LearnedSociety.NotFound", title = "Learned society not found", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : LearnedSociety(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid learned society's id", details) {
        data object Format : Invalid("LearnedSociety.Id.Invalid.Format", "Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : LearnedSociety(type, code,title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code,"Invalid learned society name", details) {
        data object Blank : Invalid("LearnedSociety.Name.Invalid.Blank","Can't be blank")
        data object TooLong : Invalid("LearnedSociety.Name.Invalid.TooLong", "Must must be less than or equals to ${fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName.MAX_LENGTH} characters")
      }
    }

    sealed class Acronym(type: Type, code: String, title: String?, details: String?) : LearnedSociety(type, code,title, details) {
      sealed class Invalid(code: String, details: String) : Acronym(Type.Validation, code,"Invalid learned society acronym", details) {
        data object Blank : Invalid("LearnedSociety.Acronym.Invalid.Blank","Can't be blank")
        data object TooLong : Invalid("LearnedSociety.Acronym.Invalid.TooLong", "Must must be less than or equals to ${fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym.MAX_LENGTH} characters")
      }
    }

    sealed class MedicalSpecialities(type: Type, code: String, title: String?, details: String?) : LearnedSociety(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : MedicalSpecialities(Type.Validation, code, "Invalid learned society medical specialities", details) {
        data object Empty : Invalid("LearnedSociety.MedicalSpecialities.Invalid.Empty","Must not be empty")
      }
    }
  }

  sealed class Laboratory(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Laboratory(Type.NotFound, "Laboratory.NotFound", title = "Laboratory not found", details = details)

    sealed class MedicalSpecialties(type: Type, code: String, title: String?, details: String?) : Laboratory(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : MedicalSpecialties(Type.Validation, code, "Invalid laboratory medical specialities", details) {
        data object Empty : Invalid("Laboratory.MedicalSpecialties.Invalid.Empty","Must not be empty")
      }
    }

    sealed class Id(type: Type, code: String, title: String?, details: String?) : Laboratory(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid laboratory's id", details) {
        data object Format : Invalid("Laboratory.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : Laboratory(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid laboratory name", details) {
        data object Blank : Invalid("Laboratory.Name.Invalid.Blank","Can't be blank")
        data object TooLong : Invalid("Laboratory.Name.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.laboratory.LaboratoryName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class Message(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Id(type: Type, code: String, title: String?, details: String?) : Message(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid message's id", details) {
        data object Format : Invalid("Message.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Title(type: Type, code: String, title: String?, details: String?) : Message(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Title(Type.Validation, code, "Invalid message's title", details) {
        data object Blank : Invalid("Message.Title.Invalid.Blank" ,"Can't be blank")
        data object TooLong : Invalid("Message.Title.Invalid.TooLong" ,"Must must be less than or equals to ${fr.labodoc.domain.labodoc.message.MessageTitle.MAX_LENGTH} characters")
      }
    }
  }

  sealed class File(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Id(type: Type, code: String, title: String?, details: String?) : File(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid file's id", details) {
        data object Format : Invalid("File.Id.Invalid.Format","Invalid format")
      }
    }
  }

  sealed class Dosage(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Dosage(Type.NotFound, "Dosage.NotFound", title = "Dosage not found", details = details)

    sealed class Name(type: Type, code: String, title: String?, details: String?) : Dosage(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid dosage name", details) {
        data object Blank : Invalid("Dosage.Name.Invalid.Blank" ,"Can't be blank")
        data object TooLong : Invalid("Dosage.Name.Invalid.TooLong" ,"Must must be less than or equals to ${DosageName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class Medicine(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Medicine(Type.NotFound, "Medicine.NotFound", title = "Medicine not found", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : Medicine(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid medicine's id", details) {
        data object Format : Invalid("Medicine.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : Medicine(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid medicine name", details) {
        data object Blank : Invalid("Medicine.Name.Invalid.Blank" ,"Can't be blank")
        data object TooLong : Invalid("Medicine.Name.Invalid.TooLong" ,"Must must be less than or equals to ${fr.labodoc.domain.labodoc.medicine.MedicineName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class Thematic(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Thematic(Type.NotFound, "Thematic.NotFound", title = "Thematic not found", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : Thematic(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid thematic's id", details) {
        data object Format : Invalid("Thematic.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : Thematic(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid thematic name", details) {
        data object Blank : Invalid("Thematic.Name.Invalid.Blank" ,"Can't be blank")
        data object TooLong : Invalid("Thematic.Name.Invalid.TooLong" ,"Must must be less than or equals to ${fr.labodoc.domain.labodoc.thematic.ThematicName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class MarketingDocument(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : MarketingDocument(Type.NotFound, "MarketingDocument.NotFound", title = "MarketingDocument not found", details = details)


    sealed class Id(type: Type, code: String, title: String?, details: String?) : MarketingDocument(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid marketing document's id", details) {
        data object Format : Invalid("MarketingDocument.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : MarketingDocument(type, code, title, details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid marketing document name", details) {
        data object Blank : Invalid("MarketingDocument.Name.Invalid.Blank" ,"Can't be blank")
        data object TooLong : Invalid("MarketingDocument.Name.Invalid.TooLong" ,"Must must be less than or equals to ${MarketingDocumentName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class Document(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Id(type: Type, code: String, title: String?, details: String?) : Document(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid document id", details) {
        data object Format : Invalid("Document.Id.Invalid.Format","Invalid format")
      }
    }
    sealed class Name(type: Type, code: String, title: String?, details: String?) : Document(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Name(Type.Validation, code, "Invalid document name", details) {
        data object Format : Invalid("Document.Name.Invalid.Format","Invalid format")
      }
    }
    class Reference(ref: String, details: String?): Document(Type.Validation, "Document.Reference.Invalid", "The given reference '${ref}' is wrong", details)
  }

  sealed class Application(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Application(Type.NotFound, "Application.NotFound", title = "Application not found", details = details)
    class IncorrectType(details: String? = null) : Application(Type.Conflict, "Application.IncorrectType", title = "Incorrect application's type", details = details)
    class ValidatedByAdmin(details: String? = null) : Application(Type.Conflict, "Application.ValidatedByAdmin", title = "Application validated by admin", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : Application(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid application contact id", details) {
        data object Format : Invalid("Application.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Email(type: Type, code: String, title: String?, details: String?) : Application(type, code, title,details) {
      class NotValidated(details: String? = null): Email(Type.Conflict, "Application.Email.NotValidated", title = "Application's email not validated", details = details)
    }
  }

  sealed class UniversityHospital(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : UniversityHospital(Type.NotFound, "UniversityHospitals.NotFound", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : UniversityHospital(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Id(Type.Validation, code, "Invalid university hospital's id", details) {
        data object Format : Invalid("UniversityHospital.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : UniversityHospital(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Name(Type.Validation, code, "Invalid university hospital's name", details) {
        data object Blank : Invalid("UniversityHospital.Name.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("UniversityHospital.Name.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class Partner(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Partner(Type.NotFound, "Partner.NotFound", title = "Partner not found", details = details)

    sealed class Name(type: Type, code: String, title: String?, details: String?) : Partner(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Name(Type.Validation, code, "Invalid partner's name", details) {
        data object Blank : Invalid("Partner.Name.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("Partner.Name.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.partner.PartnerName.MAX_LENGTH} characters")
      }
    }

    sealed class Code(type: Type, code: String, title: String?, details: String?) : Partner(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Code(Type.Validation, code, "Invalid partner's code", details) {
        data object Blank : Invalid("Partner.Code.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("Partner.Code.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.partner.PartnerCode.MAX_LENGTH} characters")
      }

      class AlreadyUsed(
        code: PartnerCode? = null
      ): Code(
        type = Type.Conflict,
        code = "Partner.Code.AlreadyUsed",
        title = "Partner's code already used",
        details = code?.let { "The partner's code [${code.value}] is already used" }
      )
    }
  }

  sealed class Partnership(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : Partnership(Type.NotFound, "Partnership.NotFound", title = "Partnership not found", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : Partnership(type, code, title,details) {
      sealed class Invalid(code: String, details: String) : Id(Type.Validation, code, "Invalid partnership's id", details) {
        data object Format : Invalid("Partnership.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : Partnership(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Name(Type.Validation, code, "Invalid partnership's name", details) {
        data object Blank : Invalid("Partnership.Name.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("Partnership.Name.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.partnership.PartnershipName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class MedicalDiploma(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class NotFound(details: String? = null) : MedicalDiploma(Type.NotFound, "MedicalDiploma.NotFound", details = details)

    class ProfessionMismatch(details: String? = null) : MedicalDiploma(Type.NotFound, "MedicalDiploma.ProfessionMismatch", details = details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : MedicalDiploma(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Id(Type.Validation, code, "Invalid medical diploma's id", details) {
        data object Format : Invalid("MedicalDiploma.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : MedicalDiploma(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Name(Type.Validation, code, "Invalid medical diploma's name", details) {
        data object Blank : Invalid("MedicalDiploma.Name.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("MedicalDiploma.Name.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class MedicalProfession(type: Type, code: String, title: String? = null, details: String? = null): Error(type, code, title, details) {
    data object NotFound: MedicalProfession(Type.NotFound, "MedicalProfession.NotFound")

    sealed class Id(type: Type, code: String, title: String?, details: String?) : MedicalProfession(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Id(Type.Validation, code, "Invalid medical profession's id", details) {
        data object Format : Invalid("MedicalProfession.Id.Invalid.Format","Invalid format")
      }
    }

    sealed class Name(type: Type, code: String, title: String?, details: String?) : MedicalProfession(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Name(Type.Validation, code, "Invalid partnership's name", details) {
        data object Blank : Invalid("MedicalProfession.Name.Invalid.Blank", "Can't be blank")
        data object TooLong : Invalid("MedicalProfession.Name.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName.MAX_LENGTH} characters")
      }
    }
  }

  sealed class MedicalSpeciality(type: Type, code: String, title: String? = null, details: String? = null): Error(type, code, title, details) {
    class NotFound(details: String? = null): MedicalSpeciality(Type.NotFound, "MedicalSpeciality.NotFound", details)

    class MismatchedMedicalProfession(details: String? = null): MedicalInterest(Type.Validation, "MedicalSpeciality.MismatchedMedicalProfession", "Wrong medical profession", details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : MedicalSpeciality(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Id(Type.Validation, code, "Invalid medical speciality's id", details) {
        data object Format : Invalid("MedicalSpeciality.Id.Invalid.Format","Invalid format")
      }
    }
  }

  sealed class MedicalInterest(type: Type, code: String, title: String? = null, details: String? = null): Error(type, code, title, details) {
    data object NotFound: MedicalInterest(Type.NotFound, "MedicalInterest.NotFound")

    class MismatchedMedicalSpeciality(details: String? = null): MedicalInterest(Type.Validation, "MedicalInterest.MismatchedMedicalSpeciality", "Wrong medical speciality", details)

    sealed class Id(type: Type, code: String, title: String?, details: String?) : MedicalInterest(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Id(Type.Validation, code, "Invalid medical interest's id", details) {
        data object Format : Invalid("MedicalInterest.Id.Invalid.Format","Invalid format")
      }
    }
  }

  sealed class FirstName(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String?) : FirstName(Type.Validation, code, "Invalide first name", details) {
      data object TooLong : Invalid("FirstName.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.common.FirstName.MAX_LENGTH} characters")
      data object Blank : Invalid("FirstName.Invalid.Blank","Can't be blank")
    }
  }

  sealed class MedicalCard(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Number(code: String, title: String, details: String? = null): MedicalCard(Type.Validation, code, title, details) {
      sealed class Invalid(code: String,details: String? = null): Number(code, "Invalid medical card's number", details) {
        data object Format: Invalid("MedicalCard.CardNumber.Invalid.Format","Must be 10 digits")
      }
    }

    class NotFound(details: String? = null) : MedicalCard(Type.NotFound, "MedicalCard.NotFound", title = "Medical card not found", details = details)
    class Expired(details: String? = null): MedicalCard(Type.Validation, "MedicalCard.Expired", title = "Medical card expired", details = details)
    class InvalidType(details: String? = null): MedicalCard(Type.Validation, "MedicalCard.InvalidType", title = "Medical card invalid type", details = details)
  }

  sealed class LastName(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String?) : LastName(Type.Validation, code, "Invalid last name", details) {
      data object TooLong : Invalid("LastName.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.common.LastName.MAX_LENGTH} characters")
      data object Blank : Invalid("LastName.Invalid.TooLong","Can't be blank")
    }
  }

  sealed class PhoneNumber(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String?) : PhoneNumber(Type.Validation, code, "Invalid phone number", details) {
      data object Format : Invalid("PhoneNumber.Invalid.Format","Must conform to the E.164 format")
    }
  }

  sealed class Password(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String?) : Password(Type.Validation, code, "Invalid password", details) {
      data object TooShort : Invalid("Password.Invalid.TooShort","Must be between or equals to ${fr.labodoc.domain.labodoc.common.Password.MIN_LENGTH} and ${fr.labodoc.domain.labodoc.common.Password.MAX_LENGTH} characters")
      data object TooLong : Invalid("Password.Invalid.TooLong","Must be between or equals to ${fr.labodoc.domain.labodoc.common.Password.MIN_LENGTH} and ${fr.labodoc.domain.labodoc.common.Password.MAX_LENGTH} characters")
      data object NoLowercaseLetter : Invalid("Password.Invalid.NoLowercaseLetter","Must contain at least one lowercase letter")
      data object NoUppercaseLetter : Invalid("Password.Invalid.NoUppercaseLetter","Must contain at least one uppercase letter")
      data object NoDigit : Invalid("Password.Invalid.NoDigit","Must contain at least one digit")
      data object NoSpecialCharacter : Invalid("Password.Invalid.NoSpecialCharacter","Must contain at least one special character")
    }
  }

  sealed class RPPSNumber(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String?) : RPPSNumber(Type.Validation, code, "Invalid RPPS number", details) {
      data object Format : Invalid("RPPSNumber.Invalid.Format","Must conform to the following regex ${fr.labodoc.domain.labodoc.common.RPPSNumber.regex.pattern}")
    }

    data object AlreadyUsed : RPPSNumber(Type.Conflict, "RPPSNumber.AlreadyUsed", "RPPS number bound to user")

    class NotFound(details: String? = null) : MedicalDiploma(Type.NotFound, "RPPSNumber.NotFound", details = details)
  }

  sealed class ContactPerson(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class JobTitle(type: Type, code: String, title: String?, details: String?) : ContactPerson(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : JobTitle(Type.Validation, code, "Invalid contact person's job title", details)
    }
  }

  sealed class Department(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Code(type: Type, code: String, title: String?, details: String?) : Department(type, code, title, details) {
      sealed class Invalid(code: String, details: String?) : Code(Type.Validation, code, "Invalid department's code", details) {
        data object Format : Invalid("Department.Code.Invalid.Format","Must conform to the following regex ${fr.labodoc.domain.labodoc.department.DepartmentCode.regex.pattern}")
      }
    }
  }

  sealed class EmailAddress(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    sealed class Invalid(code: String, details: String?) : EmailAddress(Type.Validation, code, "Invalid email address", details) {
      data object TooLong : Invalid("EmailAddress.Invalid.TooLong","Must must be less than or equals to ${fr.labodoc.domain.labodoc.common.EmailAddress.MAX_LENGTH} characters")
      data object Format : Invalid("EmailAddress.Invalid.Format", "Must conform to the following regex ${fr.labodoc.domain.labodoc.common.EmailAddress.regex.pattern}")
    }
  }

  sealed class CisCode(type: Type, code: String, title: String? = null, details: String? = null) : Error(type, code, title, details) {
    class AlreadyUsed(details: String? = null) : CisCode(Type.Conflict, "CisCode.AlreadyUsed", "CIS code already used", details)

    sealed class Invalid(code: String, details: String) : CisCode(Type.Validation, code, "Invalid CIS code", details) {
      data object Format : Invalid("CisCode.Invalid.Format","Must conform to the following regex ${fr.labodoc.domain.labodoc.medicine.CisCode.regex.pattern}")
    }
  }
}
