package fr.labodoc.app.data.admin.repository

import arrow.core.toNonEmptySetOrNull
import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateSegmentationTemplateRequest
import fr.labodoc.api.payloads.requests.UpdateSegmentationTemplateRequest
import fr.labodoc.api.payloads.responses.SegmentationTemplateCreatedResponse
import fr.labodoc.api.payloads.responses.SegmentationTemplateResponse
import fr.labodoc.app.data.admin.model.SegmentationTemplateModel
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateId
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateName

class SegmentationTemplatesRepositoryImpl(
  private val apiClient: AdminApiClient
) : SegmentationTemplatesRepository {
  override suspend fun getSegmentationTemplates(
    filterName: String?
  ): ApiResponse<Set<SegmentationTemplateModel>> =
    apiClient.segmentationTemplates
      .getSegmentationTemplates(filterName)
      .map { segmentationTemplates: Set<SegmentationTemplateResponse> ->
        segmentationTemplates
          .map { segmentationTemplate: SegmentationTemplateResponse ->
            SegmentationTemplateModel(
              id = SegmentationTemplateId(uuidFrom(segmentationTemplate.id)),
              name = SegmentationTemplateName(segmentationTemplate.name),
              segmentation = Segmentation(
                medicalProfessions = segmentationTemplate.medicalProfessions?.toNonEmptySetOrNull(),
                medicalSpecialities = segmentationTemplate.medicalSpecialities?.toNonEmptySetOrNull(),
                medicalInterests = segmentationTemplate.medicalInterests?.toNonEmptySetOrNull(),
                professionalCategories = segmentationTemplate.professionalCategories?.toNonEmptySetOrNull(),
                professionalStatuses = segmentationTemplate.professionalStatuses?.toNonEmptySetOrNull(),
                medicalCardTypes = segmentationTemplate.medicalCardTypes?.toNonEmptySetOrNull(),
                departments = null
              )
            )
          }
          .toSet()
      }

  override suspend fun createSegmentationTemplate(
    name: SegmentationTemplateName,
    segmentation: Segmentation
  ): ApiResponse<SegmentationTemplateId> =
    apiClient.segmentationTemplates
      .createSegmentationTemplate(
        payload = CreateSegmentationTemplateRequest(
          name = name.value,
          medicalProfessions = segmentation.medicalProfessions,
          medicalSpecialities = segmentation.medicalSpecialities,
          medicalInterests = segmentation.medicalInterests,
          professionalCategories = segmentation.professionalCategories,
          professionalStatuses = segmentation.professionalStatuses,
          medicalCardTypes = segmentation.medicalCardTypes,
          departments = segmentation.departments
        )
      )
      .map { segmentationTemplateCreatedResponse: SegmentationTemplateCreatedResponse ->
        SegmentationTemplateId(uuidFrom(segmentationTemplateCreatedResponse.id))
      }

  override suspend fun getSegmentationTemplate(
    id: SegmentationTemplateId
  ): ApiResponse<SegmentationTemplateModel> =
    apiClient.segmentationTemplates
      .getSegmentationTemplate(id)
      .map { segmentationTemplate: SegmentationTemplateResponse ->
        SegmentationTemplateModel(
          id = SegmentationTemplateId(uuidFrom(segmentationTemplate.id)),
          name = SegmentationTemplateName(segmentationTemplate.name),
          segmentation = Segmentation(
            medicalProfessions = segmentationTemplate.medicalProfessions?.toNonEmptySetOrNull(),
            medicalSpecialities = segmentationTemplate.medicalSpecialities?.toNonEmptySetOrNull(),
            medicalInterests = segmentationTemplate.medicalInterests?.toNonEmptySetOrNull(),
            professionalCategories = segmentationTemplate.professionalCategories?.toNonEmptySetOrNull(),
            professionalStatuses = segmentationTemplate.professionalStatuses?.toNonEmptySetOrNull(),
            medicalCardTypes = segmentationTemplate.medicalCardTypes?.toNonEmptySetOrNull(),
            departments = null
          )
        )
      }

  override suspend fun updateSegmentationTemplate(
    id: SegmentationTemplateId,
    name: SegmentationTemplateName,
    segmentation: Segmentation
  ): ApiResponse<Unit> =
    apiClient.segmentationTemplates
      .updateSegmentationTemplate(
        id = id,
        payload = UpdateSegmentationTemplateRequest(
          name = name.value,
          medicalProfessions = segmentation.medicalProfessions,
          medicalSpecialities = segmentation.medicalSpecialities,
          medicalInterests = segmentation.medicalInterests,
          professionalCategories = segmentation.professionalCategories,
          professionalStatuses = segmentation.professionalStatuses,
          medicalCardTypes = segmentation.medicalCardTypes,
          departments = segmentation.departments
        )
      )

  override suspend fun deleteSegmentationTemplate(
    id: SegmentationTemplateId
  ): ApiResponse<Unit> =
    apiClient.segmentationTemplates
      .deleteSegmentationTemplate(id)
}
