package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateSegmentationTemplateRequest
import fr.labodoc.api.payloads.requests.UpdateSegmentationTemplateRequest
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.SegmentationTemplateCreatedResponse
import fr.labodoc.api.payloads.responses.SegmentationTemplateResponse
import fr.labodoc.api.resources.SegmentationTemplates
import fr.labodoc.api.routes.SegmentationTemplateRoutes
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class SegmentationTemplateRemoteDataSource(
  private val httpClient: HttpClient
) : SegmentationTemplateRoutes {
  override suspend fun getSegmentationTemplates(
    filterName: String?
  ): ApiResponse<Set<SegmentationTemplateResponse>> {
    val response = httpClient.get(SegmentationTemplates(filterName))

    return if (response.status.isSuccess())
      response.body<Set<SegmentationTemplateResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createSegmentationTemplate(
    payload: CreateSegmentationTemplateRequest
  ): ApiResponse<SegmentationTemplateCreatedResponse> {
    val response = httpClient.post(SegmentationTemplates()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<SegmentationTemplateCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getSegmentationTemplate(
    id: SegmentationTemplateId
  ): ApiResponse<SegmentationTemplateResponse> {
    val response = httpClient.get(SegmentationTemplates.SegmentationTemplate(id.value.toString()))

    return if (response.status.isSuccess())
      response.body<SegmentationTemplateResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateSegmentationTemplate(
    id: SegmentationTemplateId,
    payload: UpdateSegmentationTemplateRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(SegmentationTemplates.SegmentationTemplate(id.value.toString())) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteSegmentationTemplate(
    id: SegmentationTemplateId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(SegmentationTemplates.SegmentationTemplate(id.value.toString()))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
