package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateLearnedSocietyRequest
import fr.labodoc.api.payloads.requests.UpdateLearnedSocietyRequest
import fr.labodoc.api.payloads.responses.AdminLearnedSocietyResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.LearnedSocietyIdResponse
import fr.labodoc.api.resources.LearnedSocieties
import fr.labodoc.api.routes.AdminLearnedSocietiesRoutes
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class AdminLearnedSocietiesRemoteDataSource(
  private val httpClient: HttpClient
) : AdminLearnedSocietiesRoutes {
  override suspend fun getLearnedSocieties(
  ): ApiResponse<Set<AdminLearnedSocietyResponse>> {
    val response = httpClient.get(LearnedSocieties())

    return if (response.status.isSuccess())
      response.body<Set<AdminLearnedSocietyResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getLearnedSociety(
    id: LearnedSocietyId
  ): ApiResponse<AdminLearnedSocietyResponse> {
    val response = httpClient.get(LearnedSocieties.LearnedSociety(id))

    return if (response.status.isSuccess())
      response.body<AdminLearnedSocietyResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createLearnedSociety(
    payload: CreateLearnedSocietyRequest,
    logo: InputFile
  ): ApiResponse<LearnedSocietyIdResponse> {
    val response = httpClient.post(LearnedSocieties()) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          append("logo", logo.content, Headers.build {
            append(HttpHeaders.ContentType, logo.mime)
            append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
          })
        }
      ))
    }

    return if (response.status.isSuccess())
      response.body<LearnedSocietyIdResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateLearnedSociety(
    id: LearnedSocietyId,
    payload: UpdateLearnedSocietyRequest,
    logo: InputFile?
  ): ApiResponse<Unit> {
    val response = httpClient.put(LearnedSocieties.LearnedSociety(id)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          logo?.let {
            append("logo", logo.content, Headers.build {
              append(HttpHeaders.ContentType, logo.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteLearnedSociety(
    id: LearnedSocietyId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(LearnedSocieties.LearnedSociety(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
