package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalLearnedSocietyResponse(
  val id: LearnedSocietyIdAsString,
  val name: LearnedSocietyNameAsString,
  val acronym: LearnedSocietyAcronymAsString?,
  val website: LearnedSocietyWebsiteAsString,
  val logo: UrlAsString,
  val medicalSpecialities: Set<MedicalSpecialityIdAsString>,
  val favorite: Boolean
)
