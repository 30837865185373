package fr.labodoc.webapp.pages.admin.medicines

import fr.labodoc.app.data.admin.model.MedicineSummaryModel
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.navigate
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicinesPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val medicines: ObservableList<MedicineSummaryModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun deleteMedicine(medicine: MedicineSummaryModel)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val medicinesRepository: MedicinesRepository by inject(named("admin"))

    private val medicines: ObservableListWrapper<MedicineSummaryModel> =
      ObservableListWrapper()

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        val newUiState = medicinesRepository
          .getMedicines()
          .fold(
            {
              ViewModel.UiState.Error
            },
            { medicines: Set<MedicineSummaryModel> ->
              this@ViewModelImpl.medicines.addAll(medicines)

              ViewModel.UiState.Loaded(
                medicines = this@ViewModelImpl.medicines
              )
            }
          )

        uiState.setState(newUiState)
      }

      observableValue
    }

    override fun deleteMedicine(medicine: MedicineSummaryModel) {
      App.scope.launch {
        medicinesRepository
          .deleteMedicine(medicine.id)
          .onLeft {
            Toast.danger("Une erreur est survenue lors de la supression du médicament")
          }
          .onRight {
            Toast.success("Médicament correctement supprimé")
            medicines.remove(medicine)
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-medicines"
    require("./css/pages/admin/medicines/medicines.css")

    div(className = "page-width") {
      header {
        h1("Liste des médicaments")

        navigoLink("", Page.AdminBackOfficeMedicineCreate()) {
          labodocButton("Ajouter un médicament", "fa-solid fa-plus")
        }
      }

      div(className = "container").bind(viewModel.uiState) { uiState ->
        when (uiState) {
          ViewModel.UiState.Loading -> {
            labodocSpinner()
          }

          ViewModel.UiState.Error -> {
            p("Impossible de récupérer la liste des médicaments")
          }

          is ViewModel.UiState.Loaded -> {
            div(className = "medicines").bindEach(uiState.medicines) { medicine ->
              val deleteConfirmationModal = MedicineDeleteConfirmationModal(
                name = medicine.name,
                onDeleteConfirmed = { viewModel.deleteMedicine(medicine) }
              )

              div(className = "medicine") {
                header {
                  image(medicine.logoUrl.toString()) {
                    onClick { App.routing.navigate(Page.AdminBackOfficeMedicineSheet(medicine.id)) }
                  }
                  navigoLink(medicine.name.value, Page.AdminBackOfficeMedicineSheet(medicine.id), className = "name")
                }

                hr()

                div(className = "actions") {
                  navigoLink("Mettre à jour", Page.AdminBackOfficeMedicineUpdate(medicine.id), icon = "fa-solid fa-pencil", className = "action edit")
                  labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "action delete") {
                    onClick {
                      deleteConfirmationModal.show()
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.adminMedicinesPages(): AdminMedicinesPage {
  val adminMedicinesPage = AdminMedicinesPage()

  this.add(adminMedicinesPage)
  return adminMedicinesPage
}
