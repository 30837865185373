package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.MedicalInterestCreatedResponse
import fr.labodoc.api.payloads.responses.MedicalProfessionCreatedResponse
import fr.labodoc.api.payloads.responses.MedicalProfessionResponse
import fr.labodoc.api.payloads.responses.MedicalSpecialityCreatedResponse
import fr.labodoc.app.data.admin.model.MedicalInterestModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.MedicalSpecialityModel
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestName
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partnership.PartnershipId

class MedicalProfessionsRepositoryImpl(
  private val apiClient: AdminApiClient
) : MedicalProfessionsRepository {
  override suspend fun getMedicalProfessions(
  ): ApiResponse<Set<MedicalProfessionModel>> =
    apiClient.medicalProfessions
      .getMedicalProfessions()
      .map { medicalProfessions: Set<MedicalProfessionResponse> ->
        medicalProfessions
          .map { medicalProfession: MedicalProfessionResponse ->
            MedicalProfessionModel(
              id = medicalProfession.id,
              name = medicalProfession.name,
              medicalSpecialities = medicalProfession.medicalSpecialities
                .map { medicalSpeciality: MedicalProfessionResponse.MedicalSpeciality ->
                  MedicalSpecialityModel(
                    id = medicalSpeciality.id,
                    name = medicalSpeciality.name,
                    partnership = medicalSpeciality.partnership,
                    medicalInterests = medicalSpeciality.medicalInterests
                      .map { medicalInterest ->
                        MedicalInterestModel(
                          id = medicalInterest.id,
                          name = medicalInterest.name
                        )
                      }
                      .toSet()
                  )
                }
                .toSet()
            )
          }
          .toSet()
      }

  override suspend fun createMedicalProfession(
    name: MedicalProfessionName
  ): ApiResponse<MedicalProfessionId> =
    apiClient.medicalProfessions
      .createMedicalProfession(
        payload = CreateMedicalProfessionRequest(
          name = name
        )
      )
      .map { medicalProfessionCreatedResponse: MedicalProfessionCreatedResponse ->
        medicalProfessionCreatedResponse.id
      }


  override suspend fun getMedicalProfession(
    id: MedicalProfessionId
  ): ApiResponse<MedicalProfessionModel> =
    apiClient.medicalProfessions
      .getMedicalProfession(id)
      .map { medicalProfession: MedicalProfessionResponse ->
        MedicalProfessionModel(
          id = medicalProfession.id,
          name = medicalProfession.name,
          medicalSpecialities = medicalProfession.medicalSpecialities
            .map { medicalSpeciality: MedicalProfessionResponse.MedicalSpeciality ->
              MedicalSpecialityModel(
                id = medicalSpeciality.id,
                name = medicalSpeciality.name,
                partnership = medicalSpeciality.partnership,
                medicalInterests = medicalSpeciality.medicalInterests
                  .map { medicalInterest ->
                    MedicalInterestModel(
                      id = medicalInterest.id,
                      name = medicalInterest.name
                    )
                  }
                  .toSet()
              )
            }
            .toSet()
        )
      }

  override suspend fun updateMedicalProfession(
    id: MedicalProfessionId,
    name: MedicalProfessionName
  ): ApiResponse<Unit> =
    apiClient.medicalProfessions
      .updateMedicalProfession(
        id = id,
        payload = UpdateMedicalProfessionRequest(
          name = name
        )
      )

  override suspend fun createMedicalSpeciality(
    medicalProfession: MedicalProfessionId,
    name: MedicalSpecialityName,
    partnership: PartnershipId?
  ): ApiResponse<MedicalSpecialityId> =
    apiClient.medicalProfessions
      .createMedicalSpeciality(
        medicalProfessionId = medicalProfession,
        payload = CreateMedicalSpecialityRequest(
          name = name,
          partnership = partnership
        )
      )
      .map { medicalSpecialityCreatedResponse: MedicalSpecialityCreatedResponse ->
        medicalSpecialityCreatedResponse.id
      }

  override suspend fun updateMedicalSpeciality(
    medicalProfession: MedicalProfessionId,
    id: MedicalSpecialityId,
    name: MedicalSpecialityName,
    partnership: PartnershipId?
  ): ApiResponse<Unit> =
    apiClient.medicalProfessions
      .updateMedicalSpeciality(
        medicalProfessionId = medicalProfession,
        id = id,
        payload = UpdateMedicalSpecialityRequest(
          name = name,
          partnership = partnership
        )
      )

  override suspend fun createMedicalInterest(
    medicalProfession: MedicalProfessionId,
    medicalSpeciality: MedicalSpecialityId,
    name: MedicalInterestName
  ): ApiResponse<MedicalInterestId> =
    apiClient.medicalProfessions
      .createMedicalInterest(
        medicalProfessionId = medicalProfession,
        medicalSpecialityId = medicalSpeciality,
        payload = CreateMedicalInterestRequest(
          name = name
        )
      )
      .map { medicalInterestCreatedResponse: MedicalInterestCreatedResponse ->
        medicalInterestCreatedResponse.id
      }

  override suspend fun updateMedicalInterest(
    medicalProfession: MedicalProfessionId,
    medicalSpeciality: MedicalSpecialityId,
    id: MedicalInterestId,
    name: MedicalInterestName
  ): ApiResponse<Unit> =
    apiClient.medicalProfessions
      .updateMedicalInterest(
        medicalProfessionId = medicalProfession,
        medicalSpecialityId = medicalSpeciality,
        id = id,
        payload = UpdateMedicalInterestRequest(
          name = name
        )
      )
}
