package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreatePartnerRequest
import fr.labodoc.api.payloads.requests.UpdatePartnerRequest
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.PartnerCodeResponse
import fr.labodoc.api.payloads.responses.PartnersResponse
import fr.labodoc.api.payloads.serializers.PartnerCodeAsString
import fr.labodoc.api.resources.Partners
import fr.labodoc.api.routes.AdminPartnersRoutes
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminPartnersRemoteDataSource(
  private val httpClient: HttpClient
) : AdminPartnersRoutes {
  override suspend fun getPartners(
  ): ApiResponse<PartnersResponse> {
    val response = httpClient.get(Partners())

    return if (response.status.isSuccess())
      response.body<PartnersResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createPartner(
    payload: CreatePartnerRequest
  ): ApiResponse<PartnerCodeResponse> {
    val response = httpClient.post(Partners()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<PartnerCodeResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updatePartner(
    code: PartnerCodeAsString,
    payload: UpdatePartnerRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(Partners.Partner(code)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deletePartner(
    code: PartnerCodeAsString
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Partners.Partner(code))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
