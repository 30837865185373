package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.AtcCategoryWithCodesResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.resources.AtcCodes
import fr.labodoc.api.routes.AtcCodeRoutes
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class AtcCodesRemoteDataSource(
  private val httpClient: HttpClient
) : AtcCodeRoutes {
  override suspend fun getAtcCodes(
  ): ApiResponse<Set<AtcCategoryWithCodesResponse>> {
    val response = httpClient.get(AtcCodes())

    return if (response.status.isSuccess())
      response.body<Set<AtcCategoryWithCodesResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getAtcCodesInUsage(
  ): ApiResponse<Set<AtcCategoryWithCodesResponse>> {
    val response = httpClient.get(AtcCodes.Used())

    return if (response.status.isSuccess())
      response.body<Set<AtcCategoryWithCodesResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
