package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.DepartmentResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.resources.Departments
import fr.labodoc.api.routes.DepartmentsRoutes
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class DepartmentsRemoteDataSource(
  private val httpClient: HttpClient
) : DepartmentsRoutes {
  override suspend fun getDepartments(
  ): ApiResponse<Set<DepartmentResponse>> {
    val response = httpClient.get(Departments())

    return if (response.status.isSuccess())
      response.body<Set<DepartmentResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
