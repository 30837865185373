package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.LastName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object LastNameAsStringSerializer : KSerializer<LastName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LastNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(lastName: String): Either<Errors.LastName.Invalid, LastName> = LastName(lastName)

  override fun deserialize(decoder: Decoder): LastName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(lastName: LastName): String = lastName.value

  override fun serialize(encoder: Encoder, value: LastName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias LastNameAsString = @Serializable(LastNameAsStringSerializer::class) LastName
