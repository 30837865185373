package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreatePartnerRequest
import fr.labodoc.api.payloads.requests.UpdatePartnerRequest
import fr.labodoc.api.payloads.responses.PartnerCodeResponse
import fr.labodoc.api.payloads.responses.PartnersResponse
import fr.labodoc.app.data.admin.model.PartnerModel
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.domain.labodoc.partner.PartnerName

class PartnersRepositoryImpl(
  private val apiClient: AdminApiClient
) : PartnersRepository {
  override suspend fun getPartners(
  ): ApiResponse<Set<PartnerModel>> =
    apiClient.partners
      .getPartners()
      .map { partnersResponse: PartnersResponse ->
        partnersResponse
          .partners
          .map { partner: PartnersResponse.Partner ->
            PartnerModel(
              code = partner.code,
              name = partner.name
            )
          }
          .toSet()
      }

  override suspend fun createPartner(
    code: PartnerCode?,
    name: PartnerName
  ): ApiResponse<PartnerCode> =
    apiClient.partners
      .createPartner(
        payload = CreatePartnerRequest(
          code = code,
          name = name
        )
      )
      .map { partnerCodeResponse: PartnerCodeResponse ->
        partnerCodeResponse.code
      }

  override suspend fun updatePartner(
    code: PartnerCode,
    newCode: PartnerCode,
    name: PartnerName
  ): ApiResponse<Unit> =
    apiClient.partners
      .updatePartner(
        code = code,
        payload = UpdatePartnerRequest(
          code = newCode,
          name = name
        )
      )

  override suspend fun deletePartner(
    code: PartnerCode
  ): ApiResponse<Unit> =
    apiClient.partners
      .deletePartner(
        code = code
      )
}
