package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.*
import io.kvision.html.*
import io.kvision.panel.Root
import io.kvision.panel.SimplePanel
import io.kvision.panel.Root.Companion.addModal
import io.kvision.panel.Root.Companion.removeModal

open class LocalLabodocPopup(
    closeButton: Boolean = false,
    icon: String? = null,
    image: ResString? = null,
    className: String? = null,
    content: (Container.(LocalLabodocPopup) -> Unit),
    beforeClose: (() -> Unit)? = null,
    init: (LocalLabodocPopup.() -> Unit)? = null
) : SimplePanel("labodoc-popup") {
  init {
    this.hide()

    require("./css/components/labodoc-popup.css")

    className?.let { addCssClass(it) }

    div(className = "labodoc-popup-container") {
      header {
        if (image != null) {
          this.background = Background(
            image = image,
            repeat = BgRepeat.NOREPEAT,
            size = BgSize.COVER
          )
          this@LocalLabodocPopup.addCssClass("with-image")
        }

        if (closeButton) {
          span("&#10006;", rich = true, className = "close-button") {
            onClick {
              beforeClose?.invoke()
              this@LocalLabodocPopup.hide()
            }
          }
          this@LocalLabodocPopup.addCssClass("with-close-button")
        }

        if (icon != null) {
          this@LocalLabodocPopup.addCssClass("with-icon")
          i(className = "icon $icon")
        }
      }

      val contentContainer = div(className = "labodoc-popup-container-content")
      content.invoke(contentContainer, this@LocalLabodocPopup)
    }

    init?.invoke(this)
  }
}
