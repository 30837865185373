package fr.labodoc.domain.labodoc.department

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class DepartmentCode private constructor(val value: String) {
  companion object {
    val regex: Regex = Regex("^(0[1-9]|[1-8][0-9]|9[0-5]|2[AB]|97[1-6]|98[6-8])\$")

    fun isFormatValid(value: String): Boolean = regex.containsMatchIn(value)

    operator fun invoke(value: String): Either<Errors.Department.Code.Invalid, DepartmentCode> =
      either {
        ensure(isFormatValid(value)) { Errors.Department.Code.Invalid.Format }

        DepartmentCode(value)
      }
  }
}
