package fr.labodoc.webapp.layouts

import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.components.picture
import io.kvision.html.*

class LabodocFooter() : Footer() {
  init {
    require("./css/labodoc-footer.css")
    addCssClass("labodoc-background-middle-blue")

    div(className = "page-width") {
      div(className = "up") {
        picture(require("./img/Labodoc-logotype-horizontal-blanc.svg"), "LaboDoc") {
          addSource("(max-width:700px)", require("./img/Labodoc-logotype-blanc.svg"))
        }
        nav {
          navigoLink("Contactez-nous", Page.Contact())
          navigoLink("Besoin d'aide ? (FAQ)", Page.FAQ())
          navigoLink("Qui sommes-nous ?", Page.WhoAreWe())
          navigoLink("À propos", Page.About())
        }
      }

      hr()

      div(className = "bottom") {
        listTag(ListType.UL) {
          li {
            content = "© Labodoc"
          }
          li {
            link("Mentions légales", Page.LegalNotice().url, target = "_blank")
          }
          li {
            link("Politique de confidentialité", Page.PrivacyPolicy().url, target = "_blank")
          }
          li {
            link("Conditions générales d'utilisation", Page.TermsOfService().url, target = "_blank")
          }
          li {
            content = "Design : Océane Remillet"
          }
        }
      }
    }
  }
}
