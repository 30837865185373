package fr.labodoc.app.data.admin.repository

import arrow.core.right
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.ProfessionalStatusModel
import fr.labodoc.domain.healthdirectory.ProfessionalStatusCode
import fr.labodoc.domain.healthdirectory.ProfessionalStatusName

class ProfessionalStatusesRepositoryImpl(
  private val apiClient: AdminApiClient
) : ProfessionalStatusesRepository {
  override suspend fun getProfessionalStatuses(
  ): ApiResponse<Set<ProfessionalStatusModel>> =
    setOf(
      ProfessionalStatusModel(
        code = ProfessionalStatusCode("S"),
        name = ProfessionalStatusName("Salarié")
      ),
      ProfessionalStatusModel(
        code = ProfessionalStatusCode("L"),
        name = ProfessionalStatusName("Libéral")
      ),
      ProfessionalStatusModel(
        code = ProfessionalStatusCode("R"),
        name = ProfessionalStatusName("Retraité")
      )
    ).right()
}
