package fr.labodoc.domain.labodoc.laboratory

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class LaboratoryName private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 40

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH

    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.Laboratory.Name.Invalid, LaboratoryName> =
      either {
        ensure(isNotTooLong(value)) { Errors.Laboratory.Name.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.Laboratory.Name.Invalid.Blank }

        LaboratoryName(value)
      }
  }
}
