package fr.labodoc.domain.labodoc.learnedsociety

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class LearnedSocietyAcronym private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 15

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH

    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.LearnedSociety.Acronym.Invalid, LearnedSocietyAcronym> =
      either {
        ensure(isNotTooLong(value)) { Errors.LearnedSociety.Acronym.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.LearnedSociety.Acronym.Invalid.Blank }

        LearnedSocietyAcronym(value)
      }
  }
}
