package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreatePartnershipRequest
import fr.labodoc.api.payloads.requests.UpdatePartnershipRequest
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.PartnershipCreatedResponse
import fr.labodoc.api.payloads.responses.PartnershipResponse
import fr.labodoc.api.payloads.responses.PartnershipsResponse
import fr.labodoc.api.payloads.serializers.PartnershipIdAsString
import fr.labodoc.api.resources.Partnerships
import fr.labodoc.api.routes.AdminPartnershipsRoutes
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class AdminPartnershipsRemoteDataSource(
  private val httpClient: HttpClient
) : AdminPartnershipsRoutes {
  override suspend fun getPartnerships(
  ): ApiResponse<PartnershipsResponse> {
    val response = httpClient.get(Partnerships())

    return if (response.status.isSuccess())
      response.body<PartnershipsResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createPartnership(
    payload: CreatePartnershipRequest,
    logo: InputFile
  ): ApiResponse<PartnershipCreatedResponse> {
    val response = httpClient.post(Partnerships()) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          append("logo", logo.content, Headers.build {
            append(HttpHeaders.ContentType, logo.mime)
            append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
          })
        }
      ))
    }

    return if (response.status.isSuccess())
      response.body<PartnershipCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getPartnership(
    id: PartnershipId
  ): ApiResponse<PartnershipResponse> {
    val response = httpClient.get(Partnerships.Partnership(id))

    return if (response.status.isSuccess())
      response.body<PartnershipResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updatePartnership(
    id: PartnershipIdAsString,
    payload: UpdatePartnershipRequest,
    logo: InputFile?
  ): ApiResponse<Unit> {
    val response = httpClient.put(Partnerships.Partnership(id)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          logo?.let {
            append("logo", logo.content, Headers.build {
              append(HttpHeaders.ContentType, logo.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deletePartnership(
    id: PartnershipIdAsString
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Partnerships.Partnership(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
