package fr.labodoc.webapp.pages.admin.universityHospitals.form

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalName
import fr.labodoc.webapp.components.labodocSelect
import fr.labodoc.webapp.components.labodocText
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.select.Select
import io.kvision.form.text.Text
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class AdminUniversityHospitalForm(
  departments: Map<DepartmentCode, DepartmentName>
) : FormPanel<AdminUniversityHospitalForm.Data>(
  serializer = Data.serializer(),
  className = "admin-university-hospital-form"
) {
  @Serializable
  data class Data(
    val nameInput: String? = null,
    val departmentCodeInput: String? = null
  ) {
    constructor(
      name: UniversityHospitalName? = null,
      departmentCode: DepartmentCode? = null
    ): this(
      nameInput = name?.value,
      departmentCodeInput = departmentCode?.value
    )

    val name: Either<Errors.UniversityHospital.Name, UniversityHospitalName>?
      get() = nameInput?.let { UniversityHospitalName(it) }

    val departmentCode: Either<Errors.Department, DepartmentCode>?
      get() = departmentCodeInput?.let { DepartmentCode(it) }

    companion object {
      fun validateName(name: Text): String? = UniversityHospitalName(name.value ?: "").fold(
        { error ->
          when (error) {
            Errors.UniversityHospital.Name.Invalid.Blank -> "Ne peut pas être vide"
            Errors.UniversityHospital.Name.Invalid.TooLong -> "Trop long"
          }
        },
        {
          null
        }
      )

      fun validateDepartmentCode(departmentCode: Select): String? = DepartmentCode(departmentCode.value ?: "").fold(
        { error ->
          when (error) {
            Errors.Department.Code.Invalid.Format -> "Numéro du département invalide"
          }
        },
        {
          null
        }
      )
    }
  }

  init {
    labodocText {
      label = "Nom"
    }.bindCustom(
      key = Data::nameInput,
      required = true,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateName(it) == null },
      validatorMessage = { Data.validateName(it) }
    )

    labodocSelect {
      label = "Département"
      options = departments
        .map { (departmentCode, departmentName) ->
          departmentCode.value to "${departmentCode.value} - ${departmentName.value}"
        }
        .sortedBy { it.first }
    }.bindCustom(
      key = Data::departmentCodeInput,
      required = true,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateDepartmentCode(it) == null },
      validatorMessage = { Data.validateDepartmentCode(it) }
    )
  }
}

fun Container.adminUniversityHospitalForm(
  departments: Map<DepartmentCode, DepartmentName>
): AdminUniversityHospitalForm {
  val adminUniversityHospitalForm = AdminUniversityHospitalForm(departments)

  this.add(adminUniversityHospitalForm)
  return adminUniversityHospitalForm
}
