package fr.labodoc.webapp.pages.admin.partner

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.PartnerModel
import fr.labodoc.app.data.admin.repository.PartnersRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.domain.labodoc.partner.PartnerName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.pages.admin.partner.form.AdminPartnerForm
import fr.labodoc.webapp.pages.admin.partner.form.adminPartnerForm
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminPartnersPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val partners: Set<PartnerModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    val createModalDisplayed: ObservableState<Boolean>

    val createProcessing: ObservableState<Boolean>

    fun refreshPage(
    )

    fun displayCreateModal(
    )

    fun createPartner(
      formData: AdminPartnerForm.Data
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val partnersRepository: PartnersRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      refreshPage()

      observableValue
    }

    override val createModalDisplayed: ObservableValue<Boolean> =
      ObservableValue(false)

    override val createProcessing: ObservableValue<Boolean> =
      ObservableValue(false)

    override fun refreshPage(
    ) {
      App.scope.launch {
        uiState.setState(ViewModel.UiState.Loading)

        val newUiState = partnersRepository
          .getPartners()
          .fold(
            {
              ViewModel.UiState.Error
            },
            { partners: Set<PartnerModel> ->
              ViewModel.UiState.Loaded(
                partners = partners
              )
            }
          )

        uiState.setState(newUiState)
      }
    }

    override fun displayCreateModal(
    ) {
      createModalDisplayed.setState(true)
    }

    override fun createPartner(
      formData: AdminPartnerForm.Data
    ) {
      App.scope.launch {
        createProcessing.setState(true)

        data class PartnerData(
          val code: PartnerCode?,
          val name: PartnerName
        )

        val partnerDataResult = either<Nel<String>, PartnerData> {
          zipOrAccumulate(
            {
              formData.code?.let { partnerCode ->
                PartnerCode(partnerCode)
                  .mapLeft { error: Errors.Partner.Code.Invalid ->
                    when (error) {
                      Errors.Partner.Code.Invalid.Blank -> "Le code du partenaire ne peut pas être vide si renseigné"
                      Errors.Partner.Code.Invalid.TooLong -> "Le code du partenaire est trop long"
                    }
                  }
                  .bind()
              }
            },
            {
              ensureNotNull(formData.name) { "Le nom du partenaire est obligatoire" }
              PartnerName(formData.name)
                .mapLeft { error: Errors.Partner.Name.Invalid ->
                  when (error) {
                    Errors.Partner.Name.Invalid.Blank -> "Le nom du partenaire ne peut pas être vide"
                    Errors.Partner.Name.Invalid.TooLong -> "Le nom du partenaire est trop long"
                  }
                }
                .bind()
            }
          ) { code, name ->
            PartnerData(
              code = code,
              name = name
            )
          }
        }

        when (partnerDataResult) {
          is Either.Left -> {
            val errorMessages = partnerDataResult.value

            errorMessages.forEach { errorMessage: String ->
              Toast.danger(errorMessage)
            }
          }

          is Either.Right -> {
            val partnerData = partnerDataResult.value

            partnersRepository
              .createPartner(
                code = partnerData.code,
                name = partnerData.name
              )
              .onLeft { errorResponse: ErrorResponse ->
                val errorMessage = when (errorResponse.code) {
                  Errors.Partner.Code.AlreadyUsed().code -> "Ce code partenaire est déjà utilisé"
                  else -> "Une erreur est survenue"
                }

                Toast.danger(errorMessage)
              }
              .onRight { _: PartnerCode ->
                Toast.success("Partenaire correctement créé")
                createModalDisplayed.setState(false)
                refreshPage()
              }
          }
        }

        createProcessing.setState(false)
      }
    }
  }

  private class PartnerRow(
    partner: PartnerModel,
    onPartnerUpdated: (PartnerCode) -> Unit,
    onPartnerDeleted: (PartnerCode) -> Unit
  ) : SimplePanel(className = "partner labodoc-background-light-blue") {
    private interface ViewModel {
      val updateModalDisplayed: ObservableState<Boolean>

      val updateProcessing: ObservableState<Boolean>

      val deleteModalDisplayed: ObservableState<Boolean>

      val deleteProcessing: ObservableState<Boolean>

      fun displayUpdateModal(
      )

      fun updatePartner(
        formData: AdminPartnerForm.Data
      )

      fun displayDeleteModal(
      )

      fun hideDeleteModal(
      )

      fun deletePartner(
      )
    }

    private class ViewModelImpl(
      private val partner: PartnerModel,
      private val onPartnerUpdated: (PartnerCode) -> Unit,
      private val onPartnerDeleted: (PartnerCode) -> Unit
    ) : ViewModel, KoinComponent {
      private val partnersRepository: PartnersRepository by inject(named("admin"))

      override val updateModalDisplayed: ObservableValue<Boolean> =
        ObservableValue(false)

      override val updateProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override val deleteModalDisplayed: ObservableValue<Boolean> =
        ObservableValue(false)

      override val deleteProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override fun displayUpdateModal(
      ) {
        updateModalDisplayed.setState(true)
      }

      override fun updatePartner(formData: AdminPartnerForm.Data) {
        App.scope.launch {
          updateProcessing.setState(true)

          data class PartnerData(
            val code: PartnerCode,
            val name: PartnerName
          )

          val partnerDataResult = either<Nel<String>, PartnerData> {
            zipOrAccumulate(
              {
                ensureNotNull(formData.code) { "Le cpde du partenaire est obligatoire" }
                PartnerCode(formData.code)
                  .mapLeft { error: Errors.Partner.Code.Invalid ->
                    when (error) {
                      Errors.Partner.Code.Invalid.Blank -> "Le code du partenaire ne peut pas être vide si renseigné"
                      Errors.Partner.Code.Invalid.TooLong -> "Le code du partenaire est trop long"
                    }
                  }
                  .bind()
              },
              {
                ensureNotNull(formData.name) { "Le nom du partenaire est obligatoire" }
                PartnerName(formData.name)
                  .mapLeft { error: Errors.Partner.Name.Invalid ->
                    when (error) {
                      Errors.Partner.Name.Invalid.Blank -> "Le nom du partenaire ne peut pas être vide"
                      Errors.Partner.Name.Invalid.TooLong -> "Le nom du partenaire est trop long"
                    }
                  }
                  .bind()
              }
            ) { code, name ->
              PartnerData(
                code = code,
                name = name
              )
            }
          }

          when (partnerDataResult) {
            is Either.Left -> {
              val errorMessages = partnerDataResult.value

              errorMessages.forEach { errorMessage: String ->
                Toast.danger(errorMessage)
              }
            }

            is Either.Right -> {
              val partnerData = partnerDataResult.value

              partnersRepository
                .updatePartner(
                  code = partner.code,
                  newCode = partnerData.code,
                  name = partnerData.name
                )
                .onLeft { errorResponse: ErrorResponse ->
                  val errorMessage = when (errorResponse.code) {
                    Errors.Partner.NotFound().code -> "Ce partenaire n'existe pas"
                    Errors.Partner.Code.AlreadyUsed().code -> "Ce code partenaire est déjà utilisé"
                    else -> "Une erreur est survenue"
                  }

                  Toast.danger(errorMessage)
                }
                .onRight { _: Unit ->
                  Toast.success("Partenaire correctement mis à jour")
                  updateModalDisplayed.setState(false)
                  onPartnerUpdated(partner.code)
                }
            }
          }

          updateProcessing.setState(false)
        }
      }

      override fun displayDeleteModal(
      ) {
        deleteModalDisplayed.setState(true)
      }

      override fun hideDeleteModal(
      ) {
        deleteModalDisplayed.setState(false)
      }

      override fun deletePartner() {
        App.scope.launch {
          deleteProcessing.setState(true)

          partnersRepository
            .deletePartner(partner.code)
            .onLeft { errorResponse: ErrorResponse ->
              val errorMessage = when (errorResponse.code) {
                Errors.Partner.NotFound().code -> "Ce partenaire n'existe pas"
                else -> "Une erreur est survenue"
              }

              Toast.danger(errorMessage)
            }
            .onRight { _: Unit ->
              Toast.success("Partenaire correctement supprimé")
              deleteModalDisplayed.setState(false)
              onPartnerDeleted(partner.code)
            }

          deleteProcessing.setState(false)
        }
      }
    }

    private val viewModel: ViewModel =
      ViewModelImpl(partner, onPartnerUpdated, onPartnerDeleted)

    private val updateModal =
      LabodocPopup(
        closeButton = true,
        icon = null,
        image = null,
        className = "admin-partner-form-modal",
        content = {
          h1("Partenaire")

          val partnerForm = adminPartnerForm(
            codeRequired = true
          )

          viewModel.updateModalDisplayed.subscribe { updateModalDisplayed ->
            if (updateModalDisplayed) {
              partnerForm.setData(
                AdminPartnerForm.Data(
                  code = partner.code.value,
                  name = partner.name.value
                )
              )
            }
          }

          labodocButton("", className = "labodoc-background-yellow").bind(viewModel.updateProcessing) { updateProcessing ->
            removeEventListeners()

            if (updateProcessing) {
              disabled = true
              text = ""
              icon = "fa fa-spinner fa-spin"
            } else {
              disabled = false
              text = "Sauvegarder"
              icon = null

              onClick {
                if (partnerForm.validate())
                  viewModel.updatePartner(partnerForm.getData())
              }
            }
          }
        },
        beforeClose = null
      ) {
        viewModel.updateModalDisplayed.subscribe { updateModalDisplayed ->
          if (updateModalDisplayed)
            show()
          else
            hide()
        }
      }

    private val deleteConfirmationModal =
      LabodocPopup(
        closeButton = true,
        icon = "fa-solid fa-warning",
        image = null,
        className = "admin-partner-delete-confirmation-modal",
        content = {
          p(className = "title") {
            content = "Êtes-vous sûr de vouloir supprimer le partenaire \"${partner.name.value}\" ?"
          }

          div(className = "choices") {
            labodocButton("", className = "delete").bind(viewModel.deleteProcessing) { deleteProcessing ->
              removeEventListeners()

              if (deleteProcessing) {
                disabled = true
                text = ""
                icon = "fa fa-spinner fa-spin"
              } else {
                disabled = false
                text = "Supprimer"
                icon = "fa-solid fa-trash"

                onClick {
                  viewModel.deletePartner()
                }
              }
            }

            labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
              onClick {
                viewModel.hideDeleteModal()
              }
            }
          }
        },
        beforeClose = null
      ) {
        viewModel.deleteModalDisplayed.subscribe { deleteModalDisplayed ->
          if (deleteModalDisplayed)
            show()
          else
            hide()
        }
      }

    init {
      div(className = "details") {
        p(partner.name.value, className = "name")

        p(partner.code.value, className = "code")
      }

      div(className = "actions") {
        button("", className = "action edit").bind(viewModel.updateProcessing) { updateProcessing ->
          removeEventListeners()

          if (updateProcessing) {
            disabled = true
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            icon = "fa-solid fa-solid fa-pencil"

            onClick {
              viewModel.displayUpdateModal()
            }
          }
        }

        button("", className = "action delete").bind(viewModel.deleteProcessing) { deleteProcessing ->
          removeEventListeners()

          if (deleteProcessing) {
            disabled = true
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            icon = "fa-solid fa-solid fa-trash"

            onClick {
              viewModel.displayDeleteModal()
            }
          }
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  private val creationModal =
    LabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-partner-form-modal",
      content = {
        h1("Partenaire")

        val partnerCreationForm = adminPartnerForm(
          codeRequired = false
        )

        viewModel.createModalDisplayed.subscribe { createModalDisplayed ->
          if (createModalDisplayed) {
            partnerCreationForm.setData(
              AdminPartnerForm.Data(
                code = null,
                name = null
              )
            )
          }
        }

        labodocButton(
          "",
          className = "labodoc-background-yellow"
        ).bind(viewModel.createProcessing) { createProcessing ->
          removeEventListeners()

          if (createProcessing) {
            disabled = true
            text = ""
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            text = "Sauvegarder"
            icon = null

            onClick {
              if (partnerCreationForm.validate())
                viewModel.createPartner(partnerCreationForm.getData())
            }
          }
        }
      },
      beforeClose = null
    ) {
      viewModel.createModalDisplayed.subscribe { createModalDisplayed ->
        if (createModalDisplayed)
          show()
        else
          hide()
      }
    }

  init {
    id = "page-admin-partners"
    require("./css/pages/admin/partner/partners.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      header {
        h1("Liste des partenaires")

        if (uiState is ViewModel.UiState.Loaded) {
          labodocButton("").bind(viewModel.createProcessing) { createProcessing ->
            removeEventListeners()

            if (createProcessing) {
              disabled = true
              text = ""
              icon = "fa fa-spinner fa-spin"


            } else {
              disabled = false
              text = "Ajouter un partenaire"
              icon = "fa-solid fa-plus"


              onClick {
                viewModel.displayCreateModal()
              }
            }
          }
        }
      }

      div(className = "partners") {
        header {
          p("Nom")
          p("Code")
          p("Actions")
        }

        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          ViewModel.UiState.Error -> p("Impossible de récupérer la liste des partenaires")

          is ViewModel.UiState.Loaded -> {
            uiState.partners.forEach { partner ->
              add(
                PartnerRow(
                  partner = partner,
                  onPartnerUpdated = { viewModel.refreshPage() },
                  onPartnerDeleted = { viewModel.refreshPage() }
                )
              )
            }
          }
        }
      }
    }
  }
}

fun Container.adminPartnersPage(): AdminPartnersPage {
  val adminPartnersPage = AdminPartnersPage()

  this.add(adminPartnersPage)
  return adminPartnersPage
}
