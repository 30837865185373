package fr.labodoc.webapp.pages.admin.medicines

import arrow.core.Either
import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.AtcCategoryModel
import fr.labodoc.app.data.admin.model.LaboratorySummaryModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.AtcCodesRepository
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.*
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.medicines.sections.AdminMedicineForm
import fr.labodoc.webapp.utils.toInputFile
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicineCreatePage(
  laboratoryId: LaboratoryId?
) : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val laboratories: Set<LaboratorySummaryModel>,
        val atcs: Set<AtcCategoryModel>,
        val medicalProfessions: Set<MedicalProfessionModel>,
        val selectedLaboratory: LaboratoryId?
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    val createMedicineResponse: ObservableState<ApiResponse<MedicineId>?>

    fun createMedicine(
      laboratory: LaboratoryId,
      name: MedicineName,
      mainComposition: MedicineMainComposition,
      cipCode: CipCode,
      atcCode: AtcCode.Code,
      governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
      website: MedicineWebsite?,
      logo: InputFile,
      segmentation: Segmentation
    )
  }

  private class ViewModelImpl(private val laboratoryId: LaboratoryId?) : ViewModel, KoinComponent {
    private val atcCodesRepository: AtcCodesRepository by inject(named("admin"))
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))
    private val medicineRepository: MedicinesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue = ObservableValue<ViewModel.UiState>(ViewModel.UiState.Loading)

      App.scope.launch {
        either {
          parZip(
            { atcCodesRepository.getAtcCategoriesWithCodes().bind() },
            { laboratoriesRepository.getLaboratories().bind() },
            { medicalProfessionsRepository.getMedicalProfessions().bind() },
            { laboratoryId?.let { laboratoriesRepository.getLaboratory(it).bind() } },
          ) { atcResponse, laboratories, medicalProfessions, laboratory ->
            ViewModel.UiState.Loaded(
              laboratories,
              atcResponse,
              medicalProfessions,
              laboratory?.id
            )
          }
        }
          .onLeft { observableValue.setState(ViewModel.UiState.Error) }
          .onRight { observableValue.setState(it) }
      }

      observableValue
    }

    override val createMedicineResponse: ObservableValue<ApiResponse<MedicineId>?> =
      ObservableValue(null)

    override fun createMedicine(
      laboratory: LaboratoryId,
      name: MedicineName,
      mainComposition: MedicineMainComposition,
      cipCode: CipCode,
      atcCode: AtcCode.Code,
      governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
      website: MedicineWebsite?,
      logo: InputFile,
      segmentation: Segmentation
    ) {
      App.scope.launch {
        medicineRepository
          .createMedicine(
            laboratory = laboratory,
            cipCode = cipCode,
            atcCode = atcCode,
            governmentPublicDatabaseId = governmentPublicDatabaseId,
            name = name,
            mainComposition = mainComposition,
            website = website,
            segmentation = segmentation,
            logo = logo
          )
          .also { createMedicineResponse.setState(it) }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(laboratoryId)

  init {
    id = "page-admin-medicine-create"
    require("./css/pages/admin/medicines/create.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1("Créer un médicament")

      when (uiState) {
        is ViewModel.UiState.Loading -> labodocSpinner()

        is ViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is ViewModel.UiState.Loaded -> {
          val medicineForm = AdminMedicineForm(
            uiState.laboratories.map { it.id to it.name }.toSet(),
            uiState.atcs.flatMap { it.codes.map { it.code to it.name } }.toSet(),
            uiState.medicalProfessions,
            null
          ).apply {
            setData(
              AdminMedicineForm.Data(
                laboratory = uiState.selectedLaboratory
              )
            )
          }

          val saveButton = LabodocButton("Sauvegarder", className = "save") {
            onClickLaunch {
              disabled = true

              if (medicineForm.validate()) {
                val medicineFormData = medicineForm.getDataWithFileContent()

                viewModel
                  .createMedicine(
                    laboratory = medicineFormData.laboratory!!,
                    name = medicineFormData.name!!,
                    mainComposition = medicineFormData.mainComposition!!,
                    cipCode = medicineFormData.cipCode!!,
                    atcCode = medicineFormData.atcCode!!,
                    governmentPublicDatabaseId = medicineFormData.governmentPublicDatabaseId,
                    website = medicineFormData.website,
                    logo = medicineFormData.logo!!.toInputFile(),
                    segmentation = medicineFormData.segmentation!!
                  )
              } else
                disabled = false
            }
          }

          viewModel.createMedicineResponse.subscribe { createMedicineResponse ->
            when (createMedicineResponse) {
              null -> {}

              is Either.Left -> {
                val errorResponse = createMedicineResponse.value

                val errorMessage = "Une erreur est survenue, vérifiez les données et réessayez"

                Toast.danger(errorMessage)
                saveButton.disabled = false
              }

              is Either.Right -> {
                val medicineIdResponse = createMedicineResponse.value
                App.routing.navigate(Page.AdminBackOfficeMedicineSheet(medicineIdResponse))
              }
            }
          }

          add(medicineForm)

          add(saveButton)
        }
      }
    }
  }
}

fun Container.adminMedicineCreatePage(laboratoryId: LaboratoryId? = null): AdminMedicineCreatePage {
  val adminMedicineCreatePage = AdminMedicineCreatePage(laboratoryId)

  this.add(adminMedicineCreatePage)
  return adminMedicineCreatePage
}
