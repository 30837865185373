package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.ProfessionCodeAsString
import fr.labodoc.api.payloads.serializers.ProfessionLabelAsString
import kotlinx.serialization.Serializable

@Serializable
data class HealthDirectoryProfessionResponse(
  val code: ProfessionCodeAsString,
  val name: ProfessionLabelAsString
)
