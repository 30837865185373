package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
sealed class CreateMessageRequest {
  @Serializable
  data class LabodocFlashInfo(
    val publishedAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }

  @Serializable
  data class LaboratoryFlashInfo(
    val laboratoryId: LaboratoryIdAsString,
    val publishedAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }

  @Serializable
  data class LaboratoryInvitation(
    val laboratoryId: LaboratoryIdAsString,
    val publishedAt: Instant,
    val eventAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val formUrl: UrlAsString?,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }

  @Serializable
  data class MedicineFlashInfo(
    val medicineId: MedicineIdAsString,
    val publishedAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }

  @Serializable
  data class MedicineInvitation(
    val medicineId: MedicineIdAsString,
    val publishedAt: Instant,
    val eventAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val formUrl: UrlAsString?,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }

  @Serializable
  data class LearnedSocietyFlashInfo(
    val learnedSocietyId: LearnedSocietyIdAsString,
    val publishedAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }

  @Serializable
  data class LearnedSocietyInvitation(
    val learnedSocietyId: LearnedSocietyIdAsString,
    val publishedAt: Instant,
    val eventAt: Instant,
    val title: MessageTitleAsString,
    val content: MessageContentAsString,
    val formUrl: UrlAsString?,
    val segmentation: Segmentation
  ) : CreateMessageRequest() {
    @Serializable
    data class Segmentation(
      val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
      val medicalInterestIds: Set<MedicalInterestIdAsString>?,
      val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
      val departmentCodes: Set<DepartmentCodeAsString>?
    )
  }
}
