package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.healthdirectory.ProfessionName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object ProfessionCodeAsStringSerializer : KSerializer<ProfessionCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ProfessionCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(professionCode: String): Either<Nothing, ProfessionCode> =
    ProfessionCode(professionCode).right()

  override fun deserialize(decoder: Decoder): ProfessionCode {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(professionCode: ProfessionCode): String = professionCode.value

  override fun serialize(encoder: Encoder, value: ProfessionCode) {
    return encoder.encodeString(serialize(value))
  }
}

typealias ProfessionCodeAsString = @Serializable(ProfessionCodeAsStringSerializer::class) ProfessionCode

object ProfessionLabelAsStringSerializer : KSerializer<ProfessionName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ProfessionLabelAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(professionLabel: String): Either<Nothing, ProfessionName> =
    ProfessionName(professionLabel).right()

  override fun deserialize(decoder: Decoder): ProfessionName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(professionName: ProfessionName): String = professionName.value

  override fun serialize(encoder: Encoder, value: ProfessionName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias ProfessionLabelAsString = @Serializable(ProfessionLabelAsStringSerializer::class) ProfessionName

