package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalName

data class UniversityHospitalModel(
  val id: UniversityHospitalId,
  val name: UniversityHospitalName,
  val department: Department
) {
  data class Department(
    val code: DepartmentCode,
    val name: DepartmentName
  )
}
