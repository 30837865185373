package fr.labodoc.webapp.utils

import fr.labodoc.domain.labodoc.InputFile
import io.kvision.types.KFile
import io.kvision.types.base64Encoded
import io.kvision.types.contentType
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi
import kotlin.math.abs
import kotlin.math.pow
import kotlin.math.round

/**
 *  Decode the byte array from the data uri string contained in the KFile object.
 */
@OptIn(ExperimentalEncodingApi::class)
val KFile.byteArray: ByteArray?
  get() = base64Encoded?.let { Base64.decode(it) }

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param decimals Number of decimal places to display.
 *
 * @return Formatted string.
 */
fun KFile.humanFileSize(si: Boolean = false, decimals: Int = 1): String {
  /**
   * Round a double to the specified number of decimal places.
   *
   * @param value The value to round.
   * @param decimals The number of decimal places to round to.
   *
   * @return The rounded value as a string.
   */
  fun roundToDecimalPlaces(value: Double, decimals: Int): String {
    val factor = 10.0.pow(decimals)
    val roundedValue = round(value * factor) / factor
    return roundedValue.toString()
  }

  val threshold = if (si) 1000 else 1024
  if (abs(size) < threshold) {
    return "$size B"
  }

  val units = if (si)
    listOf("kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB")
  else
    listOf("KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB")

  val (bytesValue, unitIndex) = generateSequence(size.toDouble() to -1) { (value, unitIndex) ->
    (value / threshold) to (unitIndex + 1)
  }.first { (value, unitIndex) ->
    abs(value) < threshold || unitIndex >= units.size - 1
  }

  return "${roundToDecimalPlaces(bytesValue, decimals)} ${units[unitIndex]}"
}

fun KFile.toInputFile(): InputFile =
  InputFile(name, contentType!!, byteArray!!)
