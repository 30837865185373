package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicalDiplomaIdAsStringSerializer : KSerializer<MedicalDiplomaId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalDiplomaIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.MedicalDiploma.Id.Invalid, MedicalDiplomaId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.MedicalDiploma.Id.Invalid.Format }
      .map { MedicalDiplomaId(it) }

  override fun deserialize(decoder: Decoder): MedicalDiplomaId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: MedicalDiplomaId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: MedicalDiplomaId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MedicalDiplomaIdAsString = @Serializable(MedicalDiplomaIdAsStringSerializer::class) MedicalDiplomaId

object MedicalDiplomaNameAsStringSerializer : KSerializer<MedicalDiplomaName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalDiplomaNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.MedicalDiploma.Name.Invalid, MedicalDiplomaName> =
    MedicalDiplomaName(name)

  override fun deserialize(decoder: Decoder): MedicalDiplomaName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(name: MedicalDiplomaName): String = name.value

  override fun serialize(encoder: Encoder, value: MedicalDiplomaName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MedicalDiplomaNameAsString = @Serializable(MedicalDiplomaNameAsStringSerializer::class) MedicalDiplomaName
