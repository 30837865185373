package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.raise.either
import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import fr.labodoc.domain.labodoc.Errors
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

internal object UuidAsStringSerializer : KSerializer<Uuid> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("UuidAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(uuid: String): Either<Errors.UUID.Invalid, Uuid> =
    either {
      arrow.core.raise.catch({
        uuidFrom(uuid)
      }) { e: IllegalArgumentException ->
        raise(
          Errors.UUID.Invalid.Format(e.message)
        )
      }
    }

  override fun deserialize(decoder: Decoder): Uuid {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(uuid: Uuid): String = uuid.toString()

  override fun serialize(encoder: Encoder, value: Uuid) {
    encoder.encodeString(serialize(value))
  }
}

typealias UuidAsString = @Serializable(UuidAsStringSerializer::class) Uuid

