package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.MedicalInterestIdAsString
import fr.labodoc.api.payloads.serializers.MedicalProfessionIdAsString
import fr.labodoc.api.payloads.serializers.MedicalSpecialityIdAsString
import io.ktor.resources.*

@Resource("medical-professions")
class MedicalProfessions {
  @Resource("{medicalProfessionId}")
  class MedicalProfession(
    val medicalProfessionId: MedicalProfessionIdAsString,
    val medicalProfessions: MedicalProfessions
  ) {
    constructor(medicalProfessionId: MedicalProfessionIdAsString): this(medicalProfessionId, MedicalProfessions())

    @Resource("medical-specialities")
    class MedicalSpecialities(
      val medicalProfession: MedicalProfession
    ) {
      constructor(medicalProfessionId: MedicalProfessionIdAsString) : this(MedicalProfession(medicalProfessionId))

      @Resource("{medicalSpecialityId}")
      class MedicalSpeciality(
        val medicalSpecialityId: MedicalSpecialityIdAsString,
        val medicalSpecialities: MedicalSpecialities
      ) {
        constructor(
          medicalProfessionId: MedicalProfessionIdAsString,
          medicalSpecialityId: MedicalSpecialityIdAsString
        ) : this(medicalSpecialityId, MedicalSpecialities(medicalProfessionId))

        @Resource("medical-interests")
        class MedicalInterests(
          val medicalSpeciality: MedicalSpeciality
        ) {
          constructor(
            medicalProfessionId: MedicalProfessionIdAsString,
            medicalSpecialityId: MedicalSpecialityIdAsString
          ) : this(MedicalSpeciality(medicalProfessionId, medicalSpecialityId))

          @Resource("{medicalInterestId}")
          class MedicalInterest(
            val medicalInterestId: MedicalInterestIdAsString,
            val medicalInterests: MedicalInterests
          ) {
            constructor(
              medicalProfessionId: MedicalProfessionIdAsString,
              medicalSpecialityId: MedicalSpecialityIdAsString,
              medicalInterestId: MedicalInterestIdAsString
            ) : this(medicalInterestId, MedicalInterests(medicalProfessionId, medicalSpecialityId))
          }
        }
      }
    }
  }
}
