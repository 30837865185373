package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.DepartmentCodeAsString
import fr.labodoc.api.payloads.serializers.UniversityHospitalNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class UpdateUniversityHospitalRequest(
  val name: UniversityHospitalNameAsString,
  val departmentCode: DepartmentCodeAsString
)
