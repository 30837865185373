package fr.labodoc.webapp.pages.admin.universityHospitals

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.fx.coroutines.parZip
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.DepartmentModel
import fr.labodoc.app.data.admin.model.UniversityHospitalModel
import fr.labodoc.app.data.admin.repository.DepartmentsRepository
import fr.labodoc.app.data.admin.repository.UniversityHospitalsRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.pages.admin.universityHospitals.form.AdminUniversityHospitalForm
import fr.labodoc.webapp.pages.admin.universityHospitals.form.adminUniversityHospitalForm
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminUniversityHospitalsPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val universityHospitals: Set<UniversityHospitalModel>,
        val departments: Set<DepartmentModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun refreshPage()
    fun createUniversityHospital(name: UniversityHospitalName, departmentCode: DepartmentCode)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val universityHospitalRepository: UniversityHospitalsRepository by inject(named("admin"))
    private val departmentRepository: DepartmentsRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      refreshPage()

      observableValue
    }

    override fun refreshPage() {
      App.scope.launch {
        uiState.setState(ViewModel.UiState.Loading)

        either {
          parZip(
            { universityHospitalRepository.getUniversityHospitals().bind() },
            { departmentRepository.getDepartments().bind() }
          ) { universityHospitals, departments ->
            ViewModel.UiState.Loaded(
              universityHospitals = universityHospitals,
              departments = departments
            )
          }
        }.fold(
          { ViewModel.UiState.Error },
          { it }
        ).also { uiState.setState(it) }
      }
    }

    override fun createUniversityHospital(name: UniversityHospitalName, departmentCode: DepartmentCode) {
      App.scope.launch {
        universityHospitalRepository
          .createUniversityHospital(
            name = name,
            departmentCode = departmentCode
          )
          .onLeft { _: ErrorResponse ->
            Toast.danger("Une erreur est survenue")
          }
          .onRight { _: UniversityHospitalId ->
            Toast.success("Centre hospitalier universitaire correctement créé")
            refreshPage()
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  private class UniversityHospitalRow(
    universityHospital: UniversityHospitalModel,
    departments: Set<DepartmentModel>,
    onUniversityHospitalUpdated: (UniversityHospitalId) -> Unit,
    onUniversityHospitalDeleted: (UniversityHospitalId) -> Unit
  ) : SimplePanel(className = "university-hospital labodoc-background-light-blue") {
    private interface ViewModel {
      val updateProcessing: ObservableState<Boolean>
      val deleteProcessing: ObservableState<Boolean>

      fun updateUniversityHospital(name: UniversityHospitalName, departmentCode: DepartmentCode)
      fun deleteUniversityHospital()
    }

    private class ViewModelImpl(
      private val universityHospital: UniversityHospitalModel,
      private val onUniversityHospitalUpdated: (UniversityHospitalId) -> Unit,
      private val onUniversityHospitalDeleted: (UniversityHospitalId) -> Unit
    ) : ViewModel, KoinComponent {
      private val universityHospitalRepository: UniversityHospitalsRepository by inject(named("admin"))

      override val updateProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override val deleteProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override fun updateUniversityHospital(name: UniversityHospitalName, departmentCode: DepartmentCode) {
        App.scope.launch {
          updateProcessing.setState(true)

          universityHospitalRepository
            .updateUniversityHospital(
              id = universityHospital.id,
              name = name,
              departmentCode = departmentCode
            )
            .onLeft { errorResponse: ErrorResponse ->
              val errorMessage = when (errorResponse.code) {
                Errors.UniversityHospital.NotFound("fixme").code -> "Ce centre hospitalier universitaire n'existe pas"
                else -> "Une erreur est survenue"
              }

              Toast.danger(errorMessage)
            }
            .onRight { _: Unit ->
              Toast.success("Centre hospitalier universitaire correctement mis à jour")
              onUniversityHospitalUpdated(universityHospital.id)
            }

          updateProcessing.setState(false)
        }
      }

      override fun deleteUniversityHospital() {
        App.scope.launch {
          deleteProcessing.setState(true)

          universityHospitalRepository
            .deleteUniversityHospital(universityHospital.id)
            .onLeft { errorResponse: ErrorResponse ->
              val errorMessage = when (errorResponse.code) {
                Errors.UniversityHospital.NotFound("fixme").code -> "Ce centre hospitalier universitaire n'existe pas"
                else -> "Une erreur est survenue"
              }

              Toast.danger(errorMessage)
            }
            .onRight {
              Toast.success("Centre hospitalier universitaire correctement supprimé")
              onUniversityHospitalDeleted(universityHospital.id)
            }

          deleteProcessing.setState(false)
        }
      }
    }

    private val viewModel: ViewModel = ViewModelImpl(universityHospital, onUniversityHospitalUpdated, onUniversityHospitalDeleted)

    private val updateModal =
      LabodocPopup(
        closeButton = true,
        icon = null,
        image = null,
        className = "admin-university-hospital-modal",
        content = { popup ->
          h1("Centre hospitalier universitaire")

          val universityHospitalForm = adminUniversityHospitalForm(
            departments = departments.associate { it.code to it.name }
          ).apply {
            setData(
              AdminUniversityHospitalForm.Data(
                name = universityHospital.name,
                departmentCode = universityHospital.department.code
              )
            )
          }

          labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
            onClickLaunch {
              disabled = true

              either {
                ensure(universityHospitalForm.validate()) { "Le formulaire n'est pas valide" }
                val universityHospitalFormData = universityHospitalForm.getData()

                val name = when (val name = universityHospitalFormData.name) {
                  null -> raise("Le nom est manquant")

                  is Either.Left -> when (name.value) {
                    Errors.UniversityHospital.Name.Invalid.Blank -> raise("Le nom ne peut pas être vide")
                    Errors.UniversityHospital.Name.Invalid.TooLong -> raise("Le nom est trop long")
                  }

                  is Either.Right -> name.value
                }
                val departmentCode = when (val departmentCode = universityHospitalFormData.departmentCode) {
                  null -> raise("Le départment est manquant")

                  is Either.Left -> when (departmentCode.value) {
                    Errors.Department.Code.Invalid.Format -> raise("Le département n'est pas valide")
                  }

                  is Either.Right -> departmentCode.value
                }

                viewModel.updateUniversityHospital(
                  name = name,
                  departmentCode = departmentCode
                )
              }
                .onLeft { errorMessage: String ->
                  Toast.danger(errorMessage)
                }
                .onRight {
                  popup.hide()
                }

              disabled = false
            }
          }
        },
        beforeClose = null
      )

    private val deleteConfirmationModal =
      LabodocPopup(
        closeButton = true,
        icon = "fa-solid fa-warning",
        image = null,
        className = "admin-university-hospital-delete-confirmation-modal",
        content = { modal ->
          p(className = "title") {
            content = "Êtes-vous sûr de vouloir supprimer le CHU \"${universityHospital.name.value}\" ?"
          }

          div(className = "choices") {
            labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "delete") {
              onClick {
                modal.hide()
                viewModel.deleteUniversityHospital()
              }
            }

            labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
              onClick {
                modal.hide()
              }
            }
          }
        },
        beforeClose = null
      )

    init {
      val name = universityHospital.name.value
      val department = departments
        .find { it.code == universityHospital.department.code }
        ?.let { "${it.code.value} - ${it.name.value}" } ?: "${universityHospital.department.code.value} - Inconnu"

      div(className = "details") {
        p(name, className = "name")

        p(department, className = "department")
      }

      div(className = "actions") {
        button("", className = "action edit").bind(viewModel.updateProcessing) { updateProcessing ->
          if (updateProcessing) {
            disabled = true
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            icon = "fa-solid fa-solid fa-pencil"

            onClick {
              updateModal.show()
            }
          }
        }

        button("", className = "action delete").bind(viewModel.deleteProcessing) { deleteProcessing ->
          if (deleteProcessing) {
            disabled = true
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            icon = "fa-solid fa-solid fa-trash"

            onClick {
              deleteConfirmationModal.show()
            }
          }
        }
      }
    }
  }

  init {
    id = "page-admin-university-hospitals"
    require("./css/pages/admin/universityHospitals/university-hospitals.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      header {
        h1("Liste des centres hospitaliers universitaire")

        if (uiState is ViewModel.UiState.Loaded) {
          val universityHospitalForm = AdminUniversityHospitalForm(
            departments = uiState.departments.associate { it.code to it.name }
          )

          val creationModal =
            LabodocPopup(
              closeButton = true,
              icon = null,
              image = null,
              className = "admin-university-hospital-modal",
              content = { popup ->
                h1("Centre hospitalier universitaire")

                add(universityHospitalForm)

                labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
                  onClickLaunch {
                    disabled = true

                    either {
                      ensure(universityHospitalForm.validate()) { "Le formulaire n'est pas valide" }
                      val universityHospitalFormData = universityHospitalForm.getData()

                      val name = when (val name = universityHospitalFormData.name) {
                        null -> raise("Le nom est manquant")

                        is Either.Left -> when (name.value) {
                          Errors.UniversityHospital.Name.Invalid.Blank -> raise("Le nom ne peut pas être vide")
                          Errors.UniversityHospital.Name.Invalid.TooLong -> raise("Le nom est trop long")
                        }

                        is Either.Right -> name.value
                      }
                      val departmentCode = when (val departmentCode = universityHospitalFormData.departmentCode) {
                        null -> raise("Le départment est manquant")

                        is Either.Left -> when (departmentCode.value) {
                          Errors.Department.Code.Invalid.Format -> raise("Le département n'est pas valide")
                        }

                        is Either.Right -> departmentCode.value
                      }

                      viewModel.createUniversityHospital(
                        name = name,
                        departmentCode = departmentCode
                      )
                    }
                      .onLeft { errorMessage: String ->
                        Toast.danger(errorMessage)
                      }
                      .onRight {
                        popup.hide()
                        universityHospitalForm.reset()
                      }

                    disabled = false
                  }
                }
              },
              beforeClose = { universityHospitalForm.reset() }
            )

          labodocButton("Ajouter un CHU", "fa-solid fa-plus") {
            onClick {
              creationModal.show()
            }
          }
        }
      }

      div(className = "university-hospitals") {
        header {
          p("Nom")
          p("Départment")
          p("Actions")
        }

        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          ViewModel.UiState.Error -> Toast.danger("Impossible de récupérer la liste des centres hospitalier universitaire")

          is ViewModel.UiState.Loaded -> {
            uiState.universityHospitals.forEach { universityHospital ->
              add(
                UniversityHospitalRow(
                  universityHospital = universityHospital,
                  departments = uiState.departments,
                  onUniversityHospitalUpdated = { viewModel.refreshPage() },
                  onUniversityHospitalDeleted = { viewModel.refreshPage() }
                )
              )
            }
          }
        }
      }
    }
  }
}

fun Container.adminUniversityHospitalsPage(): AdminUniversityHospitalsPage {
  val adminUniversityHospitalsPage = AdminUniversityHospitalsPage()

  this.add(adminUniversityHospitalsPage)
  return adminUniversityHospitalsPage
}
