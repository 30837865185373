package fr.labodoc.app.data.healthprofessional.model

import io.ktor.http.*

data class MedicineModel(
  val id: String,
  val laboratory: Laboratory?,
  val name: String,
  val atcClassification: AtcClassification,
  val dosages: Set<Dosage>,
  val logoUrl: Url,
  val marketingDocuments: Set<MarketingDocument>
) {
  data class Laboratory(
    val id: String,
    val name: String,
    val website: Url,
    val pharmacovigilance: Url
  )

  data class AtcClassification(
    val code: String,
    val name: String,
    val shortName: String?,
    val parent: AtcClassification?
  ) {
    val level: Int = when (code.length) {
      1 -> 1
      3 -> 2
      4 -> 3
      5 -> 4
      7 -> 5
      else -> -1
    }

    private fun getLevel(level: Int): MedicineModel.AtcClassification? =
      if (this.level == level)
        this
      else
        parent?.getLevel(level)

    fun getLevelOrHigher(level: Int): MedicineModel.AtcClassification? =
      if (this.level == level)
        this
      else
        parent?.getLevel(level) ?: parent?.getLevelOrHigher(level - 1)

    val firstLevel: MedicineModel.AtcClassification? = getLevel(1)
    val secondLevel: MedicineModel.AtcClassification? = getLevel(2)
    val thirdLevel: MedicineModel.AtcClassification? = getLevel(3)
    val fourthLevel: MedicineModel.AtcClassification? = getLevel(4)
    val fifthLevel: MedicineModel.AtcClassification? = getLevel(5)
  }

  data class MarketingDocument(
    val id: String,
    val name: String,
    val url: Url,
    val version: Int,
    val latestVersionSeen: Int?
  ) {
    val seenLatestVersion: Boolean = latestVersionSeen == version
  }

  data class Dosage(
    val cisCode: String,
    val name: String
  )
}
