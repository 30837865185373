package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class MedicalDiplomaResponse(
  val id: MedicalDiplomaIdAsString,
  val name: MedicalDiplomaNameAsString,
  val healthDirectoryProfessionCode: ProfessionCodeAsString,
  val labodocMedicalSpeciality: MedicalSpeciality
) {
  @Serializable
  data class MedicalSpeciality(
    val id: MedicalSpecialityIdAsString,
    val medicalProfession: MedicalProfession,
    val name: MedicalSpecialityNameAsString
  ) {
    @Serializable
    data class MedicalProfession(
      val id: MedicalProfessionIdAsString,
      val name: MedicalProfessionNameAsString
    )
  }
}
