package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName

data class MedicalProfessionAndSpecialityModel(
  val medicalSpecialityId: MedicalSpecialityId,
  val medicalProfessionName: MedicalProfessionName,
  val medicalSpecialityName: MedicalSpecialityName
)
