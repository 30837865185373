package fr.labodoc.domain.labodoc.common

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class Password private constructor(val value: String) {
  companion object {
    const val MIN_LENGTH: Int = 8
    const val MAX_LENGTH: Int = 32

    fun isNotTooShort(value: String): Boolean = value.length >= MIN_LENGTH
    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH
    fun hasLowercaseLetter(value: String): Boolean = value.any { it.isLowerCase() }
    fun hasUppercaseLetter(value: String): Boolean = value.any { it.isUpperCase() }
    fun hasDigit(value: String): Boolean = value.any { it.isDigit() }
    fun hasSpecialCharacter(value: String): Boolean = value.any { !it.isLetterOrDigit() }

    operator fun invoke(value: String): Either<Errors.Password.Invalid, Password> {
      return either {
        ensure(isNotTooShort(value)) { Errors.Password.Invalid.TooShort }
        ensure(isNotTooLong(value)) { Errors.Password.Invalid.TooLong }
        ensure(hasLowercaseLetter(value)) { Errors.Password.Invalid.NoLowercaseLetter }
        ensure(hasUppercaseLetter(value)) { Errors.Password.Invalid.NoUppercaseLetter }
        ensure(hasDigit(value)) { Errors.Password.Invalid.NoDigit }
        ensure(hasSpecialCharacter(value)) { Errors.Password.Invalid.NoSpecialCharacter }

        Password(value)
      }
    }
  }
}
