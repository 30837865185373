package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.MedicalProfessionIdAsString
import fr.labodoc.api.payloads.serializers.MedicalProfessionNameAsString
import fr.labodoc.api.payloads.serializers.MedicalSpecialityIdAsString
import fr.labodoc.api.payloads.serializers.MedicalSpecialityNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class MedicalSpecialityWithMedicalProfessionResponse(
  val medicalSpecialityId: MedicalSpecialityIdAsString,
  val medicalSpecialityName: MedicalSpecialityNameAsString,
  val medicalProfessionId: MedicalProfessionIdAsString,
  val medicalProfessionName: MedicalProfessionNameAsString
)
