package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.form.check.CheckBox
import io.kvision.form.check.CheckBoxInput

class LabodocCheckbox(
  value: Boolean = false, name: String? = null, labelFirst: Boolean = false,
  label: String? = null, rich: Boolean = false,
  init: (LabodocCheckbox.() -> Unit)? = null
) : CheckBox(value, name, labelFirst, label, rich, null) {
  init {
    require("./css/components/labodoc-checkbox.css")

    addCssClass("labodoc-checkbox")

    init?.invoke(this)
  }
}

fun Container.labodocCheckbox(
  value: Boolean = false, name: String? = null, labelFirst: Boolean = false, label: String? = null,
  rich: Boolean = false, init: (LabodocCheckbox.() -> Unit)? = null
): LabodocCheckbox {
  val checkbox = LabodocCheckbox(value, name, labelFirst, label, rich, init)
  this.add(checkbox)
  return checkbox
}

class LabodocCheckBoxInput(
  value: Boolean = false,
  className: String? = null,
  init: (LabodocCheckBoxInput.() -> Unit)? = null
) : CheckBoxInput(value, className, null) {
  init {
    require("./css/components/labodoc-checkbox.css")

    addCssClass("labodoc-checkbox")

    init?.invoke(this)
  }
}

fun Container.labodocCheckBoxInput(
  value: Boolean = false,
  className: String? = null,
  init: (LabodocCheckBoxInput.() -> Unit)? = null
): LabodocCheckBoxInput {
  val checkBoxInput = LabodocCheckBoxInput(value, className, init)
  this.add(checkBoxInput)
  return checkBoxInput
}
