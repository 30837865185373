package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.*
import io.ktor.http.*
import kotlinx.datetime.Instant

data class MedicineModelWithDocuments(
  val id: MedicineId,
  val laboratory: LaboratoryId,
  val cipCode: CipCode,
  val atcCode: AtcCode.Code,
  val name: MedicineName,
  val mainComposition: MedicineMainComposition,
  val website: MedicineWebsite?,
  val logoUrl: Url,
  val segmentation: Segmentation,
  val marketingDocuments: Set<MarketingDocument>,
  val activated: Boolean
) {
  data class MarketingDocument(
    val id: MarketingDocumentId,
    val name: MarketingDocumentName,
    val segmentation: Segmentation,
    val documentUrl: Url,
    val expireAt: Instant?
  )
}
