package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.FreestandingApplicationResponse
import fr.labodoc.api.resources.Applications
import fr.labodoc.api.routes.AdminApplicationsRoutes
import fr.labodoc.domain.labodoc.application.ApplicationId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class AdminApplicationsRemoteDataSource(
  private val httpClient: HttpClient
) : AdminApplicationsRoutes {
  override suspend fun getPendingFreestandingApplications(
  ): ApiResponse<Set<FreestandingApplicationResponse>> {
    val response = httpClient.get(Applications())

    return if (response.status.isSuccess())
      response.body<Set<FreestandingApplicationResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun validateFreestandingPendingApplication(
    id: ApplicationId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Applications.Application.Validate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun rejectFreestandingPendingApplication(
    id: ApplicationId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Applications.Application.Reject(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
