package fr.labodoc

open class Error(
  val type: Type,
  val code : String,
  val title: String? = null,
  var details: String? = null,
  val cause: Throwable? = null,
  location : List<String>? = null
) {

  enum class Type {
    Validation,
    Conflict,
    NotFound,
    Failure,
    Unexpected,
    Unauthorized,
    Forbidden,
    Spam,
    Unsupported
  }

  val location: List<String>?

  init {
    if (isDevelopmentMode)
      this.location = location ?: leanStacktrace((cause ?: Throwable()).stackTraceToString())
    else
      this.location = location
  }

  constructor(
    exception: Throwable,
    type: Type? = null,
    code: String,
    title: String? = null,
    details: String? = null
  ) : this(
      type = type ?: Type.Unexpected,
      code = code ?: "exception::class.qualifiedName",
      title = title ?: exception.message,
      details = details,
      cause = exception
    )
  companion object {
    fun leanStacktrace(stacktrace: String) : List<String> {
      return stacktrace
        .replace("\t", "")
        .lines()
        .map { it.takeIf { it.contains("fr.labodoc")  } ?: "..." }
        .let { lines -> lines.filterIndexed { index, line -> index == 0 || lines[index - 1] != line } }
        .let { lines -> lines.filter { !it.contains("fr.labodoc.Error.") } }
    }
  }

  override fun toString(): String = "${type} ${code} ${title} ${details}"
}
