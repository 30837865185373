package fr.labodoc.webapp.pages.admin.medicalDiplomas.form

import arrow.core.Either
import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.healthdirectory.ProfessionName
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaName
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.webapp.components.labodocSelect
import fr.labodoc.webapp.components.labodocText
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.text.Text
import io.kvision.i18n.I18n
import io.kvision.state.ObservableValue
import kotlinx.serialization.Serializable

class AdminMedicalDiplomaForm(
    healthDirectoryProfessions: Map<ProfessionCode, ProfessionName>,
    labodocMedicalProfessions: Set<MedicalProfessionModel>
) : FormPanel<AdminMedicalDiplomaForm.Data>(
  serializer = Data.serializer(),
  className = "admin-medical-diploma-form"
) {
  @Serializable
  data class Data(
    val nameInput: String? = null,
    val healthDirectoryProfessionCodeInput: String? = null,
    val labodocMedicalProfessionIdInput: String? = null,
    val labodocMedicalSpecialityIdInput: String? = null
  ) {
    constructor(
      name: MedicalDiplomaName? = null,
      healthDirectoryProfessionCode: ProfessionCode? = null,
      labodocMedicalProfessionId: MedicalProfessionId? = null,
      labodocMedicalSpecialityId: MedicalSpecialityId? = null
    ): this(
      nameInput = name?.value,
      healthDirectoryProfessionCodeInput = healthDirectoryProfessionCode?.value?.toString(),
      labodocMedicalProfessionIdInput = labodocMedicalProfessionId?.value?.toString(),
      labodocMedicalSpecialityIdInput = labodocMedicalSpecialityId?.value?.toString()
    )

    val name: Either<Errors.MedicalDiploma.Name, MedicalDiplomaName>?
      get() = nameInput?.let { MedicalDiplomaName(it) }

    val healthDirectoryProfessionCode: ProfessionCode?
      get() = healthDirectoryProfessionCodeInput?.let { ProfessionCode(it) }

    val labodocMedicalProfessionId: MedicalProfessionId?
      get() = labodocMedicalProfessionIdInput?.let { MedicalProfessionId(uuidFrom(it)) }

    val labodocMedicalSpecialityId: MedicalSpecialityId?
      get() = labodocMedicalSpecialityIdInput?.let { MedicalSpecialityId(uuidFrom(it)) }

    companion object {
      fun validateName(name: Text): String? = MedicalDiplomaName(name.value ?: "").fold(
        { error ->
          when (error) {
            Errors.MedicalDiploma.Name.Invalid.Blank -> "Ne peut pas être vide"
            Errors.MedicalDiploma.Name.Invalid.TooLong -> "Trop long"
          }
        },
        {
          null
        }
      )
    }
  }

  private val selectedLabodocMedicalProfession: ObservableValue<MedicalProfessionModel?> =
    ObservableValue((null))

  init {
    labodocText {
      label = "Nom"
    }.bind(
      key = Data::nameInput,
      required = true,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateName(it) == null },
      validatorMessage = { Data.validateName(it) }
    )

    labodocSelect {
      label = "Profession de l'annuaire santé"
      options = healthDirectoryProfessions
        .map { (professionCode, professionLabel) ->
          professionCode.value to professionLabel.value
        }
    }.bind(
      key = Data::healthDirectoryProfessionCodeInput,
      required = true,
      requiredMessage = I18n.tr("Field.Required")
    )

    labodocSelect {
      label = "Profession LaboDoc"
      options = labodocMedicalProfessions
        .map { medicalProfession ->
          medicalProfession.id.value.toString() to medicalProfession.name.value
        }

      subscribe { medicalProfessionIdInput ->
        selectedLabodocMedicalProfession.setState(labodocMedicalProfessions.find { it.id.value.toString() == medicalProfessionIdInput })
      }
    }.bind(
      key = Data::labodocMedicalProfessionIdInput,
      required = true,
      requiredMessage = I18n.tr("Field.Required")
    )

    labodocSelect {
      label = "Spécialité LaboDoc"

      selectedLabodocMedicalProfession.subscribe { selectedLabodocMedicalProfession ->
        options = selectedLabodocMedicalProfession?.medicalSpecialities
          ?.map { medicalSpeciality ->
            medicalSpeciality.id.value.toString() to medicalSpeciality.name.value
          }
      }
    }.bind(
      key = Data::labodocMedicalSpecialityIdInput,
      required = true,
      requiredMessage = I18n.tr("Field.Required")
    )
  }
}

fun Container.adminMedicalDiplomaForm(
    healthDirectoryProfessions: Map<ProfessionCode, ProfessionName>,
    labodocMedicalProfessions: Set<MedicalProfessionModel>
): AdminMedicalDiplomaForm {
  val adminMedicalDiplomaForm = AdminMedicalDiplomaForm(
    healthDirectoryProfessions,
    labodocMedicalProfessions
  )

  this.add(adminMedicalDiplomaForm)
  return adminMedicalDiplomaForm
}
