package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.PartnerCodeAsString
import fr.labodoc.api.payloads.serializers.PartnerNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class CreatePartnerRequest(
  val code: PartnerCodeAsString?,
  val name: PartnerNameAsString
)
