package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.NationalIdentifier
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object NationalIdentifierAsStringSerializer : KSerializer<NationalIdentifier> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("NationalIdentifierAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(nationalIdentifier: String): Either<Nothing, NationalIdentifier> =
    NationalIdentifier(nationalIdentifier).right()

  override fun deserialize(decoder: Decoder): NationalIdentifier =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(nationalIdentifier: NationalIdentifier): String =
    nationalIdentifier.value

  override fun serialize(encoder: Encoder, value: NationalIdentifier) =
    encoder.encodeString(serialize(value))
}

typealias HealthProfessionalNationalIdentifierAsString = @Serializable(NationalIdentifierAsStringSerializer::class) NationalIdentifier
