package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class
AdminMedicineResponse(val medicine: Medicine) {
  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val laboratory: LaboratoryIdAsString,
    val cipCode: CipCodeAsString,
    val atcCode: AtcCodeAsString,
    val governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseIdAsString?,
    val name: MedicineNameAsString,
    val mainComposition: MedicineMainCompositionAsString,
    val website: MedicineWebsiteAsString?,
    val segmentation: Segmentation,
    val logoUrl: UrlAsString,
    val activated: Boolean
  ) {
    @Serializable
    data class Segmentation(
      val medicalProfessions: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialities: Set<MedicalSpecialityIdAsString>?,
      val medicalInterests: Set<MedicalInterestIdAsString>?,
      val professionalCategories: Set<ProfessionalCategoryCodeAsString>?,
      val professionalStatuses: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypes: Set<MedicalCardTypeCodeAsString>?,
    )
  }
}
