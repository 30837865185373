package fr.labodoc.webapp.pages.admin.medicalDiplomas

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZip
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.MedicalDiplomaModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.HealthDirectoryRepository
import fr.labodoc.app.data.admin.repository.MedicalDiplomasRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.domain.healthdirectory.Profession
import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.pages.admin.medicalDiplomas.form.AdminMedicalDiplomaForm
import fr.labodoc.webapp.pages.admin.medicalDiplomas.form.adminMedicalDiplomaForm
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicalDiplomasPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val medicalDiplomas: Set<MedicalDiplomaModel>,
        val healthDirectoryProfessions: Set<Profession>,
        val labodocMedicalProfessions: Set<MedicalProfessionModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun refreshPage()
    fun createMedicalDiploma(
      name: MedicalDiplomaName,
      healthDirectoryProfessionCode: ProfessionCode,
      labodocMedicalSpeciality: MedicalSpecialityId
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val medicalDiplomaRepository: MedicalDiplomasRepository by inject(named("admin"))
    private val healthDirectoryRepository: HealthDirectoryRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> =
        ObservableValue(ViewModel.UiState.Loading)

      refreshPage()

      observableValue
    }

    override fun refreshPage() {
      App.scope.launch {
        uiState.setState(ViewModel.UiState.Loading)

        either {
          parZip(
            { medicalDiplomaRepository.getMedicalDiplomas().bind() },
            { healthDirectoryRepository.getProfessions().bind() },
            { medicalProfessionsRepository.getMedicalProfessions().bind() }
          ) { medicalDiplomas, healthDirectoryProfessions, labodocMedicalProfessions ->
            ViewModel.UiState.Loaded(
              medicalDiplomas = medicalDiplomas.sortedBy { it.name.value }.toSet(),
              healthDirectoryProfessions = healthDirectoryProfessions.map { Profession(it.code, it.name) }.toSet(),
              labodocMedicalProfessions = labodocMedicalProfessions
            )
          }
        }.fold(
          { ViewModel.UiState.Error },
          { it }
        ).also { uiState.setState(it) }
      }
    }

    override fun createMedicalDiploma(
      name: MedicalDiplomaName,
      healthDirectoryProfessionCode: ProfessionCode,
      labodocMedicalSpeciality: MedicalSpecialityId
    ) {
      App.scope.launch {
        medicalDiplomaRepository
          .createMedicalDiploma(
            name = name,
            healthDirectoryProfessionCode = healthDirectoryProfessionCode,
            labodocMedicalSpeciality = labodocMedicalSpeciality
          )
          .onLeft { _: ErrorResponse ->
            Toast.danger("Une erreur est survenue")
          }
          .onRight { _: MedicalDiplomaId ->
            Toast.success("Diplôme médical correctement créé")
            refreshPage()
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  private class MedicalDiplomaRow(
    medicalDiploma: MedicalDiplomaModel,
    healthDirectoryProfessions: Set<Profession>,
    labodocMedicalProfessions: Set<MedicalProfessionModel>,
    onMedicalDiplomaUpdated: (MedicalDiplomaId) -> Unit,
    onMedicalDiplomaDeleted: (MedicalDiplomaId) -> Unit
  ) : SimplePanel(className = "medical-diploma labodoc-background-light-blue") {
    private interface ViewModel {
      val updateProcessing: ObservableState<Boolean>
      val deleteProcessing: ObservableState<Boolean>

      fun updateMedicalDiploma(
        name: MedicalDiplomaName,
        healthDirectoryProfessionCode: ProfessionCode,
        labodocMedicalSpeciality: MedicalSpecialityId
      )

      fun deleteMedicalDiploma()
    }

    private class ViewModelImpl(
      private val medicalDiploma: MedicalDiplomaModel,
      private val onMedicalDiplomaUpdated: (MedicalDiplomaId) -> Unit,
      private val onMedicalDiplomaDeleted: (MedicalDiplomaId) -> Unit
    ) : ViewModel, KoinComponent {
      private val medicalDiplomaRepository: MedicalDiplomasRepository by inject(named("admin"))

      override val updateProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override val deleteProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override fun updateMedicalDiploma(
        name: MedicalDiplomaName,
        healthDirectoryProfessionCode: ProfessionCode,
        labodocMedicalSpeciality: MedicalSpecialityId
      ) {
        App.scope.launch {
          updateProcessing.setState(true)

          medicalDiplomaRepository
            .updateMedicalDiploma(
              id = medicalDiploma.id,
              name = name,
              healthDirectoryProfessionCode = healthDirectoryProfessionCode,
              labodocMedicalSpeciality = labodocMedicalSpeciality
            )
            .onLeft { errorResponse: ErrorResponse ->
              val errorMessage = when (errorResponse.code) {
                Errors.MedicalDiploma.NotFound("fixme").code -> "Ce diplôme médical n'existe pas"
                else -> "Une erreur est survenue"
              }

              Toast.danger(errorMessage)
            }
            .onRight { _: Unit ->
              Toast.success("Diplôme médical correctement mis à jour")
              onMedicalDiplomaUpdated(medicalDiploma.id)
            }

          updateProcessing.setState(false)
        }
      }

      override fun deleteMedicalDiploma() {
        App.scope.launch {
          deleteProcessing.setState(true)

          medicalDiplomaRepository
            .deleteMedicalDiploma(medicalDiploma.id)
            .onLeft { errorResponse: ErrorResponse ->
              val errorMessage = when (errorResponse.code) {
                Errors.UniversityHospital.NotFound("fixme").code -> "Ce diplôme médical n'existe pas"
                else -> "Une erreur est survenue"
              }

              Toast.danger(errorMessage)
            }
            .onRight {
              Toast.success("Diplôme médical correctement supprimé")
              onMedicalDiplomaDeleted(medicalDiploma.id)
            }

          deleteProcessing.setState(false)
        }
      }
    }

    private val viewModel: ViewModel = ViewModelImpl(medicalDiploma, onMedicalDiplomaUpdated, onMedicalDiplomaDeleted)

    private val updateModal =
      LabodocPopup(
        closeButton = true,
        icon = null,
        image = null,
        className = "admin-medical-diploma-modal",
        content = { popup ->
          h1("Diplôme médical")

          val medicalDiplomaForm = adminMedicalDiplomaForm(
            healthDirectoryProfessions = healthDirectoryProfessions.associate { it.code to it.name },
            labodocMedicalProfessions = labodocMedicalProfessions
          ).apply {
            setData(
              AdminMedicalDiplomaForm.Data(
                name = medicalDiploma.name,
                healthDirectoryProfessionCode = medicalDiploma.healthDirectoryProfessionCode,
                labodocMedicalProfessionId = medicalDiploma.labodocMedicalSpeciality.medicalProfession.id,
                labodocMedicalSpecialityId = medicalDiploma.labodocMedicalSpeciality.id
              )
            )
          }

          labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
            onClickLaunch {
              disabled = true

              either {
                ensure(medicalDiplomaForm.validate()) { "Le formulaire n'est pas valide" }
                val medicalDiplomaFormData = medicalDiplomaForm.getData()

                val name = when (val name = medicalDiplomaFormData.name) {
                  null -> raise("Le nom est manquant")

                  is Either.Left -> when (name.value) {
                    Errors.MedicalDiploma.Name.Invalid.Blank -> raise("Le nom ne peut pas être vide")
                    Errors.MedicalDiploma.Name.Invalid.TooLong -> raise("Le nom est trop long")
                  }

                  is Either.Right -> name.value
                }
                val healthDirectoryProfessionCode = ensureNotNull(medicalDiplomaFormData.healthDirectoryProfessionCode) { "La profession de l'annuaire santé est manquante" }
                val labodocMedicalProfessionId = ensureNotNull(medicalDiplomaFormData.labodocMedicalProfessionId) { "La profession LaboDoc est manquante" }
                val labodocMedicalSpecialityId = ensureNotNull(medicalDiplomaFormData.labodocMedicalSpecialityId) { "La spécialité LaboDoc est manquante" }

                viewModel.updateMedicalDiploma(
                  name = name,
                  healthDirectoryProfessionCode = healthDirectoryProfessionCode,
                  labodocMedicalSpeciality = labodocMedicalSpecialityId
                )
              }
                .onLeft { errorMessage: String ->
                  Toast.danger(errorMessage)
                }
                .onRight {
                  popup.hide()
                }

              disabled = false
            }
          }
        },
        beforeClose = null
      )

    private val deleteConfirmationModal =
      LabodocPopup(
        closeButton = true,
        icon = "fa-solid fa-warning",
        image = null,
        className = "admin-medical-diploma-delete-confirmation-modal",
        content = { modal ->
          p(className = "title") {
            content = "Êtes-vous sûr de vouloir supprimer le diplôme médical \"${medicalDiploma.name.value}\" ?"
          }

          div(className = "choices") {
            labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "delete") {
              onClick {
                modal.hide()
                viewModel.deleteMedicalDiploma()
              }
            }

            labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
              onClick {
                modal.hide()
              }
            }
          }
        },
        beforeClose = null
      )

    init {
      val name = medicalDiploma.name.value
      val healthDirectoryProfessionName = healthDirectoryProfessions.find { it.id == medicalDiploma.healthDirectoryProfessionCode }?.name?.value ?: "Inconnue"

      div(className = "details") {
        p(name, className = "name")

        p(healthDirectoryProfessionName, className = "health-directory-profession")

        p(medicalDiploma.labodocMedicalSpeciality.medicalProfession.name.value, className = "labodoc-medical-profession")

        p(medicalDiploma.labodocMedicalSpeciality.name.value, className = "labodoc-medical-speciality")
      }

      div(className = "actions") {
        button("", className = "action edit").bind(viewModel.updateProcessing) { updateProcessing ->
          if (updateProcessing) {
            disabled = true
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            icon = "fa-solid fa-solid fa-pencil"

            onClick {
              updateModal.show()
            }
          }
        }

        button("", className = "action delete").bind(viewModel.deleteProcessing) { deleteProcessing ->
          if (deleteProcessing) {
            disabled = true
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            icon = "fa-solid fa-solid fa-trash"

            onClick {
              deleteConfirmationModal.show()
            }
          }
        }
      }
    }
  }

  init {
    id = "page-admin-medical-diplomas"
    require("./css/pages/admin/medicalDiplomas/medical-diplomas.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      header {
        h1("Liste des diplômes médicaux")

        if (uiState is ViewModel.UiState.Loaded) {
          val medicalDiplomaForm = AdminMedicalDiplomaForm(
            healthDirectoryProfessions = uiState.healthDirectoryProfessions.associate { it.code to it.name },
            labodocMedicalProfessions = uiState.labodocMedicalProfessions
          )

          val creationModal =
            LabodocPopup(
              closeButton = true,
              icon = null,
              image = null,
              className = "admin-medical-diploma-modal",
              content = { popup ->
                h1("Diplôme médical")

                add(medicalDiplomaForm)

                labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
                  onClickLaunch {
                    disabled = true

                    either {
                      ensure(medicalDiplomaForm.validate()) { "Le formulaire n'est pas valide" }
                      val medicalDiplomaFormData = medicalDiplomaForm.getData()

                      val name = when (val name = medicalDiplomaFormData.name) {
                        null -> raise("Le nom est manquant")

                        is Either.Left -> when (name.value) {
                          Errors.MedicalDiploma.Name.Invalid.Blank -> raise("Le nom ne peut pas être vide")
                          Errors.MedicalDiploma.Name.Invalid.TooLong -> raise("Le nom est trop long")
                        }

                        is Either.Right -> name.value
                      }
                      val healthDirectoryProfessionCode = ensureNotNull(medicalDiplomaFormData.healthDirectoryProfessionCode) { "La profession de l'annuaire santé est manquante" }
                      val labodocMedicalProfessionId = ensureNotNull(medicalDiplomaFormData.labodocMedicalProfessionId) { "La profession LaboDoc est manquante" }
                      val labodocMedicalSpecialityId = ensureNotNull(medicalDiplomaFormData.labodocMedicalSpecialityId) { "La spécialité LaboDoc est manquante" }

                      viewModel.createMedicalDiploma(
                        name = name,
                        healthDirectoryProfessionCode = healthDirectoryProfessionCode,
                        labodocMedicalSpeciality = labodocMedicalSpecialityId
                      )
                    }
                      .onLeft { errorMessage: String ->
                        Toast.danger(errorMessage)
                      }
                      .onRight {
                        popup.hide()
                        medicalDiplomaForm.reset()
                      }

                    disabled = false
                  }
                }
              },
              beforeClose = { medicalDiplomaForm.reset() }
            )

          labodocButton("Ajouter un diplôme", "fa-solid fa-plus") {
            onClick {
              creationModal.show()
            }
          }
        }
      }

      div(className = "medical-diplomas") {
        header {
          p("Nom")
          p("Profession du répertoire de santé")
          p("Profession LaboDoc")
          p("Spécialité Laboodoc")
          p("Actions")
        }

        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          ViewModel.UiState.Error -> Toast.danger("Impossible de récupérer la liste des diplômes")

          is ViewModel.UiState.Loaded -> {
            uiState.medicalDiplomas.forEach { medicalDiploma ->
              add(
                MedicalDiplomaRow(
                  medicalDiploma = medicalDiploma,
                  healthDirectoryProfessions = uiState.healthDirectoryProfessions,
                  labodocMedicalProfessions = uiState.labodocMedicalProfessions,
                  onMedicalDiplomaUpdated = { viewModel.refreshPage() },
                  onMedicalDiplomaDeleted = { viewModel.refreshPage() }
                )
              )
            }
          }
        }
      }
    }
  }
}

fun Container.adminMedicalDiplomasPage(): AdminMedicalDiplomasPage {
  val adminMedicalDiplomasPage = AdminMedicalDiplomasPage()

  this.add(adminMedicalDiplomasPage)
  return adminMedicalDiplomasPage
}
