package fr.labodoc.webapp.pages.contact

import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import fr.labodoc.webapp.pages.contact.sections.ContactForm
import io.kvision.core.Container

fun Container.contactPage() {
  add(LabodocHeader())
  add(ContactForm())
  add(LabodocFooter())
}
