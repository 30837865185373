package fr.labodoc.domain.labodoc.partner

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class PartnerCode private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 10

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH
    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.Partner.Code.Invalid, PartnerCode> =
      either {
        ensure(isNotTooLong(value)) { Errors.Partner.Code.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.Partner.Code.Invalid.Blank }

        PartnerCode(value)
      }

    fun random(): PartnerCode = PartnerCode(
      List(6) {
        (('a'..'z') + ('A'..'Z') + ('0'..'9')).random()
      }.joinToString("")
    )
  }
}
