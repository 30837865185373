package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName

data class MedicalSpecialityModel(
  val id: MedicalSpecialityId,
  val name: MedicalSpecialityName,
  val medicalInterests: Set<MedicalInterestModel>
)
