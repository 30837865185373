package fr.labodoc.api.payloads.serializers

import io.ktor.http.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.SerializationException
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UrlAsStringSerializer : KSerializer<Url> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("UrlAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(url: String): Url =
    Url(url)

  override fun deserialize(decoder: Decoder): Url =
    try {
      deserialize(decoder.decodeString())
    } catch (e: URLParserException) {
      throw SerializationException(e)
    }

  fun serialize(url: Url): String =
    url.toString()

  override fun serialize(encoder: Encoder, value: Url) =
    encoder.encodeString(serialize(value))
}

typealias UrlAsString = @Serializable(UrlAsStringSerializer::class) Url
