package fr.labodoc.webapp.pages.contact.sections

import fr.labodoc.api.payloads.serializers.EmailAddressAsString
import fr.labodoc.app.data.healthprofessional.repository.ContactRepository
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocText
import fr.labodoc.webapp.components.labodocTextArea
import io.kvision.form.formPanel
import io.kvision.form.text.Text
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.html.p
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class ContactForm : SimplePanel(), KoinComponent {
  private val contactRepository: ContactRepository by inject()

  @Serializable
  data class Contact(
    val name: String,
    val email: EmailAddressAsString,
    val subject: String,
    val message: String
  ) {
    companion object {
      fun validateEmail(email: Text): String? = EmailAddress(email.value ?: "").fold(
        { I18n.tr("Errors.EmailAddress.Invalid") },
        { null }
      )
    }
  }

  init {
    require("./css/pages/contact/sections/contact-form.css")

    id = "contact-form"
    addCssClass("labodoc-background-beige")
    div(className = "page-width") {
      div(className = "labodoc-background-white") {
        id = "contact-form-container"

        h1("Formulaire de contact")

        p {
          rich = true

          content = """
            Besoin d’aide ou d’informations ? remplissez ce formulaire de contact, et nous reviendrons vers vous dans les plus brefs délais.<br>
            <br>
            <b>L'équipe LaboDoc s'occupe uniquement de la logistique de l'application et n'est pas habilitée à répondre à des questions médicales.</b>
          """.trimIndent()
        }

        hr()

        val form = formPanel<Contact> {
          labodocText {
            id = "contact-name"
            label = "Votre nom"
          }.bind(
            Contact::name,
            required = true,
            requiredMessage = I18n.tr("Field.Required"),
          )

          labodocText {
            id = "contact-email"
            label = "Votre email"
          }.bindCustom(
            Contact::email,
            required = true,
            requiredMessage = I18n.tr("Field.Required"),
            validatorMessage = { Contact.validateEmail(it) },
            validator = { Contact.validateEmail(it) == null }
          )

          labodocText {
            id = "contact-subject"
            label = "Objet du message"
          }.bindCustom(
            Contact::subject,
            required = true,
            requiredMessage = I18n.tr("Field.Required"),
          )

          labodocTextArea {
            id = "contact-message"
            label = "Votre message"
          }.bindCustom(
            Contact::message,
            required = true,
            requiredMessage = I18n.tr("Field.Required"),
          )
        }

        labodocButton("Envoyer", className = "labodoc-background-yellow") {
          onClick {
            if (form.validate()) {
              val formData = form.getData()

              App.scope.launch {
                contactRepository
                  .sendContactRequest(
                    formData.name,
                    formData.email,
                    formData.subject,
                    formData.message
                  )
                  .onLeft {
                    Toast.danger("Une erreur est survenue lors de l'envoi du message, veuillez réessayer")
                  }
                  .onRight {
                    Toast.success("Votre message a correctement été envoyé")
                    form.reset()
                  }
              }
            }
          }
        }
      }
    }
  }
}
