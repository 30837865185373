package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.app.data.healthprofessional.model.LearnedSocietyModel
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId

class LearnedSocietiesRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : LearnedSocietiesRepository {
  override suspend fun getLearnedSocieties(
  ): ApiResponse<Set<LearnedSocietyModel>> =
    apiClient.learnedSocieties
      .getLearnedSocieties()
      .map { learnedSocieties ->
        learnedSocieties
          .map { learnedSociety ->
            LearnedSocietyModel(
              id = learnedSociety.id,
              name = learnedSociety.name,
              acronym = learnedSociety.acronym,
              website = learnedSociety.website,
              logoUrl = learnedSociety.logo,
              medicalSpecialities = learnedSociety.medicalSpecialities,
              favorite = learnedSociety.favorite
            )
          }
          .toSet()
      }

  override suspend fun favorite(
    id: LearnedSocietyId
  ): ApiResponse<Unit> =
    apiClient.learnedSocieties
      .favoriteLearnedSociety(id)

  override suspend fun unfavorite(
    id: LearnedSocietyId
  ): ApiResponse<Unit> =
    apiClient.learnedSocieties
      .unfavoriteLearnedSociety(id)
}
