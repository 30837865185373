package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateMedicalDiplomaRequest
import fr.labodoc.api.payloads.requests.UpdateMedicalDiplomaRequest
import fr.labodoc.app.data.admin.model.MedicalDiplomaModel
import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiploma
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId

class MedicalDiplomasRepositoryImpl(
  private val apiClient: AdminApiClient
) : MedicalDiplomasRepository {
  override suspend fun getMedicalDiplomas(): ApiResponse<Set<MedicalDiplomaModel>> =
    apiClient.medicalDiplomas
      .getMedicalDiplomas()
      .map { medicalDiplomas ->
        medicalDiplomas
          .map { medicalDiploma ->
            MedicalDiplomaModel(
              id = medicalDiploma.id,
              name = medicalDiploma.name,
              healthDirectoryProfessionCode = medicalDiploma.healthDirectoryProfessionCode,
              labodocMedicalSpeciality = MedicalDiplomaModel.MedicalSpeciality(
                id = medicalDiploma.labodocMedicalSpeciality.id,
                medicalProfession = MedicalDiplomaModel.MedicalSpeciality.MedicalProfession(
                  id = medicalDiploma.labodocMedicalSpeciality.medicalProfession.id,
                  name = medicalDiploma.labodocMedicalSpeciality.medicalProfession.name
                ),
                name = medicalDiploma.labodocMedicalSpeciality.name
              )
            )
          }
          .toSet()
      }

  override suspend fun createMedicalDiploma(
    name: MedicalDiplomaName,
    healthDirectoryProfessionCode: ProfessionCode,
    labodocMedicalSpeciality: MedicalSpecialityId
  ): ApiResponse<MedicalDiplomaId> =
    apiClient.medicalDiplomas
      .createMedicalDiploma(
        payload = CreateMedicalDiplomaRequest(
          name = name,
          healthDirectoryProfessionCode = healthDirectoryProfessionCode,
          labodocMedicalSpeciality = labodocMedicalSpeciality
        )
      )
      .map { medicalDiplomaIdResponse ->
        medicalDiplomaIdResponse.id
      }

  override suspend fun updateMedicalDiploma(
    id: MedicalDiplomaId,
    name: MedicalDiplomaName,
    healthDirectoryProfessionCode: ProfessionCode,
    labodocMedicalSpeciality: MedicalSpecialityId
  ): ApiResponse<Unit> =
    apiClient.medicalDiplomas
      .updateMedicalDiploma(
        id = id,
        payload = UpdateMedicalDiplomaRequest(
          name = name,
          healthDirectoryProfessionCode = healthDirectoryProfessionCode,
          labodocMedicalSpeciality = labodocMedicalSpeciality
        )
      )

  override suspend fun deleteMedicalDiploma(id: MedicalDiplomaId): ApiResponse<Unit> =
    apiClient.medicalDiplomas
      .deleteMedicalDiploma(id)
}
