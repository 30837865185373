package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.ContactRequest
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.resources.Contact
import fr.labodoc.api.routes.ContactRoutes
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class ContactRemoteDataSource(
  private val httpClient: HttpClient
) : ContactRoutes {
  override suspend fun sendContactRequest(payload: ContactRequest): ApiResponse<Unit> {
    val response = httpClient.post(Contact()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
