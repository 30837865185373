package fr.labodoc.api.routes

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateMarketingDocumentRequest
import fr.labodoc.api.payloads.requests.CreateMedicineRequest
import fr.labodoc.api.payloads.requests.UpdateMarketingDocumentRequest
import fr.labodoc.api.payloads.requests.UpdateMedicineRequest
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId

interface AdminMedicinesRoutes {
  suspend fun getMedicines(
    forLaboratory: LaboratoryId? = null
  ): ApiResponse<AdminMedicinesSummaryResponse>

  suspend fun createMedicine(
    payload: CreateMedicineRequest,
    logo: InputFile
  ): ApiResponse<MedicineCreatedResponse>

  suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<AdminMedicineResponse>

  suspend fun updateMedicine(
    id: MedicineId,
    payload: UpdateMedicineRequest,
    logo: InputFile?
  ): ApiResponse<Unit>

  suspend fun deleteMedicine(
    id: MedicineId
  ): ApiResponse<Unit>

  suspend fun getMarketingDocuments(
    forMedicine: MedicineId
  ): ApiResponse<AdminMarketingDocumentsResponse>

  suspend fun addMarketingDocument(
    forMedicine: MedicineId,
    payload: CreateMarketingDocumentRequest,
    document: InputFile
  ): ApiResponse<MarketingDocumentCreatedResponse>

  suspend fun updateMarketingDocument(
    forMedicine: MedicineId,
    id: MarketingDocumentId,
    payload: UpdateMarketingDocumentRequest,
    document: InputFile?
  ): ApiResponse<Unit>

  suspend fun deleteMarketingDocument(
    forMedicine: MedicineId,
    id: MarketingDocumentId
  ): ApiResponse<Unit>

  suspend fun activate(
    id: MedicineId
  ): ApiResponse<Unit>

  suspend fun deactivate(
    id: MedicineId
  ): ApiResponse<Unit>
}
