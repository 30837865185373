package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.domain.labodoc.message.MessageContent
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import io.ktor.http.*
import kotlinx.datetime.Instant

sealed class MessageModel {
  abstract val id: MessageId
  abstract val title: MessageTitle
  abstract val content: MessageContent
  abstract val publishedAt: Instant
  abstract val segmentation: Segmentation
  abstract val bannerUrl: Url
  abstract val documentUrl: Url?
}

sealed class LabodocMessageModel : MessageModel()

sealed class LaboratoryMessageModel : MessageModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  abstract val laboratory: Laboratory
}

sealed class MedicineMessageModel : MessageModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  data class Medicine(
    val id: MedicineId,
    val name: MedicineName
  )

  abstract val laboratory: Laboratory
  abstract val medicine: Medicine
}

sealed class LearnedSocietyMessageModel : MessageModel() {
  data class LearnedSociety(
    val id: LearnedSocietyId,
    val name: LearnedSocietyName
  )

  abstract val learnedSociety: LearnedSociety
}

sealed interface FlashInfoMessageModel

sealed interface InvitationMessageModel {
  val eventAt: Instant
  val formUrl: Url?
}


data class LabodocFlashInfoModel(
  override val id: MessageId,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LabodocMessageModel(), FlashInfoMessageModel

data class LaboratoryFlashInfoModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LaboratoryMessageModel(), FlashInfoMessageModel

data class LaboratoryInvitationModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val eventAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val formUrl: Url?,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LaboratoryMessageModel(), InvitationMessageModel

data class MedicineFlashInfoModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : MedicineMessageModel(), FlashInfoMessageModel

data class MedicineInvitationModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val medicine: Medicine,
  override val publishedAt: Instant,
  override val eventAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val formUrl: Url?,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : MedicineMessageModel(), InvitationMessageModel

data class LearnedSocietyFlashInfoModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LearnedSocietyMessageModel(), FlashInfoMessageModel

data class LearnedSocietyInvitationModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val eventAt: Instant,
  override val title: MessageTitle,
  override val content: MessageContent,
  override val formUrl: Url?,
  override val segmentation: Segmentation,
  override val bannerUrl: Url,
  override val documentUrl: Url?
) : LearnedSocietyMessageModel(), InvitationMessageModel
