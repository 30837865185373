package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.EmailAddressAsString
import kotlinx.serialization.Serializable

@Serializable
data class ContactRequest(
  val name: String,
  val emailAddress: EmailAddressAsString,
  val subject: String,
  val message: String
)
