package fr.labodoc.webapp.di

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.webapp.pages.admin.medicines.create.AdminMedicineCreatePageViewModel
import fr.labodoc.webapp.pages.admin.medicines.create.AdminMedicineCreatePageViewModelImpl
import fr.labodoc.webapp.pages.admin.medicines.list.AdminMedicinesPageViewModel
import fr.labodoc.webapp.pages.admin.medicines.list.AdminMedicinesPageViewModelImpl
import fr.labodoc.webapp.pages.admin.medicines.update.AdminMedicineUpdatePageViewModel
import fr.labodoc.webapp.pages.admin.medicines.update.AdminMedicineUpdatePageViewModelImpl
import fr.labodoc.webapp.pages.admin.medicines.view.AdminMedicinePageViewModel
import fr.labodoc.webapp.pages.admin.medicines.view.AdminMedicinePageViewModelImpl
import fr.labodoc.webapp.pages.healthProfessional.dashboard.components.MedicineListViewModel
import fr.labodoc.webapp.pages.healthProfessional.dashboard.components.MedicineListViewModelImpl
import org.koin.core.qualifier.named
import org.koin.dsl.module

val viewModelModule = module {
  factory<AdminMedicinesPageViewModel> {
    AdminMedicinesPageViewModelImpl(
      medicinesRepository = get(named("admin"))
    )
  }

  factory<AdminMedicineCreatePageViewModel> { (laboratoryId: LaboratoryId?) ->
    AdminMedicineCreatePageViewModelImpl(
      laboratoryId = laboratoryId,
      laboratoriesRepository = get(named("admin")),
      medicineRepository = get(named("admin"))
    )
  }

  factory<AdminMedicinePageViewModel> { (medicineId: MedicineId) ->
    AdminMedicinePageViewModelImpl(
      medicineId = medicineId,
      medicinesRepository = get(named("admin")),
      laboratoryRepository = get(named("admin")),
      medicalProfessionsRepository = get(named("admin")),
      professionalStatusesRepository = get(named("admin")),
      medicalCardTypesRepository = get(named("admin"))
    )
  }

  factory<AdminMedicineUpdatePageViewModel> { (medicineId: MedicineId) ->
    AdminMedicineUpdatePageViewModelImpl(
      medicineId = medicineId,
      laboratoriesRepository = get(named("admin")),
      medicineRepository = get(named("admin"))
    )
  }

  factory<MedicineListViewModel> {
    MedicineListViewModelImpl(
      medicineRepository = get()
    )
  }
}
