package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.LearnedSocietyModel
import fr.labodoc.app.data.healthprofessional.source.remote.LearnedSocietiesRemoteDataSource
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId

class LearnedSocietiesRepositoryImpl(
  private val learnedSocietiesRemoteDataSource: LearnedSocietiesRemoteDataSource
) : LearnedSocietiesRepository {
  override suspend fun getLearnedSocieties(
  ): Either<RepositoryError.DataSource, Set<LearnedSocietyModel>> =
    learnedSocietiesRemoteDataSource
      .getLearnedSocieties()
      .mapLeft(RepositoryError::DataSource)

  override suspend fun favorite(
    id: LearnedSocietyId
  ): Either<RepositoryError, Unit> =
    learnedSocietiesRemoteDataSource
      .favoriteLearnedSociety(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)

  override suspend fun unfavorite(
    id: LearnedSocietyId
  ): Either<RepositoryError, Unit> =
    learnedSocietiesRemoteDataSource
      .unfavoriteLearnedSociety(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)
}
