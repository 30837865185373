package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.LaboratoryNameAsString
import fr.labodoc.api.payloads.serializers.LaboratoryPharmacovigilanceAsString
import fr.labodoc.api.payloads.serializers.LaboratoryWebsiteAsString
import kotlinx.serialization.Serializable

@Serializable
data class CreateLaboratoryRequest(
  val name: LaboratoryNameAsString,
  val website: LaboratoryWebsiteAsString,
  val pharmacovigilance: LaboratoryPharmacovigilanceAsString?
)
