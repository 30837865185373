package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.html.Button

class LabodocButtonSpinner(className: String?, init: (LabodocButtonSpinner.() -> Unit)? = null) : Button(text = "", className, disabled = true) {
  init {
    require("./css/components/labodoc-button.css")
    require("./css/components/labodoc-button-spinner.css")

    addCssClass("labodoc-button")
    addCssClass("labodoc-button-spinner")

    init?.invoke(this)
  }
}

fun Container.labodocButtonSpinner(className: String? = null, init: (LabodocButtonSpinner.() -> Unit)? = null): LabodocButtonSpinner {
  val spinner = LabodocButtonSpinner(className, init)
  this.add(spinner)
  return spinner
}
