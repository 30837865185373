package fr.labodoc.webapp.pages.admin.medicines

import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.require
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import io.kvision.html.div
import io.kvision.html.p

class MedicineDeleteConfirmationModal(
  name: MedicineName,
  onDeleteConfirmed: () -> Unit,
) {
  private val modal = LabodocPopup(
    closeButton = true,
    icon = "fa-solid fa-warning",
    image = null,
    className = "admin-medicine-delete-confirmation-modal",
    content = { modal ->
      p(className = "title") {
        content = "Êtes-vous sûr de vouloir supprimer le médicament \"${name.value}\" ?"
      }

      p {
        content = """
          Cette action supprimera tous les contenus liés: documents, messages, favoris des professionels de santé, ...
        """.trimIndent()
      }

      div(className = "choices") {
        labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "delete") {
          onClick {
            modal.hide()
            onDeleteConfirmed()
          }
        }

        labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
          onClick {
            modal.hide()
          }
        }
      }
    },
    beforeClose = null
  )

  init {
    require("./css/pages/admin/medicines/admin-medicine-delete-confirmation-modal.css")
  }

  fun show() {
    modal.show()
  }

  fun hide() {
    modal.hide()
  }
}
