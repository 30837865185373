package fr.labodoc.webapp.pages.register

import fr.labodoc.require
import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import fr.labodoc.webapp.pages.register.sections.Home
import fr.labodoc.webapp.pages.register.sections.WithCardForm
import fr.labodoc.webapp.pages.register.sections.WithoutCardForm
import io.kvision.core.Container
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind

class RegisterPage : SimplePanel() {
  enum class State {
    Home,
    WithCardForm,
    WithoutCardForm
  }

  val state: MutableState<State> = ObservableValue(State.Home)

  init {
    require("./css/pages/register/register.css")
    id = "page-register"

    h1("Créer son compte")

    div(className = "container").bind(state) {
      when (it) {
        State.Home -> {
          this@RegisterPage.removeCssClass("form")
          add(Home(state))
        }
        State.WithCardForm ->  {
          this@RegisterPage.addCssClass("form")
          add(WithCardForm(state))
        }
        State.WithoutCardForm ->  {
          this@RegisterPage.addCssClass("form")
          add(WithoutCardForm(state))
        }
      }
    }
  }
}

fun Container.registerPage() {
  add(LabodocHeader())
  add(RegisterPage())
  add(LabodocFooter())
}


