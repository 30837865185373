package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateMessageRequest
import fr.labodoc.api.payloads.requests.UpdateMessageRequest
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.api.resources.Messages
import fr.labodoc.api.routes.AdminMessagesRoutes
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.message.MessageId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class AdminMessagesRemoteDataSource(
  private val httpClient: HttpClient
) : AdminMessagesRoutes {
  override suspend fun getMessages(
  ): ApiResponse<AdminMessagesSummaryResponse> {
    val response = httpClient.get(Messages())

    return if (response.status.isSuccess())
      response.body<AdminMessagesSummaryResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createMessage(
    payload: CreateMessageRequest,
    document: InputFile?,
    banner: InputFile?
  ): ApiResponse<MessageIdResponse> {
    val response = httpClient.post(Messages()) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          document?.let {
            append("document", document.content, Headers.build {
              append(HttpHeaders.ContentType, document.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${document.name}\"")
            })
          }
          banner?.let {
            append("banner", banner.content, Headers.build {
              append(HttpHeaders.ContentType, banner.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${banner.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      response.body<MessageIdResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMessage(
    id: MessageId
  ): ApiResponse<AdminMessageResponse> {
    val response = httpClient.get(Messages.Message(id))

    return if (response.status.isSuccess())
      response.body<AdminMessageResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMessage(
    id: MessageId,
    payload: UpdateMessageRequest,
    document: InputFile?,
    banner: InputFile?
  ): ApiResponse<Unit> {
    val response = httpClient.put(Messages.Message(id)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          document?.let {
            append("document", document.content, Headers.build {
              append(HttpHeaders.ContentType, document.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${document.name}\"")
            })
          }
          banner?.let {
            append("banner", banner.content, Headers.build {
              append(HttpHeaders.ContentType, banner.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${banner.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteMessage(
    id: MessageId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Messages.Message(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
