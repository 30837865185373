package fr.labodoc.api.payloads.responses

import fr.labodoc.Error
import kotlinx.serialization.Serializable

@Serializable
data class ErrorResponse(
  val type: Error.Type,
  val code: String,
  val title: String? = null,
  val details: String? = null,
  val location: List<String>? = null
)
