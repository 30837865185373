package fr.labodoc.webapp.pages.admin.message

import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZip
import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.*
import fr.labodoc.app.data.admin.repository.*
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageContent
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocButton
import fr.labodoc.webapp.components.labodocSelect
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.message.form.*
import fr.labodoc.webapp.utils.toInputFile
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.onChange
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.datetime.toKotlinInstant
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMessageCreatePage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val laboratories: Set<LaboratorySummaryModel>,
        val learnedSocieties: Set<LearnedSocietyModel>,
        val medicalProfessions: Set<MedicalProfessionModel>,
        val professionalCategory: Set<ProfessionalCategoryModel>,
        val professionalStatuses: Set<ProfessionalStatusModel>,
        val medicalCardTypes: Set<MedicalCardTypeModel>,
        val departments: Set<DepartmentModel>,
        val messageFormType: MessageFormType?
      ) : UiState()

      data class MessageCreated(
        val messageId: MessageId
      ): UiState()
    }

    enum class MessageFormType(val label: String) {
      LabodocFlashInfo("Flash Info Labodoc"),
      LaboratoryFlashInfo("Flash Info Laboratoire"),
      LaboratoryInvitation("Invitation Laboratoire"),
      MedicineFlashInfo("Flash Info Médicament"),
      MedicineInvitation("Invitation Médicament"),
      LearnedSocietyFlashInfo("Flash Info Société Savante"),
      LearnedSocietyInvitation("Invitation Société Savante")
    }

    val uiState: ObservableState<UiState>
    val createMessageErrorMessage: ObservableState<String?>

    fun changeMessageFormType(messageFormType: MessageFormType)
    fun createMessage(messageFormData: AdminMessageForm.AdminMessageFormData)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val laboratoryRepository: LaboratoriesRepository by inject(named("admin"))
    private val learnedSocietyRepository: LearnedSocietiesRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))
    private val departmentsRepository: DepartmentsRepository by inject(named("admin"))
    private val messageRepository: MessagesRepository by inject(named("admin"))
    private val professionalCategoriesRepository: ProfessionalCategoriesRepository by inject(named("admin"))
    private val professionalStatusesRepository: ProfessionalStatusesRepository by inject(named("admin"))
    private val medicalCardTypesRepository: MedicalCardTypesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue = ObservableValue<ViewModel.UiState>(ViewModel.UiState.Loading)

      App.scope.launch {
        either {
          parZip(
            { laboratoryRepository.getLaboratories().bind() },
            { learnedSocietyRepository.getLearnedSocieties().bind() },
            { medicalProfessionsRepository.getMedicalProfessions().bind() },
            { departmentsRepository.getDepartments().bind() },
            { professionalCategoriesRepository.getProfessionalCategories().bind() },
            { professionalStatusesRepository.getProfessionalStatuses().bind() },
            { medicalCardTypesRepository.getMedicalCardTypes().bind() }
          ) { laboratories, learnedSocieties, medicalProfessionsResponse, departments, professionalCategories, professionalStatuses, medicalCardTypes ->
            ViewModel.UiState.Loaded(
              laboratories,
              learnedSocieties,
              medicalProfessionsResponse,
              professionalCategories,
              professionalStatuses,
              medicalCardTypes,
              departments,
              null
            )
          }
        }
          .onLeft { observableValue.setState(ViewModel.UiState.Error) }
          .onRight { observableValue.setState(it) }
      }

      observableValue
    }

    override val createMessageErrorMessage: ObservableValue<String?> =
      ObservableValue(null)

    override fun changeMessageFormType(messageFormType: ViewModel.MessageFormType) {
      val currentUiState = uiState.getState()

      if (currentUiState is ViewModel.UiState.Loaded) {
        uiState.setState(currentUiState.copy(messageFormType = messageFormType))
      }
    }

    override fun createMessage(messageFormData: AdminMessageForm.AdminMessageFormData) {
      App.scope.launch {
        either {
          val publishedAt = messageFormData.publishedAt.let { publishedAt ->
            ensureNotNull(publishedAt) { "Date de publication manquante" }
            publishedAt.toKotlinInstant()
          }

          val title = messageFormData.title.let { title ->
            ensureNotNull(title) { "Titre manquant" }
            MessageTitle(title)
              .mapLeft { error ->
                when (error) {
                  Errors.Message.Title.Invalid.Blank -> "Le titre ne peut pas être vide"
                  Errors.Message.Title.Invalid.TooLong -> "Le titre est trop long, taille maximum: ${MessageTitle.MAX_LENGTH} caractères"
                }
              }
              .bind()
          }

          val text = messageFormData.text.let { text ->
            ensureNotNull(text) { "Texte manquant" }
            MessageContent(text)
          }

          val segmentation = ensureNotNull(messageFormData.segmentation) { "Segmentation manquante" }

          val banner = messageFormData.banner?.firstOrNull()

          val document = messageFormData.document?.firstOrNull()

          when (messageFormData) {
            is AdminLabodocFlashInfoMessageForm.Data -> {
              messageRepository
                .createLabodocFlashInfo(
                  publishedAt = publishedAt,
                  title = title,
                  content = text,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLaboratoryFlashInfoMessageForm.Data -> {
              val laboratoryId = messageFormData.laboratoryId.let { laboratoryId ->
                ensureNotNull(laboratoryId) { "Laboratoire manquant" }
                LaboratoryId(uuidFrom(laboratoryId))
              }

              messageRepository.createLaboratoryFlashInfo(
                laboratoryId = laboratoryId,
                publishedAt = publishedAt,
                title = title,
                content = text,
                segmentation = segmentation,
                banner = banner?.toInputFile(),
                document = document?.toInputFile()
              )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLaboratoryInvitationMessageForm.Data -> {
              val laboratoryId = messageFormData.laboratoryId.let { laboratoryId ->
                ensureNotNull(laboratoryId) { "Laboratoire manquant" }
                LaboratoryId(uuidFrom(laboratoryId))
              }

              val eventAt = messageFormData.eventAt.let { eventAt ->
                ensureNotNull(eventAt) { "Date d'événement manquant" }
                eventAt.toKotlinInstant()
              }

              val formUrl = messageFormData.formUrl?.let { formUrl ->
                Url(formUrl)
              }

              messageRepository
                .createLaboratoryInvitation(
                  laboratoryId = laboratoryId,
                  publishedAt = publishedAt,
                  title = title,
                  eventAt = eventAt,
                  content = text,
                  formUrl = formUrl,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminMedicineFlashInfoMessageForm.Data -> {
              val medicineId = messageFormData.medicineId.let { medicineId ->
                ensureNotNull(medicineId) { "Médicament manquant" }
                MedicineId(uuidFrom(medicineId))
              }

              messageRepository.createMedicineFlashInfo(
                medicineId = medicineId,
                publishedAt = publishedAt,
                title = title,
                content = text,
                segmentation = segmentation,
                banner = banner?.toInputFile(),
                document = document?.toInputFile()
              )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminMedicineInvitationMessageForm.Data -> {
              val medicineId = messageFormData.medicineId.let { medicineId ->
                ensureNotNull(medicineId) { "Médicament manquant" }
                MedicineId(uuidFrom(medicineId))
              }

              val eventAt = messageFormData.eventAt.let { eventAt ->
                ensureNotNull(eventAt) { "Date d'événement manquant" }
                eventAt.toKotlinInstant()
              }

              val formUrl = messageFormData.formUrl?.let { formUrl ->
                Url(formUrl)
              }

              messageRepository
                .createMedicineInvitation(
                  medicineId = medicineId,
                  publishedAt = publishedAt,
                  title = title,
                  eventAt = eventAt,
                  content = text,
                  formUrl = formUrl,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLearnedSocietyFlashInfoMessageForm.Data -> {
              val learnedSocietyId = messageFormData.learnedSocietyId.let { learnedSocietyId ->
                ensureNotNull(learnedSocietyId) { "Laboratoire manquant" }
                LearnedSocietyId(uuidFrom(learnedSocietyId))
              }

              messageRepository.createLearnedSocietyFlashInfo(
                learnedSocietyId = learnedSocietyId,
                publishedAt = publishedAt,
                title = title,
                content = text,
                segmentation = segmentation,
                banner = banner?.toInputFile(),
                document = document?.toInputFile()
              )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLearnedSocietyInvitationMessageForm.Data -> {
              val learnedSocietyId = messageFormData.learnedSocietyId.let { learnedSocietyId ->
                ensureNotNull(learnedSocietyId) { "Laboratoire manquant" }
                LearnedSocietyId(uuidFrom(learnedSocietyId))
              }

              val eventAt = messageFormData.eventAt.let { eventAt ->
                ensureNotNull(eventAt) { "Date d'événement manquant" }
                eventAt.toKotlinInstant()
              }

              val formUrl = messageFormData.formUrl?.let { formUrl ->
                Url(formUrl)
              }

              messageRepository
                .createLearnedSocietyInvitation(
                  learnedSocietyId = learnedSocietyId,
                  publishedAt = publishedAt,
                  title = title,
                  eventAt = eventAt,
                  content = text,
                  formUrl = formUrl,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }
          }
        }
          .onLeft { errorMessage: String ->
            createMessageErrorMessage.setState(errorMessage)
          }
          .onRight { createdMessageId: MessageId ->
            uiState.setState(ViewModel.UiState.MessageCreated(createdMessageId))
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-message-create"
    require("./css/pages/admin/message/create.css")

    viewModel.createMessageErrorMessage.subscribe { errorMessage: String? ->
      errorMessage?.let { Toast.danger(it) }
    }

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1("Créer un message")

      when (uiState) {
        is ViewModel.UiState.Loading -> labodocSpinner()

        is ViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is ViewModel.UiState.Loaded -> {
          labodocSelect {
            label = "Type de message"

            options = ViewModel.MessageFormType.entries.map { it.name to it.label }

            value = uiState.messageFormType?.name

            onChange {
              val messageForm = value?.let { ViewModel.MessageFormType.valueOf(it) }

              messageForm?.let { viewModel.changeMessageFormType(it) }
            }
          }

          val messageForm = when (uiState.messageFormType) {
            null -> null
            ViewModel.MessageFormType.LabodocFlashInfo -> AdminLabodocFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet()
            )

            ViewModel.MessageFormType.LaboratoryFlashInfo -> AdminLaboratoryFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = uiState.laboratories
            )

            ViewModel.MessageFormType.LaboratoryInvitation -> AdminLaboratoryInvitationMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = uiState.laboratories
            )

            ViewModel.MessageFormType.MedicineFlashInfo -> AdminMedicineFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = uiState.laboratories
            )

            ViewModel.MessageFormType.MedicineInvitation -> AdminMedicineInvitationMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = uiState.laboratories
            )

            ViewModel.MessageFormType.LearnedSocietyFlashInfo -> AdminLearnedSocietyFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              learnedSocieties = uiState.learnedSocieties
            )

            ViewModel.MessageFormType.LearnedSocietyInvitation -> AdminLearnedSocietyInvitationMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              learnedSocieties = uiState.learnedSocieties
            )
          }

          messageForm?.let {
            val saveButton = LabodocButton("Sauvegarder", className = "save") {
              onClickLaunch {
                disabled = true

                if (messageForm.validate()) {
                  val messageFormData = messageForm.getDataWithFileContent()

                  viewModel.createMessage(messageFormData)
                } else
                  disabled = false
              }

              viewModel.createMessageErrorMessage.subscribe { _: String? ->
                disabled = false
              }
            }

            add(messageForm)

            add(saveButton)
          }
        }

        is ViewModel.UiState.MessageCreated -> App.routing.navigate(Page.AdminBackOfficeMessagesList())
      }
    }
  }
}

fun Container.adminMessageCreatePage(): AdminMessageCreatePage {
  val adminMessageCreatePage = AdminMessageCreatePage()

  this.add(adminMessageCreatePage)
  return adminMessageCreatePage
}
