package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite
import io.ktor.http.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object PartnershipIdAsStringSerializer : KSerializer<PartnershipId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PartnershipIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.Partnership.Id.Invalid, PartnershipId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.Partnership.Id.Invalid.Format }
      .map { PartnershipId(it) }

  override fun deserialize(decoder: Decoder): PartnershipId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: PartnershipId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: PartnershipId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias PartnershipIdAsString = @Serializable(PartnershipIdAsStringSerializer::class) PartnershipId


object PartnershipNameAsStringSerializer : KSerializer<PartnershipName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PartnershipNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.Partnership.Name.Invalid, PartnershipName> =
    PartnershipName(name)

  override fun deserialize(decoder: Decoder): PartnershipName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(name: PartnershipName): String = name.value

  override fun serialize(encoder: Encoder, value: PartnershipName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias PartnershipNameAsString = @Serializable(PartnershipNameAsStringSerializer::class) PartnershipName


object PartnershipWebsiteAsStringSerializer : KSerializer<PartnershipWebsite> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PartnershipWebsiteAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(website: String): Either<Nothing, PartnershipWebsite> =
    PartnershipWebsite(Url(website)).right()

  override fun deserialize(decoder: Decoder): PartnershipWebsite =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(website: PartnershipWebsite): String = website.value.toString()

  override fun serialize(encoder: Encoder, value: PartnershipWebsite) =
    encoder.encodeString(serialize(value))
}

typealias PartnershipWebsiteAsString = @Serializable(PartnershipWebsiteAsStringSerializer::class) PartnershipWebsite
