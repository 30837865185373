package fr.labodoc.webapp.pages.admin.medicines.update

import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.webapp.App
import fr.labodoc.webapp.pages.admin.medicines.AdminMedicineForm
import io.kvision.state.ObservableValue
import kotlinx.coroutines.launch

class AdminMedicineUpdatePageViewModelImpl(
  private val medicineId: MedicineId,
  private val medicineRepository: MedicinesRepository,
  private val laboratoriesRepository: LaboratoriesRepository
) : AdminMedicineUpdatePageViewModel() {
  private val processing: ObservableValue<Boolean> = ObservableValue(false)

  override val uiState: ObservableValue<UiState> by lazy {
    refresh()
    ObservableValue(UiState.Loading)
  }
  override val errorMessage: ObservableValue<String?> = ObservableValue(null)
  override val successMessage: ObservableValue<String?> = ObservableValue(null)

  override fun refresh() {
    App.scope.launch {
      uiState.value = UiState.Loading

      uiState.value = either {
        parZip(
          { medicineRepository.getMedicine(medicineId).bind() },
          { laboratoriesRepository.getLaboratories().bind() },
        ) { medicine, laboratories ->
          UiState.Form(
            medicine = medicine,
            laboratories = laboratories,
            formData = AdminMedicineForm.Data(
              laboratory = medicine.laboratory?.value?.toString(),
              name = medicine.name.value,
              atcClassificationCode = medicine.atcClassification.code.value
            ),
            processing = processing
          )
        }
      }.mapLeft {
        UiState.Error
      }.fold({ it }, { it })
    }
  }

  override fun updateMedicine(
    form: AdminMedicineForm
  ) {
    App.scope.launch {
      processing.value = true

      form.getValidatedData()
        .onLeft {
          errorMessage.value = "Le formulaire n'est pas valide"
        }
        .onRight { validatedData ->
          medicineRepository
            .updateMedicine(
              id = medicineId,
              laboratory = validatedData.laboratory,
              name = validatedData.name,
              atcClassificationCode = validatedData.atcClassificationCode,
              logo = validatedData.logo
            )
            .onLeft {
              errorMessage.value = "Une erreur est survenue, vérifiez les données et réessayez"
            }
            .onRight {
              uiState.value = UiState.MedicineUpdated(
                id = medicineId
              )
            }
        }

      processing.value = false
    }
  }
}
