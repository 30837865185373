package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.MedicalProfessionResponse
import fr.labodoc.app.data.healthprofessional.model.MedicalInterestModel
import fr.labodoc.app.data.healthprofessional.model.MedicalProfessionModel
import fr.labodoc.app.data.healthprofessional.model.MedicalSpecialityModel
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId

class MedicalProfessionsRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : MedicalProfessionsRepository {
  override suspend fun getMedicalProfessions(): ApiResponse<Set<MedicalProfessionModel>> =
    apiClient.medicalProfessions
      .getMedicalProfessions()
      .map { medicalProfessions ->
        medicalProfessions
          .map(MedicalProfessionResponse::toModel)
          .toSet()
      }

  override suspend fun getMedicalProfession(id: MedicalProfessionId): ApiResponse<MedicalProfessionModel> =
    apiClient.medicalProfessions
      .getMedicalProfession(id)
      .map(MedicalProfessionResponse::toModel)
}

fun MedicalProfessionResponse.toModel(): MedicalProfessionModel =
  MedicalProfessionModel(
    id = id,
    name = name,
    medicalSpecialities = medicalSpecialities.map { it.toModel() }.toSet()
  )

fun MedicalProfessionResponse.MedicalSpeciality.toModel(): MedicalSpecialityModel =
  MedicalSpecialityModel(
    id = id,
    name = name,
    medicalInterests = medicalInterests.map { it.toModel() }.toSet()
  )

fun MedicalProfessionResponse.MedicalSpeciality.MedicalInterest.toModel(): MedicalInterestModel =
  MedicalInterestModel(
    id = id,
    name = name
  )
