package fr.labodoc.app.data.healthprofessional.source.remote

import arrow.core.Either
import arrow.core.raise.catch
import arrow.core.raise.either
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.UniversityHospitalResponse
import fr.labodoc.app.data.error.RemoteDataSourceError
import fr.labodoc.app.data.healthprofessional.model.UniversityHospitalModel

class UniversityHospitalsRemoteDataSourceImpl(
  private val apiClient: HealthProfessionalApiClient
) : UniversityHospitalsRemoteDataSource {
  override suspend fun getUniversityHospitals(
  ): Either<RemoteDataSourceError, Set<UniversityHospitalModel>> =
    either {
      catch({
        apiClient.universityHospitals
          .getUniversityHospitals()
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map { response: Set<UniversityHospitalResponse> ->
            response.toModel()
          }
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }
}

private fun UniversityHospitalResponse.toModel(): UniversityHospitalModel =
  UniversityHospitalModel(
    id = id,
    name = name,
    department = UniversityHospitalModel.Department(
      code = department.code,
      name = department.name
    )
  )

private fun Set<UniversityHospitalResponse>.toModel(): Set<UniversityHospitalModel> =
  this
    .map { universityHospital: UniversityHospitalResponse ->
      UniversityHospitalModel(
        id = universityHospital.id,
        name = universityHospital.name,
        department = UniversityHospitalModel.Department(
          code = universityHospital.department.code,
          name = universityHospital.department.name
        )
      )
    }
    .toSet()
