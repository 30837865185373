package fr.labodoc.api

import fr.labodoc.api.client.*
import io.ktor.client.*

class HealthProfessionalApiClient(httpClient: HttpClient) {
  val applications = HealthProfessionalApplicationsRemoteDataSource(httpClient)
  val atcCodes = AtcCodesRemoteDataSource(httpClient)
  val contact = ContactRemoteDataSource(httpClient)
  val departments = DepartmentsRemoteDataSource(httpClient)
  val healthDirectory = HealthDirectoryRemoteDataSource(httpClient)
  val laboratories = HealthProfessionalLaboratoriesRemoteDataSource(httpClient)
  val learnedSocieties = HealthProfessionalLearnedSocietiesRemoteDataSource(httpClient)
  val medicalDiplomas = HealthProfessionalMedicalDiplomasRemoteDataSource(httpClient)
  val medicalProfessions = HealthProfessionalMedicalProfessionsRemoteDataSource(httpClient)
  val medicines = HealthProfessionalMedicinesRemoteDataSource(httpClient)
  val messages = HealthProfessionalMessagesRemoteDataSource(httpClient)
  val universityHospitals = HealthProfessionalUniversityHospitalsRemoteDataSource(httpClient)
  val users = HealthProfessionalUsersRemoteDataSource(httpClient)
}
