package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName

data class MedicalProfessionModel(
  val id: MedicalProfessionId,
  val name: MedicalProfessionName,
  val medicalSpecialities: Set<MedicalSpecialityModel>
)
