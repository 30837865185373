package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import io.ktor.http.*

data class LaboratoryModel(
  val id: LaboratoryId,
  val name: LaboratoryName,
  val website: LaboratoryWebsite,
  val logoUrl: Url,
  val favorite: Boolean
)
