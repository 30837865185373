package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.form.text.TextArea
import io.kvision.form.text.TextAreaInput
import io.kvision.html.span

class LabodocTextArea(
  cols: Int? = null, rows: Int? = null, value: String? = null, name: String? = null,
  label: String? = null, rich: Boolean = false, notice: String? = null,
  init: (LabodocTextArea.() -> Unit)? = null
) : TextArea(cols, rows, value, name, label, rich, false, null) {

  var notice by refreshOnUpdate(notice) {
    spanNotice.content = it
  }

  private val spanNotice = span(className = "notice")

  init {
    require("./css/components/labodoc-text.css")

    addCssClass("labodoc-text")

    placeholder = ""

    add(spanNotice)

    init?.invoke(this)
  }
}

fun Container.labodocTextArea(
  cols: Int? = null, rows: Int? = null, value: String? = null, name: String? = null,
  label: String? = null, rich: Boolean = false, notice: String? = null,
  init: (LabodocTextArea.() -> Unit)? = null
): LabodocTextArea {
  val textArea = LabodocTextArea(cols, rows, value, name, label, rich, notice, init)
  this.add(textArea)
  return textArea
}

class LabodocTextAreaInput(
  cols: Int? = null,
  rows: Int? = null,
  value: String? = null,
  className: String? = null,
  init: (LabodocTextAreaInput.() -> Unit)? = null
) : TextAreaInput(cols, rows, value, className, null) {

  init {
    require("./css/components/labodoc-text.css")

    addCssClass("labodoc-text")

    init?.invoke(this)
  }
}

fun Container.labodocTextAreaInput(
  cols: Int? = null, rows: Int? = null, value: String? = null,
  className: String? = null,
  init: (LabodocTextAreaInput.() -> Unit)? = null
): LabodocTextAreaInput {
  val textAreaInput = LabodocTextAreaInput(cols, rows, value, className, init)
  this.add(textAreaInput)
  return textAreaInput
}
