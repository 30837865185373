package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.LaboratoryIdAsString
import io.ktor.resources.*

@Resource("laboratories")
class Laboratories(
  val filter: String? = null
) {
  @Resource("{laboratoryId}")
  class Laboratory(
    val laboratoryId: LaboratoryIdAsString,
    val laboratories: Laboratories = Laboratories()
  ) {
    @Resource("activate")
    class Activate(val laboratory: Laboratory) {
      constructor(laboratoryId: LaboratoryIdAsString) : this(Laboratory(laboratoryId))
    }

    @Resource("deactivate")
    class Deactivate(
      val laboratory: Laboratory
    ) {
      constructor(laboratoryId: LaboratoryIdAsString) : this(Laboratory(laboratoryId))
    }

    @Resource("favorite")
    class Favorite(
      val laboratory: Laboratory
    ) {
      constructor(laboratoryId: LaboratoryIdAsString) : this(Laboratory(laboratoryId))
    }
  }
}
