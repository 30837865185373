package fr.labodoc.webapp.pages.admin.partnership.form

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocText
import fr.labodoc.webapp.components.LabodocUpload
import fr.labodoc.webapp.utils.toInputFile
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.html.Image
import io.kvision.html.div
import io.kvision.i18n.I18n
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.w3c.dom.url.URL

class AdminPartnershipForm(
  val currentLogoUrl: Url?
) : FormPanel<AdminPartnershipForm.Data>(
  serializer = Data.serializer(),
  className = "admin-partnership-form"
) {
  @Serializable
  data class Data(
    val name: String? = null,
    val logo: List<KFile>? = null,
    val website: String? = null
  )

  data class ValidatedData(
    val name: PartnershipName,
    val logo: InputFile?,
    val website: PartnershipWebsite
  )

  private val nameInput = LabodocText {
    label = "Nom"
  }.bind(
    key = Data::name,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validateName(it.value).isRight() },
    validatorMessage = { validateName(it.value).leftOrNull() }
  )

  private val logoPreview = Image(currentLogoUrl?.toString())

  private val logoInput = LabodocUpload {
    label = "Logo"
    multiple = false
    accept = listOf("image/*")

    subscribe {
      App.scope.launch {
        logoPreview.src = it?.firstOrNull()?.let { getNativeFile(it)?.getContent() } ?: currentLogoUrl?.toString()

        if (logoPreview.src != null)
          logoPreview.show()
        else
          logoPreview.hide()
      }
    }
  }.bind(
    key = Data::logo,
    required = currentLogoUrl == null,
    requiredMessage = I18n.tr("Field.Required")
  )

  private val websiteInput = LabodocText {
    label = "Site internet"
  }.bind(
    key = Data::website,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validateWebsite(it.value).isRight() },
    validatorMessage = { validateWebsite(it.value).leftOrNull() }
  )

  init {
    div(className = "information") {
      add(nameInput)

      add(websiteInput)
    }

    div(className = "logo") {
      add(logoPreview)

      add(logoInput)
    }
  }

  private fun validateName(input: String?): Either<String, PartnershipName> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      PartnershipName(input)
        .mapLeft { error ->
          when (error) {
            Errors.Partnership.Name.Invalid.Blank -> "Ne peut pas être vide"
            Errors.Partnership.Name.Invalid.TooLong -> "Trop long"
          }
        }
        .bind()
    }

  private fun validateLogo(input: List<KFile>?): Either<String, InputFile?> =
    either {
      val firstFile = input?.firstOrNull()

      if (currentLogoUrl == null)
        ensureNotNull(firstFile) { I18n.tr("Field.Required") }

      firstFile?.toInputFile()
    }

  private fun validateWebsite(input: String?): Either<String, PartnershipWebsite> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      try {
        URL(input)
        PartnershipWebsite(Url(input))
      } catch (exception: dynamic) {
        raise("L'URL est invalide")
      }
    }

  suspend fun getValidatedData(): Either<Nel<String>, ValidatedData> =
    either {
      val inputs = getDataWithFileContent()

      zipOrAccumulate(
        {
          validateName(inputs.name)
            .onLeft { nameInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${nameInput.label}: $it" }
            .bind()
        },
        {
          validateLogo(inputs.logo)
            .onLeft { logoInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${logoInput.label}: $it" }
            .bind()
        },
        {
          validateWebsite(inputs.website)
            .onLeft { websiteInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${websiteInput.label}: $it" }
            .bind()
        }
      ) { name, logo, website ->
        ValidatedData(
          name = name,
          logo = logo,
          website = website
        )
      }
    }
}

fun Container.adminPartnershipForm(
  currentLogoUrl: Url?
): AdminPartnershipForm {
  val adminPartnershipForm = AdminPartnershipForm(
    currentLogoUrl = currentLogoUrl
  )

  this.add(adminPartnershipForm)
  return adminPartnershipForm
}
