package fr.labodoc.webapp.pages.register.sections

import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocCheckbox
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.pages.register.RegisterPage
import io.kvision.form.check.CheckBox
import io.kvision.form.formPanel
import io.kvision.html.div
import io.kvision.html.i
import io.kvision.html.p
import io.kvision.html.strong
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import kotlinx.serialization.Serializable


class Home(state: MutableState<RegisterPage.State>) : SimplePanel("home") {
  @Serializable
  data class Requirements(
    val termsOfServiceAccepted: Boolean
  ) {
    companion object {
      fun validateTermsOfService(termsOfService: CheckBox): String? =
        if (termsOfService.value) null else I18n.tr("Field.Required")
    }
  }

  init {
    require("./css/pages/register/sections/home.css")

    div(className = "page-width") {

      p("Vous avez déjà un compte ? ", rich = true) {
        navigoLink("Connectez-vous", Page.Login())
      }

      hr()

      i(className = "icon fa-solid fa-address-card")

      formPanel<Requirements> {
        p {
          strong("LaboDoc est réservé aux professionnels de santé en activité et aux étudiants en santé.")
        }

        add(
          Requirements::termsOfServiceAccepted,
          labodocCheckbox(
            label = "J'accepte les <a href=\"${Page.TermsOfService().url}\" target=\"_blank\">conditions générales d'utilisation",
            rich = true
          ),
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validatorMessage = { Requirements.validateTermsOfService(it) },
          validator = { Requirements.validateTermsOfService(it) == null }
        )

        div(className = "buttons") {
          labodocButton("J'ai une carte CPS/CPF", className = "with-card-form labodoc-background-middle-blue") {
            onClick {
              if (this@formPanel.validate())
                state.setState(RegisterPage.State.WithCardForm)
            }
          }
          labodocButton(
            "Je n'ai pas de carte CPS/CPF",
            className = "without-card-form labodoc-background-middle-blue-reverse"
          ) {
            onClick {
              if (this@formPanel.validate())
                state.setState(RegisterPage.State.WithoutCardForm)
            }
          }
        }
      }
    }
  }
}
