package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.healthdirectory.MedicalCardNumber
import fr.labodoc.domain.labodoc.Errors
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicalCardNumberAsStringSerializer : KSerializer<MedicalCardNumber> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalCardNumberAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(medicalCardNumber: String): Either<Errors.MedicalCard.Number.Invalid, MedicalCardNumber> =
    MedicalCardNumber(medicalCardNumber)

  override fun deserialize(decoder: Decoder): MedicalCardNumber {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(medicalCardNumber: MedicalCardNumber): String = medicalCardNumber.value

  override fun serialize(encoder: Encoder, value: MedicalCardNumber) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MedicalCardNumberAsString = @Serializable(MedicalCardNumberAsStringSerializer::class) MedicalCardNumber
