package fr.labodoc.webapp.di

import fr.labodoc.Cookie
import fr.labodoc.Cookies
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.client.httpClient
import fr.labodoc.process
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.resources.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.delay
import org.koin.dsl.module

val dataSourceModule = module {
  single<HttpClient> {
    httpClient {
      defaultRequest {
        url(process.env.API_URL)
      }

      install(Auth) {
        bearer {
          loadTokens {
            (Cookie.get(Cookies.AuthToken) as? String)?.let {
              BearerTokens(it, null)
            }
          }

          refreshTokens {
            // This is stupid.
            // If it detects too fast that there is a new token available, it will cancel the previous request to make a new one with the token.
            // HOWEVER, this throw a gigantic error at the face of the user because something tries to read data when the request has been cancelled.
            // This seems to be introduced by this commit https://github.com/ktorio/ktor/commit/40042dc845e38dcdc2ba2fee0224fbfd2b13d172 on the JS engine.
            delay(100)
            (Cookie.get(Cookies.AuthToken) as? String)?.let {
              BearerTokens(it, null)
            }
          }
        }
      }

      install(Resources)

      install(ContentNegotiation) {
        json()
      }
    }
  }

  single<AdminApiClient> {
    AdminApiClient(get())
  }

  single<HealthProfessionalApiClient> {
    HealthProfessionalApiClient(get())
  }
}
