package fr.labodoc.webapp.pages.admin.partnership

import fr.labodoc.app.data.admin.model.PartnershipModel
import fr.labodoc.app.data.admin.repository.PartnershipsRepository
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.partnership.form.AdminPartnershipForm
import fr.labodoc.webapp.pages.admin.partnership.form.adminPartnershipForm
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.html.p
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminPartnershipUpdatePage(
  partnershipId: PartnershipId
) : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading: UiState()

      data object Error: UiState()

      data class Form(
        val partnership: PartnershipModel,
        val updateProcessing: ObservableState<Boolean>,
        val error: ObservableState<String?>,
      ): UiState()

      data class Updated(
        val id: PartnershipId
      ): UiState()
    }

    val uiState: ObservableState<UiState>

    fun updatePartnership(
      name: PartnershipName,
      logo: InputFile?,
      website: PartnershipWebsite
    )
  }

  private class ViewModelImpl(
    private val partnershipId: PartnershipId
  ): ViewModel, KoinComponent {
    private val partnershipsRepository: PartnershipsRepository by inject(named("admin"))

    private val updateProcessing: ObservableValue<Boolean> =
      ObservableValue(false)

    private val error: ObservableValue<String?> =
      ObservableValue(null)

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      App.scope.launch {
        val newUiState = partnershipsRepository
          .getPartnership(partnershipId)
          .fold(
            {
              ViewModel.UiState.Error
            },
            { partnership ->
              ViewModel.UiState.Form(
                partnership = partnership,
                updateProcessing = updateProcessing,
                error = error
              )
            }
          )

        uiState.value = newUiState
      }

      ObservableValue(ViewModel.UiState.Loading)
    }

    override fun updatePartnership(
      name: PartnershipName,
      logo: InputFile?,
      website: PartnershipWebsite
    ) {
      App.scope.launch {
        updateProcessing.value = true

        partnershipsRepository
          .updatePartnership(
            id = partnershipId,
            name = name,
            logo = logo,
            website = website
          )
          .onLeft {
            error.value = "Une erreur est survenue"
          }
          .onRight {
            uiState.value = ViewModel.UiState.Updated(
              id = partnershipId
            )
          }

        updateProcessing.value = false
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(
    partnershipId = partnershipId
  )

  init {
    id = "page-admin-partnership-update"
    require("./css/pages/admin/partnership/update.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1 {
        content = "Mettre à jour un partenariat"
      }

      when (uiState) {
        is ViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        is ViewModel.UiState.Error -> {
          p("Une erreur est survenue lors de la récupération du partenariat")
        }

        is ViewModel.UiState.Form -> {
          uiState.error.subscribe { error ->
            error?.also { Toast.danger(it) }
          }

          val partnershipForm = adminPartnershipForm(
            currentLogoUrl = uiState.partnership.logo
          ).apply {
            setData(
              AdminPartnershipForm.Data(
                name = uiState.partnership.name.value,
                website = uiState.partnership.website.value.toString()
              )
            )
          }

          labodocButton("Sauvegarder") {
            uiState.updateProcessing.subscribe { processing ->
              if (processing) {
                disabled = true
                text = "Traitement"
                icon = "fa fa-spinner fa-spin"
              } else {
                disabled = false
                text = "Sauvegarder"
                icon = null
              }
            }

            onClickLaunch {
              if (partnershipForm.validate()) {
                partnershipForm.getValidatedData()
                  .onLeft {
                    Toast.danger("Le formulaire est invalide")
                  }
                  .onRight { data ->
                    viewModel
                      .updatePartnership(
                        name = data.name,
                        logo = data.logo,
                        website = data.website
                      )
                  }
              }
            }
          }
        }

        is ViewModel.UiState.Updated -> {
          Toast.success("Partenariat correctement mis à jour")
          App.routing.navigate(Page.AdminBackOfficePartnershipList())
        }
      }
    }
  }
}

fun Container.adminPartnershipUpdatePage(
  partnershipId: PartnershipId
): AdminPartnershipUpdatePage {
  val adminPartnershipUpdatePage = AdminPartnershipUpdatePage(
    partnershipId = partnershipId
  )

  this.add(adminPartnershipUpdatePage)
  return adminPartnershipUpdatePage
}
