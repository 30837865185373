package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.ProfessionalCategoryCode
import fr.labodoc.domain.healthdirectory.ProfessionalCategoryName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object ProfessionalCategoryCodeAsStringSerializer : KSerializer<ProfessionalCategoryCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ProfessionalCategoryCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(professionalCategoryCode: String): Either<Nothing, ProfessionalCategoryCode> =
    ProfessionalCategoryCode(professionalCategoryCode).right()

  override fun deserialize(decoder: Decoder): ProfessionalCategoryCode =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(professionalCategoryCode: ProfessionalCategoryCode): String =
    professionalCategoryCode.value

  override fun serialize(encoder: Encoder, value: ProfessionalCategoryCode) =
    encoder.encodeString(serialize(value))
}

typealias ProfessionalCategoryCodeAsString = @Serializable(ProfessionalCategoryCodeAsStringSerializer::class) ProfessionalCategoryCode


object ProfessionalCategoryNameAsStringSerializer : KSerializer<ProfessionalCategoryName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ProfessionalCategoryNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(professionalCategoryLabel: String): Either<Nothing, ProfessionalCategoryName> =
    ProfessionalCategoryName(professionalCategoryLabel).right()

  override fun deserialize(decoder: Decoder): ProfessionalCategoryName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(professionalCategoryName: ProfessionalCategoryName): String =
    professionalCategoryName.value

  override fun serialize(encoder: Encoder, value: ProfessionalCategoryName) =
    encoder.encodeString(serialize(value))
}

typealias ProfessionalCategoryNameAsString = @Serializable(ProfessionalCategoryNameAsStringSerializer::class) ProfessionalCategoryName
