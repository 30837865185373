package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationName
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationShortName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object AtcClassificationCodeAsStringSerializer : KSerializer<AtcClassificationCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcClassificationCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(code: String): Either<Errors.AtcClassification.Code.Invalid, AtcClassificationCode> =
    AtcClassificationCode(code)

  override fun deserialize(decoder: Decoder): AtcClassificationCode =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(code: AtcClassificationCode): String =
    code.value

  override fun serialize(encoder: Encoder, value: AtcClassificationCode) =
    encoder.encodeString(serialize(value))
}

typealias AtcClassificationCodeAsString = @Serializable(AtcClassificationCodeAsStringSerializer::class) AtcClassificationCode

object AtcClassificationNameAsStringSerializer : KSerializer<AtcClassificationName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcClassificationNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.AtcClassification.Name.Invalid, AtcClassificationName> =
    AtcClassificationName(name)

  override fun deserialize(decoder: Decoder): AtcClassificationName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(name: AtcClassificationName): String =
    name.value

  override fun serialize(encoder: Encoder, value: AtcClassificationName) =
    encoder.encodeString(serialize(value))
}

typealias AtcClassificationNameAsString = @Serializable(AtcClassificationNameAsStringSerializer::class) AtcClassificationName


object AtcClassificationShortNameAsStringSerializer : KSerializer<AtcClassificationShortName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcClassificationShortNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(shortName: String): Either<Errors.AtcClassification.ShortName.Invalid, AtcClassificationShortName> =
    AtcClassificationShortName(shortName)

  override fun deserialize(decoder: Decoder): AtcClassificationShortName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(shortName: AtcClassificationShortName): String =
    shortName.value

  override fun serialize(encoder: Encoder, value: AtcClassificationShortName) =
    encoder.encodeString(serialize(value))
}

typealias AtcClassificationShortNameAsString = @Serializable(AtcClassificationShortNameAsStringSerializer::class) AtcClassificationShortName
