package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.LearnedSocietyIdAsString
import io.ktor.resources.*

@Resource("learned-societies")
class LearnedSocieties {
  @Resource("{learnedSocietyId}")
  class LearnedSociety(
    val learnedSocietyId: LearnedSocietyIdAsString,
    val learnedSocieties: LearnedSocieties = LearnedSocieties()
  ) {
    @Resource("favorite")
    class Favorite(
      val learnedSociety: LearnedSociety
    ) {
      constructor(learnedSocietyId: LearnedSocietyIdAsString) : this(LearnedSociety(learnedSocietyId))
    }
  }
}
