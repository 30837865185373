package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.UniversityHospitalResponse
import fr.labodoc.app.data.healthprofessional.model.UniversityHospitalModel

class UniversityHospitalsRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : UniversityHospitalsRepository {
  override suspend fun getUniversityHospitals(): ApiResponse<Set<UniversityHospitalModel>> =
    apiClient.universityHospitals
      .getUniversityHospitals()
      .map { universityHospitals: Set<UniversityHospitalResponse> ->
        universityHospitals
          .map(UniversityHospitalResponse::asModel)
          .toSet()
      }
}

fun UniversityHospitalResponse.asModel(): UniversityHospitalModel =
  UniversityHospitalModel(
    id = id,
    name = name,
    department = UniversityHospitalModel.Department(
      code = department.code,
      name = department.name
    )
  )
