package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import fr.labodoc.domain.labodoc.medicine.*
import io.ktor.http.*
import kotlinx.datetime.Instant

data class MedicineModel(
  val id: MedicineId,
  val cipCode: CipCode,
  val laboratory: Laboratory,
  val atc: AtcCodeModel,
  val governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
  val name: MedicineName,
  val mainComposition: MedicineMainComposition,
  val website: MedicineWebsite?,
  val logoUrl: Url,
  val marketingDocuments: Set<MarketingDocument>,
  val favorite: Boolean
) {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName,
    val website: LaboratoryWebsite,
    val pharmacovigilance: LaboratoryPharmacovigilance
  )

  data class MarketingDocument(
    val id: MarketingDocumentId,
    val name: MarketingDocumentName,
    val url: Url,
    val seenAt: Instant?,
    val seenLatestVersion: Boolean
  )
}
