package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.ExpertiseCode
import fr.labodoc.domain.healthdirectory.ExpertiseName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object ExpertiseCodeAsStringSerializer : KSerializer<ExpertiseCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ExpertiseCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(expertiseCode: String): Either<Nothing, ExpertiseCode> =
    ExpertiseCode(expertiseCode).right()

  override fun deserialize(decoder: Decoder): ExpertiseCode {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(expertiseCode: ExpertiseCode): String = expertiseCode.value

  override fun serialize(encoder: Encoder, value: ExpertiseCode) {
    return encoder.encodeString(serialize(value))
  }
}

typealias ExpertiseCodeAsString = @Serializable(ExpertiseCodeAsStringSerializer::class) ExpertiseCode

object ExpertiseLabelAsStringSerializer : KSerializer<ExpertiseName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ExpertiseLabelAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(expertiseLabel: String): Either<Nothing, ExpertiseName> =
    ExpertiseName(expertiseLabel).right()

  override fun deserialize(decoder: Decoder): ExpertiseName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(expertiseName: ExpertiseName): String = expertiseName.value

  override fun serialize(encoder: Encoder, value: ExpertiseName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias ExpertiseNameAsString = @Serializable(ExpertiseLabelAsStringSerializer::class) ExpertiseName

