package fr.labodoc.webapp.pages.admin.message

import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZip
import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.*
import fr.labodoc.app.data.admin.repository.*
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageContent
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.message.form.*
import fr.labodoc.webapp.utils.toInputFile
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import kotlinx.datetime.toJSDate
import kotlinx.datetime.toKotlinInstant
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMessageUpdatePage(
  messageId: MessageId
) : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val message: MessageModel,
        val medicalProfessions: Set<MedicalProfessionModel>,
        val professionalCategory: Set<ProfessionalCategoryModel>,
        val professionalStatuses: Set<ProfessionalStatusModel>,
        val medicalCardTypes: Set<MedicalCardTypeModel>,
        val departments: Set<DepartmentModel>
      ) : UiState()

      data object MedicineUpdated : UiState()
    }

    val uiState: ObservableState<UiState>
    val updateMessageErrorMessage: ObservableState<String?>

    fun updateMessage(messageFormData: AdminMessageForm.AdminMessageFormData)
  }

  private class ViewModelImpl(
    private val messageId: MessageId
  ) : ViewModel, KoinComponent {
    private val messageRepository: MessagesRepository by inject(named("admin"))
    private val medicalProfessionsRespository: MedicalProfessionsRepository by inject(named("admin"))
    private val departmentRepository: DepartmentsRepository by inject(named("admin"))
    private val professionalCategoriesRepository: ProfessionalCategoriesRepository by inject(named("admin"))
    private val professionalStatusesRepository: ProfessionalStatusesRepository by inject(named("admin"))
    private val medicalCardTypesRepository: MedicalCardTypesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue = ObservableValue<ViewModel.UiState>(ViewModel.UiState.Loading)

      App.scope.launch {
        either {
          parZip(
            { messageRepository.getMessage(messageId).bind() },
            { medicalProfessionsRespository.getMedicalProfessions().bind() },
            { departmentRepository.getDepartments().bind() },
            { professionalCategoriesRepository.getProfessionalCategories().bind() },
            { professionalStatusesRepository.getProfessionalStatuses().bind() },
            { medicalCardTypesRepository.getMedicalCardTypes().bind() }
          ) { message, medicalProfessionsResponse, departments, professionalCategories, professionalStatuses, medicalCardTypes ->
            ViewModel.UiState.Loaded(
              message,
              medicalProfessionsResponse,
              professionalCategories,
              professionalStatuses,
              medicalCardTypes,
              departments
            )
          }
        }
          .onLeft { observableValue.setState(ViewModel.UiState.Error) }
          .onRight { observableValue.setState(it) }
      }

      observableValue
    }

    override val updateMessageErrorMessage: ObservableValue<String?> =
      ObservableValue(null)

    override fun updateMessage(messageFormData: AdminMessageForm.AdminMessageFormData) {
      App.scope.launch {
        either {
          val publishedAt = messageFormData.publishedAt.let { publishedAt ->
            ensureNotNull(publishedAt) { "Date de publication manquante" }
            publishedAt.toKotlinInstant()
          }

          val title = messageFormData.title.let { title ->
            ensureNotNull(title) { "Titre manquant" }
            MessageTitle(title)
              .mapLeft { error ->
                when (error) {
                  Errors.Message.Title.Invalid.Blank -> "Le titre ne peut pas être vide"
                  Errors.Message.Title.Invalid.TooLong -> "Le titre est trop long, taille maximum: ${MessageTitle.MAX_LENGTH} caractères"
                }
              }
              .bind()
          }

          val text = messageFormData.text.let { text ->
            ensureNotNull(text) { "Texte manquant" }
            MessageContent(text)
          }

          val segmentation = ensureNotNull(messageFormData.segmentation) { "Segmentation manquante" }

          val banner = messageFormData.banner?.firstOrNull()

          val document = messageFormData.document?.firstOrNull()

          when (messageFormData) {
            is AdminLabodocFlashInfoMessageForm.Data -> {
              messageRepository
                .updateLabodocFlashInfo(
                  id = messageId,
                  publishedAt = publishedAt,
                  title = title,
                  content = text,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLaboratoryFlashInfoMessageForm.Data -> {
              val laboratoryId = messageFormData.laboratoryId.let { laboratoryId ->
                ensureNotNull(laboratoryId) { "Laboratoire manquant" }
                LaboratoryId(uuidFrom(laboratoryId))
              }

              messageRepository.updateLaboratoryFlashInfo(
                id = messageId,
                laboratoryId = laboratoryId,
                publishedAt = publishedAt,
                title = title,
                content = text,
                segmentation = segmentation,
                banner = banner?.toInputFile(),
                document = document?.toInputFile()
              )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLaboratoryInvitationMessageForm.Data -> {
              val laboratoryId = messageFormData.laboratoryId.let { laboratoryId ->
                ensureNotNull(laboratoryId) { "Laboratoire manquant" }
                LaboratoryId(uuidFrom(laboratoryId))
              }

              val eventAt = messageFormData.eventAt.let { eventAt ->
                ensureNotNull(eventAt) { "Date d'événement manquant" }
                eventAt.toKotlinInstant()
              }

              val formUrl = messageFormData.formUrl?.let { formUrl ->
                Url(formUrl)
              }

              messageRepository
                .updateLaboratoryInvitation(
                  id = messageId,
                  laboratoryId = laboratoryId,
                  publishedAt = publishedAt,
                  title = title,
                  eventAt = eventAt,
                  content = text,
                  formUrl = formUrl,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminMedicineFlashInfoMessageForm.Data -> {
              val medicineId = messageFormData.medicineId.let { medicineId ->
                ensureNotNull(medicineId) { "Médicament manquant" }
                MedicineId(uuidFrom(medicineId))
              }

              messageRepository.updateMedicineFlashInfo(
                id = messageId,
                medicineId = medicineId,
                publishedAt = publishedAt,
                title = title,
                content = text,
                segmentation = segmentation,
                banner = banner?.toInputFile(),
                document = document?.toInputFile()
              )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminMedicineInvitationMessageForm.Data -> {
              val medicineId = messageFormData.medicineId.let { medicineId ->
                ensureNotNull(medicineId) { "Médicament manquant" }
                MedicineId(uuidFrom(medicineId))
              }

              val eventAt = messageFormData.eventAt.let { eventAt ->
                ensureNotNull(eventAt) { "Date d'événement manquant" }
                eventAt.toKotlinInstant()
              }

              val formUrl = messageFormData.formUrl?.let { formUrl ->
                Url(formUrl)
              }

              messageRepository
                .updateMedicineInvitation(
                  id = messageId,
                  medicineId = medicineId,
                  publishedAt = publishedAt,
                  title = title,
                  eventAt = eventAt,
                  content = text,
                  formUrl = formUrl,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLearnedSocietyFlashInfoMessageForm.Data -> {
              val learnedSocietyId = messageFormData.learnedSocietyId.let { learnedSocietyId ->
                ensureNotNull(learnedSocietyId) { "Laboratoire manquant" }
                LearnedSocietyId(uuidFrom(learnedSocietyId))
              }

              messageRepository.updateLearnedSocietyFlashInfo(
                id = messageId,
                learnedSocietyId = learnedSocietyId,
                publishedAt = publishedAt,
                title = title,
                content = text,
                segmentation = segmentation,
                banner = banner?.toInputFile(),
                document = document?.toInputFile()
              )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }

            is AdminLearnedSocietyInvitationMessageForm.Data -> {
              val learnedSocietyId = messageFormData.learnedSocietyId.let { learnedSocietyId ->
                ensureNotNull(learnedSocietyId) { "Laboratoire manquant" }
                LearnedSocietyId(uuidFrom(learnedSocietyId))
              }

              val eventAt = messageFormData.eventAt.let { eventAt ->
                ensureNotNull(eventAt) { "Date d'événement manquant" }
                eventAt.toKotlinInstant()
              }

              val formUrl = messageFormData.formUrl?.let { formUrl ->
                Url(formUrl)
              }

              messageRepository
                .updateLearnedSocietyInvitation(
                  id = messageId,
                  learnedSocietyId = learnedSocietyId,
                  publishedAt = publishedAt,
                  title = title,
                  eventAt = eventAt,
                  content = text,
                  formUrl = formUrl,
                  segmentation = segmentation,
                  banner = banner?.toInputFile(),
                  document = document?.toInputFile()
                )
                .mapLeft { "Une erreur est survenue lors de la création du message" }
                .bind()
            }
          }
        }
          .onLeft { errorMessage: String ->
            updateMessageErrorMessage.setState(errorMessage)
          }
          .onRight { _: Unit ->
            uiState.setState(ViewModel.UiState.MedicineUpdated)
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(messageId)

  init {
    id = "page-admin-message-update"
    require("./css/pages/admin/message/update.css")

    viewModel.updateMessageErrorMessage.subscribe { errorMessage: String? ->
      errorMessage?.let { Toast.danger(it) }
    }

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1("Mettre à jour un message")

      when (uiState) {
        is ViewModel.UiState.Loading -> labodocSpinner()

        is ViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is ViewModel.UiState.Loaded -> {
          val messageForm = when (val message = uiState.message) {
            is LabodocFlashInfoModel -> AdminLabodocFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminLabodocFlashInfoMessageForm.Data(
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                text = message.content.value,
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )

            is LaboratoryFlashInfoModel -> AdminLaboratoryFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = null,
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminLaboratoryFlashInfoMessageForm.Data(
                laboratoryId = message.laboratory.id.value.toString(),
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                text = message.content.value,
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )

            is LaboratoryInvitationModel -> AdminLaboratoryInvitationMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = null,
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminLaboratoryInvitationMessageForm.Data(
                laboratoryId = message.laboratory.id.value.toString(),
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                eventAt = message.eventAt.toJSDate(),
                text = message.content.value,
                formUrl = message.formUrl?.toString(),
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )

            is MedicineFlashInfoModel -> AdminMedicineFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = null,
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminMedicineFlashInfoMessageForm.Data(
                laboratoryId = message.laboratory.id.value.toString(),
                medicineId = message.medicine.id.value.toString(),
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                text = message.content.value,
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )

            is MedicineInvitationModel -> AdminMedicineInvitationMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              laboratories = null,
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminMedicineInvitationMessageForm.Data(
                laboratoryId = message.laboratory.id.value.toString(),
                medicineId = message.medicine.id.value.toString(),
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                eventAt = message.eventAt.toJSDate(),
                text = message.content.value,
                formUrl = message.formUrl?.toString(),
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )

            is LearnedSocietyFlashInfoModel -> AdminLearnedSocietyFlashInfoMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              learnedSocieties = null,
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminLearnedSocietyFlashInfoMessageForm.Data(
                learnedSocietyId = message.learnedSociety.id.value.toString(),
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                text = message.content.value,
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )

            is LearnedSocietyInvitationModel -> AdminLearnedSocietyInvitationMessageForm(
              medicalProfessions = uiState.medicalProfessions,
              professionalCategories = uiState.professionalCategory.map { it.code to it.name }.toSet(),
              professionalStatuses = uiState.professionalStatuses.map { it.code to it.name }.toSet(),
              medicalCardTypes = uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
              departments = uiState.departments.map { it.code to it.name }.toSet(),
              learnedSocieties = null,
              bannerLogoUrl = message.bannerUrl,
              initialData = AdminLearnedSocietyInvitationMessageForm.Data(
                learnedSocietyId = message.learnedSociety.id.value.toString(),
                publishedAt = message.publishedAt.toJSDate(),
                title = message.title.value,
                eventAt = message.eventAt.toJSDate(),
                text = message.content.value,
                formUrl = message.formUrl?.toString(),
                segmentation = message.segmentation,
                document = null,
                banner = null
              )
            )
          }

          val saveButton = LabodocButton("Sauvegarder", className = "save") {
            onClickLaunch {
              disabled = true

              if (messageForm.validate()) {
                val messageFormData = messageForm.getDataWithFileContent()

                viewModel.updateMessage(messageFormData)
              } else
                disabled = false
            }

            viewModel.updateMessageErrorMessage.subscribe { _: String? ->
              disabled = false
            }
          }

          add(messageForm)

          add(saveButton)
        }

        ViewModel.UiState.MedicineUpdated -> App.routing.navigate(Page.AdminBackOfficeMessagesList())
      }
    }
  }
}

fun Container.adminMessageUpdatePage(
  messageId: MessageId
): AdminMessageUpdatePage {
  val adminMedicineUpdatePage = AdminMessageUpdatePage(
    messageId
  )

  this.add(adminMedicineUpdatePage)
  return adminMedicineUpdatePage
}
