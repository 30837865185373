package fr.labodoc.webapp.pages.admin.learnedSocieties.components

import arrow.core.Either
import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyWebsite
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.labodocSelectCheckbox
import fr.labodoc.webapp.components.labodocText
import fr.labodoc.webapp.components.labodocUpload
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.onChange
import io.kvision.form.FormPanel
import io.kvision.form.text.Text
import io.kvision.html.div
import io.kvision.html.image
import io.kvision.i18n.I18n
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.w3c.dom.url.URL

class AdminLearnedSocietyForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  learnedSocietyLogoUrl: Url? = null
) : FormPanel<AdminLearnedSocietyForm.FormData>(serializer = FormData.serializer(), className = "learned-society-form") {
  @Serializable
  data class FormData(
    val nameInput: String? = null,
    val acronymInput: String? = null,
    val websiteInput: String? = null,
    val medicalSpecialitiesInput: String? = null,
    val logoInput: List<KFile>? = null,
  ) {
    constructor(
      name: LearnedSocietyName? = null,
      acronym: LearnedSocietyAcronym? = null,
      website: LearnedSocietyWebsite? = null,
      medicalSpecialities: Set<MedicalSpecialityId>? = null
    ) : this(
      nameInput = name?.value,
      acronymInput = acronym?.value,
      websiteInput = website?.value?.toString(),
      medicalSpecialitiesInput = medicalSpecialities?.joinToString(",") { it.value.toString() }
    )

    val name: Either<Errors.LearnedSociety.Name, LearnedSocietyName>?
      get() = nameInput?.let { LearnedSocietyName(it) }

    val acronym: Either<Errors.LearnedSociety.Acronym, LearnedSocietyAcronym>?
      get() = acronymInput?.let { LearnedSocietyAcronym(it) }

    val website: LearnedSocietyWebsite?
      get() = websiteInput?.let { LearnedSocietyWebsite(Url(it)) }

    val medicalSpecialities: Set<MedicalSpecialityId>?
      get() = medicalSpecialitiesInput
        ?.split(",")
        ?.map { MedicalSpecialityId(uuidFrom(it)) }
        ?.toSet()

    val logo: KFile?
      get() = logoInput?.first()

    companion object {
      fun validateName(name: Text): String? = LearnedSocietyName(name.value ?: "").fold(
        { error ->
          when (error) {
            Errors.LearnedSociety.Name.Invalid.Blank -> "Ne peut pas être vide"
            Errors.LearnedSociety.Name.Invalid.TooLong -> "Est trop long"
          }
        },
        {
          null
        }
      )

      fun validateAcronym(acronym: Text): String? =
        acronym.value?.let {
          LearnedSocietyAcronym(it)
            .fold(
              { error ->
                when (error) {
                  Errors.LearnedSociety.Acronym.Invalid.Blank -> "Ne peut pas être vide"
                  Errors.LearnedSociety.Acronym.Invalid.TooLong -> "Est trop long"
                }
              },
              {
                null
              }
            )
        }

      fun validateWebsite(website: Text): String? =
        try {
          URL(website.value ?: "")
          null
        } catch (exception: dynamic) {
          "L'url n'est pas valide"
        }
    }
  }

  init {
    require("./css/pages/admin/learnedSocieties/components/learned-society-form.css")

    div(className = "information") {
      labodocText {
        label = "Nom"
      }.bind(
        FormData::nameInput,
        required = true,
        requiredMessage = I18n.tr("Field.Required"),
        validator = { FormData.validateName(it) == null },
        validatorMessage = { FormData.validateName(it) }
      )

      labodocText {
        label = "Sigle"
      }.bind(
        FormData::acronymInput,
        required = false,
        requiredMessage = I18n.tr("Field.Required"),
        validator = { FormData.validateAcronym(it) == null },
        validatorMessage = { FormData.validateAcronym(it) }
      )

      labodocText {
        label = "Site internet"
      }.bind(
        FormData::websiteInput,
        required = true,
        requiredMessage = I18n.tr("Field.Required"),
        validator = { FormData.validateWebsite(it) == null },
        validatorMessage = { FormData.validateWebsite(it) }
      )

      labodocSelectCheckbox {
        label = "Spécialités médicales:"
        options = medicalProfessions
          .associate { medicalProfession ->
            medicalProfession.name.value to medicalProfession.medicalSpecialities.map { medicalSpeciality ->
              medicalSpeciality.id.value.toString() to medicalSpeciality.name.value
            }
          }
      }.bind(
        FormData::medicalSpecialitiesInput,
        required = true,
        requiredMessage = I18n.tr("Field.Required")
      )
    }

    div(className = "logo") {
      val logoPreview = image(learnedSocietyLogoUrl?.toString())

      labodocUpload(label = "Logo", accept = listOf("image/*"), multiple = false) {
        onChange {
          App.scope.launch {
            value?.firstOrNull()?.also { logoPreview.src = getNativeFile(it)?.getContent() }
          }
        }
      }.bind(
        FormData::logoInput,
        required = learnedSocietyLogoUrl == null,
        requiredMessage = I18n.tr("Field.Required"),
        validator = null,
        validatorMessage = null
      )
    }
  }
}

fun Container.adminLearnedSocietyForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  learnedSocietyLogoUrl: Url? = null
): AdminLearnedSocietyForm {
  val adminLearnedSocietyForm = AdminLearnedSocietyForm(
    medicalProfessions,
    learnedSocietyLogoUrl
  )

  this.add(adminLearnedSocietyForm)
  return adminLearnedSocietyForm
}
