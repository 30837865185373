package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.MedicalCardTypeCode
import fr.labodoc.domain.healthdirectory.MedicalCardTypeLabel
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicalCardTypeCodeAsStringSerializer : KSerializer<MedicalCardTypeCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalCardTypeCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(medicalCardTypeCode: String): Either<Nothing, MedicalCardTypeCode> =
    MedicalCardTypeCode(medicalCardTypeCode).right()

  override fun deserialize(decoder: Decoder): MedicalCardTypeCode =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalCardTypeCode: MedicalCardTypeCode): String =
    medicalCardTypeCode.value

  override fun serialize(encoder: Encoder, value: MedicalCardTypeCode) =
    encoder.encodeString(serialize(value))
}

typealias MedicalCardTypeCodeAsString = @Serializable(MedicalCardTypeCodeAsStringSerializer::class) MedicalCardTypeCode


object MedicalCardTypeLabelAsStringSerializer : KSerializer<MedicalCardTypeLabel> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalCardTypeLabelAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(medicalCardTypeLabel: String): Either<Nothing, MedicalCardTypeLabel> =
    MedicalCardTypeLabel(medicalCardTypeLabel).right()

  override fun deserialize(decoder: Decoder): MedicalCardTypeLabel =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalCardTypeLabel: MedicalCardTypeLabel): String =
    medicalCardTypeLabel.value

  override fun serialize(encoder: Encoder, value: MedicalCardTypeLabel) =
    encoder.encodeString(serialize(value))
}

typealias MedicalCardTypeLabelAsString = @Serializable(MedicalCardTypeLabelAsStringSerializer::class) MedicalCardTypeLabel
