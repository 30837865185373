package fr.labodoc.app.di

import fr.labodoc.app.data.healthprofessional.repository.*
import org.koin.dsl.module

val healthProfessionalRepositoryModule = module {
  single<AtcClassificationRepository> {
    AtcClassificationRepositoryImpl(
      atcClassificationsRemoteDataSource = get()
    )
  }

  single<ContactRepository> {
    ContactRepositoryImpl(
      apiClient = get()
    )
  }

  single<DepartmentsRepository> {
    DepartmentsRepositoryImpl(
      departmentsRemoteDataSource = get()
    )
  }

  single<HealthDirectoryRepository> {
    HealthDirectoryRepositoryImpl(
      apiClient = get()
    )
  }

  single<LaboratoriesRepository> {
    LaboratoriesRepositoryImpl(
      laboratoriesRemoteDataSource = get()
    )
  }

  single<LearnedSocietiesRepository> {
    LearnedSocietiesRepositoryImpl(
      learnedSocietiesRemoteDataSource = get()
    )
  }

  single<MedicalDiplomasRepository> {
    MedicalDiplomasRepositoryImpl(
      apiClient = get()
    )
  }

  single<MedicalProfessionsRepository> {
    MedicalProfessionsRepositoryImpl(
      apiClient = get()
    )
  }

  single<MedicinesRepository> {
    MedicineRepositoryImpl(
      medicinesRemoteDataSource = get()
    )
  }

  single<MessagesRepository> {
    MessagesRepositoryImpl(
      messagesRemoteDataSource = get()
    )
  }

  single<UniversityHospitalsRepository> {
    UniversityHospitalsRepositoryImpl(
      universityHospitalsRemoteDataSource = get()
    )
  }

  single<UsersRepository> {
    UsersRepositoryImpl(
      apiClient = get()
    )
  }
}
