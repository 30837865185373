package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.HealthProfessionalLearnedSocietyResponse
import fr.labodoc.api.resources.LearnedSocieties
import fr.labodoc.api.routes.HealthProfessionalLearnedSocietiesRoutes
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalLearnedSocietiesRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalLearnedSocietiesRoutes {
  override suspend fun getLearnedSocieties(
  ): ApiResponse<Set<HealthProfessionalLearnedSocietyResponse>> {
    val response = httpClient.get(LearnedSocieties())

    return if (response.status.isSuccess())
      response.body<Set<HealthProfessionalLearnedSocietyResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun favoriteLearnedSociety(
    id: LearnedSocietyId
  ): ApiResponse<Unit> {
    val response = httpClient.post(LearnedSocieties.LearnedSociety.Favorite(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun unfavoriteLearnedSociety(
    id: LearnedSocietyId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(LearnedSocieties.LearnedSociety.Favorite(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
