package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.UniversityHospitalModel
import fr.labodoc.app.data.healthprofessional.source.remote.UniversityHospitalsRemoteDataSource

class UniversityHospitalsRepositoryImpl(
  private val universityHospitalsRemoteDataSource: UniversityHospitalsRemoteDataSource
) : UniversityHospitalsRepository {
  override suspend fun getUniversityHospitals(
  ): Either<RepositoryError.DataSource, Set<UniversityHospitalModel>> =
    universityHospitalsRemoteDataSource
      .getUniversityHospitals()
      .mapLeft(RepositoryError::DataSource)
}
