package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.requests.ContactRequest
import fr.labodoc.domain.labodoc.common.EmailAddress

class ContactRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : ContactRepository {
  override suspend fun sendContactRequest(
    name: String,
    emailAddress: EmailAddress,
    subject: String,
    message: String
  ): ApiResponse<Unit> =
    apiClient.contact
      .sendContactRequest(
        payload = ContactRequest(
          name = name,
          emailAddress = emailAddress,
          subject = subject,
          message = message
        )
      )
}
