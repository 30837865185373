package fr.labodoc.domain.labodoc.common

import arrow.core.NonEmptySet
import fr.labodoc.domain.healthdirectory.MedicalCardTypeCode
import fr.labodoc.domain.healthdirectory.ProfessionalCategoryCode
import fr.labodoc.domain.healthdirectory.ProfessionalStatusCode
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId

data class Segmentation(
  val medicalProfessions: NonEmptySet<MedicalProfessionId>?,
  val medicalSpecialities: NonEmptySet<MedicalSpecialityId>?,
  val medicalInterests: NonEmptySet<MedicalInterestId>?,
  val professionalCategories: NonEmptySet<ProfessionalCategoryCode>?,
  val professionalStatuses: NonEmptySet<ProfessionalStatusCode>?,
  val medicalCardTypes: NonEmptySet<MedicalCardTypeCode>?,
  val departments: NonEmptySet<DepartmentCode>?
) {
  val isEmpty: Boolean =
    medicalProfessions.isNullOrEmpty() &&
      medicalSpecialities.isNullOrEmpty() &&
      medicalInterests.isNullOrEmpty() &&
      professionalCategories.isNullOrEmpty() &&
      professionalStatuses.isNullOrEmpty() &&
      medicalCardTypes.isNullOrEmpty() &&
      departments.isNullOrEmpty()
}
