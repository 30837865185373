package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.MedicalDiplomaNameAsString
import fr.labodoc.api.payloads.serializers.MedicalSpecialityIdAsString
import fr.labodoc.api.payloads.serializers.ProfessionCodeAsString
import kotlinx.serialization.Serializable

@Serializable
data class CreateMedicalDiplomaRequest(
  val name: MedicalDiplomaNameAsString,
  val healthDirectoryProfessionCode: ProfessionCodeAsString,
  val labodocMedicalSpeciality: MedicalSpecialityIdAsString
)
