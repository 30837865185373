package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UniversityHospitalIdAsStringSerializer : KSerializer<UniversityHospitalId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("UniversityHospitalIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.UniversityHospital.Id.Invalid, UniversityHospitalId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.UniversityHospital.Id.Invalid.Format }
      .map { UniversityHospitalId(it) }

  override fun deserialize(decoder: Decoder): UniversityHospitalId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: UniversityHospitalId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: UniversityHospitalId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias UniversityHospitalIdAsString = @Serializable(UniversityHospitalIdAsStringSerializer::class) UniversityHospitalId

object UniversityHospitalNameAsStringSerializer : KSerializer<UniversityHospitalName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("UniversityHospitalNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.UniversityHospital.Name.Invalid, UniversityHospitalName> =
    UniversityHospitalName(name)

  override fun deserialize(decoder: Decoder): UniversityHospitalName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(name: UniversityHospitalName): String = name.value

  override fun serialize(encoder: Encoder, value: UniversityHospitalName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias UniversityHospitalNameAsString = @Serializable(UniversityHospitalNameAsStringSerializer::class) UniversityHospitalName
