package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.PartnershipIdAsString
import io.ktor.resources.*

@Resource("partnerships")
class Partnerships {
  @Resource("{partnershipId}")
  class Partnership(
    val partnershipId: PartnershipIdAsString,
    val partners: Partnerships = Partnerships()
  )
}
