package fr.labodoc.domain.labodoc.medicine

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

/**
 * [Definition](https://sante.gouv.fr/soins-et-maladies/medicaments/glossaire/article/code-cip)
 */
@JvmInline
value class CipCode private constructor(val value: String) {
  companion object {
    val regex: Regex = Regex("^(\\d{7}|\\d{13})\$")

    fun isFormatValid(value: String): Boolean = regex.matches(value)

    operator fun invoke(value: String): Either<Errors.CIPCode.Invalid, CipCode> =
      either {
        ensure(isFormatValid(value)) { Errors.CIPCode.Invalid.Format }

        CipCode(value)
      }
  }
}
