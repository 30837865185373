package fr.labodoc.app.data.error

import fr.labodoc.api.payloads.responses.ErrorResponse
import io.ktor.client.statement.*

sealed class RemoteDataSourceError: DataSourceError() {
  sealed class WithoutBody : RemoteDataSourceError()

  sealed class WithBody : RemoteDataSourceError()


  data class UnreachableServer(val cause: Throwable) : WithoutBody()

  data class WrongHttpStatusCode(val httpResponse: HttpResponse) : WithoutBody()

  data class EmptyBody(val httpResponse: HttpResponse) : WithBody()

  data class IncorrectResponse(val cause: Throwable) : WithBody()

  data class SomethingWentWrong(val cause: ErrorResponse) : WithBody()
}
