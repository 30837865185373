package fr.labodoc.webapp.pages.admin.message.form

import fr.labodoc.app.data.admin.model.MedicalInterestModel
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.message.MessageTitle
import fr.labodoc.webapp.components.LabodocSegmentation
import fr.labodoc.webapp.components.SegmentationAsString
import io.kvision.form.FormPanel
import io.kvision.form.text.Text
import io.kvision.types.KFile
import kotlinx.serialization.Contextual
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlin.js.Date

sealed class AdminMessageForm<K : AdminMessageForm.AdminMessageFormData>(
  serializer: KSerializer<K>?,
  className: String? = null
) : FormPanel<K>(serializer = serializer, className = className) {
  @Serializable
  sealed interface AdminMessageFormData {
    @Contextual val publishedAt: Date?
    val title: String?
    val text: String?
    val segmentation: SegmentationAsString?
    val document: List<KFile>?
    val banner: List<KFile>?

    companion object {
      fun validateTitle(titleInput: Text): String? {
        return titleInput.value?.let { title ->
          MessageTitle(title).fold(
            { error ->
              when (error) {
                Errors.Message.Title.Invalid.Blank -> "Ne peut pas être vide"
                Errors.Message.Title.Invalid.TooLong -> "Trop long, taille maximum: ${MessageTitle.MAX_LENGTH} caractères"
              }
            },
            {
              null
            }
          )
        }
      }

      fun validateSegmentation(segmentationInput: LabodocSegmentation): String? {
        val segmentation = segmentationInput.data

        return if (segmentation.medicalProfessions.isNullOrEmpty() || segmentation.medicalSpecialities.isNullOrEmpty())
          "Aucune spécialité renseignée"
        else if (segmentation.professionalCategories.isNullOrEmpty())
          "Aucune catégorie professionnelle renseignée"
        else if (segmentation.professionalStatuses.isNullOrEmpty())
          "Aucune situation professionnelle renseignée"
        else if (segmentation.medicalCardTypes.isNullOrEmpty())
          "Aucune type de carte renseigné"
        else if (segmentation.departments.isNullOrEmpty())
          "Aucun département renseigné"
        else
          null
      }
    }
  }
}
