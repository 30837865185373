package fr.labodoc.webapp.components

import fr.labodoc.require
import fr.labodoc.webapp.utils.humanFileSize
import fr.labodoc.webapp.utils.middleEllipsis
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.core.onInput
import io.kvision.form.ValidationStatus
import io.kvision.form.upload.Capture
import io.kvision.form.upload.Upload
import io.kvision.html.Div
import io.kvision.html.Span
import io.kvision.html.button
import io.kvision.html.p
import io.kvision.types.KFile


class LabodocUpload(
  multiple: Boolean = false, accept: List<String>? = null, capture: Capture? = null,
  label: String? = null,
  rich: Boolean = false,
  notice: String? = null,
  init: (LabodocUpload.() -> Unit)? = null
) : Upload(multiple, accept, capture, label, rich) {
  // DO NOT REMOVE, OTHERWISE FORM VALIDATION WILL BREAK THE INPUT
  override var validatorError: String?
    get() = super.validatorError
    set(value) {
      invalidFeedback.content = value
      invalidFeedback.visible = value != null
      input.validationStatus = if (value != null) ValidationStatus.INVALID else null
      if (hasCssClass("kv-control-horiz")) {
        if (value != null) {
          input.addSurroundingCssClass("is-invalid")
        } else {
          input.removeSurroundingCssClass("is-invalid")
        }
      }
      refresh()
    }

  var notice by refreshOnUpdate(notice) {
    noticeSpan.content = it
  }

  private val filesPreview = Div(className = "files-preview")

  private val noticeSpan = Span(className = "notice")

  override var value: List<KFile>? = input.value
    set(value) {
      field = value
      input.value = value
    }

  private fun setFilesPreview() {
    val filesPreviewContent = value?.map { file ->
      Div(className = "file-preview") {
        button("", icon = "fa-solid fa-trash", className = "delete") {
          onClick {
            value = value?.let { it - file }?.ifEmpty { null }
          }
        }

        p(className = "name") {
          content = "${file.name.middleEllipsis(50)} (${file.humanFileSize(si = true)})"
        }
      }
    }

    filesPreview.removeAll()
    if (!filesPreviewContent.isNullOrEmpty())
      filesPreview.addAll(filesPreviewContent)
  }

  init {
    require("./css/components/labodoc-input-file.css")

    addCssClass("labodoc-input-file")

    add(filesPreview)
    add(noticeSpan)

    // User upload file
    onInput { value = input.value }

    // Value of input change
    subscribe { setFilesPreview() }

    // Tricks to "upload" the same previous selection
    onClick { value = null }

    init?.invoke(this)
  }
}

fun Container.labodocUpload(
  multiple: Boolean = false, accept: List<String>? = null, capture: Capture? = null,
  label: String? = null,
  rich: Boolean = false,
  notice: String? = null,
  init: (LabodocUpload.() -> Unit)? = null
): LabodocUpload {
  val upload = LabodocUpload(multiple, accept, capture, label, rich, notice, init)

  this.add(upload)
  return upload
}
