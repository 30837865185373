package fr.labodoc.webapp.pages.admin.users

import fr.labodoc.require
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.pages.admin.users.section.applicationsList
import fr.labodoc.webapp.pages.admin.users.section.usersList
import io.kvision.core.Container
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.html.header
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind

class AdminUsersAdministrationPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object UsersList : UiState()
      data object ApplicationsList : UiState()
    }

    val uiState: ObservableState<UiState>

    fun displayUsersList()
    fun displayApplicationsList()
  }

  private class ViewModelImpl : ViewModel {
    override val uiState: ObservableValue<ViewModel.UiState> =
      ObservableValue(ViewModel.UiState.UsersList)

    override fun displayUsersList() {
      uiState.value = ViewModel.UiState.UsersList
    }

    override fun displayApplicationsList() {
      uiState.value = ViewModel.UiState.ApplicationsList
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-users-administration"
    require("./css/pages/admin/users/administration.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      header {
        h1("Gestion des utilisateurs")

        div(className = "tabs") {
          labodocButton("Liste des utilisateurs", className = "tab") {
            if (uiState is ViewModel.UiState.UsersList)
              addCssClass("active")

            onClick {
              viewModel.displayUsersList()
            }
          }

          labodocButton("Liste des demande d'inscription", className = "tab") {
            if (uiState is ViewModel.UiState.ApplicationsList)
              addCssClass("active")

            onClick {
              viewModel.displayApplicationsList()
            }
          }
        }
      }

      div(className = "container") {
        when (uiState) {
          ViewModel.UiState.UsersList -> usersList()
          ViewModel.UiState.ApplicationsList -> applicationsList()
        }
      }
    }
  }
}

fun Container.adminUsersAdministrationPage(
): AdminUsersAdministrationPage {
  val adminUsersAdministrationPage = AdminUsersAdministrationPage(
  )

  this.add(adminUsersAdministrationPage)
  return adminUsersAdministrationPage
}
