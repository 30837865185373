package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.MedicalSpecialityNameAsString
import fr.labodoc.api.payloads.serializers.PartnershipIdAsString
import kotlinx.serialization.Serializable

@Serializable
data class UpdateMedicalSpecialityRequest(
  val name: MedicalSpecialityNameAsString,
  val partnership: PartnershipIdAsString?
)
