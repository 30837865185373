package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.application.ApplicationId
import fr.labodoc.domain.labodoc.common.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partner.PartnerCode
import io.ktor.http.*

data class FreestandingApplicationModel(
  val id: ApplicationId,
  val isEmailValidated: Boolean,
  val email: EmailAddress,
  val medicalSpeciality: MedicalSpecialityName,
  val medicalProfession: MedicalProfessionName,
  val phone: PhoneNumber,
  val firstName: FirstName,
  val lastName: LastName,
  val supportingDocumentUrl: Url,
  val facility: Facility,
  val comment: String?,
  val rppsNumber: RPPSNumber?,
  val partnerCode: PartnerCode?
) {
  data class Facility(
    val name: String,
    val unit: String,
    val departmentCode: DepartmentCode,
    val city: String
  )
}
