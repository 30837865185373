package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import kotlinx.datetime.Instant

sealed class MessageSummaryModel {
  abstract val id: MessageId
  abstract val title: MessageTitle
  abstract val publishedAt: Instant
}

sealed class LabodocMessageSummaryModel : MessageSummaryModel()

sealed class LaboratoryMessageSummaryModel : MessageSummaryModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  abstract val laboratory: Laboratory
}

sealed class MedicineMessageSummaryModel : MessageSummaryModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  data class Medicine(
    val id: MedicineId,
    val name: MedicineName
  )

  abstract val laboratory: Laboratory
  abstract val medicine: Medicine
}

sealed class LearnedSocietyMessageSummaryModel : MessageSummaryModel() {
  data class LearnedSociety(
    val id: LearnedSocietyId,
    val name: LearnedSocietyName
  )

  abstract val learnedSociety: LearnedSociety
}

sealed interface FlashInfoMessageSummaryModel

sealed interface InvitationMessageSummaryModel


data class LabodocFlashInfoSummaryModel(
  override val id: MessageId,
  override val publishedAt: Instant,
  override val title: MessageTitle,
) : LabodocMessageSummaryModel(), FlashInfoMessageSummaryModel

data class LaboratoryFlashInfoSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle
) : LaboratoryMessageSummaryModel(), FlashInfoMessageSummaryModel

data class LaboratoryInvitationSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle
) : LaboratoryMessageSummaryModel(), InvitationMessageSummaryModel

data class MedicineFlashInfoSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitle
) : MedicineMessageSummaryModel(), FlashInfoMessageSummaryModel

data class MedicineInvitationSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitle
) : MedicineMessageSummaryModel(), InvitationMessageSummaryModel

data class LearnedSocietyFlashInfoSummaryModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitle
) : LearnedSocietyMessageSummaryModel(), FlashInfoMessageSummaryModel

data class LearnedSocietyInvitationSummaryModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitle
) : LearnedSocietyMessageSummaryModel(), InvitationMessageSummaryModel
