package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import kotlinx.datetime.Instant

sealed class MessageSummaryModel {
  abstract val id: MessageId
  abstract val title: MessageTitle
  abstract val publishedAt: Instant
  abstract val seen: Boolean
  abstract val favorite: Boolean

  abstract fun setSeen(seen: Boolean): MessageSummaryModel
  abstract fun setFavorite(favorite: Boolean): MessageSummaryModel
}

sealed class LabodocMessageSummaryModel : MessageSummaryModel() {
  abstract override fun setSeen(seen: Boolean): LabodocMessageSummaryModel
  abstract override fun setFavorite(favorite: Boolean): LabodocMessageSummaryModel
}

sealed class LaboratoryMessageSummaryModel : MessageSummaryModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  abstract val laboratory: Laboratory

  abstract override fun setSeen(seen: Boolean): LaboratoryMessageSummaryModel
  abstract override fun setFavorite(favorite: Boolean): LaboratoryMessageSummaryModel
}

sealed class MedicineMessageSummaryModel : MessageSummaryModel() {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName
  )

  abstract val laboratory: Laboratory

  abstract override fun setSeen(seen: Boolean): MedicineMessageSummaryModel
  abstract override fun setFavorite(favorite: Boolean): MedicineMessageSummaryModel
}

sealed class LearnedSocietyMessageSummaryModel : MessageSummaryModel() {
  data class LearnedSociety(
    val id: LearnedSocietyId,
    val name: LearnedSocietyName,
    val acronym: LearnedSocietyAcronym?
  )

  abstract val learnedSociety: LearnedSociety

  abstract override fun setSeen(seen: Boolean): LearnedSocietyMessageSummaryModel
  abstract override fun setFavorite(favorite: Boolean): LearnedSocietyMessageSummaryModel
}

sealed interface FlashInfoMessageSummaryModel

sealed interface InvitationMessageSummaryModel


data class LabodocFlashInfoSummaryModel(
  override val id: MessageId,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : LabodocMessageSummaryModel(), FlashInfoMessageSummaryModel {
  override fun setSeen(seen: Boolean): LabodocFlashInfoSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LabodocFlashInfoSummaryModel =
    this.copy(favorite = favorite)
}

data class LaboratoryFlashInfoSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : LaboratoryMessageSummaryModel(), FlashInfoMessageSummaryModel {
  override fun setSeen(seen: Boolean): LaboratoryFlashInfoSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LaboratoryFlashInfoSummaryModel =
    this.copy(favorite = favorite)
}

data class LaboratoryInvitationSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : LaboratoryMessageSummaryModel(), InvitationMessageSummaryModel {
  override fun setSeen(seen: Boolean): LaboratoryInvitationSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LaboratoryInvitationSummaryModel =
    this.copy(favorite = favorite)
}

data class MedicineFlashInfoSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : MedicineMessageSummaryModel(), FlashInfoMessageSummaryModel {
  override fun setSeen(seen: Boolean): MedicineFlashInfoSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): MedicineFlashInfoSummaryModel =
    this.copy(favorite = favorite)
}

data class MedicineInvitationSummaryModel(
  override val id: MessageId,
  override val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : MedicineMessageSummaryModel(), InvitationMessageSummaryModel {
  override fun setSeen(seen: Boolean): MedicineInvitationSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): MedicineInvitationSummaryModel =
    this.copy(favorite = favorite)
}

data class LearnedSocietyFlashInfoSummaryModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : LearnedSocietyMessageSummaryModel(), FlashInfoMessageSummaryModel {
  override fun setSeen(seen: Boolean): LearnedSocietyFlashInfoSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LearnedSocietyFlashInfoSummaryModel =
    this.copy(favorite = favorite)
}

data class LearnedSocietyInvitationSummaryModel(
  override val id: MessageId,
  override val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitle,
  override val seen: Boolean,
  override val favorite: Boolean,
) : LearnedSocietyMessageSummaryModel(), InvitationMessageSummaryModel {
  override fun setSeen(seen: Boolean): LearnedSocietyInvitationSummaryModel =
    this.copy(seen = seen)

  override fun setFavorite(favorite: Boolean): LearnedSocietyInvitationSummaryModel =
    this.copy(favorite = favorite)
}
