package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.healthdirectory.ProfessionalCategoryCode
import fr.labodoc.domain.healthdirectory.ProfessionalCategoryName
import fr.labodoc.domain.healthdirectory.ProfessionalStatusCode
import fr.labodoc.domain.healthdirectory.ProfessionalStatusName
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.common.FirstName
import fr.labodoc.domain.labodoc.common.LastName
import fr.labodoc.domain.labodoc.common.RPPSNumber
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestName
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite
import io.ktor.http.*
import kotlinx.datetime.Instant

data class HealthProfessionalUserModel(
  override val emailAddress: EmailAddress,
  override val lastLoginDate: Instant?,
  val rppsNumber: RPPSNumber?,
  val firstName: FirstName,
  val lastName: LastName,
  val department: Department,
  val medicalProfession: MedicalProfession,
  val medicalSpeciality: MedicalSpeciality,
  val medicalInterests: Set<MedicalInterest>,
  val canHaveMedicalInterests: Boolean,
  val professionalCategory: ProfessionalCategory,
  val professionalStatus: ProfessionalStatus,
  val dailyNotificationEnabled: Boolean,
  val weeklyNotificationEnabled: Boolean
) : UserModel() {
  data class Department(
    val code: DepartmentCode,
    val name: DepartmentName
  )

  data class MedicalProfession(
    val id: MedicalProfessionId,
    val name: MedicalProfessionName
  )

  data class MedicalSpeciality(
    val id: MedicalSpecialityId,
    val name: MedicalSpecialityName,
    val partnership: Partnership?
  ) {
    data class Partnership(
      val name: PartnershipName,
      val logo: Url,
      val website: PartnershipWebsite
    )
  }

  data class MedicalInterest(
    val id: MedicalInterestId,
    val name: MedicalInterestName
  )

  data class ProfessionalCategory(
    val code: ProfessionalCategoryCode,
    val name: ProfessionalCategoryName
  )

  data class ProfessionalStatus(
    val code: ProfessionalStatusCode,
    val name: ProfessionalStatusName
  )
}
