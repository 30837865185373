package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.MarketingDocumentIdAsString
import fr.labodoc.api.payloads.serializers.MarketingDocumentNameAsString
import fr.labodoc.api.payloads.serializers.UrlAsString
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalMarketingDocumentResponse(
  val marketingDocument: MarketingDocument
) {
  @Serializable
  data class MarketingDocument(
    val id: MarketingDocumentIdAsString,
    val name: MarketingDocumentNameAsString,
    val documentUrl: UrlAsString,
    val seenAt: Instant?,
    val seenLatestVersion: Boolean
  )
}
