package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import io.ktor.http.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object LaboratoryIdAsStringSerializer : KSerializer<LaboratoryId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LaboratoryIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.Laboratory.Id.Invalid, LaboratoryId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.Laboratory.Id.Invalid.Format }
      .map { LaboratoryId(it) }

  override fun deserialize(decoder: Decoder): LaboratoryId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: LaboratoryId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: LaboratoryId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias LaboratoryIdAsString = @Serializable(LaboratoryIdAsStringSerializer::class) LaboratoryId


object LaboratoryNameAsStringSerializer : KSerializer<LaboratoryName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LaboratoryNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.Laboratory.Name.Invalid, LaboratoryName> =
    LaboratoryName(name)

  override fun deserialize(decoder: Decoder): LaboratoryName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(name: LaboratoryName): String = name.value

  override fun serialize(encoder: Encoder, value: LaboratoryName) =
    encoder.encodeString(serialize(value))
}

typealias LaboratoryNameAsString = @Serializable(LaboratoryNameAsStringSerializer::class) LaboratoryName


object LaboratoryWebsiteAsStringSerializer : KSerializer<LaboratoryWebsite> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LaboratoryWebsiteAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(website: String): Either<Nothing, LaboratoryWebsite> =
    LaboratoryWebsite(Url(website)).right()

  override fun deserialize(decoder: Decoder): LaboratoryWebsite =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(website: LaboratoryWebsite): String = website.value.toString()

  override fun serialize(encoder: Encoder, value: LaboratoryWebsite) =
    encoder.encodeString(serialize(value))
}

typealias LaboratoryWebsiteAsString = @Serializable(LaboratoryWebsiteAsStringSerializer::class) LaboratoryWebsite


object LaboratoryPharmacovigilanceAsStringSerializer : KSerializer<LaboratoryPharmacovigilance> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LaboratoryPharmacovigilanceAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(pharmacovigilance: String): Either<Nothing, LaboratoryPharmacovigilance> =
    LaboratoryPharmacovigilance(Url(pharmacovigilance)).right()

  override fun deserialize(decoder: Decoder): LaboratoryPharmacovigilance =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(pharmacovigilance: LaboratoryPharmacovigilance): String = pharmacovigilance.value.toString()

  override fun serialize(encoder: Encoder, value: LaboratoryPharmacovigilance) =
    encoder.encodeString(serialize(value))
}

typealias LaboratoryPharmacovigilanceAsString = @Serializable(LaboratoryPharmacovigilanceAsStringSerializer::class) LaboratoryPharmacovigilance
