package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.html.span
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableValue
import io.kvision.state.bind

class LabodocPagination(
  initialPage: Int,
  lastPage: Int?,
  private val pageChangeCallback: ((targetPage: Int) -> Unit)? = null
) : SimplePanel("labodoc-pagination") {
  val currentPage: ObservableValue<Int> = ObservableValue(initialPage)

  init {
    require("./css/components/labodoc-pagination.css")

    bind(currentPage) { currentPage ->
      if (currentPage > 1) {
        span(className = "pagination-btn pagination-btn-back") {
          content = "<";
          onClick { pageChangeCallback?.invoke(currentPage - 1) }
        }

        if (lastPage != null) {
          span(className = "pagination-btn pagination-btn-page") {
            content = "1";
            onClick { pageChangeCallback?.invoke(1) }
          }
        }
      }

      if (lastPage != null && currentPage > 1 && currentPage - 3 > 2) {
        span(className = "pagination-btn pagination-ellipsis") {
          content = "..."
        }
      }

      if (lastPage != null) {
        if (currentPage - 3 <= 1) {
          for (i in 2..currentPage - 1) {
            span(className = "pagination-btn pagination-btn-page") {
              content = i.toString()
              onClick { pageChangeCallback?.invoke(i) }
            }
          }
        } else {
          for (i in currentPage - 3..currentPage - 1) {
            span(className = "pagination-btn pagination-btn-page") {
              content = i.toString()
              onClick { pageChangeCallback?.invoke(i) }
            }
          }
        }
      }

      span(className = "pagination-btn pagination-btn-current-page") {
        content = currentPage.toString()
      }

      if (lastPage != null) {
        if (lastPage - 3 <= currentPage) {
          for (i in currentPage + 1..lastPage - 1) {
            span(className = "pagination-btn pagination-btn-page") {
              content = i.toString()
              onClick { pageChangeCallback?.invoke(i) }
            }
          }
        } else {
          for (i in currentPage + 1..currentPage + 3) {
            span(className = "pagination-btn pagination-btn-page") {
              content = i.toString()
              onClick { pageChangeCallback?.invoke(i) }
            }
          }
        }
      }

      if (lastPage != null && lastPage - 4 > currentPage) {
        span(className = "pagination-btn pagination-ellipsis") {
          content = "..."
        }
      }

      if (lastPage != null && currentPage < lastPage) {
        if (lastPage != null) {
          span(className = "pagination-btn pagination-btn-page") {
            content = lastPage.toString();
            onClick { pageChangeCallback?.invoke(lastPage) }
          }
        }

        span(className = "pagination-btn pagination-btn-next") {
          content = ">";
          onClick { pageChangeCallback?.invoke(currentPage + 1) }
        }
      } else if (lastPage != null) {
        span(className = "pagination-btn pagination-btn-next") {
          content = ">";
          onClick { pageChangeCallback?.invoke(currentPage + 1) }
        }
      }
    }
  }
}

fun Container.labodocPagination(
  initialPage: Int,
  lastPage: Int?,
  pageChangeCallback: ((targetPage: Int) -> Unit)? = null
) {
  val pagination = LabodocPagination(initialPage, lastPage, pageChangeCallback)
  this.add(pagination)
}
