package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
sealed class AdminMessageResponse {
  @Serializable
  data class Laboratory(
    val id: LaboratoryIdAsString,
    val name: LaboratoryNameAsString
  )

  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val name: MedicineNameAsString
  )

  @Serializable
  data class LearnedSociety(
    val id: LearnedSocietyIdAsString,
    val name: LearnedSocietyNameAsString
  )

  @Serializable
  data class Segmentation(
    val medicalProfessionIds: Set<MedicalProfessionIdAsString>?,
    val medicalSpecialityIds: Set<MedicalSpecialityIdAsString>?,
    val medicalInterestIds: Set<MedicalInterestIdAsString>?,
    val professionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>?,
    val professionalStatusCodes: Set<ProfessionalStatusCodeAsString>?,
    val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString>?,
    val departmentCodes: Set<DepartmentCodeAsString>?
  )

  abstract val id: MessageIdAsString
  abstract val publishedAt: Instant
  abstract val title: MessageTitleAsString
  abstract val content: MessageContentAsString
  abstract val segmentation: Segmentation
  abstract val bannerUrl: UrlAsString
  abstract val documentUrl: UrlAsString?
}

@Serializable
data class AdminLabodocFlashInfoResponse(
  override val id: MessageIdAsString,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()

@Serializable
data class AdminLaboratoryFlashInfoResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()

@Serializable
data class AdminLaboratoryInvitationResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  val eventAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  val formUrl: UrlAsString?,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()

@Serializable
data class AdminMedicineFlashInfoResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()

@Serializable
data class AdminMedicineInvitationResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  val medicine: Medicine,
  override val publishedAt: Instant,
  val eventAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  val formUrl: UrlAsString?,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()

@Serializable
data class AdminLearnedSocietyFlashInfoResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()

@Serializable
data class AdminLearnedSocietyInvitationResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  val eventAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  val formUrl: UrlAsString?,
  override val segmentation: Segmentation,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?
) : AdminMessageResponse()
