package fr.labodoc.app.di

import fr.labodoc.app.data.admin.repository.*
import org.koin.core.qualifier.named
import org.koin.dsl.module

val adminRepositoryModule = module {
  single<ApplicationsRepository>(named("admin")) { ApplicationsRepositoryImpl(get()) }
  single<AtcCodesRepository>(named("admin")) { AtcCodesRepositoryImpl(get()) }
  single<DepartmentsRepository>(named("admin")) { DepartmentsRepositoryImpl(get()) }
  single<HealthDirectoryRepository>(named("admin")) { HealthDirectoryRepositoryImpl(get()) }
  single<LaboratoriesRepository>(named("admin")) { LaboratoriesRepositoryImpl(get()) }
  single<LearnedSocietiesRepository>(named("admin")) { LearnedSocietiesRepositoryImpl(get()) }
  single<MedicalCardTypesRepository>(named("admin")) { MedicalCardTypesRepositoryImpl(get()) }
  single<MedicalDiplomasRepository>(named("admin")) { MedicalDiplomasRepositoryImpl(get()) }
  single<MedicalProfessionsRepository>(named("admin")) { MedicalProfessionsRepositoryImpl(get()) }
  single<MedicinesRepository>(named("admin")) { MedicinesRepositoryImpl(get()) }
  single<MessagesRepository>(named("admin")) { MessagesRepositoryImpl(get()) }
  single<PartnersRepository>(named("admin")) { PartnersRepositoryImpl(get()) }
  single<PartnershipsRepository>(named("admin")) { PartnershipsRepositoryImpl(get()) }
  single<ProfessionalCategoriesRepository>(named("admin")) { ProfessionalCategoriesRepositoryImpl(get()) }
  single<ProfessionalStatusesRepository>(named("admin")) { ProfessionalStatusesRepositoryImpl(get()) }
  single<SegmentationTemplatesRepository>(named("admin")) { SegmentationTemplatesRepositoryImpl(get()) }
  single<UniversityHospitalsRepository>(named("admin")) { UniversityHospitalsRepositoryImpl(get()) }
  single<UsersRepository>(named("admin")) { UsersRepositoryImpl(get()) }
}
