package fr.labodoc.webapp.pages.admin.users.section

import fr.labodoc.app.data.admin.model.FreestandingApplicationModel.Facility
import fr.labodoc.app.data.admin.repository.ApplicationsRepository
import fr.labodoc.domain.labodoc.application.ApplicationId
import fr.labodoc.domain.labodoc.common.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.html.div
import io.kvision.html.icon
import io.kvision.html.link
import io.kvision.html.p
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class ApplicationsList : SimplePanel(className = "applications-list") {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val applications: ObservableList<Application>,
        val successMessage: ObservableState<String?>,
        val errorMessage: ObservableState<String?>
      ) : UiState()
    }

    data class Application(
      val id: ApplicationId,
      val isEmailValidated: Boolean,
      val email: EmailAddress,
      val medicalSpeciality: MedicalSpecialityName,
      val medicalProfession: MedicalProfessionName,
      val phone: PhoneNumber,
      val firstName: FirstName,
      val lastName: LastName,
      val supportingDocumentUrl: Url,
      val facility: Facility,
      val comment: String?,
      val rppsNumber: RPPSNumber?,
      val partnerCode: PartnerCode?,
      val processing: Boolean
    ) {
      data class Facility(
        val name: String,
        val unit: String,
        val departmentCode: DepartmentCode,
        val city: String
      )
    }

    val uiState: ObservableState<UiState>

    fun validateApplication(application: Application)

    fun rejectApplication(application: Application)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val applicationsRepository: ApplicationsRepository by inject(named("admin"))

    private val applications: ObservableListWrapper<ViewModel.Application> =
      ObservableListWrapper()

    private val successMessage: ObservableValue<String?> =
      ObservableValue(null)

    private val errorMessage: ObservableValue<String?> =
      ObservableValue(null)

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        val newUiState = applicationsRepository
          .getPendingFreestandingApplications()
          .fold(
            {
              ViewModel.UiState.Error
            },
            { remoteApplications ->
              applications.addAll(
                remoteApplications
                  .map {
                    ViewModel.Application(
                      id = it.id,
                      isEmailValidated = it.isEmailValidated,
                      email = it.email,
                      medicalSpeciality = it.medicalSpeciality,
                      medicalProfession = it.medicalProfession,
                      phone = it.phone,
                      firstName = it.firstName,
                      lastName = it.lastName,
                      supportingDocumentUrl = it.supportingDocumentUrl,
                      facility = ViewModel.Application.Facility(
                        name = it.facility.name,
                        unit = it.facility.unit,
                        departmentCode = it.facility.departmentCode,
                        city = it.facility.city
                      ),
                      comment = it.comment,
                      rppsNumber = it.rppsNumber,
                      partnerCode = it.partnerCode,
                      processing = false
                    )
                  }
              )

              ViewModel.UiState.Loaded(
                applications = applications,
                successMessage = successMessage,
                errorMessage = errorMessage
              )
            }
          )

        observableValue.setState(newUiState)
      }

      observableValue
    }

    private fun bidule(applicationId: ApplicationId, truc: () -> ViewModel.Application) {
      applications.find { it.id == applicationId }?.let { application ->
        applications
          .indexOf(application)
          .takeIf { it != -1 }
          ?.let { applications.set(it, truc()) }
      }
    }

    override fun validateApplication(application: ViewModel.Application) {
      App.scope.launch {
        bidule(application.id) {
          application.copy(
            processing = true
          )
        }

        applicationsRepository
          .validateFreestandingPendingApplication(application.id)
          .onLeft {
            bidule(application.id) {
              application.copy(
                processing = false
              )
            }
            errorMessage.value = "Une erreur est survenue lors de la validation de la demande d'inscription"
          }
          .onRight {
            applications.remove(application)
          }
      }
    }

    override fun rejectApplication(application: ViewModel.Application) {
      App.scope.launch {
        bidule(application.id) {
          application.copy(
            processing = true
          )
        }

        applicationsRepository
          .rejectFreestandingPendingApplication(application.id)
          .onLeft {
            bidule(application.id) {
              application.copy(
                processing = false
              )
            }
            errorMessage.value = "Une erreur est survenue lors du refus de la demande d'inscription"
          }
          .onRight {
            applications.remove(application)
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    this.bind(viewModel.uiState) { uiState ->
      when (uiState) {
        ViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        ViewModel.UiState.Error -> {
          p("Impossible de récupérer la liste des demandes d'inscription")
        }

        is ViewModel.UiState.Loaded -> {
          uiState.successMessage.subscribe { successMessage ->
            successMessage?.let { Toast.success(it) }
          }

          uiState.errorMessage.subscribe { errorMessage ->
            errorMessage?.let { Toast.danger(it) }
          }

          div(className = "applications").bindEach(uiState.applications) { application ->
            div(className = "application") {
              div(className = "information") {
                div(className = "category personal") {
                  p(className = "title") {
                    rich = true
                    content = """<i class="fa-solid fa-user"></i> <b>Informations personnelles</b>"""
                  }

                  p {
                    rich = true
                    content = "<b>Nom</b>:<br>${application.lastName.value}"
                  }

                  p {
                    rich = true
                    content = "<b>Prénom</b>:<br>${application.firstName.value}"
                  }

                  p {
                    rich = true
                    content =
                      """<b><i class="fa-solid fa-phone"></i> Téléphone</b>:<br><a href="tel:${application.phone.value}">${application.phone.value}</a>"""
                  }

                  p {
                    rich = true
                    content =
                      """<b><i class="fa-solid fa-envelope"></i> Email</b>:<br><a href="mailto:${application.email.value}">${application.email.value}"""
                  }
                }

                div(className = "category professional") {
                  p(className = "title") {
                    rich = true
                    content = """<i class="fa-solid fa-user-doctor"></i> <b>Informations professionnelles</b>"""
                  }

                  p {
                    rich = true
                    content = "<b>Profession</b>:<br>${application.medicalProfession.value}"
                  }

                  p {
                    rich = true
                    content = "<b>Spécialité</b>:<br>${application.medicalSpeciality.value}"
                  }

                  p {
                    rich = true
                    content = "<b>Justificatif</b>:<br>"
                    link("Consulter le document", application.supportingDocumentUrl.toString(), target = "_blank")
                  }
                }

                div(className = "category facility") {
                  p(className = "title") {
                    rich = true
                    content = """<i class="fa-solid fa-house-medical"></i> <b>Structure</b>"""
                  }

                  p {
                    rich = true
                    content = "<b>Nom</b>:<br>${application.facility.name}"
                  }

                  p {
                    rich = true
                    content =
                      "<b>Ville</b>:<br>${application.facility.departmentCode.value} - ${application.facility.city}"
                  }

                  p {
                    rich = true
                    content = "<b>Service</b>:<br>${application.facility.unit}"
                  }
                }

                div(className = "category complementary") {
                  p(className = "title") {
                    rich = true
                    content = """<i class="fa-solid fa-circle-info"></i> <b>Informations complémentaires</b>"""
                  }

                  p {
                    rich = true
                    content = "<b>Code partenaire utilisé</b>:<br>${application.partnerCode?.value ?: "/"}"
                  }

                  p {
                    rich = true
                    content = "<b>Commentaire</b>:<br>${application.comment?.replace("\n", "<br>") ?: "/"}"
                  }
                }
              }

              div(className = "actions") {
                if (!application.processing) {
                  labodocButton("Valider", "fa-solid fa-check", className = "validate") {
                    onClick {
                      viewModel.validateApplication(application)
                    }
                  }

                  labodocButton("Refuser", "fa-solid fa-ban", className = "reject") {
                    onClick {
                      viewModel.rejectApplication(application)
                    }
                  }
                } else {
                  icon("fa fa-spinner fa-spin")
                }
              }
            }
          }
        }
      }
    }

  }
}

fun Container.applicationsList(
): ApplicationsList {
  val applicationsList = ApplicationsList(
  )

  this.add(applicationsList)
  return applicationsList
}

