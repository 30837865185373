package fr.labodoc.webapp.utils

import io.kvision.utils.Object

fun Object?.toMap(): Map<String, Any?>? = this?.let {
  (js("Object.entries") as (dynamic) -> Array<Array<Any?>>)
    .invoke(it)
    .map { entry -> entry[0] as String to entry[1] }
    .ifEmpty { null }
    ?.toMap()
}
