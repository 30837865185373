package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.PharmacistTableSection
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object PharmacistTableSectionCodeAsStringSerializer : KSerializer<PharmacistTableSection.Code> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("PharmacistTableSectionCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(pharmacistTableSectionCode: String): Either<Nothing, PharmacistTableSection.Code> =
    PharmacistTableSection.Code(pharmacistTableSectionCode).right()

  override fun deserialize(decoder: Decoder): PharmacistTableSection.Code =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(pharmacistTableSectionCode: PharmacistTableSection.Code): String =
    pharmacistTableSectionCode.value

  override fun serialize(encoder: Encoder, value: PharmacistTableSection.Code) =
    encoder.encodeString(serialize(value))
}

typealias PharmacistTableSectionCodeAsString = @Serializable(PharmacistTableSectionCodeAsStringSerializer::class) PharmacistTableSection.Code
