package fr.labodoc.webapp.pages.admin.medicines

import arrow.core.Either
import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.AtcCategoryModel
import fr.labodoc.app.data.admin.model.LaboratorySummaryModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.MedicineModel
import fr.labodoc.app.data.admin.repository.AtcCodesRepository
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.*
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.medicines.sections.AdminMedicineForm
import fr.labodoc.webapp.utils.byteArray
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import io.kvision.types.KFile
import io.kvision.types.contentType
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicineUpdatePage(
  medicineId: MedicineId
) : SimplePanel() {
  private sealed interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val medicine: MedicineModel,
        val laboratories: Set<LaboratorySummaryModel>,
        val atcs: Set<AtcCategoryModel>,
        val medicalProfessions: Set<MedicalProfessionModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    val updateMedicineResponse: ObservableState<ApiResponse<Unit>?>

    fun updateMedicine(
      laboratory: LaboratoryId,
      name: MedicineName,
      mainComposition: MedicineMainComposition,
      cipCode: CipCode,
      atcCode: AtcCode.Code,
      governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
      website: MedicineWebsite?,
      logo: KFile?,
      segmentation: Segmentation
    )
  }

  private class ViewModelImpl(private val medicineId: MedicineId) : ViewModel, KoinComponent {
    private val medicineRepository: MedicinesRepository by inject(named("admin"))
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))
    private val atcCodesRepository: AtcCodesRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue = ObservableValue<ViewModel.UiState>(ViewModel.UiState.Loading)

      App.scope.launch {
        either {
          parZip(
            { medicineRepository.getMedicine(medicineId).bind() },
            { laboratoriesRepository.getLaboratories().bind() },
            { atcCodesRepository.getAtcCategoriesWithCodes().bind() },
            { medicalProfessionsRepository.getMedicalProfessions().bind() }
          ) { medicine, laboratories, atcCodes, medicalProfessions ->
            ViewModel.UiState.Loaded(
              medicine,
              laboratories,
              atcCodes,
              medicalProfessions
            )
          }
        }
          .onLeft { observableValue.setState(ViewModel.UiState.Error) }
          .onRight { observableValue.setState(it) }
      }

      observableValue
    }

    override val updateMedicineResponse: ObservableState<ApiResponse<Unit>?> =
      ObservableValue(null)

    override fun updateMedicine(
      laboratory: LaboratoryId,
      name: MedicineName,
      mainComposition: MedicineMainComposition,
      cipCode: CipCode,
      atcCode: AtcCode.Code,
      governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
      website: MedicineWebsite?,
      logo: KFile?,
      segmentation: Segmentation
    ) {
      App.scope.launch {
        medicineRepository
          .updateMedicine(
            id = medicineId,
            laboratory = laboratory,
            cipCode = cipCode,
            atcCode = atcCode,
            governmentPublicDatabaseId = governmentPublicDatabaseId,
            name = name,
            mainComposition = mainComposition,
            website = website,
            segmentation = segmentation,
            logo = logo?.let {
              InputFile(
                logo.name,
                logo.contentType!!,
                logo.byteArray!!
              )
            }
          )
          .onRight {
            App.routing.navigate(Page.AdminBackOfficeMedicineSheet(medicineId))
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(medicineId)

  init {
    id = "page-admin-medicine-update"
    require("./css/pages/admin/medicines/update.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1("Mettre à jour un médicament")

      when (uiState) {
        ViewModel.UiState.Loading -> labodocSpinner()

        ViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is ViewModel.UiState.Loaded -> {
          val medicineForm = AdminMedicineForm(
            uiState.laboratories.map { it.id to it.name }.toSet(),
            uiState.atcs.flatMap { it.codes.map { it.code to it.name } }.toSet(),
            uiState.medicalProfessions,
            uiState.medicine.logoUrl
          ).apply {
            setData(
              AdminMedicineForm.Data(
                laboratory = uiState.medicine.laboratory,
                name = uiState.medicine.name,
                mainComposition = uiState.medicine.mainComposition,
                cipCode = uiState.medicine.cipCode,
                atcCode = uiState.medicine.atcCode,
                governmentPublicDatabaseId = uiState.medicine.governmentPublicDatabaseId,
                website = uiState.medicine.website,
                segmentation = uiState.medicine.segmentation
              )
            )
          }

          val saveButton = LabodocButton("Sauvegarder", className = "save") {
            onClickLaunch {
              //disabled = true

              if (medicineForm.validate()) {
                val medicineFormData = medicineForm.getDataWithFileContent()
                viewModel
                  .updateMedicine(
                    laboratory = medicineFormData.laboratory!!,
                    name = medicineFormData.name!!,
                    mainComposition = medicineFormData.mainComposition!!,
                    cipCode = medicineFormData.cipCode!!,
                    atcCode = medicineFormData.atcCode!!,
                    governmentPublicDatabaseId = medicineFormData.governmentPublicDatabaseId,
                    website = medicineFormData.website,
                    logo = medicineFormData.logo,
                    segmentation = medicineFormData.segmentation!!
                  )
              } else
                disabled = false
            }
          }

          viewModel.updateMedicineResponse.subscribe { updateMedicineResponse ->
            when (updateMedicineResponse) {
              null -> {}

              is Either.Left -> {
                val errorResponse = updateMedicineResponse.value

                val errorMessage = "Une erreur est survenue, vérifiez les données et réessayez"

                Toast.danger(errorMessage)
                saveButton.disabled = false
              }

              is Either.Right -> {
                App.routing.navigate(Page.AdminBackOfficeMedicineSheet(uiState.medicine.id))
              }
            }
          }

          add(medicineForm)

          add(saveButton)
        }
      }
    }
  }
}

fun Container.adminMedicineUpdatePage(
  medicineId: MedicineId
): AdminMedicineUpdatePage {
  val adminMedicineUpdatePage = AdminMedicineUpdatePage(medicineId)

  this.add(adminMedicineUpdatePage)
  return adminMedicineUpdatePage
}
