package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.api.resources.HealthDirectory
import fr.labodoc.api.routes.HealthDirectoryRoutes
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthDirectoryRemoteDataSource(
  private val httpClient: HttpClient
) : HealthDirectoryRoutes {
  override suspend fun searchForMedicalCard(
    cardNumber: MedicalCardNumber
  ): ApiResponse<HealthProfessionalResponse> {
    TODO("Not yet implemented")
  }

  override suspend fun getExpertises(
  ): ApiResponse<Set<HealthDirectoryExpertiseResponse>> {
    val response = httpClient.get(HealthDirectory.Expertises())

    return if (response.status.isSuccess())
      response.body<Set<HealthDirectoryExpertiseResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getProfessions(
  ): ApiResponse<Set<HealthDirectoryProfessionResponse>> {
    val response = httpClient.get(HealthDirectory.Professions())

    return if (response.status.isSuccess())
      response.body<Set<HealthDirectoryProfessionResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getLabodocMedicalProfessionAndSpeciality(
    professionCode: ProfessionCode,
    professionalCategoryCode: ProfessionalCategoryCode,
    professionalStatusCode: ProfessionalStatusCode?,
    expertiseCode: ExpertiseCode?,
    pharmacistTableSectionCode: PharmacistTableSection.Code?
  ): ApiResponse<MedicalSpecialityWithMedicalProfessionResponse> {
    val route = HealthDirectory.LabodocMedicalProfessionAndSpeciality(
      professionCode,
      professionalCategoryCode,
      professionalStatusCode,
      expertiseCode,
      pharmacistTableSectionCode
    )

    val response = httpClient.get(route)

    return if (response.status.isSuccess())
      response.body<MedicalSpecialityWithMedicalProfessionResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getHealthProfessionals(
    pageSize: Int,
    pageNumber: Int,
    nationalIdentifier: NationalIdentifier?,
    name: String?,
    healthRepositoryProfessionCodes: Set<ProfessionCode>?,
    healthRepositoryProfessionalCategoryCodes: Set<ProfessionalCategoryCode>?,
    healthRepositoryProfessionalStatusCodes: Set<ProfessionalStatusCode>?,
    healthRepositoryPharmacistTableSectionCodes: Set<PharmacistTableSection.Code>?,
    healthRepositoryExpertiseCodes: Set<ExpertiseCode>?,
    medicalCardNumber: MedicalCardNumber?,
    medicalCardTypeCodes: Set<MedicalCardTypeCode?>?,
    labodocMedicalProfessionIds: Set<MedicalProfessionId>?,
    labodocMedicalSpecialitiesIds: Set<MedicalSpecialityId>?,
    departmentsCodes: Set<DepartmentCode>?
  ): ApiResponse<PaginatedEntities<HealthDirectoryHealthProfessionalResponse>> {
    val route = HealthDirectory.HealthProfessionals(
      pageSize,
      pageNumber,
      nationalIdentifier,
      name,
      healthRepositoryProfessionCodes,
      healthRepositoryProfessionalCategoryCodes,
      healthRepositoryProfessionalStatusCodes,
      healthRepositoryPharmacistTableSectionCodes,
      healthRepositoryExpertiseCodes,
      medicalCardNumber,
      medicalCardTypeCodes?.map { it ?: MedicalCardTypeCode("null") }?.toSet(), // FIXME fix serialization of null ?
      labodocMedicalProfessionIds,
      labodocMedicalSpecialitiesIds,
      departmentsCodes
    )

    val response = httpClient.get(route)

    return if (response.status.isSuccess())
      response.body<PaginatedEntities<HealthDirectoryHealthProfessionalResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
