package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.UniversityHospitalIdAsString
import io.ktor.resources.*

@Resource("university-hospitals")
class UniversityHospitals {
  @Resource("{universityHospitalId}")
  class UniversityHospital(
    val universityHospitalId: UniversityHospitalIdAsString,
    val universityHospitals: UniversityHospitals = UniversityHospitals(),
  )
}
