package fr.labodoc.app.data.admin.repository

import arrow.core.right
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.ProfessionalCategoryModel
import fr.labodoc.domain.healthdirectory.ProfessionalCategoryCode
import fr.labodoc.domain.healthdirectory.ProfessionalCategoryName

class ProfessionalCategoriesRepositoryImpl(
  private val apiClient: AdminApiClient
) : ProfessionalCategoriesRepository {
  override suspend fun getProfessionalCategories(
  ): ApiResponse<Set<ProfessionalCategoryModel>> =
    setOf(
      ProfessionalCategoryModel(
        code = ProfessionalCategoryCode("E"),
        name = ProfessionalCategoryName("Étudiant")
      ),
      ProfessionalCategoryModel(
        code = ProfessionalCategoryCode("C"),
        name = ProfessionalCategoryName("Civil")
      ),
      ProfessionalCategoryModel(
        code = ProfessionalCategoryCode("M"),
        name = ProfessionalCategoryName("Militaire")
      )
    ).right()
}
