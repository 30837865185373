package fr.labodoc.domain.labodoc.common

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

/**
 * https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/
 * https://shinesolutions.com/2018/01/08/falsehoods-programmers-believe-about-names-with-examples/
 * https://a-tokyo.medium.com/first-and-last-name-validation-for-forms-and-databases-d3edf29ad29d
 */
@JvmInline
value class FirstName private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 50

    fun isNotTooLong(value: String): Boolean = value.length <= LastName.MAX_LENGTH
    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.FirstName.Invalid, FirstName> =
      either {
        ensure(isNotTooLong(value)) { Errors.FirstName.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.FirstName.Invalid.Blank }

        FirstName(value)
      }
  }
}
