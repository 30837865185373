package fr.labodoc.webapp.components

import fr.labodoc.require
import fr.labodoc.webapp.utils.containsNormalized
import io.kvision.core.*
import io.kvision.form.*
import io.kvision.form.text.TextInput
import io.kvision.html.Div
import io.kvision.html.Label
import io.kvision.html.Li
import io.kvision.html.Ul
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import io.kvision.utils.SnOn
import kotlinx.browser.document
import org.w3c.dom.Node
import org.w3c.dom.events.MouseEvent

class LabodocSelectCheckbox(
  options: Map<String?, List<StringPair>>? = null, values: String? = null,
  name: String? = null,
  label: String? = null, rich: Boolean = false,
  init: (LabodocSelectCheckbox.() -> Unit)? = null
) : SimplePanel("form-group"), StringFormControl, MutableState<String?> {
  private val idc = "labodoc_select_checkbox_$counter"

  var options
    get() = input.options
    set(value) {
      input.options = value
    }

  override var value
    get() = input.value
    set(value) {
      input.value = value
    }

  var values
    get() = input.values
    set(value) {
      input.values = value
    }

  var label
    get() = flabel.content
    set(value) {
      flabel.content = value
    }

  var rich
    get() = flabel.rich
    set(value) {
      flabel.rich = value
    }

  override val input: LabodocSelectCheckboxInput = LabodocSelectCheckboxInput(
    options, values
  ).apply {
    this.id = this@LabodocSelectCheckbox.idc
    this.name = name
  }
  override val flabel: FieldLabel = FieldLabel(idc, label, rich, "form-label")
  override val invalidFeedback: InvalidFeedback = InvalidFeedback().apply { visible = false }

  init {
    input.eventTarget = this
    this.addPrivate(flabel)
    this.addPrivate(input)
    this.addPrivate(invalidFeedback)
    counter++

    init?.invoke(this)
  }

  override fun buildClassSet(classSetBuilder: ClassSetBuilder) {
    super.buildClassSet(classSetBuilder)
    if (validatorError != null) {
      classSetBuilder.add("text-danger")
    }
  }

  override fun <T : Widget> setEventListener(block: SnOn<T>.() -> Unit): Int {
    return input.setEventListener(block)
  }

  override fun removeEventListener(id: Int) {
    input.removeEventListener(id)
  }

  override fun removeEventListeners() {
    input.removeEventListeners()
  }

  override fun add(child: Component) {
    input.add(child)
  }

  override fun add(position: Int, child: Component) {
    input.add(position, child)
  }

  override fun addAll(children: List<Component>) {
    input.addAll(children)
  }

  override fun remove(child: Component) {
    input.remove(child)
  }

  override fun removeAt(position: Int) {
    input.removeAt(position)
  }

  override fun removeAll() {
    input.removeAll()
  }

  override fun disposeAll() {
    input.disposeAll()
  }

  override fun getChildren(): List<Component> {
    return input.getChildren()
  }

  override fun focus() {
    input.focus()
  }

  override fun blur() {
    input.blur()
  }

  override fun getState(): String? = input.getState()

  override fun subscribe(observer: (String?) -> Unit): () -> Unit {
    return input.subscribe(observer)
  }

  override fun setState(state: String?) {
    input.setState(state)
  }

  companion object {
    internal var counter = 0
  }
}

fun Container.labodocSelectCheckbox(
  options: Map<String?, List<StringPair>>? = null, values: String? = null,
  name: String? = null,
  label: String? = null, rich: Boolean = false,
  init: (LabodocSelectCheckbox.() -> Unit)? = null
): LabodocSelectCheckbox {
  val labodocSelectCheckbox = LabodocSelectCheckbox(options, values, name, label, rich, init)
  this.add(labodocSelectCheckbox)
  return labodocSelectCheckbox
}

class LabodocSelectCheckboxInput(
  options: Map<String?, List<StringPair>>? = null, value: String? = null,
  className: String? = null, init: (LabodocSelectCheckboxInput.() -> Unit)? = null
) : SimplePanel((className?.let { "$it " } ?: "") + "labodoc-select-checkbox"), FormInput,
  GenericFormComponent<String?>, MutableState<String?> {

  private val observers = mutableListOf<(String?) -> Unit>()

  var options by refreshOnUpdate(options) { setChildrenFromOptions() }

  override var value by refreshOnUpdate(value) {
    refreshState()
    observers.forEach { ob -> ob(it) }
  }

  var values: List<String>?
    set(value) {
      this.value = value?.joinToString(",")
    }
    get() = value?.split(",")

  override var name: String? by refreshOnUpdate()

  override var disabled by refreshOnUpdate(false)

  override var size: InputSize? by refreshOnUpdate()

  override var validationStatus: ValidationStatus? by refreshOnUpdate()

  private val cssOpenClass = "open"

  private fun openDropdown() {
    addCssClass(cssOpenClass)
    searchInput.focus()
  }

  private fun closeDropdown() {
    removeCssClass(cssOpenClass)
    searchInput.value = null
    refreshState()
  }

  private val label = Label {
    onClick {
      if (this@LabodocSelectCheckboxInput.hasCssClass(cssOpenClass))
        closeDropdown()
      else
        openDropdown()
    }
  }

  private val dropdown = Div(className = "dropdown")

  private val optionList = Ul()

  private val searchInput = TextInput(className = "search") {
    placeholder = "Rechercher une valeur"

    onInput {
      singleRenderAsync {
        refreshState()
      }
    }
  }

  private val checkboxAll = LabodocCheckbox {
    rich = true
    label = "<b>[Tout]</b>"

    onInput {
      categories
        .forEach { category ->
          category.value.values
            .filter { it.visible }
            .forEach { categoryOptions ->
              categoryOptions.value = this.value
            }
        }

      this@LabodocSelectCheckboxInput.value = selectedOptions.keys.joinToString(",").ifBlank { null }
    }
  }

  private var categories: Map<LabodocCheckbox, Map<String, LabodocCheckbox>> = mapOf()

  private val availableOptions: Map<String, LabodocCheckbox>
    get() = categories
      .flatMap { category ->
        category.value
          .map { it.key to it.value }
      }
      .toMap()

  private val selectedOptions: Map<String, LabodocCheckbox>
    get() = availableOptions.filter { it.value.value }

  private fun setChildrenFromOptions() {
    singleRenderAsync {
      optionList.removeAll()

      optionList.add(Li(className = "check-all") { add(checkboxAll) })

      options.orEmpty().let { options ->
        this.categories = options.map { option ->
          val categoryOptions: Map<String, LabodocCheckbox> = option.value.associate { categoryOption ->
            val categoryOptionCheckbox = LabodocCheckbox {
              label = categoryOption.second

              onInput {
                this@LabodocSelectCheckboxInput.value = selectedOptions.keys.joinToString(",").ifBlank { null }
              }
            }

            categoryOption.first to categoryOptionCheckbox
          }

          val categoryCheckbox = LabodocCheckbox {
            rich = true
            label = "<b>${option.key ?: "Aucune catégorie"}</b>"

            onInput {
              categoryOptions.values
                .filter { it.visible }
                .forEach { categoryOption ->
                  categoryOption.value = this.value
                }

              this@LabodocSelectCheckboxInput.value = selectedOptions.keys.joinToString(",").ifBlank { null }
            }
          }

          if (options.size > 1 || (options.size == 1 && !options.contains(null)))
            optionList.add(Li(className = "category") { add(categoryCheckbox) })

          categoryOptions.forEach { optionList.add(Li(className = "option") { add(it.value) }) }

          categoryCheckbox to categoryOptions
        }.toMap()
      }

      // Remove selected values no more available in options
      values = values?.filter { it in availableOptions.keys }

      refreshState()
    }
  }

  private fun refreshState() {
    // Check the needed case base on the input's values
    availableOptions
      .forEach { availableOption ->
        availableOption.value.value = values?.contains(availableOption.key) == true
      }

    // Update the text label based on what is selected
    label.content = when (selectedOptions.size) {
      0 -> "Sélectionner des options"
      1 -> selectedOptions.values.first().label
      availableOptions.size -> "Toutes séléctionnées"
      else -> "${selectedOptions.size} séléctionnées"
    }

    // Display the options based on the current search input value
    val searchInputValue = searchInput.value
    categories.forEach { category ->
      category.value.forEach { categoryOption ->
        val isVisible =
          searchInputValue == null || categoryOption.value.label?.containsNormalized(searchInputValue, true) == true

        categoryOption.value.visible = isVisible
        categoryOption.value.parent?.visible = isVisible
      }

      category.key.value = category.value.values.filter { it.visible }.all { it.value }
      category.key.input.visible = category.value.values.any { it.visible }
    }
    checkboxAll.value = categories.all { it.key.value }
    checkboxAll.input.visible = categories.any { it.key.input.visible }
  }

  init {
    require("./css/components/labodoc-select-checkbox.css")

    add(label)
    dropdown.add(searchInput)
    dropdown.add(optionList)
    add(dropdown)

    document.addEventListener("click", { event ->
      if (event is MouseEvent) {
        val target = event.target

        if (
          (target !is Node || this@LabodocSelectCheckboxInput.getElement()?.contains(target) == false) &&
          this.hasCssClass(cssOpenClass)
        )
          closeDropdown()
      }
    })

    init?.invoke(this)
  }

  override fun buildAttributeSet(attributeSetBuilder: AttributeSetBuilder) {
    super.buildAttributeSet(attributeSetBuilder)
    if (disabled) {
      attributeSetBuilder.add("disabled")
    }
  }

  override fun getState(): String? = value

  override fun subscribe(observer: (String?) -> Unit): () -> Unit {
    observers += observer
    observer(value)
    return {
      observers -= observer
    }
  }

  override fun setState(state: String?) {
    value = state
  }
}

fun Container.labodocSelectCheckboxInput(
  options: Map<String?, List<StringPair>>? = null, value: String? = null,
  className: String? = null, init: (LabodocSelectCheckboxInput.() -> Unit)? = null
): LabodocSelectCheckboxInput {
  val labodocSelectCheckboxInput = LabodocSelectCheckboxInput(options, value, className, init)
  this.add(labodocSelectCheckboxInput)
  return labodocSelectCheckboxInput
}
