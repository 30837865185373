package fr.labodoc.webapp.components

import io.kvision.core.Container
import io.kvision.core.Widget
import io.kvision.panel.SimplePanel
import io.kvision.snabbdom.VNode

class Hr(
  className: String? = null,
  init: (Hr.() -> Unit)? = null
) : Widget(className) {
  init {
    init?.invoke(this)
  }

  override fun render(): VNode {
    return render("hr")
  }
}

fun Container.hr(
  className: String? = null,
  init: (Hr.() -> Unit)? = null
): Hr {
  val hr = Hr(className, init)
  this.add(hr)
  return hr

}
