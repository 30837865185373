package fr.labodoc.webapp.pages.admin.message

import fr.labodoc.app.data.admin.model.*
import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.app.utils.datetimeFormat
import io.kvision.html.*

class MessageModal(
  message: MessageModel
) {
  private val modal = LabodocPopup(
    closeButton = true,
    icon = null,
    image = message.bannerUrl.toString(),
    className = "admin-message-modal",
    content = { popup ->
      div(className = "container") {
        header {
          p(className = "type") {
            rich = true
            content = when (message) {
              is FlashInfoMessageModel -> """<i class="fa-solid fa-bell"></i> Flash Info"""
              is InvitationMessageModel -> """<i class="fa-solid fa-envelope"></i> Invitation"""
            }
          }
        }

        p(message.title.value, className = "title")

        p(className = "author") {
          when (message) {
            is LabodocMessageModel -> {
              content = "LaboDoc"
            }

            is LaboratoryMessageModel -> {
              navigoLink(message.laboratory.name.value, Page.AdminBackOfficeLaboratorySheet(message.laboratory.id)) {
                onClick { popup.hide() }
              }
            }

            is MedicineMessageModel -> {
              navigoLink(message.laboratory.name.value, Page.AdminBackOfficeLaboratorySheet(message.laboratory.id)) {
                onClick { popup.hide() }
              }

              span(" - ")

              navigoLink(message.medicine.name.value, Page.AdminBackOfficeMedicineSheet(message.medicine.id)) {
                onClick { popup.hide() }
              }
            }

            is LearnedSocietyMessageModel -> {
              navigoLink(message.learnedSociety.name.value, Page.AdminBackOfficeLearnedSocietiesList()) {
                onClick { popup.hide() }
              }
            }
          }
        }

        if (message is InvitationMessageModel) {
          p("Date de l'événement: ${message.eventAt.datetimeFormat}", className = "date")
        }

        p(message.content.value.replace("\n", "<br>"), rich = true, className = "text")

        div(className = "actions") {
          message.documentUrl?.let {
            link(
              "Consulter le document",
              it.toString(),
              target = "_blank",
              className = "labodoc-background-deep-blue-reverse"
            ) {
              icon("fa-solid fa-download")
            }
          }

          if (message is InvitationMessageModel) {
            message.formUrl?.let { formUrl ->
              link(
                label = "S'inscrire à cet évènement",
                url = formUrl.toString(),
                target = "_blank",
                className = "labodoc-background-middle-blue"
              )
            }
          }
        }
      }
    },
    beforeClose = null
  )

  init {
    require("./css/pages/admin/message/admin-message-modal.css")
  }

  fun show() {
    modal.show()
  }

  fun hide() {
    modal.hide()
  }
}
