package fr.labodoc.domain.labodoc.medicaldiploma

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class MedicalDiplomaName private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 128

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH
    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.MedicalDiploma.Name.Invalid, MedicalDiplomaName> =
      either {
        ensure(isNotTooLong(value)) { Errors.MedicalDiploma.Name.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.MedicalDiploma.Name.Invalid.Blank }

        MedicalDiplomaName(value)
      }
  }
}
