package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.ExpertiseCodeAsString
import fr.labodoc.api.payloads.serializers.ExpertiseNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class HealthDirectoryExpertiseResponse(
  val code: ExpertiseCodeAsString,
  val name: ExpertiseNameAsString
)
