package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object DepartmentCodeAsStringSerializer : KSerializer<DepartmentCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("DepartmentCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(departmentCode: String): Either<Errors.Department.Code.Invalid, DepartmentCode> =
    DepartmentCode(departmentCode)

  override fun deserialize(decoder: Decoder): DepartmentCode {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(departmentCode: DepartmentCode): String = departmentCode.value

  override fun serialize(encoder: Encoder, value: DepartmentCode) {
    return encoder.encodeString(serialize(value))
  }
}

typealias DepartmentCodeAsString = @Serializable(DepartmentCodeAsStringSerializer::class) DepartmentCode


object DepartmentNameAsStringSerializer : KSerializer<DepartmentName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("DepartmentNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(departmentName: String): Either<Nothing, DepartmentName> =
    DepartmentName(departmentName).right()

  override fun deserialize(decoder: Decoder): DepartmentName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(departmentName: DepartmentName): String = departmentName.value

  override fun serialize(encoder: Encoder, value: DepartmentName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias DepartmentNameAsString = @Serializable(DepartmentNameAsStringSerializer::class) DepartmentName
