package fr.labodoc.webapp.pages.admin.medicines

import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.app.data.admin.model.*
import fr.labodoc.app.data.admin.repository.*
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.medicines.sections.MarketingDocumentForm
import fr.labodoc.webapp.utils.toInputFile
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.form.getDataWithFileContent
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import io.kvision.types.KFile
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.datetime.toJSDate
import kotlinx.datetime.toKotlinInstant
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicinePage(
  medicineId: MedicineId
) : SimplePanel() {
  private sealed interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val medicine: MedicineModelWithDocuments,
        val laboratory: LaboratoryModel,
        val atcCodes: Set<AtcCategoryModel>,
        val medicalProfessions: Set<MedicalProfessionModel>,
        val professionalStatuses: Set<ProfessionalStatusModel>,
        val medicalCardTypes: Set<MedicalCardTypeModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun deleteMedicine()

    fun addMarketingDocument(name: MarketingDocumentName, file: KFile, segmentation: Segmentation, expireAt: Instant?)

    fun updateMarketingDocument(
      marketingDocumentId: MarketingDocumentId,
      name: MarketingDocumentName,
      file: KFile?,
      segmentation: Segmentation,
      expireAt: Instant?
    )

    fun deleteMarketingDocument(marketingDocumentId: MarketingDocumentId)
  }

  private class ViewModelImpl(
    private val medicineId: MedicineId
  ) : ViewModel, KoinComponent {
    private val medicinesRepository: MedicinesRepository by inject(named("admin"))
    private val laboratoryRepository: LaboratoriesRepository by inject(named("admin"))
    private val atcCodesRepository: AtcCodesRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))
    private val professionalStatusesRepository: ProfessionalStatusesRepository by inject(named("admin"))
    private val medicalCardTypesRepository: MedicalCardTypesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        val uiState = either {
          parZip(
            {
              val medicine = medicinesRepository.getMedicineWithDocuments(medicineId).bind()
              val laboratory = laboratoryRepository.getLaboratory(medicine.laboratory).bind()

              Pair(medicine, laboratory)
            },
            { atcCodesRepository.getAtcCategoriesWithCodes().bind() },
            { medicalProfessionsRepository.getMedicalProfessions().bind() },
            { professionalStatusesRepository.getProfessionalStatuses().bind() },
            { medicalCardTypesRepository.getMedicalCardTypes().bind() }
          ) { (medicine, laboratory), atcCodes, medicalProfessions, professionalStatuses, medicalCardTypes ->
            ViewModel.UiState.Loaded(
              medicine,
              laboratory,
              atcCodes,
              medicalProfessions,
              professionalStatuses,
              medicalCardTypes
            )
          }
        }
          .fold({ ViewModel.UiState.Error }, { it })

        observableValue.setState(uiState)
      }

      observableValue
    }

    private fun reloadMedicine() {
      val currentUiState = uiState.value
      if (currentUiState is ViewModel.UiState.Loaded) {
        App.scope.launch {
          medicinesRepository.getMedicineWithDocuments(medicineId)
            .onRight {
              uiState.setState(
                currentUiState.copy(
                  medicine = it
                )
              )
            }
        }
      }
    }

    override fun deleteMedicine() {
      App.scope.launch {
        medicinesRepository.deleteMedicine(medicineId)
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Medicament supprimé")
            App.routing.navigate(Page.AdminBackOfficeMedicinesList())
          }
      }
    }

    override fun addMarketingDocument(name: MarketingDocumentName, file: KFile, segmentation: Segmentation, expireAt: Instant?) {
      App.scope.launch {
        medicinesRepository
          .addMarketingDocument(
            medicine = medicineId,
            name = name,
            segmentation = segmentation,
            document = file.toInputFile(),
            expireAt = expireAt
          )
          .onLeft {
            Toast.danger("Une erreur est survenue lors du chargement du document")
          }
          .onRight {
            Toast.success("Document correctement enregistré")
            reloadMedicine()
          }
      }
    }

    override fun updateMarketingDocument(
      marketingDocumentId: MarketingDocumentId,
      name: MarketingDocumentName,
      file: KFile?,
      segmentation: Segmentation,
      expireAt: Instant?
    ) {
      App.scope.launch {
        medicinesRepository
          .updateMarketingDocument(
            medicine = medicineId,
            id = marketingDocumentId,
            name = name,
            segmentation = segmentation,
            document = file?.toInputFile(),
            expireAt = expireAt
          )
          .onLeft {
            Toast.danger("Une erreur est survenue lors du chargement du document")
          }
          .onRight {
            Toast.success("Document correctement enregistré")
            reloadMedicine()
          }
      }
    }

    override fun deleteMarketingDocument(marketingDocumentId: MarketingDocumentId) {
      App.scope.launch {
        medicinesRepository
          .deleteMarketingDocument(medicineId, marketingDocumentId)
          .onLeft {
            Toast.danger("Une erreur est survenue lors de la supression du document")
          }
          .onRight {
            Toast.success("Document correctement supprimé")

            val currentUiState = uiState.value
            if (currentUiState is ViewModel.UiState.Loaded) {
              uiState.setState(
                currentUiState.copy(
                  medicine = currentUiState.medicine.copy(
                    marketingDocuments = currentUiState.medicine.marketingDocuments.filter { it.id != marketingDocumentId }.toSet()
                  )
                )
              )
            }
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(medicineId)

  private fun displayMarketingDocumentModal(
    uiState: ViewModel.UiState.Loaded,
    marketingDocument: MedicineModelWithDocuments.MarketingDocument?
  ) {
    LabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-medicine-marketing-document-modal",
      content = { popup ->
        h1("Document marketing")

        val marketingDocumentForm = MarketingDocumentForm(
          uiState.medicalProfessions,
          uiState.professionalStatuses.map { it.code to it.name }.toSet(),
          uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
          fileMandatory = marketingDocument == null
        ).apply {
          marketingDocument?.let {
            setData(
              MarketingDocumentForm.Data(
                name = marketingDocument.name,
                segmentation = marketingDocument.segmentation,
                expireAt = marketingDocument.expireAt?.toJSDate()
              )
            )
          }
        }

        add(marketingDocumentForm)

        labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
          onClickLaunch {
            disabled = true

            if (marketingDocumentForm.validate()) {
              val marketingDocumentFormData = marketingDocumentForm.getDataWithFileContent()

              marketingDocument?.let {
                viewModel.updateMarketingDocument(
                  marketingDocument.id,
                  marketingDocumentFormData.name!!,
                  marketingDocumentFormData.file,
                  marketingDocumentFormData.segmentation!!,
                  marketingDocumentFormData.expireAt?.toKotlinInstant()
                )
              } ?: run {
                viewModel.addMarketingDocument(
                  marketingDocumentFormData.name!!,
                  marketingDocumentFormData.file!!,
                  marketingDocumentFormData.segmentation!!,
                  marketingDocumentFormData.expireAt?.toKotlinInstant()
                )
              }

              popup.hide()
            } else
              disabled = false
          }
        }
      }).show()
  }

  init {
    id = "page-admin-medicine"
    require("./css/pages/admin/medicines/medicine.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      when (uiState) {
        is ViewModel.UiState.Loading -> labodocSpinner()

        is ViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is ViewModel.UiState.Loaded -> {
          val deleteConfirmationModal = MedicineDeleteConfirmationModal(
            name = uiState.medicine.name,
            onDeleteConfirmed = { viewModel.deleteMedicine() }
          )

          div(className = "medicine") {
            div(className = "information") {
              image(uiState.medicine.logoUrl.toString(), className = "logo")

              div(className = "data") {
                navigoLink(
                  "Laboratoire ${uiState.laboratory.name.value}",
                  Page.AdminBackOfficeLaboratorySheet(uiState.laboratory.id),
                  className = "laboratory"
                )

                p(uiState.medicine.name.value, className = "name")

                p(uiState.medicine.mainComposition.value, className = "main-composition")

                p(
                  "Code ATC : ${uiState.medicine.atcCode.value} - ${uiState.atcCodes.flatMap { it.codes }.find { uiState.medicine.atcCode == it.code }?.name?.value ?: ""}",
                  className = "atc"
                )

                p("Code CIP : ${uiState.medicine.cipCode.value}", className = "cip")

                if (uiState.medicine.website != null) {
                  link(
                    "Site internet",
                    uiState.medicine.website?.value.toString(),
                    "fa-solid fa-globe",
                    target = "_blank",
                    className = "website"
                  )
                }

                if (uiState.laboratory.pharmacovigilance != null) {
                  link(
                    "Pharmacovigilance",
                    uiState.laboratory.pharmacovigilance!!.value.toString(),
                    "fa-solid fa-warning",
                    target = "_blank",
                    className = "pharmacovigilence"
                  )
                }

                labodocSegmentation(
                  uiState.medicalProfessions,
                  null,
                  uiState.professionalStatuses.map { it.code to it.name }.toSet(),
                  uiState.medicalCardTypes.map { it.code to it.label }.toSet(),
                  null,
                  readOnly = true,
                ) {
                  label = "Segmentation "
                  data = uiState.medicine.segmentation
                }
              }
            }

            div(className = "documents-list") {
              div(className = "marketing-documents category") {
                header {
                  h4("Documents marketing")

                  button("", "fa-solid fa-folder-plus") {
                    onClick {
                      displayMarketingDocumentModal(uiState, null)
                    }
                  }
                }

                ul(className = "documents") {
                  uiState.medicine.marketingDocuments.sortedBy { it.id.value }.forEach { marketingDocument ->
                    li(className = "document") {
                      p(marketingDocument.name.value, className = "value")

                      div(className = "actions") {
                        button("", "fa-solid fa-file-arrow-down") {
                          onClick { window.open(marketingDocument.documentUrl.toString(), target = "_blank") }
                        }

                        button("", "fa-solid fa-pencil") {
                          onClick { displayMarketingDocumentModal(uiState, marketingDocument) }
                        }

                        button("", "fa-solid fa-trash") {
                          onClick { viewModel.deleteMarketingDocument(marketingDocument.id) }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          div(className = "actions") {
            navigoLink("", Page.AdminBackOfficeMedicineUpdate(uiState.medicine.id)) {
              labodocButton("Mettre à jour", className = "action edit")
            }

            labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "action delete") {
              onClick {
                deleteConfirmationModal.show()
              }
            }
          }
        }
      }
    }
  }
}

fun Container.adminMedicinePage(
  medicineId: MedicineId
): AdminMedicinePage {
  val adminMedicinePage = AdminMedicinePage(
    medicineId
  )

  this.add(adminMedicinePage)
  return adminMedicinePage
}
