package fr.labodoc.domain.healthdirectory

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.RPPSNumber
import kotlin.jvm.JvmInline

@JvmInline
value class NationalIdentifier(val value: String) {
  fun toRPPSNumber(): Either<Errors.RPPSNumber.Invalid, RPPSNumber> = either {
    ensure(value.firstOrNull() == '8') { Errors.RPPSNumber.Invalid.Format }

    RPPSNumber(value.drop(1)).bind()
  }
}
