package fr.labodoc.api.payloads.responses

import kotlinx.serialization.Serializable

@Serializable
data class PaginatedEntities<T>(val entities: List<T>, val pagination: Pagination) {
  @Serializable
  data class Pagination(val totalRecords: Long, val pageSize: Int, val self: Int) {
    val first: Int?

    val last: Int?

    val prev: Int?

    val next: Int?

    init {
      if (totalRecords > 0) {
        first = 1
        last = ((totalRecords + pageSize - 1) / pageSize).toInt()
        if (totalRecords > pageSize) {
          next = if (last > self) {
            self + 1
          } else {
            null
          }
          prev = if (first < self) {
            self - 1
          } else {
            null
          }
        } else {
          next = null
          prev = null
        }
      } else {
        next = null
        prev = null
        last = null
        first = null
      }
    }
  }
}
