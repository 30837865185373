package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.medicine.AtcCategory
import fr.labodoc.domain.labodoc.medicine.AtcCode

data class AtcCategoryModel(
  val code: AtcCategory.Code,
  val name: AtcCategory.Name,
  val codes: Set<Atc>
) {
  data class Atc(
    val code: AtcCode.Code,
    val name: AtcCode.Name
  )
}
