package fr.labodoc.webapp.pages.admin.medicalProfessions

import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.MedicalInterestModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.MedicalSpecialityModel
import fr.labodoc.app.data.admin.model.PartnershipModel
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.admin.repository.PartnershipsRepository
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestName
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LocalLabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.pages.admin.medicalProfessions.form.AdminMedicalInterestForm
import fr.labodoc.webapp.pages.admin.medicalProfessions.form.AdminMedicalProfessionForm
import fr.labodoc.webapp.pages.admin.medicalProfessions.form.AdminMedicalSpecialityForm
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.core.onClickLaunch
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMedicalProfessionsPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val medicalProfessions: Set<MedicalProfessionModel>,
        val partnerships: Set<PartnershipModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun refreshPage()
    fun createMedicalProfession(
      name: MedicalProfessionName
    )

    fun updateMedicalProfession(
      id: MedicalProfessionId,
      name: MedicalProfessionName
    )

    fun createMedicalSpeciality(
      medicalProfession: MedicalProfessionId,
      name: MedicalSpecialityName,
      partnership: PartnershipId?
    )

    fun updateMedicalSpeciality(
      id: MedicalSpecialityId,
      medicalProfession: MedicalProfessionId,
      name: MedicalSpecialityName,
      partnership: PartnershipId?
    )

    fun createMedicalInterest(
      medicalProfession: MedicalProfessionId,
      medicalSpeciality: MedicalSpecialityId,
      name: MedicalInterestName
    )

    fun updateMedicalInterest(
      id: MedicalInterestId,
      medicalProfession: MedicalProfessionId,
      medicalSpeciality: MedicalSpecialityId,
      name: MedicalInterestName
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))
    private val partnershipsRepository: PartnershipsRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      refreshPage()

      ObservableValue(ViewModel.UiState.Loading)
    }

    override fun refreshPage() {
      App.scope.launch {
        uiState.setState(ViewModel.UiState.Loading)

        val newUiState = either<Nel<ErrorResponse>, ViewModel.UiState.Loaded> {
          zipOrAccumulate(
            {
              medicalProfessionsRepository
                .getMedicalProfessions()
                .bind()
            },
            {
              partnershipsRepository
                .getPartnerships()
                .bind()
            }
          ) { medicalProfessions, partnerships ->
            ViewModel.UiState.Loaded(
              medicalProfessions = medicalProfessions,
              partnerships = partnerships
            )
          }
        }.fold(
          {
            ViewModel.UiState.Error
          },
          {
            it
          }
        )


        uiState.setState(newUiState)
      }
    }

    override fun createMedicalProfession(
      name: MedicalProfessionName
    ) {
      App.scope.launch {
        medicalProfessionsRepository
          .createMedicalProfession(
            name = name
          )
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Profession médicale correctement créée")
            refreshPage()
          }
      }
    }

    override fun updateMedicalProfession(
      id: MedicalProfessionId,
      name: MedicalProfessionName
    ) {
      App.scope.launch {
        medicalProfessionsRepository
          .updateMedicalProfession(
            id = id,
            name = name
          )
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Profession médicale correctement mise à jour")
            refreshPage()
          }
      }
    }

    override fun createMedicalSpeciality(
      medicalProfession: MedicalProfessionId,
      name: MedicalSpecialityName,
      partnership: PartnershipId?
    ) {
      App.scope.launch {
        medicalProfessionsRepository
          .createMedicalSpeciality(
            medicalProfession = medicalProfession,
            name = name,
            partnership = partnership
          )
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Spécialité médicale correctement créée")
            refreshPage()
          }
      }
    }

    override fun updateMedicalSpeciality(
      id: MedicalSpecialityId,
      medicalProfession: MedicalProfessionId,
      name: MedicalSpecialityName,
      partnership: PartnershipId?
    ) {
      App.scope.launch {
        medicalProfessionsRepository
          .updateMedicalSpeciality(
            medicalProfession = medicalProfession,
            id = id,
            name = name,
            partnership = partnership
          )
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Spécialité médicale correctement mise à jour")
            refreshPage()
          }
      }
    }

    override fun createMedicalInterest(
      medicalProfession: MedicalProfessionId,
      medicalSpeciality: MedicalSpecialityId,
      name: MedicalInterestName
    ) {
      App.scope.launch {
        medicalProfessionsRepository
          .createMedicalInterest(
            medicalProfession = medicalProfession,
            medicalSpeciality = medicalSpeciality,
            name = name
          )
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Centre d'intérêt correctement créé")
            refreshPage()
          }
      }
    }

    override fun updateMedicalInterest(
      id: MedicalInterestId,
      medicalProfession: MedicalProfessionId,
      medicalSpeciality: MedicalSpecialityId,
      name: MedicalInterestName
    ) {
      App.scope.launch {
        medicalProfessionsRepository
          .updateMedicalInterest(
            medicalProfession = medicalProfession,
            medicalSpeciality = medicalSpeciality,
            id = id,
            name = name
          )
          .onLeft {
            Toast.danger("Une erreur est survenue")
          }
          .onRight {
            Toast.success("Centre d'intérêt correctement créé")
            refreshPage()
          }
      }
    }
  }

  class MedicalProfessionRow(
    medicalProfession: MedicalProfessionModel,
    partnerships: Set<PartnershipModel>,
    onMedicalProfessionUpdateSubmit: (AdminMedicalProfessionForm.ValidatedData) -> Unit,
    onMedicalSpecialityCreateSubmit: (AdminMedicalSpecialityForm.ValidatedData) -> Unit,
    onMedicalSpecialityUpdateSubmit: (MedicalSpecialityId, AdminMedicalSpecialityForm.ValidatedData) -> Unit,
    onMedicalInterestCreateSubmit: (MedicalSpecialityId, AdminMedicalInterestForm.ValidatedData) -> Unit,
    onMedicalInterestUpdateSubmit: (MedicalSpecialityId, MedicalInterestId, AdminMedicalInterestForm.ValidatedData) -> Unit
  ) : SimplePanel(className = "medical-profession") {
    private val medicalSpecialitiesDisplayed = ObservableValue(false)

    private val updateMedicalProfessionModal = LocalLabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-medical-profession-form-modal",
      content = {
        val form = AdminMedicalProfessionForm().apply {
          setData(
            AdminMedicalProfessionForm.Data(
              name = medicalProfession.name.value
            )
          )
        }

        add(form)

        labodocButton("Sauvegarder") {
          onClickLaunch {
            if (form.validate()) {
              form.getValidatedData()
                .onLeft {
                  Toast.danger("Le formulaire est invalide")
                }
                .onRight { data ->
                  onMedicalProfessionUpdateSubmit(data)
                }
            }
          }
        }
      }
    )

    private val createMedicalSpecialityModal = LocalLabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-medical-speciality-form-modal",
      content = {
        val form = AdminMedicalSpecialityForm(
          partnershipOptions = partnerships
            .map { partnership ->
              partnership.id.value.toString() to partnership.name.value
            }
        )

        add(form)

        labodocButton("Sauvegarder") {
          onClickLaunch {
            if (form.validate()) {
              form.getValidatedData()
                .onLeft {
                  Toast.danger("Le formulaire est invalide")
                }
                .onRight { data ->
                  onMedicalSpecialityCreateSubmit(data)
                }
            }
          }
        }
      }
    )

    init {
      add(updateMedicalProfessionModal)
      add(createMedicalSpecialityModal)

      header {
        div(className = "information") {
          p(className = "name") {
            content = medicalProfession.name.value
          }

          p(className = "number") {
            content = medicalProfession.medicalSpecialities.size.toString() + " spécialité(s)"
            icon("fa-solid fa-caret-up").bind(medicalSpecialitiesDisplayed) {
              icon = if (it)
                "fa-solid fa-caret-down"
              else
                "fa-solid fa-caret-up"
            }

            onClick {
              medicalSpecialitiesDisplayed.value = !medicalSpecialitiesDisplayed.value
            }
          }
        }

        div(className = "actions") {
          labodocButton("Ajouter une spécialité", icon = "fa-solid fa-plus", className = "edit") {
            onClick {
              createMedicalSpecialityModal.show()
            }
          }
          labodocButton("Mettre à jour", icon = "fa-solid fa-pencil", className = "edit") {
            onClick {
              updateMedicalProfessionModal.show()
            }
          }
        }
      }

      div(className = "medical-specialities") {
        medicalSpecialitiesDisplayed.subscribe {
          if (it)
            show()
          else
            hide()
        }

        medicalProfession.medicalSpecialities.forEach { medicalSpeciality ->
          add(
            MedicalSpecialityRow(
              medicalSpeciality = medicalSpeciality,
              partnerships = partnerships,
              onMedicalSpecialityUpdateSubmit = { data ->
                onMedicalSpecialityUpdateSubmit(medicalSpeciality.id, data)
              },
              onMedicalInterestCreateSubmit = { data ->
                onMedicalInterestCreateSubmit(medicalSpeciality.id, data)
              },
              onMedicalInterestUpdateSubmit = { id, data ->
                onMedicalInterestUpdateSubmit(medicalSpeciality.id, id, data)
              }
            )
          )
        }
      }
    }
  }

  class MedicalSpecialityRow(
    medicalSpeciality: MedicalSpecialityModel,
    partnerships: Set<PartnershipModel>,
    onMedicalSpecialityUpdateSubmit: (AdminMedicalSpecialityForm.ValidatedData) -> Unit,
    onMedicalInterestCreateSubmit: (AdminMedicalInterestForm.ValidatedData) -> Unit,
    onMedicalInterestUpdateSubmit: (MedicalInterestId, AdminMedicalInterestForm.ValidatedData) -> Unit
  ) : SimplePanel("medical-speciality") {
    private val medicalInterestsDisplayed = ObservableValue(false)

    private val updateMedicalSpecialityModal = LocalLabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-medical-speciality-form-modal",
      content = {
        val form = AdminMedicalSpecialityForm(
          partnershipOptions = partnerships
            .map { partnership ->
              partnership.id.value.toString() to partnership.name.value
            }
        ).apply {
          setData(
            AdminMedicalSpecialityForm.Data(
              name = medicalSpeciality.name.value,
              partnership = medicalSpeciality.partnership?.value?.toString() ?: "none"
            )
          )
        }

        add(form)

        labodocButton("Sauvegarder") {
          onClickLaunch {
            if (form.validate()) {
              form.getValidatedData()
                .onLeft {
                  Toast.danger("Le formulaire est invalide")
                }
                .onRight { data ->
                  onMedicalSpecialityUpdateSubmit(data)
                }
            }
          }
        }
      }
    )

    private val createMedicalInterestModal = LocalLabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-medical-interest-form-modal",
      content = {
        val form = AdminMedicalInterestForm()

        add(form)

        labodocButton("Sauvegarder") {
          onClickLaunch {
            if (form.validate()) {
              form.getValidatedData()
                .onLeft {
                  Toast.danger("Le formulaire est invalide")
                }
                .onRight { data ->
                  onMedicalInterestCreateSubmit(data)
                }
            }
          }
        }
      }
    )

    init {
      add(updateMedicalSpecialityModal)
      add(createMedicalInterestModal)

      header {
        div(className = "information") {
          p(className = "name") {
            content = medicalSpeciality.name.value
          }

          p(className = "number") {
            content = medicalSpeciality.medicalInterests.size.toString() + " centre(s) d'intéret(s)"
            icon("fa-solid fa-caret-up").bind(medicalInterestsDisplayed) {
              icon = if (it)
                "fa-solid fa-caret-down"
              else
                "fa-solid fa-caret-up"
            }

            onClick {
              medicalInterestsDisplayed.value = !medicalInterestsDisplayed.value
            }
          }
        }

        div(className = "actions") {
          labodocButton("Ajouter une centre d'intéret", icon = "fa-solid fa-plus") {
            onClick {
              createMedicalInterestModal.show()
            }
          }

          labodocButton("Mettre à jour", icon = "fa-solid fa-pencil") {
            onClick {
              updateMedicalSpecialityModal.show()
            }
          }
        }
      }

      div(className = "medical-interests") {
        medicalInterestsDisplayed.subscribe {
          if (it)
            show()
          else
            hide()
        }

        medicalSpeciality.medicalInterests.forEach { medicalInterest ->
          add(
            MedicalInterestRow(
              medicalInterest = medicalInterest,
              onMedicalInterestUpdateSubmit = { data ->
                onMedicalInterestUpdateSubmit(medicalInterest.id, data)
              }
            )
          )
        }
      }
    }
  }

  class MedicalInterestRow(
    medicalInterest: MedicalInterestModel,
    onMedicalInterestUpdateSubmit: (AdminMedicalInterestForm.ValidatedData) -> Unit
  ) : SimplePanel("medical-interest") {
    private val updateMedicalInterestModal = LocalLabodocPopup(
      closeButton = true,
      icon = null,
      image = null,
      className = "admin-medical-interest-form-modal",
      content = {
        val form = AdminMedicalInterestForm().apply {
          setData(
            AdminMedicalInterestForm.Data(
              name = medicalInterest.name.value
            )
          )
        }

        add(form)

        labodocButton("Sauvegarder") {
          onClickLaunch {
            if (form.validate()) {
              form.getValidatedData()
                .onLeft {
                  Toast.danger("Le formulaire est invalide")
                }
                .onRight { data ->
                  onMedicalInterestUpdateSubmit(data)
                }
            }
          }
        }
      }
    )

    init {
      add(updateMedicalInterestModal)

      header {
        div(className = "information") {
          p(className = "name") {
            content = medicalInterest.name.value
          }
        }

        div(className = "actions") {
          labodocButton("Mettre à jour", icon = "fa-solid fa-pencil") {
            onClick {
              updateMedicalInterestModal.show()
            }
          }
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-medical-professions"
    require("./css/pages/admin/medicalProfessions/medical-professions.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      val createMedicalProfessionModal = LocalLabodocPopup(
        closeButton = true,
        icon = null,
        image = null,
        className = "admin-medical-profession-form-modal",
        content = {
          val form = AdminMedicalProfessionForm()

          add(form)

          labodocButton("Sauvegarder") {
            onClickLaunch {
              if (form.validate()) {
                form.getValidatedData()
                  .onLeft {
                    Toast.danger("Le formulaire est invalide")
                  }
                  .onRight { data ->
                    viewModel.createMedicalProfession(
                      name = data.name
                    )
                  }
              }
            }
          }
        }
      )

      add(createMedicalProfessionModal)

      header {
        h1("Liste des professions Labodoc")

        labodocButton("Ajouter une profession Labodoc", icon = "fa-solid fa-plus") {
          onClick {
            createMedicalProfessionModal.show()
          }
        }
      }

      when (uiState) {
        ViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        ViewModel.UiState.Error -> {
          p("Impossible de récupérer la liste des professions")
        }

        is ViewModel.UiState.Loaded -> {
          div(className = "medical-professions") {
            uiState.medicalProfessions.forEach { medicalProfession ->
              add(
                MedicalProfessionRow(
                  medicalProfession = medicalProfession,
                  partnerships = uiState.partnerships,
                  onMedicalProfessionUpdateSubmit = { data ->
                    viewModel.updateMedicalProfession(
                      id = medicalProfession.id,
                      name = data.name
                    )
                  },
                  onMedicalSpecialityCreateSubmit = { data ->
                    viewModel.createMedicalSpeciality(
                      medicalProfession = medicalProfession.id,
                      name = data.name,
                      partnership = data.partnership
                    )
                  },
                  onMedicalSpecialityUpdateSubmit = { id, data ->
                    viewModel.updateMedicalSpeciality(
                      id = id,
                      medicalProfession = medicalProfession.id,
                      name = data.name,
                      partnership = data.partnership
                    )
                  },
                  onMedicalInterestCreateSubmit = { medicalSpecialityId, data ->
                    viewModel.createMedicalInterest(
                      medicalProfession = medicalProfession.id,
                      medicalSpeciality = medicalSpecialityId,
                      name = data.name
                    )
                  },
                  onMedicalInterestUpdateSubmit = { medicalSpecialityId, id, data ->
                    viewModel.updateMedicalInterest(
                      id = id,
                      medicalProfession = medicalProfession.id,
                      medicalSpeciality = medicalSpecialityId,
                      name = data.name
                    )
                  }
                )
              )
            }
          }
        }
      }
    }
  }
}

fun Container.adminMedicalProfessionsPage(): AdminMedicalProfessionsPage {
  val adminMedicalProfessionsPage = AdminMedicalProfessionsPage()

  this.add(adminMedicalProfessionsPage)
  return adminMedicalProfessionsPage
}
