package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.app.data.healthprofessional.model.AtcCategoryModel

class AtcCodesRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : AtcCodesRepository {
  override suspend fun getAtcCategoriesWithCodes(
  ): ApiResponse<Set<AtcCategoryModel>> =
    apiClient.atcCodes
      .getAtcCodes()
      .map { atcCategories ->
        atcCategories
          .map { atcCategory ->
            AtcCategoryModel(
              code = atcCategory.code,
              name = atcCategory.name,
              codes = atcCategory.codes
                .map { atc ->
                  AtcCategoryModel.Atc(
                    code = atc.code,
                    name = atc.name
                  )
                }
                .toSet()
            )
          }
          .toSet()
      }

  override suspend fun getAtcCategoriesWithCodesInUsage(
  ): ApiResponse<Set<AtcCategoryModel>> =
    apiClient.atcCodes
      .getAtcCodesInUsage()
      .map { atcCategories ->
        atcCategories
          .map { atcCategory ->
            AtcCategoryModel(
              code = atcCategory.code,
              name = atcCategory.name,
              codes = atcCategory.codes
                .map { atc ->
                  AtcCategoryModel.Atc(
                    code = atc.code,
                    name = atc.name
                  )
                }
                .toSet()
            )
          }
          .toSet()
      }
}
