package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.LaboratoryIdAsString
import fr.labodoc.api.payloads.serializers.LaboratoryNameAsString
import fr.labodoc.api.payloads.serializers.LaboratoryWebsiteAsString
import fr.labodoc.api.payloads.serializers.UrlAsString
import kotlinx.serialization.Serializable

@Serializable
data class LaboratoriesResponse(
  val laboratories: List<Laboratory>
) {
  @Serializable
  data class Laboratory(
    val id: LaboratoryIdAsString,
    val name: LaboratoryNameAsString,
    val logoUrl: UrlAsString,
    val website: LaboratoryWebsiteAsString,
    val favorite: Boolean
  )
}
