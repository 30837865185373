package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
sealed class MessageSummaryResponse {
  @Serializable
  data class Laboratory(
    val id: LaboratoryIdAsString,
    val name: LaboratoryNameAsString
  )

  @Serializable
  data class LearnedSociety(
    val id: LearnedSocietyIdAsString,
    val name: LearnedSocietyNameAsString,
    val acronym: LearnedSocietyAcronymAsString?
  )

  abstract val id: MessageIdAsString
  abstract val publishedAt: Instant
  abstract val title: MessageTitleAsString
  abstract val seen: Boolean
  abstract val favorite: Boolean
}

@Serializable
data class LabodocFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()

@Serializable
data class LaboratoryFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()

@Serializable
data class LaboratoryInvitationSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()

@Serializable
data class MedicineFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()

@Serializable
data class MedicineInvitationSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()

@Serializable
data class LearnedSocietyFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()

@Serializable
data class LearnedSocietyInvitationSummaryResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageSummaryResponse()
