package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.medicine.*
import io.ktor.http.*

data class MedicineSummaryModel(
  val id: MedicineId,
  val cipCode: CipCode,
  val atcCode: AtcCode.Code,
  val name: MedicineName,
  val mainComposition: MedicineMainComposition,
  val logoUrl: Url,
  val activated: Boolean
)
