package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.HealthProfessionalMedicinesSummaryResponse
import fr.labodoc.app.data.healthprofessional.model.AtcCodeModel
import fr.labodoc.app.data.healthprofessional.model.MedicineModel
import fr.labodoc.app.data.healthprofessional.model.MedicineSummaryModel
import fr.labodoc.domain.labodoc.medicine.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId

class MedicineRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : MedicinesRepository {
  override suspend fun getMedicinesForSpeciality(): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getSegmentedMedicines()
      .map { response ->
        response.medicines
          .map { medicine: HealthProfessionalMedicinesSummaryResponse.Medicine ->
            MedicineSummaryModel(
              id = medicine.id,
              laboratory = MedicineSummaryModel.Laboratory(
                id = medicine.laboratory.id,
                name = medicine.laboratory.name
              ),
              name = medicine.name,
              mainComposition = medicine.mainComposition,
              logoUrl = medicine.logoUrl,
              atcCode = medicine.atcCode,
              favorite = medicine.favorite,
              unreadDocuments = medicine.unreadDocuments
            )
          }
          .toSet()
      }

  override suspend fun getMedicines(): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getMedicines()
      .map { response ->
        response.medicines
          .map { medicine: HealthProfessionalMedicinesSummaryResponse.Medicine ->
            MedicineSummaryModel(
              id = medicine.id,
              laboratory = MedicineSummaryModel.Laboratory(
                id = medicine.laboratory.id,
                name = medicine.laboratory.name
              ),
              name = medicine.name,
              mainComposition = medicine.mainComposition,
              logoUrl = medicine.logoUrl,
              atcCode = medicine.atcCode,
              favorite = medicine.favorite,
              unreadDocuments = medicine.unreadDocuments
            )
          }
          .toSet()
      }

  override suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<MedicineModel> =
    either {
      parZip(
        {
          apiClient.medicines.getMedicine(id).bind()
        },
        {
          apiClient.medicines.getMarketingDocuments(id).bind()
        }
      ) { medicineResponse, marketingDocumentsResponse ->
        val medicine = medicineResponse.medicine
        val marketingDocuments = marketingDocumentsResponse.marketingDocuments

        MedicineModel(
          id = medicine.id,
          cipCode = medicine.cipCode,
          laboratory = MedicineModel.Laboratory(
            id = medicine.laboratory.id,
            name = medicine.laboratory.name,
            website = medicine.laboratory.website,
            pharmacovigilance = medicine.laboratory.pharmacovigilance
          ),
          atc = AtcCodeModel(
            code = medicine.atcCode.code,
            name = medicine.atcCode.name
          ),
          governmentPublicDatabaseId = medicine.governmentPublicDatabaseId,
          name = medicine.name,
          mainComposition = medicine.mainComposition,
          logoUrl = medicine.logoURL,
          website = medicine.website,
          marketingDocuments = marketingDocuments
            .map { marketingDocument ->
              MedicineModel.MarketingDocument(
                id = marketingDocument.id,
                name = marketingDocument.name,
                url = marketingDocument.documentUrl,
                seenAt = marketingDocument.seenAt,
                seenLatestVersion = marketingDocument.seenLatestVersion
              )
            }
            .toSet(),
          favorite = medicine.favorite
        )
      }
    }

  override suspend fun tagDocumentAsSeen(id: MarketingDocumentId, forMedicine: MedicineId): ApiResponse<Unit> =
    apiClient.medicines
      .getMarketingDocument(id, forMedicine)
      .map {  }

  override suspend fun favoriteMedicine(id: MedicineId): ApiResponse<Unit> =
    apiClient.medicines
      .favoriteMedicine(id)

  override suspend fun unfavoriteMedicine(id: MedicineId): ApiResponse<Unit> =
    apiClient.medicines
      .unfavoriteMedicine(id)
}
