package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.LaboratoryIdAsString
import fr.labodoc.api.payloads.serializers.MarketingDocumentIdAsString
import fr.labodoc.api.payloads.serializers.MedicineIdAsString
import io.ktor.resources.*

@Resource("medicines")
class Medicines(
  val forLaboratory: LaboratoryIdAsString? = null
) {
  @Resource("view")
  class ViewList(val filter: String? = null, val sort: String? = null, val medicines: Medicines = Medicines())

  @Resource("usage")
  class UsageList(val filter: String? = null, val sort: String? = null, val medicines: Medicines = Medicines())

  @Resource("{medicineId}")
  class Medicine(val medicineId: MedicineIdAsString, val medicines: Medicines = Medicines()) {
    @Resource("activate")
    class Activate(val medicine: Medicine) {
      constructor(medicineId: MedicineIdAsString) : this(Medicine(medicineId))
    }

    @Resource("deactivate")
    class Deactivate(val medicine: Medicine) {
      constructor(medicineId: MedicineIdAsString) : this(Medicine(medicineId))
    }

    @Resource("favorite")
    class Favorite(val medicine: Medicine) {
      constructor(medicineId: MedicineIdAsString) : this(Medicine(medicineId))
    }

    @Resource("marketing-documents")
    class MarketingDocuments(val medicine: Medicine) {
      constructor(medicineId: MedicineIdAsString) : this(Medicine(medicineId))

      @Resource("{marketingDocumentId")
      class MarketingDocument(val marketingDocumentId: MarketingDocumentIdAsString, val marketingDocuments: MarketingDocuments) {
        constructor(medicineId: MedicineIdAsString, marketingDocumentId: MarketingDocumentIdAsString): this(marketingDocumentId, MarketingDocuments(medicineId))
      }
    }

    @Resource("messages")
    class Messages(val medicine: Medicine) {
      constructor(medicineId: MedicineIdAsString) : this(Medicine(medicineId))
    }
  }
}
