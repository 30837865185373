package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import io.ktor.http.*

data class LaboratoryModel(
  val id: LaboratoryId,
  val name: LaboratoryName,
  val website: LaboratoryWebsite,
  val pharmacovigilance: LaboratoryPharmacovigilance?,
  val logoUrl: Url?,
  val activated: Boolean
)
