package fr.labodoc.domain.healthdirectory

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class MedicalCardNumber private constructor(val value: String) {
  companion object {

    fun isFormatValid(value: String): Boolean = value.length == 10 && value.all { it.isDigit() }

    operator fun invoke(value: String): Either<Errors.MedicalCard.Number.Invalid, MedicalCardNumber> = either {
      ensure(isFormatValid(value)) { Errors.MedicalCard.Number.Invalid.Format }

      MedicalCardNumber(value)
    }
  }
}
