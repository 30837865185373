package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.api.payloads.serializers.MarketingDocumentIdAsString
import fr.labodoc.api.resources.Medicines
import fr.labodoc.api.routes.AdminMedicinesRoutes
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.CisCode
import fr.labodoc.domain.labodoc.medicine.MedicineId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.serialization.json.Json

class AdminMedicinesRemoteDataSource(
  private val httpClient: HttpClient
) : AdminMedicinesRoutes {
  override suspend fun getMedicines(
    forLaboratory: LaboratoryId?
  ): ApiResponse<AdminMedicinesSummaryResponse> {
    val response = httpClient.get(Medicines(forLaboratory))

    return if (response.status.isSuccess())
      response.body<AdminMedicinesSummaryResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<AdminMedicineResponse> {
    val response = httpClient.get(Medicines.Medicine(id))

    return if (response.status.isSuccess())
      response.body<AdminMedicineResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createMedicine(
    payload: CreateMedicineRequest,
    logo: InputFile?
  ): ApiResponse<MedicineCreatedResponse> {
    val response = httpClient.post(Medicines()) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          logo?.let {
            append("logo", logo.content, Headers.build {
              append(HttpHeaders.ContentType, logo.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      response.body<MedicineCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMedicine(
    id: MedicineId,
    payload: UpdateMedicineRequest,
    logo: InputFile?
  ): ApiResponse<Unit> {
    val response = httpClient.put(Medicines.Medicine(id)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          logo?.let {
            append("logo", logo.content, Headers.build {
              append(HttpHeaders.ContentType, logo.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteMedicine(
    id: MedicineId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Medicines.Medicine(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMarketingDocuments(
    forMedicine: MedicineId
  ): ApiResponse<AdminMarketingDocumentsResponse> {
    val response = httpClient.get(Medicines.Medicine.MarketingDocuments(forMedicine))

    return if (response.status.isSuccess())
      response.body<AdminMarketingDocumentsResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun addMarketingDocument(
    forMedicine: MedicineId,
    payload: CreateMarketingDocumentRequest,
    document: InputFile
  ): ApiResponse<MarketingDocumentCreatedResponse> {
    val response = httpClient.post(Medicines.Medicine.MarketingDocuments(forMedicine)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })

          append("document", document.content, Headers.build {
            append(HttpHeaders.ContentType, document.mime)
            append(HttpHeaders.ContentDisposition, "filename=\"${document.name}\"")
          })
        }
      ))
    }

    return if (response.status.isSuccess())
      response.body<MarketingDocumentCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMarketingDocument(
    forMedicine: MedicineId,
    id: MarketingDocumentId
  ): ApiResponse<AdminMarketingDocumentResponse> {
    val response = httpClient.get(Medicines.Medicine.MarketingDocuments.MarketingDocument(forMedicine, id))

    return if (response.status.isSuccess())
      response.body<AdminMarketingDocumentResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMarketingDocument(
    forMedicine: MedicineId,
    id: MarketingDocumentIdAsString,
    payload: UpdateMarketingDocumentRequest,
    document: InputFile?
  ): ApiResponse<Unit> {
    val response = httpClient.put(Medicines.Medicine.MarketingDocuments.MarketingDocument(forMedicine, id)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })

          document?.let {
            append("document", document.content, Headers.build {
              append(HttpHeaders.ContentType, document.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${document.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteMarketingDocument(
    forMedicine: MedicineId,
    id: MarketingDocumentId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Medicines.Medicine.MarketingDocuments.MarketingDocument(forMedicine, id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateDosages(
    forMedicine: MedicineId,
    payload: UpdateDosagesRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(Medicines.Medicine.Dosages(forMedicine)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun addDosage(
    forMedicine: MedicineId,
    payload: CreateDosageRequest
  ): ApiResponse<Unit> {
    val response = httpClient.post(Medicines.Medicine.Dosages(forMedicine)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteDosage(
    forMedicine: MedicineId,
    cisCode: CisCode
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Medicines.Medicine.Dosages.Dosage(forMedicine, cisCode))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun activate(id: MedicineId): ApiResponse<Unit> {
    val response = httpClient.post(Medicines.Medicine.Activate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deactivate(
    id: MedicineId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Medicines.Medicine.Deactivate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
