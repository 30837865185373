package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.MedicalProfessionResponse
import fr.labodoc.api.resources.MedicalProfessions
import fr.labodoc.api.routes.HealthProfessionalMedicalProfessionsRoutes
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalMedicalProfessionsRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalMedicalProfessionsRoutes {
  override suspend fun getMedicalProfessions(
  ): ApiResponse<Set<MedicalProfessionResponse>> {
    val response = httpClient.get(MedicalProfessions())

    return if (response.status.isSuccess())
      response.body<Set<MedicalProfessionResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMedicalProfession(
    id: MedicalProfessionId
  ): ApiResponse<MedicalProfessionResponse> {
    val response = httpClient.get(MedicalProfessions.MedicalProfession(id))

    return if (response.status.isSuccess())
      response.body<MedicalProfessionResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
