package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.AtcCode
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object AtcCodeAsStringSerializer : KSerializer<AtcCode.Code> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(code: String): Either<Errors.ATC.Code.Invalid, AtcCode.Code> =
    AtcCode.Code(code)

  override fun deserialize(decoder: Decoder): AtcCode.Code =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(code: AtcCode.Code): String =
    code.value

  override fun serialize(encoder: Encoder, value: AtcCode.Code) =
    encoder.encodeString(serialize(value))
}

typealias AtcCodeAsString = @Serializable(AtcCodeAsStringSerializer::class) AtcCode.Code

object AtcNameAsStringSerializer : KSerializer<AtcCode.Name> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.ATC.Label.Invalid, AtcCode.Name> =
    AtcCode.Name(name)

  override fun deserialize(decoder: Decoder): AtcCode.Name =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(name: AtcCode.Name): String =
    name.value

  override fun serialize(encoder: Encoder, value: AtcCode.Name) =
    encoder.encodeString(serialize(value))
}

typealias AtcNameAsString = @Serializable(AtcNameAsStringSerializer::class) AtcCode.Name
