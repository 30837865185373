package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.CisCode
import fr.labodoc.domain.labodoc.medicine.DosageName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import io.ktor.http.*

data class MedicineModel(
  val id: MedicineId,
  val laboratory: LaboratoryId?,
  val name: MedicineName,
  val atcClassification: AtcClassificationModel,
  val logoUrl: Url,
  val segmentation: Segmentation,
  val dosages: Set<Dosage>,
  val activated: Boolean
) {
  data class Dosage(
    val cisCode: CisCode,
    val name: DosageName
  )
}
