package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicalInterestIdAsStringSerializer : KSerializer<MedicalInterestId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalInterestIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.MedicalInterest.Id.Invalid, MedicalInterestId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.MedicalInterest.Id.Invalid.Format }
      .map { MedicalInterestId(it) }

  override fun deserialize(decoder: Decoder): MedicalInterestId =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalInterestId: MedicalInterestId): String =
    UuidAsStringSerializer.serialize(medicalInterestId.value)

  override fun serialize(encoder: Encoder, value: MedicalInterestId) =
    encoder.encodeString(serialize(value))
}

typealias MedicalInterestIdAsString = @Serializable(MedicalInterestIdAsStringSerializer::class) MedicalInterestId

object MedicalInterestNameAsStringSerializer : KSerializer<MedicalInterestName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicalInterestNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Nothing, MedicalInterestName> =
    MedicalInterestName(name).right()

  override fun deserialize(decoder: Decoder): MedicalInterestName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(medicalInterestName: MedicalInterestName): String =
    medicalInterestName.value

  override fun serialize(encoder: Encoder, value: MedicalInterestName) =
    encoder.encodeString(serialize(value))
}

typealias MedicalInterestNameAsString = @Serializable(MedicalInterestNameAsStringSerializer::class) MedicalInterestName
