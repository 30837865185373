package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateMedicalDiplomaRequest
import fr.labodoc.api.payloads.requests.UpdateMedicalDiplomaRequest
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.MedicalDiplomaIdResponse
import fr.labodoc.api.payloads.responses.MedicalDiplomaResponse
import fr.labodoc.api.resources.MedicalDiplomas
import fr.labodoc.api.routes.AdminMedicalDiplomasRoutes
import fr.labodoc.api.routes.HealthProfessionalMedicalDiplomasRoutes
import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminMedicalDiplomasRemoteDataSource(
  private val httpClient: HttpClient
) : AdminMedicalDiplomasRoutes {
  override suspend fun getMedicalDiplomas(
    forHealthDirectoryProfessionCode: ProfessionCode?
  ): ApiResponse<Set<MedicalDiplomaResponse>> {
    val response = httpClient.get(MedicalDiplomas(forHealthDirectoryProfessionCode))

    return if (response.status.isSuccess())
      response.body<Set<MedicalDiplomaResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createMedicalDiploma(
    payload: CreateMedicalDiplomaRequest
  ): ApiResponse<MedicalDiplomaIdResponse> {
    val response = httpClient.post(MedicalDiplomas()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<MedicalDiplomaIdResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMedicalDiploma(
    id: MedicalDiplomaId,
    payload: UpdateMedicalDiplomaRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(MedicalDiplomas.MedicalDiploma(id)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteMedicalDiploma(
    id: MedicalDiplomaId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(MedicalDiplomas.MedicalDiploma(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
