package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.form.check.RadioGroup

class LabodocRadioGroup(
  options: List<StringPair>? = null, value: String? = null, name: String? = null, inline: Boolean = false,
  label: String? = null,
  rich: Boolean = false,
  init: (LabodocRadioGroup.() -> Unit)? = null
) : RadioGroup(
  options, value, name, inline,
  label,
  rich
) {
  init {
    require("./css/components/labodoc-radio-group.css")

    addCssClass("labodoc-radio-group")

    init?.invoke(this)
  }
}

fun Container.labodocRadioGroup(
  options: List<StringPair>? = null, value: String? = null, name: String? = null, inline: Boolean = false,
  label: String? = null, rich: Boolean = false, init: (LabodocRadioGroup.() -> Unit)? = null
): LabodocRadioGroup {
  val labodocRadioGroup = LabodocRadioGroup(options, value, name, inline, label, rich, init)
  this.add(labodocRadioGroup)
  return labodocRadioGroup
}
