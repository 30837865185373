package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class MedicalCardCPSRegistrationRequest(
  val rppsNumber: RPPSNumberAsString?,
  val medicalCardNumber: MedicalCardNumberAsString,
  val firstName: FirstNameAsString,
  val lastName: LastNameAsString,
  val professionCode: ProfessionCodeAsString,
  val professionalCategoryCode: ProfessionalCategoryCodeAsString,
  val professionalStatusCode: ProfessionalStatusCodeAsString,
  val expertiseCode: ExpertiseCodeAsString?,
  val pharmacistSectionCode: PharmacistTableSectionCodeAsString?,
  val departmentCode: DepartmentCodeAsString,
  val dailyNotificationEnabled: Boolean,
  val weeklyNotificationEnabled: Boolean,
  val email: EmailAddressAsString,
  val password: PasswordAsString,
  val partnerCode: PartnerCodeAsString?
)
