package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.MarketingDocumentIdAsString
import kotlinx.serialization.Serializable

@Serializable
data class MarketingDocumentCreatedResponse(
  val id: MarketingDocumentIdAsString
)


