package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.app.data.healthprofessional.model.LaboratoryModel
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId

class LaboratoriesRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : LaboratoriesRepository {
  override suspend fun getLaboratories(
  ): ApiResponse<Set<LaboratoryModel>> =
    apiClient.laboratories
      .getLaboratories()
      .map { response ->
        response.laboratories
          .map { laboratory ->
            LaboratoryModel(
              id = laboratory.id,
              name = laboratory.name,
              website = laboratory.website,
              logoUrl = laboratory.logoUrl,
              favorite = laboratory.favorite
            )
          }
          .toSet()
      }

  override suspend fun favorite(
    id: LaboratoryId
  ): ApiResponse<Unit> =
    apiClient.laboratories
      .favoriteLaboratory(id)

  override suspend fun unfavorite(
    id: LaboratoryId
  ): ApiResponse<Unit> =
    apiClient.laboratories
      .unfavoriteLaboratory(id)
}
