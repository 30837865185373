package fr.labodoc.webapp.pages.admin.message

import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.*
import fr.labodoc.app.data.admin.repository.MessagesRepository
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.app.utils.datetimeFormat
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMessagesPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val messages: Set<MessageSummaryModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun deleteMessage(id: MessageId, deleteButton: Button)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val messageRepository: MessagesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> =
        ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        messageRepository.getMessages()
          .fold(
            { ViewModel.UiState.Error },
            { ViewModel.UiState.Loaded(it) }
          )
          .also { uiState.setState(it) }
      }

      observableValue
    }

    override fun deleteMessage(id: MessageId, deleteButton: Button) {
      App.scope.launch {
        deleteButton.disabled = true

        messageRepository.deleteMessage(id)
          .onLeft { errorResponse: ErrorResponse ->
            val errorMessage: String = when (errorResponse.code) {
              "Message.NotFound" -> "Ce médicament n'existe pas"
              else -> "Une erreur est survenue"
            }

            Toast.danger(errorMessage)
          }
          .onRight {
            val currentUiState = uiState.getState()
            if (currentUiState is ViewModel.UiState.Loaded)
              uiState.setState(currentUiState.copy(messages = currentUiState.messages.filter { it.id != id }.toSet()))
          }

        deleteButton.disabled = false
      }
    }
  }

  private class MessageRow(
    private val message: MessageSummaryModel,
    onDeleteClick: (Button) -> Unit,
  ) : SimplePanel(className = "message labodoc-background-light-blue"), KoinComponent {
    private val messagesRepository: MessagesRepository by inject(named("admin"))

    private fun showMessage() {
      App.scope.launch {
        messagesRepository
          .getMessage(message.id)
          .onRight {
            MessageModal(it).show()
          }
      }
    }

    init {
      div(className = "details") {
        val type = when (message) {
          is LabodocFlashInfoSummaryModel -> "Flash Info Labodoc"
          is LaboratoryFlashInfoSummaryModel -> "Flash Info Laboratoire"
          is LaboratoryInvitationSummaryModel -> "Invitation Laboratoire"
          is MedicineFlashInfoSummaryModel -> "Flash Info Médicament"
          is MedicineInvitationSummaryModel -> "Invitation Médicament"
          is LearnedSocietyFlashInfoSummaryModel -> "Flash Info Société Savante"
          is LearnedSocietyInvitationSummaryModel -> "Invitation Société Savante"
        }
        p(type, className = "type")

        p(message.title.value, className = "title")

        p(className = "author") {
        when (message) {
          is LabodocMessageSummaryModel -> {
           content = "LaboDoc"
          }

          is LaboratoryMessageSummaryModel -> {
            navigoLink(
              message.laboratory.name.value,
              Page.AdminBackOfficeLaboratorySheet(message.laboratory.id)
            )
          }

          is MedicineMessageSummaryModel -> {
            navigoLink(
              message.medicine.name.value,
              Page.AdminBackOfficeMedicineSheet(message.medicine.id)
            )
          }

          is LearnedSocietyMessageSummaryModel -> {
            navigoLink(
              message.learnedSociety.name.value,
              Page.AdminBackOfficeLearnedSocietiesList()
            )
          }
        }
      }

        p(message.publishedAt.datetimeFormat, className = "published-at")
      }

      div(className = "actions") {
        button("", icon = "fa-solid fa-eye", className = "action read") {
          onClick {
            showMessage()
          }
        }

        navigoLink(
          "",
          Page.AdminBackOfficeMessageUpdate(message.id),
          icon = "fa-solid fa-solid fa-pencil",
          className = "action edit"
        )

        button("", "fa-solid fa-trash", className = "action delete") {
          onClick { onDeleteClick(this@button) }
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-messages"
    require("./css/pages/admin/message/messages.css")

    div(className = "page-width") {
      header {
        h1("Liste des messages")

        navigoLink("", Page.AdminBackOfficeMessageCreate()) {
          labodocButton("Ajouter un message", "fa-solid fa-plus")
        }
      }

      div(className = "messages").bind(viewModel.uiState) { uiState ->
        header {
          p("Type")
          p("Titre")
          p("Auteur")
          p("Publié le")
          p("Actions")
        }
        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          ViewModel.UiState.Error -> Toast.danger("Impossible de récupérer la liste des médicaments")

          is ViewModel.UiState.Loaded -> uiState.messages.forEach { message ->
            add(MessageRow(message) { deleteButton -> viewModel.deleteMessage(message.id, deleteButton) })
          }
        }
      }
    }
  }
}

fun Container.adminMessagesPage(): AdminMessagesPage {
  val adminMessagesPage = AdminMessagesPage()

  this.add(adminMessagesPage)
  return adminMessagesPage
}
