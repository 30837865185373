package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.FirstName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object FirstNameAsStringSerializer : KSerializer<FirstName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("FirstNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(firstName: String): Either<Errors.FirstName.Invalid, FirstName> = FirstName(firstName)

  override fun deserialize(decoder: Decoder): FirstName {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(firstName: FirstName): String = firstName.value

  override fun serialize(encoder: Encoder, value: FirstName) {
    return encoder.encodeString(serialize(value))
  }
}

typealias FirstNameAsString = @Serializable(FirstNameAsStringSerializer::class) FirstName
