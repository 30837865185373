package fr.labodoc.webapp.layouts

import fr.labodoc.require
import fr.labodoc.webapp.App.Companion.routing
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.navigoLink
import io.kvision.core.Container
import io.kvision.html.Nav
import io.kvision.html.div
import io.kvision.panel.SimplePanel

class AdminLayout(init: (AdminLayout.() -> Unit)? = null) : SimplePanel() {
  class Navigation : Nav() {
    init {
      val currentRoute = routing.current!!.first().route.name

      div(className = "page-width") {
        val usersApplicationsLink = navigoLink("Utilisateurs", Page.AdminBackOfficeUsersAdministration().url)
        val laboratoriesLink = navigoLink("Laboratoires", Page.AdminBackOfficeLaboratoriesList().url)
        val medicinesLink = navigoLink("Médicaments", Page.AdminBackOfficeMedicinesList().url)
        val messagesLinks = navigoLink("Messages", Page.AdminBackOfficeMessagesList().url)
        val learnedSocietiesLink = navigoLink("Sociétés savantes", Page.AdminBackOfficeLearnedSocietiesList().url)
        val partnersLink = navigoLink("Partenaires", Page.AdminBackOfficePartnerList().url)
        val partnershipsLink = navigoLink("Partenariats", Page.AdminBackOfficePartnershipList().url)
        val medicalProfessionLink = navigoLink("Professions médicales", Page.AdminBackOfficeMedicalProfessionList().url)
        val universityHospitalsLink = navigoLink("CHU", Page.AdminBackOfficeUniversityHospitalsList().url)
        val medicalDiplomasLink = navigoLink("Dîplomes médicaux", Page.AdminBackOfficeMedicalDiplomasList().url)
        val healthDirectoryLink = navigoLink("Annuaire de santé", Page.AdminBackOfficeHealthDirectory().url)

        when {
          currentRoute.startsWith("admin/users") -> usersApplicationsLink.addCssClass("active")
          currentRoute.startsWith("admin/laboratories") -> laboratoriesLink.addCssClass("active")
          currentRoute.startsWith("admin/medicines") -> medicinesLink.addCssClass("active")
          currentRoute.startsWith("admin/messages") -> messagesLinks.addCssClass("active")
          currentRoute.startsWith("admin/health-directory") -> healthDirectoryLink.addCssClass("active")
          currentRoute.startsWith("admin/learned-societies") -> learnedSocietiesLink.addCssClass("active")
          currentRoute.startsWith("admin/partnerships") -> partnershipsLink.addCssClass("active")
          currentRoute.startsWith("admin/partners") -> partnersLink.addCssClass("active")
          currentRoute.startsWith("admin/medical-professions") -> medicalProfessionLink.addCssClass("active")
          currentRoute.startsWith("admin/university-hospitals") -> universityHospitalsLink.addCssClass("active")
          currentRoute.startsWith("admin/medical-diplomas") -> medicalDiplomasLink.addCssClass("active")
        }
      }
    }
  }

  init {
    id = "layout-admin"
    require("./css/layouts/admin.css")

    add(LabodocHeader())
    add(Navigation())
    init?.invoke(this)
    add(LabodocFooter())
  }
}

fun Container.adminLayout(init: (AdminLayout.() -> Unit)? = null): AdminLayout {
  val adminLayout = AdminLayout(init)
  this.add(adminLayout)
  return adminLayout
}
