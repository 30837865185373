package fr.labodoc.app.data.admin.repository

import arrow.core.NonEmptySet
import arrow.core.toNonEmptySetOrNull
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateLearnedSocietyRequest
import fr.labodoc.api.payloads.requests.UpdateLearnedSocietyRequest
import fr.labodoc.api.payloads.responses.AdminLearnedSocietyResponse
import fr.labodoc.api.payloads.responses.LearnedSocietyIdResponse
import fr.labodoc.app.data.admin.model.LearnedSocietyModel
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyWebsite
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId

class LearnedSocietiesRepositoryImpl(
  private val apiClient: AdminApiClient
) : LearnedSocietiesRepository {
  override suspend fun getLearnedSocieties(): ApiResponse<Set<LearnedSocietyModel>> =
    apiClient.learnedSocieties
      .getLearnedSocieties()
      .map { learnedSocieties: Set<AdminLearnedSocietyResponse> ->
        learnedSocieties
          .map { learnedSociety: AdminLearnedSocietyResponse ->
            LearnedSocietyModel(
              id = learnedSociety.id,
              name = learnedSociety.name,
              acronym = learnedSociety.acronym,
              website = learnedSociety.website,
              medicalSpecialities = learnedSociety.medicalSpecialities.toNonEmptySetOrNull()!!, // FIXME better error handling ? But constrain by [ApiResponse] type
              logoUrl = learnedSociety.logo
            )
          }
          .toSet()
      }

  override suspend fun createLearnSociety(
    name: LearnedSocietyName,
    acronym: LearnedSocietyAcronym?,
    website: LearnedSocietyWebsite,
    medicalSpecialities: NonEmptySet<MedicalSpecialityId>,
    logo: InputFile
  ): ApiResponse<LearnedSocietyId> =
    apiClient.learnedSocieties
      .createLearnedSociety(
        payload = CreateLearnedSocietyRequest(
          name = name,
          acronym = acronym,
          website = website,
          medicalSpecialities = medicalSpecialities
        ),
        logo = logo
      )
      .map { learnedSocietyIdResponse: LearnedSocietyIdResponse ->
        learnedSocietyIdResponse.id
      }

  override suspend fun getLearnSociety(id: LearnedSocietyId): ApiResponse<LearnedSocietyModel> =
    apiClient.learnedSocieties
      .getLearnedSociety(id)
      .map { learnedSociety: AdminLearnedSocietyResponse ->
        LearnedSocietyModel(
          id = learnedSociety.id,
          name = learnedSociety.name,
          acronym = learnedSociety.acronym,
          website = learnedSociety.website,
          medicalSpecialities = learnedSociety.medicalSpecialities.toNonEmptySetOrNull()!!, // FIXME better error handling ? But constrain by [ApiResponse] type
          logoUrl = learnedSociety.logo
        )
      }

  override suspend fun updateLearnSociety(
    id: LearnedSocietyId,
    name: LearnedSocietyName,
    acronym: LearnedSocietyAcronym?,
    website: LearnedSocietyWebsite,
    medicalSpecialities: NonEmptySet<MedicalSpecialityId>,
    logo: InputFile?
  ): ApiResponse<Unit> =
    apiClient.learnedSocieties
      .updateLearnedSociety(
        id = id,
        payload = UpdateLearnedSocietyRequest(
          name = name,
          acronym = acronym,
          website = website,
          medicalSpecialities = medicalSpecialities,
        ),
        logo = logo
      )

  override suspend fun deleteLearnSociety(id: LearnedSocietyId): ApiResponse<Unit> =
    apiClient.learnedSocieties
      .deleteLearnedSociety(id)
}
