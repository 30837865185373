package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableValue

class LabodocStepper() : SimplePanel() {
  private val steps: MutableList<Pair<String, Li>> = mutableListOf()
  val currentStep = ObservableValue(0)

  val size: Int
    get() = steps.size

  private val list = Ol()
  private val mobileText = P()

  init {
    require("./css/components/labodoc-stepper.css")

    addCssClass("labodoc-stepper")
    add(list)
    add(mobileText)

    currentStep.subscribe { currentStep ->
      steps.forEachIndexed { index, step ->
        if (index == currentStep - 1) {
          step.second.addCssClass("current")
          mobileText.content = step.first
        } else
          step.second.removeCssClass("current")
      }
    }
  }

  fun addStep(title: String) {
    val step = Li {
      div((steps.size + 1).toString())
      p(title)
    }
    list.add(step)
    steps.add(title to step)
  }
}
