package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationName

data class AtcClassificationModel(
  val code: AtcClassificationCode,
  val name: AtcClassificationName,
  val parent: AtcClassificationModel?,
  val children: Set<AtcClassificationModel>
) {
  val level: Int = when (code.value.length) {
    1 -> 1
    3 -> 2
    4 -> 3
    5 -> 4
    7 -> 5
    else -> -1
  }

  private fun getLevel(level: Int): AtcClassificationModel? =
    if (this.level == level)
      this
    else
      parent?.getLevel(level)

  val firstLevel: AtcClassificationModel? = getLevel(1)
  val secondLevel: AtcClassificationModel? = getLevel(2)
  val thirdLevel: AtcClassificationModel? = getLevel(3)
  val fourthLevel: AtcClassificationModel? = getLevel(4)
  val fifthLevel: AtcClassificationModel? = getLevel(5)
}
