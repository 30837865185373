package fr.labodoc.webapp.pages.healthProfessional.dashboard.components

import fr.labodoc.app.data.healthprofessional.model.MedicinesSummaryModel
import io.kvision.core.StringPair
import io.kvision.state.ObservableList
import io.kvision.state.ObservableState

abstract class MedicineListViewModel {
  sealed class UiState {
    abstract val filter: Filter
    abstract val sort: ObservableState<Sort>

    data class Loading(
      override val filter: Filter,
      override val sort: ObservableState<Sort>
    ) : UiState()

    data class Error(
      override val filter: Filter,
      override val sort: ObservableState<Sort>
    ) : UiState()

    data class MedicineList(
      val medicines: ObservableList<MedicinesSummaryModel.Medicine>,
      override val filter: Filter,
      override val sort: ObservableState<Sort>
    ) : UiState()
  }

  data class Filter(
    val searchInput: ObservableState<String?>,
    val atcClassificationFilterOptions: Map<String?, List<StringPair>>,
    val atcClassificationFilter: ObservableList<String>,
  )

  sealed interface Sort {
    data object Default : Sort
    data object Alphabetical : Sort
    data object AtcClassificationCode : Sort
  }

  abstract val uiState: ObservableState<UiState>

  abstract fun refresh()

  abstract fun updateSearchInput(searchInput: String?)

  abstract fun updateAtcClassificationFilter(codes: Set<String>)

  abstract fun updateSort(sort: Sort)
}
