package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.AtcClassificationModel
import fr.labodoc.app.data.healthprofessional.source.remote.AtcClassificationsRemoteDataSource
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode

class AtcClassificationRepositoryImpl(
  private val atcClassificationsRemoteDataSource: AtcClassificationsRemoteDataSource
) : AtcClassificationRepository {
  override suspend fun getAtcClassifications(
  ): Either<RepositoryError.DataSource, Set<AtcClassificationModel>> =
    atcClassificationsRemoteDataSource
      .getAtcClassifications()
      .mapLeft(RepositoryError::DataSource)

  override suspend fun getAtcClassification(
    code: AtcClassificationCode
  ): Either<RepositoryError.DataSource, AtcClassificationModel> =
    atcClassificationsRemoteDataSource
      .getAtcClassification(
        code = code
      )
      .mapLeft(RepositoryError::DataSource) // Fixme not found
}
