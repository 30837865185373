package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class AdminMedicineResponse(
  val medicine: Medicine
) {
  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val laboratory: LaboratoryIdAsString?,
    val name: MedicineNameAsString,
    val atcClassification: AtcClassification,
    val segmentation: Segmentation,
    val logoUrl: UrlAsString,
    val dosages: Set<Dosage>,
    val activated: Boolean
  ) {
    @Serializable
    data class AtcClassification(
      val code: AtcClassificationCodeAsString,
      val name: AtcClassificationLabelAsString,
      val parent: AtcClassification?
    )

    @Serializable
    data class Segmentation(
      val medicalProfessions: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialities: Set<MedicalSpecialityIdAsString>?,
      val medicalInterests: Set<MedicalInterestIdAsString>?
    )

    @Serializable
    data class Dosage(
      val cisCode: CisCodeAsString,
      val name: DosageNameAsString
    )
  }
}
