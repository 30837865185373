package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.AtcCategory
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object AtcCategoryCodeAsStringSerializer : KSerializer<AtcCategory.Code> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcCategoryCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(code: String): Either<Errors.AtcCategory.Code.Invalid, AtcCategory.Code> =
    AtcCategory.Code(code)

  override fun deserialize(decoder: Decoder): AtcCategory.Code =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(code: AtcCategory.Code): String =
    code.value

  override fun serialize(encoder: Encoder, value: AtcCategory.Code) =
    encoder.encodeString(serialize(value))
}

typealias AtcCategoryCodeAsString = @Serializable(AtcCategoryCodeAsStringSerializer::class) AtcCategory.Code

object AtcCategoryNameAsStringSerializer : KSerializer<AtcCategory.Name> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcCategoryNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.AtcCategory.Label.Invalid, AtcCategory.Name> =
    AtcCategory.Name(name)

  override fun deserialize(decoder: Decoder): AtcCategory.Name =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(name: AtcCategory.Name): String =
    name.value

  override fun serialize(encoder: Encoder, value: AtcCategory.Name) =
    encoder.encodeString(serialize(value))
}

typealias AtcCategoryNameAsString = @Serializable(AtcCategoryNameAsStringSerializer::class) AtcCategory.Name
