package fr.labodoc.domain.labodoc.medicalprofession

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class MedicalProfessionName private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 30

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH
    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.MedicalProfession.Name.Invalid, MedicalProfessionName> =
      either {
        ensure(isNotTooLong(value)) { Errors.MedicalProfession.Name.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.MedicalProfession.Name.Invalid.Blank }

        MedicalProfessionName(value)
      }
  }
}
