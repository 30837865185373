package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyWebsite
import io.ktor.http.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object LearnedSocietyIdAsStringSerializer : KSerializer<LearnedSocietyId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LearnedSocietyIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.LearnedSociety.Id.Invalid, LearnedSocietyId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.LearnedSociety.Id.Invalid.Format }
      .map { LearnedSocietyId(it) }

  override fun deserialize(decoder: Decoder): LearnedSocietyId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: LearnedSocietyId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: LearnedSocietyId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias LearnedSocietyIdAsString = @Serializable(LearnedSocietyIdAsStringSerializer::class) LearnedSocietyId


object LearnedSocietyNameAsStringSerializer : KSerializer<LearnedSocietyName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LearnedSocietyNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.LearnedSociety.Name.Invalid, LearnedSocietyName> =
    LearnedSocietyName(name)

  override fun deserialize(decoder: Decoder): LearnedSocietyName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(name: LearnedSocietyName): String = name.value

  override fun serialize(encoder: Encoder, value: LearnedSocietyName) =
    encoder.encodeString(serialize(value))
}

typealias LearnedSocietyNameAsString = @Serializable(LearnedSocietyNameAsStringSerializer::class) LearnedSocietyName

object LearnedSocietyAcronymAsStringSerializer : KSerializer<LearnedSocietyAcronym> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LearnedSocietyAcronymAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(acronym: String): Either<Errors.LearnedSociety.Acronym.Invalid, LearnedSocietyAcronym> =
    LearnedSocietyAcronym(acronym)

  override fun deserialize(decoder: Decoder): LearnedSocietyAcronym =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(acronym: LearnedSocietyAcronym): String = acronym.value

  override fun serialize(encoder: Encoder, value: LearnedSocietyAcronym) =
    encoder.encodeString(serialize(value))
}

typealias LearnedSocietyAcronymAsString = @Serializable(LearnedSocietyAcronymAsStringSerializer::class) LearnedSocietyAcronym


object LearnedSocietyWebsiteAsStringSerializer : KSerializer<LearnedSocietyWebsite> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("LearnedSocietyWebsiteAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(website: String): Either<Nothing, LearnedSocietyWebsite> =
    LearnedSocietyWebsite(Url(website)).right()

  override fun deserialize(decoder: Decoder): LearnedSocietyWebsite =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(website: LearnedSocietyWebsite): String = website.value.toString()

  override fun serialize(encoder: Encoder, value: LearnedSocietyWebsite) =
    encoder.encodeString(serialize(value))
}

typealias LearnedSocietyWebsiteAsString = @Serializable(LearnedSocietyWebsiteAsStringSerializer::class) LearnedSocietyWebsite
