package fr.labodoc.webapp.pages.admin.laboratories.components

import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.labodocText
import fr.labodoc.webapp.components.labodocUpload
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.onChange
import io.kvision.form.FormPanel
import io.kvision.html.div
import io.kvision.html.image
import io.kvision.i18n.I18n
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.w3c.dom.url.URL

class AdminLaboratoryForm(
  laboratoryLogoUrl: Url? = null
) : FormPanel<AdminLaboratoryForm.Data>(serializer = Data.serializer(), className = "laboratory-form") {
  @Serializable
  data class Data(
    val name: String? = null,
    val website: String? = null,
    val pharmacovigilance: String? = null,
    val logo: List<KFile>? = null
  ) {
    companion object {
      fun validateName(name: String?): String? =
        name?.let {
          LaboratoryName(name)
            .fold(
              { error ->
                when (error) {
                  Errors.Laboratory.Name.Invalid.Blank -> "Ne peut pas être vide"
                  Errors.Laboratory.Name.Invalid.TooLong -> "Trop long, taille maximum ${LaboratoryName.MAX_LENGTH} charactères"
                }
              },
              {
                null
              }
            )
        }

      fun validateUrl(website: String?): String? =
        website?.let {
          try {
            URL(website)
            null
          } catch (exception: dynamic) {
            "L'url n'est pas valide"
          }
        }
    }
  }

  init {
    require("./css/pages/admin/laboratories/components/laboratory-form.css")

    div(className = "information") {
      labodocText {
        label = "Nom"
      }.bind(
        Data::name,
        required = true,
        requiredMessage = I18n.tr("Field.Required"),
        validator = { Data.validateName(it.value) == null },
        validatorMessage = { Data.validateName(it.value) }
      )

      labodocText {
        label = "Site internet"
      }.bind(
        Data::website,
        required = true,
        requiredMessage = I18n.tr("Field.Required"),
        validator = { Data.validateUrl(it.value) == null },
        validatorMessage = { Data.validateUrl(it.value) }
      )

      labodocText {
        label = "Site de pharmacovigilance"
        notice = "https://www.service-public.fr/particuliers/vosdroits/R14404"
      }.bind(
        Data::pharmacovigilance,
        required = false,
        requiredMessage = I18n.tr("Field.Required"),
        validator = { Data.validateUrl(it.value) == null },
        validatorMessage = { Data.validateUrl(it.value) }
      )
    }

    div(className = "logo") {
      val logoPreview = image(laboratoryLogoUrl?.toString())

      labodocUpload(label = "Logo", accept = listOf("image/*"), multiple = false) {
        onChange {
          App.scope.launch {
            value?.firstOrNull()?.also { logoPreview.src = getNativeFile(it)?.getContent() }
          }
        }
      }.bind(
        Data::logo,
        required = false,
        requiredMessage = I18n.tr("Field.Required"),
        validator = null,
        validatorMessage = null
      )
    }
  }
}

fun Container.adminLaboratoryForm(
  laboratoryLogoUrl: Url? = null
): AdminLaboratoryForm {
  val adminLaboratoryForm = AdminLaboratoryForm(laboratoryLogoUrl)
  this.add(adminLaboratoryForm)
  return adminLaboratoryForm
}
