package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.EmailAddressAsString
import fr.labodoc.api.payloads.serializers.PasswordAsString
import kotlinx.serialization.Serializable

@Serializable
data class LoginRequest(
  val email: EmailAddressAsString,
  val password: PasswordAsString,
  val rememberMe: Boolean
)
