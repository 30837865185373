package fr.labodoc.webapp.pages.admin.partnership

import fr.labodoc.app.data.admin.model.PartnershipModel
import fr.labodoc.app.data.admin.repository.PartnershipsRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import io.kvision.toast.ToastOptions
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminPartnershipsPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data object Error : UiState()

      data class Loaded(
        val partnerships: ObservableList<Partnership>,
        val errorMessage: ObservableState<String?>,
        val successMessage: ObservableState<String?>
      ) : UiState()
    }

    data class Partnership(
      val data: PartnershipModel,
      val deleteProcessing: Boolean
    )

    val uiState: ObservableState<UiState>

    fun refreshPage()

    fun deletePartnership(
      partnership: Partnership
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val partnershipsRepository: PartnershipsRepository by inject(named("admin"))

    private val partnerships: ObservableListWrapper<ViewModel.Partnership> =
      ObservableListWrapper()

    private val errorMessage: ObservableValue<String?> =
      ObservableValue(null)

    private val successMessage: ObservableValue<String?> =
      ObservableValue(null)

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      refreshPage()

      observableValue
    }

    override fun refreshPage() {
      App.scope.launch {
        uiState.value = ViewModel.UiState.Loading

        partnerships.clear()
        errorMessage.value = null
        successMessage.value = null

        val newUiState = partnershipsRepository
          .getPartnerships()
          .fold(
            {
              ViewModel.UiState.Error
            },
            { partnerships ->
              this@ViewModelImpl.partnerships.addAll(partnerships.map { ViewModel.Partnership(it, false) })

              ViewModel.UiState.Loaded(
                partnerships = this@ViewModelImpl.partnerships,
                errorMessage = errorMessage,
                successMessage = successMessage
              )
            }
          )

        uiState.value = newUiState
      }
    }

    override fun deletePartnership(
      partnership: ViewModel.Partnership
    ) {
      App.scope.launch {
        val partnershipIndex = partnerships
          .indexOf(partnership)
          .takeIf { it != -1 }

        partnershipIndex?.let {
          partnerships.getOrNull(partnershipIndex)?.let { partnership ->
            partnerships[partnershipIndex] = partnership.copy(deleteProcessing = true)
          }
        }

        partnershipsRepository
          .deletePartnership(
            id = partnership.data.id
          )
          .onLeft { error ->
            when (error.code) {
              Errors.Partnership.NotFound().code -> {
                errorMessage.value = "Le partenariat ${partnership.data.name.value} n'existe déjà plus"
                partnershipIndex?.let { partnerships.removeAt(partnershipIndex) }
              }

              else -> {
                errorMessage.value =
                  "Une erreur est survenue lors de la supression du partenariat ${partnership.data.name.value}"
              }
            }
          }
          .onRight {
            successMessage.value = "Le partenariat ${partnership.data.name.value} a correctement été supprimé"
            partnershipIndex?.let { partnerships.removeAt(partnershipIndex) }
          }

        partnershipIndex?.let {
          partnerships.getOrNull(partnershipIndex)?.let { partnership ->
            partnerships[partnershipIndex] = partnership.copy(deleteProcessing = false)
          }
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-partnerships"
    require("./css/pages/admin/partnership/partnerships.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      header {
        h1("Liste des partenariats")

        navigoLink("", Page.AdminBackOfficePartnershipCreate()) {
          labodocButton("Ajouter un partenariat", icon = "fa-solid fa-plus")
        }
      }

      when (uiState) {
        ViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        ViewModel.UiState.Error -> {
          p("Impossible de récupérer la liste des partenariats")
        }

        is ViewModel.UiState.Loaded -> {
          uiState.errorMessage.subscribe { errorMessage ->
            if (errorMessage != null)
              Toast.danger(errorMessage)
          }

          uiState.successMessage.subscribe { successMessage ->
            if (successMessage != null)
              Toast.success(successMessage)
          }

          div(className = "partnerships").bindEach(uiState.partnerships) { partnership ->
            val deleteConfirmationModal = LocalLabodocPopup(
              closeButton = true,
              icon = "fa-solid fa-warning",
              image = null,
              className = "admin-partnership-delete-confirmation-modal",
              content = { modal ->
                p(className = "title") {
                  content = """
                    Êtes-vous sûr de vouloir supprimer le partenariat ${partnership.data.name.value}?
                  """.trimIndent()
                }

                div(className = "choices") {
                  labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "confirm") {
                    onClick {
                      modal.hide()
                      viewModel.deletePartnership(partnership)
                    }
                  }

                  labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
                    onClick {
                      modal.hide()
                    }
                  }
                }
              }
            )

            div(className = "partnership") {
              add(deleteConfirmationModal)

              header {
                image(partnership.data.logo.toString())

                p(partnership.data.name.value)

                link(
                  label = partnership.data.website.value.toString(),
                  url = partnership.data.website.value.toString(),
                  target = "_blank"
                )
              }

              hr()

              div(className = "actions") {
                if (!partnership.deleteProcessing) {
                  navigoLink(
                    label = "Mettre à jour",
                    page = Page.AdminBackOfficePartnershipUpdate(partnership.data.id),
                    icon = "fa-solid fa-pencil",
                    className = "action edit"
                  )

                  labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "action delete") {
                    onClick {
                      deleteConfirmationModal.show()
                    }
                  }
                } else {
                  labodocButton("Traitement", icon = "fa fa-spinner fa-spin", className = "action delete", disabled = true)
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.adminPartnershipsPage(): AdminPartnershipsPage {
  val adminPartnershipsPage = AdminPartnershipsPage()

  this.add(adminPartnershipsPage)
  return adminPartnershipsPage
}
