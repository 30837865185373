package fr.labodoc.webapp.pages.admin.healthDirectory

import arrow.core.Either
import arrow.core.fold
import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.payloads.responses.HealthDirectoryHealthProfessionalResponse
import fr.labodoc.api.payloads.serializers.ExpertiseCodeAsString
import fr.labodoc.api.payloads.serializers.HealthProfessionalNationalIdentifierAsString
import fr.labodoc.api.payloads.serializers.MedicalCardNumberAsString
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.require
import fr.labodoc.webapp.components.*
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.core.onClick
import io.kvision.core.onEvent
import io.kvision.form.FormPanel
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.bind
import kotlinx.serialization.Serializable

class AdminHealthDirectoryPage : SimplePanel() {
  private val viewModel: AdminHealthDirectoryPageViewModel = AdminHealthDirectoryPageViewModelImpl()

  init {
    id = "page-admin-health-directory"
    require("./css/pages/admin/healthDirectory/healthDirectory.css")

    bind(viewModel.pageDataResponse) { pageDataResponse ->
      when (pageDataResponse) {
        is Either.Left -> TODO("Display an error")
        is Either.Right -> {
          val pageData = pageDataResponse.value

          header {
            div(className = "page-width") {
              val formFilter = FormFilters(
                mapOf(null to pageData.healthRepositoryProfessions.map { it.key.value.toString() to it.value.value }),
                mapOf(null to pageData.healthRepositoryProfessionalCategories.map { it.key.value to it.value.value }),
                mapOf(null to pageData.healthRepositoryProfessionalStatus.map { it.key.value to it.value.value }),
                mapOf(null to pageData.healthRepositoryPharmacistTableSectionCodes.map { it.key.value to it.value.value }),
                mapOf(null to pageData.healthRepositoryExpertises.map { it.key.value to it.value.value }),
                mapOf(null to pageData.medicalCardTypes.map { it.key.value to it.value.value } + ("null" to "Aucune")),
                mapOf(null to pageData.labodocMedicalProfessions.map { it.key.value.toString() to it.value.name.value }),
                pageData.labodocMedicalProfessions.fold(mutableMapOf()) { acc, medicalProfession ->
                  acc[medicalProfession.value.name.value] = medicalProfession.value.medicalSpecialities.map { medicalSpeciality ->
                    medicalSpeciality.id.value.toString() to medicalSpeciality.name.value
                  }
                  acc
                },
                mapOf(null to pageData.departments.map { it.key.value to "${it.key.value} - ${it.value.value}" })
              )
              add(formFilter)
              div(className = "page-width").bind(viewModel.healthProfessionalsResponse) { healthProfessionalsResponse ->
                when (healthProfessionalsResponse) {
                  is Either.Left -> {
                    labodocButton("Appliquer les filtres") {
                      disabled = true;
                    }
                  }

                  is Either.Right -> {
                    labodocButton("Appliquer les filtres") {
                      onClick {
                        if (formFilter.validate())
                          viewModel.changeFilters(formFilter.getData())
                      }
                    }
                  }

                  null -> {
                    labodocButtonSpinner() {}
                  }
                }
              }
            }
          }

          main {
            div(className = "page-width").bind(viewModel.healthProfessionalsResponse) { healthProfessionalsResponse ->
              when (healthProfessionalsResponse) {
                is Either.Left -> TODO("Display an error")
                is Either.Right -> {
                  val healthProfessionals = healthProfessionalsResponse.value.entities
                  val pagination = healthProfessionalsResponse.value.pagination

                  div(className = "pagination") {
                    p("Nombre de professionels: <b>${pagination.totalRecords}</b>", rich = true)
                    labodocPagination(pagination.self, pagination.last) { viewModel.changePage(it) }
                  }

                  div(className = "health-professionals") {
                    healthProfessionals.forEach {
                      add(
                        HealthProfessionalRow(
                          it,
                          pageData.healthRepositoryProfessions,
                          pageData.healthRepositoryProfessionalCategories,
                          pageData.healthRepositoryProfessionalStatus,
                          pageData.healthRepositoryExpertises
                        )
                      )
                    }
                  }
                }

                null -> labodocSpinner()
              }
            }
          }
        }

        null -> labodocSpinner()
      }
    }
  }
}

class HealthProfessionalRow(
  healthProfessional: HealthDirectoryHealthProfessionalResponse,
  healthDirectoryProfessions: Map<ProfessionCode, ProfessionName>,
  healthDirectoryProfessionalCategories: Map<ProfessionalCategoryCode, ProfessionalCategoryName>,
  healthDirectoryProfessionalStatus: Map<ProfessionalStatusCode, ProfessionalStatusName>,
  healthDirectoryExpertises: Map<ExpertiseCode, ExpertiseName>
) : SimplePanel(className = "health-professional") {
  init {
    div(className = "information") {
      rich = true
      content =
        """<i class="fa-solid fa-user"></i> <span class="first-name">${healthProfessional.firstName}</span> <span class="last-name">${healthProfessional.lastName}</span> - <span class="national-identifier">${healthProfessional.nationalIdentifier.value}</span>"""
    }

    div (className = "cards") {
      h3("Cartes:")
      healthProfessional.medicalCards.forEach { medicalCard ->
        div(className = "card") {
          rich = true
          content = """<i class="fa-solid fa-id-card"></i> ${medicalCard.typeCode.value} => ${medicalCard.number.value}"""
        }
      }
    }

    div(className = "practices") {
      h3("Pratiques:")
      healthProfessional.practices.forEach { practice ->
        div(className = "practice") {
          p(className = "professional-information") {
            rich = true
            content =
              """<i class="fa-solid fa-user-doctor"></i> <span class="profession">${healthDirectoryProfessions[practice.professionCode]?.value}</span> - <span class="professional-category">${healthDirectoryProfessionalCategories[practice.professionalCategoryCode]?.value}</span>"""
          }

          if (practice.professionCode.value != "21") {
            div(className = "expertises") {
              h4("Expertises:")
              val expertises =
                practice.expertises.mapNotNull { healthDirectoryExpertises[it]?.value }.ifEmpty { listOf("Aucune") }
              ul(expertises)
            }
          }

          div(className = "situations") {
            h4("Situations:")
            val situations = practice.situations.mapNotNull { situation ->
              val professionalStatus = healthDirectoryProfessionalStatus[situation.statusCode]
              val pharmacistTableSectionCode = situation.pharmacistTableSectionCode?.value
              val facility = situation.facility?.let { "${it.name} - ${it.street} , ${it.zipcode} ${it.city}" }


              var situationString = professionalStatus?.value
              if (pharmacistTableSectionCode != null)
                situationString += " (Pharmacien $pharmacistTableSectionCode)"
              if (facility != null)
                situationString += " à $facility"

              situationString
            }
            ul(situations)
          }

          onClick {
            if (hasCssClass("open"))
              removeCssClass("open")
            else
              addCssClass("open")
          }
        }
      }
    }
  }
}


class FormFilters(
  val healthRepositoryProfessions: Map<String?, List<StringPair>>,
  val healthRepositoryProfessionalCategories: Map<String?, List<StringPair>>,
  val healthRepositoryProfessionalStatus: Map<String?, List<StringPair>>,
  val healthRepositoryPharmacistTableSectionCodes: Map<String?, List<StringPair>>,
  val healthRepositoryExpertises: Map<String?, List<StringPair>>,
  val medicalCardTypes: Map<String?, List<StringPair>>,
  val labodocMedicalProfessions: Map<String?, List<StringPair>>,
  val labodocMedicalSpecialities: Map<String?, List<StringPair>>,
  val departments: Map<String?, List<StringPair>>
) : FormPanel<FormFilters.Filters>(serializer = Filters.serializer()) {
  @Serializable
  data class Filters(
    val nationalIdentifier: HealthProfessionalNationalIdentifierAsString? = null,
    val name: String? = null,
    val healthRepositoryProfessionCodesInput: String? = null,
    val healthRepositoryProfessionalCategoryCodesInput: String? = null,
    val healthRepositoryProfessionalStatusCodesInput: String? = null,
    val healthRepositoryPharmacistTableSectionCodesInput: String? = null,
    val healthRepositoryExpertiseCodesInput: String? = null,
    val medicalCardNumber: MedicalCardNumberAsString? = null,
    val medicalCardTypesCodesInput: String? = null,
    val labodocMedicalProfessionIdsInput: String? = null,
    val labodocMedicalSpecialitiesIdsInput: String? = null,
    val departmentCodesInput: String? = null,
  ) {
    val healthRepositoryProfessionCodes: Set<ProfessionCode>?
      get() = healthRepositoryProfessionCodesInput?.split(",")?.map { ProfessionCode(it) }?.toSet()

    val healthRepositoryProfessionalCategoryCodes: Set<ProfessionalCategoryCode>?
      get() = healthRepositoryProfessionalCategoryCodesInput?.split(",")?.map { ProfessionalCategoryCode(it) }?.toSet()

    val healthRepositoryProfessionalStatusCodes: Set<ProfessionalStatusCode>?
      get() = healthRepositoryProfessionalStatusCodesInput?.split(",")?.map { ProfessionalStatusCode(it) }?.toSet()

    val healthRepositoryPharmacistTableSectionCodes: Set<PharmacistTableSection.Code>?
      get() = healthRepositoryPharmacistTableSectionCodesInput?.split(",")?.map { PharmacistTableSection.Code(it) }
        ?.toSet()

    val healthRepositoryExpertiseCodes: Set<ExpertiseCodeAsString>?
      get() = healthRepositoryExpertiseCodesInput?.split(",")?.map { ExpertiseCode(it) }?.toSet()

    val medicalCardTypeCodes: Set<MedicalCardTypeCode?>?
      get() = medicalCardTypesCodesInput?.split(",")?.map { if (it != "null") MedicalCardTypeCode(it) else null }?.toSet()

    val labodocMedicalProfessionIds: Set<MedicalProfessionId>?
      get() = labodocMedicalProfessionIdsInput?.split(",")?.map { MedicalProfessionId(uuidFrom(it)) }?.toSet()

    val labodocMedicalSpecialitiesIds: Set<MedicalSpecialityId>?
      get() = labodocMedicalSpecialitiesIdsInput?.split(",")?.map { MedicalSpecialityId(uuidFrom(it)) }?.toSet()

    val departmentCodes: Set<DepartmentCode>?
      get() = departmentCodesInput?.split(",")?.mapNotNull { DepartmentCode(it).getOrNull() }?.toSet()
  }

  init {
    div(className = "filter-lines") {
      labodocText {
        rich = true
        label = """<i class="fa-solid fa-search"></i> Identifiant National"""

        onEvent {
          keypress = {
            if (it.key == "Enter")
              it.preventDefault()
          }
        }
      }.bindCustom(Filters::nationalIdentifier)

      labodocText {
        rich = true
        label = """<i class="fa-solid fa-search"></i> Nom"""

        onEvent {
          keypress = {
            if (it.key == "Enter")
              it.preventDefault()
          }
        }
      }.bindCustom(Filters::name)

      labodocSelectCheckbox {
        label = "Département"
        options = departments
      }.bindCustom(Filters::departmentCodesInput)
    }

    div(className = "filter-lines") {
      labodocSelectCheckbox {
        label = "Profession LaboDoc"
        options = labodocMedicalProfessions
      }.bindCustom(Filters::labodocMedicalProfessionIdsInput)

      labodocSelectCheckbox {
        label = "Spécialités LaboDoc"
        options = labodocMedicalSpecialities
      }.bindCustom(Filters::labodocMedicalSpecialitiesIdsInput)
    }

    div(className = "filter-lines") {
      labodocSelectCheckbox {
        label = "Catégorie Professionel"
        options = healthRepositoryProfessionalCategories
      }.bindCustom(Filters::healthRepositoryProfessionalCategoryCodesInput)

      labodocSelectCheckbox {
        label = "Statut Professionel"
        options = healthRepositoryProfessionalStatus
      }.bindCustom(Filters::healthRepositoryProfessionalStatusCodesInput)
    }

    div(className = "filter-lines") {
      labodocText {
        rich = true
        label = """<i class="fa-solid fa-search"></i> Numéro de carte"""

        onEvent {
          keypress = {
            if (it.key == "Enter")
              it.preventDefault()
          }
        }
      }.bindCustom(Filters::medicalCardNumber)

      labodocSelectCheckbox {
        label = "Type de carte"
        options = medicalCardTypes
      }.bindCustom(Filters::medicalCardTypesCodesInput)
    }

    div(className = "filter-lines") {
      labodocSelectCheckbox {
        label = "Profession Annuaire Santé"
        options = healthRepositoryProfessions
      }.bindCustom(Filters::healthRepositoryProfessionCodesInput)


      labodocSelectCheckbox {
        label = "Expertise Annuaire Santé"
        options = healthRepositoryExpertises
      }.bindCustom(Filters::healthRepositoryExpertiseCodesInput)

      labodocSelectCheckbox {
        label = "Code pharmacien Annuaire Santé"
        options = healthRepositoryPharmacistTableSectionCodes
      }.bindCustom(Filters::healthRepositoryPharmacistTableSectionCodesInput)
    }

  }
}


fun Container.adminHealthDirectoryPage(): AdminHealthDirectoryPage {
  val adminHealthDirectoryPage = AdminHealthDirectoryPage()
  this.add(adminHealthDirectoryPage)
  return adminHealthDirectoryPage
}
