package fr.labodoc.app.data.healthprofessional.model

import io.ktor.http.*

data class MedicinesSummaryModel(
  val medicines: Set<Medicine>,
  val atcClassifications: Set<AtcClassification>,
  val thematics: Set<Thematic>,
) {
  data class Medicine(
    val id: String,
    val laboratory: Laboratory?,
    val name: String,
    val logoUrl: Url,
    val atcClassification: AtcClassification,
    val isPartner: Boolean
  ) {
    data class Laboratory(
      val id: String,
      val name: String
    )
  }

  data class AtcClassification(
    val code: String,
    val name: String,
    val shortName: String?,
    val numberOfMedicines: Int,
    var parent: AtcClassification?
  ) {
    val level: Int = when (code.length) {
      1 -> 1
      3 -> 2
      4 -> 3
      5 -> 4
      7 -> 5
      else -> -1
    }

    private fun getLevel(level: Int): AtcClassification? =
      if (this.level == level)
        this
      else
        parent?.getLevel(level)

    fun getLevelOrHigher(level: Int): AtcClassification? =
      if (this.level == level)
        this
      else
        parent?.getLevel(level) ?: parent?.getLevelOrHigher(level - 1)

    val firstLevel: AtcClassification?
      get() = getLevel(1)
    val secondLevel: AtcClassification?
      get() = getLevel(2)
    val thirdLevel: AtcClassification?
      get() = getLevel(3)
    val fourthLevel: AtcClassification?
      get() = getLevel(4)
    val fifthLevel: AtcClassification?
      get() = getLevel(5)
  }

  data class Thematic(
    val id: String,
    val name: String,
    val atcClassificationsCodes: Set<String>,
    val numberOfMedicines: Int
  )
}
