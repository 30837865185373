package fr.labodoc.webapp.pages.admin.learnedSocieties

import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.LearnedSocietyModel
import fr.labodoc.app.data.admin.repository.LearnedSocietiesRepository
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminLearnedSocietiesPage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      object Loading : UiState()

      data class Error(
        val errorMessage: String
      ) : UiState()

      data class Loaded(
        val learnedSocieties: Set<LearnedSocietyModel>
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun refreshPage()
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val learnedSocietyRepository: LearnedSocietiesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      refreshPage()

      observableValue
    }

    override fun refreshPage() {
      App.scope.launch {
        learnedSocietyRepository
          .getLearnedSocieties()
          .fold(
            { ViewModel.UiState.Error("Impossible de charger la liste des sociétés savantes") },
            { ViewModel.UiState.Loaded(it) }
          )
          .also { uiState.setState(it) }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  private class LearnSocietyCard(
    learnedSociety: LearnedSocietyModel,
    onLearnedSocietyDeleted: (LearnedSocietyId) -> Unit
  ) : SimplePanel("learned-society") {
    private interface ViewModel {
      val deleteProcessing: ObservableState<Boolean>

      fun deleteLearnedSociety()
    }

    private class ViewModelImpl(
      private val learnedSociety: LearnedSocietyModel,
      private val onLearnedSocietyDeleted: (LearnedSocietyId) -> Unit
    ) : ViewModel, KoinComponent {
      private val learnedSocietyRepository: LearnedSocietiesRepository by inject(named("admin"))

      override val deleteProcessing: ObservableValue<Boolean> =
        ObservableValue(false)

      override fun deleteLearnedSociety() {
        App.scope.launch {
          deleteProcessing.setState(true)

          learnedSocietyRepository
            .deleteLearnSociety(learnedSociety.id)
            .onLeft { errorResponse: ErrorResponse ->
              val errorMessage = when (errorResponse.code) {
                "LearnedSociety.NotFound" -> "Cette société savante n'existe pas" //FIXME
                else -> "Une erreur est survenue"
              }

              Toast.danger(errorMessage)
            }
            .onRight {
              Toast.success("Société savante correctement supprimée")
              onLearnedSocietyDeleted(learnedSociety.id)
            }

          deleteProcessing.setState(true)
        }
      }
    }

    private val viewModel: ViewModel = ViewModelImpl(learnedSociety, onLearnedSocietyDeleted)

    private val deleteConfirmationModal =
      LabodocPopup(
        closeButton = true,
        icon = "fa-solid fa-warning",
        image = null,
        className = "admin-learned-society-delete-confirmation-modal",
        content = { modal ->
          p(className = "title") {
            content = "Êtes-vous sûr de vouloir supprimer la société savante \"${learnedSociety.name.value}\" ?"
          }

          div(className = "choices") {
            labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "delete") {
              onClick {
                modal.hide()
                viewModel.deleteLearnedSociety()
              }
            }

            labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
              onClick {
                modal.hide()
              }
            }
          }
        },
        beforeClose = null
      )

    init {
      link("", learnedSociety.website.value.toString(), target = "_blank") {
        header {
          image(learnedSociety.logoUrl.toString())

          p(learnedSociety.name.value, className = "name")
        }
      }

      hr()

      div(className = "actions") {
        navigoLink("", Page.AdminBackOfficeLearnedSocietyUpdate(learnedSociety.id)) {
          labodocButton("Mettre à jour", "fa-solid fa-pencil", className = "edit")
        }

        labodocButton("", icon = "", className = "delete").bind(viewModel.deleteProcessing) { deleteProcessing ->
          if (deleteProcessing) {
            disabled = true
            text = "Traitement"
            icon = "fa fa-spinner fa-spin"
          } else {
            disabled = false
            text = "Supprimer"
            icon = "fa-solid fa-trash"

            onClick {
              deleteConfirmationModal.show()
            }
          }
        }
      }
    }
  }

  init {
    id = "page-admin-learned-societies"
    require("./css/pages/admin/learnedSocieties/learned-societies.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState: ViewModel.UiState ->
      header {
        h1("Liste des sociétés savantes")

        navigoLink("", Page.AdminBackOfficeLearnedSocietyCreate()) {
          labodocButton("Ajouter une société savante", "fa-solid fa-plus")
        }
      }

      div(className = "learned-societies") {
        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          is ViewModel.UiState.Error -> Toast.danger(uiState.errorMessage)

          is ViewModel.UiState.Loaded -> {
            uiState.learnedSocieties.forEach { learnedSociety: LearnedSocietyModel ->
              val card = LearnSocietyCard(
                learnedSociety = learnedSociety,
                onLearnedSocietyDeleted = { viewModel.refreshPage() }
              )

              add(card)
            }
          }
        }
      }
    }
  }
}

fun Container.adminLearnedSocietiesPage(): AdminLearnedSocietiesPage {
  val adminLearnedSocietiesPage = AdminLearnedSocietiesPage()

  this.add(adminLearnedSocietiesPage)
  return adminLearnedSocietiesPage
}
