package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.form.text.Text
import io.kvision.form.text.TextInput
import io.kvision.form.text.textInput
import io.kvision.html.InputType
import io.kvision.html.span

class LabodocText(
  type: InputType = InputType.TEXT, value: String? = null, name: String? = null, maxlength: Int? = null,
  label: String? = null, rich: Boolean = false, notice: String? = null, noticeRich: Boolean = false,
  init: (LabodocText.() -> Unit)? = null
) : Text(type, value, name, maxlength, label, rich, false, null) {
  private val spanNotice = span(className = "notice", rich = noticeRich)

  var notice
    get() = spanNotice.content
    set(value) {
      spanNotice.content = value
    }

  var noticeRich
    get() = spanNotice.rich
    set(value) {
      spanNotice.rich = value
    }

  init {
    require("./css/components/labodoc-text.css")

    addCssClass("labodoc-text")

    placeholder = ""

    add(spanNotice)

    init?.invoke(this)
  }
}

fun Container.labodocText(
  type: InputType = InputType.TEXT, value: String? = null, name: String? = null, maxlength: Int? = null,
  label: String? = null, rich: Boolean = false, notice: String? = null, noticeRiche: Boolean = false,
  init: (LabodocText.() -> Unit)? = null
): LabodocText {
  val text = LabodocText(type, value, name, maxlength, label, rich, notice, noticeRiche, init)
  this.add(text)
  return text
}

class LabodocTextInput(
  type: InputType = InputType.TEXT,
  value: String? = null,
  maxlength: Int? = null,
  className: String? = null,
  init: (LabodocTextInput.() -> Unit)? = null
) : TextInput(type, value, maxlength, className, null) {
  init {
    require("./css/components/labodoc-text.css")

    addCssClass("labodoc-text")

    init?.invoke(this)
  }
}

fun Container.labodocTextInput(
  type: InputType = InputType.TEXT, value: String? = null,
  maxlength: Int? = null,
  className: String? = null,
  init: (LabodocTextInput.() -> Unit)? = null
): LabodocTextInput {
  val textInput = LabodocTextInput(type, value, maxlength, className, init)
  this.add(textInput)
  return textInput
}
