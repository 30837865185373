package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.app.data.healthprofessional.model.DepartmentModel

class DepartmentsRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : DepartmentsRepository {
  override suspend fun getDepartments(
  ): ApiResponse<Set<DepartmentModel>> =
    apiClient.departments
      .getDepartments()
      .map { departments ->
        departments
          .map { department ->
            DepartmentModel(
              code = department.code,
              name = department.name
            )
          }
          .toSet()
      }
}
