package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaName
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionName
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityName

data class MedicalDiplomaModel(
  val id: MedicalDiplomaId,
  val name: MedicalDiplomaName,
  val healthDirectoryProfessionCode: ProfessionCode,
  val labodocMedicalSpeciality: MedicalSpeciality
) {
  data class MedicalSpeciality(
    val id: MedicalSpecialityId,
    val medicalProfession: MedicalProfession,
    val name: MedicalSpecialityName
  ) {
    data class MedicalProfession(
      val id: MedicalProfessionId,
      val name: MedicalProfessionName
    )
  }
}
