package fr.labodoc.app.data.admin.repository

import arrow.core.toNonEmptySetOrNull
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateMessageRequest
import fr.labodoc.api.payloads.requests.UpdateMessageRequest
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.app.data.admin.model.*
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageContent
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.message.MessageTitle
import io.ktor.http.*
import kotlinx.datetime.Instant

class MessagesRepositoryImpl(
  private val apiClient: AdminApiClient
) : MessagesRepository {
  override suspend fun getMessages(): ApiResponse<Set<MessageSummaryModel>> =
    apiClient.messages
      .getMessages()
      .map { adminMessagesSummaryResponse: AdminMessagesSummaryResponse ->
        adminMessagesSummaryResponse.messages
          .map { message ->
            when (message) {
              is AdminLabodocFlashInfoSummaryResponse -> LabodocFlashInfoSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                title = message.title,
              )

              is AdminLaboratoryFlashInfoSummaryResponse -> LaboratoryFlashInfoSummaryModel(
                id = message.id,
                laboratory = LaboratoryMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                publishedAt = message.publishedAt,
                title = message.title,
              )

              is AdminLaboratoryInvitationSummaryResponse -> LaboratoryInvitationSummaryModel(
                id = message.id,
                laboratory = LaboratoryMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                publishedAt = message.publishedAt,
                title = message.title
              )

              is AdminMedicineFlashInfoSummaryResponse -> MedicineFlashInfoSummaryModel(
                id = message.id,
                laboratory = MedicineMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                medicine = MedicineMessageSummaryModel.Medicine(
                  id = message.medicine.id,
                  name = message.medicine.name
                ),
                publishedAt = message.publishedAt,
                title = message.title,
              )

              is AdminMedicineInvitationSummaryResponse -> MedicineInvitationSummaryModel(
                id = message.id,
                laboratory = MedicineMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                medicine = MedicineMessageSummaryModel.Medicine(
                  id = message.medicine.id,
                  name = message.medicine.name
                ),
                publishedAt = message.publishedAt,
                title = message.title
              )

              is AdminLearnedSocietyFlashInfoSummaryResponse -> LearnedSocietyFlashInfoSummaryModel(
                id = message.id,
                learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
                  id = message.learnedSociety.id,
                  name = message.learnedSociety.name
                ),
                publishedAt = message.publishedAt,
                title = message.title,
              )

              is AdminLearnedSocietyInvitationSummaryResponse -> LearnedSocietyInvitationSummaryModel(
                id = message.id,
                learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
                  id = message.learnedSociety.id,
                  name = message.learnedSociety.name
                ),
                publishedAt = message.publishedAt,
                title = message.title
              )
            }
          }
          .toSet()
      }

  override suspend fun getMessage(id: MessageId): ApiResponse<MessageModel> =
    apiClient.messages
      .getMessage(id)
      .map { messageResponse: AdminMessageResponse ->
        when (messageResponse) {
          is AdminLabodocFlashInfoResponse -> LabodocFlashInfoModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            title = messageResponse.title,
            content = messageResponse.content,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is AdminLaboratoryFlashInfoResponse -> LaboratoryFlashInfoModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            laboratory = LaboratoryMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            title = messageResponse.title,
            content = messageResponse.content,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is AdminLaboratoryInvitationResponse -> LaboratoryInvitationModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            laboratory = LaboratoryMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            title = messageResponse.title,
            eventAt = messageResponse.eventAt,
            content = messageResponse.content,
            formUrl = messageResponse.formUrl,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is AdminMedicineFlashInfoResponse -> MedicineFlashInfoModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            laboratory = MedicineMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            medicine = MedicineMessageModel.Medicine(
              id = messageResponse.medicine.id,
              name = messageResponse.medicine.name
            ),
            title = messageResponse.title,
            content = messageResponse.content,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is AdminMedicineInvitationResponse -> MedicineInvitationModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            laboratory = MedicineMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            medicine = MedicineMessageModel.Medicine(
              id = messageResponse.medicine.id,
              name = messageResponse.medicine.name
            ),
            title = messageResponse.title,
            eventAt = messageResponse.eventAt,
            content = messageResponse.content,
            formUrl = messageResponse.formUrl,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is AdminLearnedSocietyFlashInfoResponse -> LearnedSocietyFlashInfoModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            learnedSociety = LearnedSocietyMessageModel.LearnedSociety(
              id = messageResponse.learnedSociety.id,
              name = messageResponse.learnedSociety.name
            ),
            title = messageResponse.title,
            content = messageResponse.content,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is AdminLearnedSocietyInvitationResponse -> LearnedSocietyInvitationModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            learnedSociety = LearnedSocietyMessageModel.LearnedSociety(
              id = messageResponse.learnedSociety.id,
              name = messageResponse.learnedSociety.name
            ),
            title = messageResponse.title,
            eventAt = messageResponse.eventAt,
            content = messageResponse.content,
            formUrl = messageResponse.formUrl,
            segmentation = Segmentation(
              medicalProfessions = messageResponse.segmentation.medicalProfessionIds?.toNonEmptySetOrNull(),
              medicalSpecialities = messageResponse.segmentation.medicalSpecialityIds?.toNonEmptySetOrNull(),
              medicalInterests = messageResponse.segmentation.medicalInterestIds?.toNonEmptySetOrNull(),
              professionalCategories = messageResponse.segmentation.professionalCategoryCodes?.toNonEmptySetOrNull(),
              professionalStatuses = messageResponse.segmentation.professionalStatusCodes?.toNonEmptySetOrNull(),
              medicalCardTypes = messageResponse.segmentation.medicalCardTypeCodes?.toNonEmptySetOrNull(),
              departments = messageResponse.segmentation.departmentCodes?.toNonEmptySetOrNull()
            ),
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )
        }
      }

  override suspend fun createLabodocFlashInfo(
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.LabodocFlashInfo(
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = CreateMessageRequest.LabodocFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun createLaboratoryFlashInfo(
    laboratoryId: LaboratoryId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.LaboratoryFlashInfo(
          laboratoryId = laboratoryId,
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = CreateMessageRequest.LaboratoryFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun createLaboratoryInvitation(
    laboratoryId: LaboratoryId,
    publishedAt: Instant,
    title: MessageTitle,
    eventAt: Instant,
    content: MessageContent,
    formUrl: Url?,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.LaboratoryInvitation(
          laboratoryId = laboratoryId,
          publishedAt = publishedAt,
          title = title,
          eventAt = eventAt,
          content = content,
          formUrl = formUrl,
          segmentation = CreateMessageRequest.LaboratoryInvitation.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun createMedicineFlashInfo(
    medicineId: MedicineId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.MedicineFlashInfo(
          medicineId = medicineId,
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = CreateMessageRequest.MedicineFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun createMedicineInvitation(
    medicineId: MedicineId,
    publishedAt: Instant,
    title: MessageTitle,
    eventAt: Instant,
    content: MessageContent,
    formUrl: Url?,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.MedicineInvitation(
          medicineId = medicineId,
          publishedAt = publishedAt,
          title = title,
          eventAt = eventAt,
          content = content,
          formUrl = formUrl,
          segmentation = CreateMessageRequest.MedicineInvitation.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun createLearnedSocietyFlashInfo(
    learnedSocietyId: LearnedSocietyId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.LearnedSocietyFlashInfo(
          learnedSocietyId = learnedSocietyId,
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = CreateMessageRequest.LearnedSocietyFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun createLearnedSocietyInvitation(
    learnedSocietyId: LearnedSocietyId,
    publishedAt: Instant,
    title: MessageTitle,
    eventAt: Instant,
    content: MessageContent,
    formUrl: Url?,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<MessageId> =
    apiClient.messages
      .createMessage(
        payload = CreateMessageRequest.LearnedSocietyInvitation(
          learnedSocietyId = learnedSocietyId,
          publishedAt = publishedAt,
          title = title,
          eventAt = eventAt,
          content = content,
          formUrl = formUrl,
          segmentation = CreateMessageRequest.LearnedSocietyInvitation.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )
      .map { messageIdResponse: MessageIdResponse ->
        messageIdResponse.id
      }

  override suspend fun updateLabodocFlashInfo(
    id: MessageId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.LabodocFlashInfo(
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = UpdateMessageRequest.LabodocFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments,
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun updateLaboratoryFlashInfo(
    id: MessageId,
    laboratoryId: LaboratoryId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.LaboratoryFlashInfo(
          laboratoryId = laboratoryId,
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = UpdateMessageRequest.LaboratoryFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun updateLaboratoryInvitation(
    id: MessageId,
    laboratoryId: LaboratoryId,
    publishedAt: Instant,
    title: MessageTitle,
    eventAt: Instant,
    content: MessageContent,
    formUrl: Url?,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.LaboratoryInvitation(
          laboratoryId = laboratoryId,
          publishedAt = publishedAt,
          title = title,
          eventAt = eventAt,
          content = content,
          formUrl = formUrl,
          segmentation = UpdateMessageRequest.LaboratoryInvitation.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun updateMedicineFlashInfo(
    id: MessageId,
    medicineId: MedicineId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.MedicineFlashInfo(
          medicineId = medicineId,
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = UpdateMessageRequest.MedicineFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun updateMedicineInvitation(
    id: MessageId,
    medicineId: MedicineId,
    publishedAt: Instant,
    title: MessageTitle,
    eventAt: Instant,
    content: MessageContent,
    formUrl: Url?,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.MedicineInvitation(
          medicineId = medicineId,
          publishedAt = publishedAt,
          title = title,
          eventAt = eventAt,
          content = content,
          formUrl = formUrl,
          segmentation = UpdateMessageRequest.MedicineInvitation.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun updateLearnedSocietyFlashInfo(
    id: MessageId,
    learnedSocietyId: LearnedSocietyId,
    publishedAt: Instant,
    title: MessageTitle,
    content: MessageContent,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.LearnedSocietyFlashInfo(
          learnedSocietyId = learnedSocietyId,
          publishedAt = publishedAt,
          title = title,
          content = content,
          segmentation = UpdateMessageRequest.LearnedSocietyFlashInfo.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun updateLearnedSocietyInvitation(
    id: MessageId,
    learnedSocietyId: LearnedSocietyId,
    publishedAt: Instant,
    title: MessageTitle,
    eventAt: Instant,
    content: MessageContent,
    formUrl: Url?,
    segmentation: Segmentation,
    banner: InputFile?,
    document: InputFile?
  ): ApiResponse<Unit> =
    apiClient.messages
      .updateMessage(
        id = id,
        payload = UpdateMessageRequest.LearnedSocietyInvitation(
          learnedSocietyId = learnedSocietyId,
          publishedAt = publishedAt,
          title = title,
          eventAt = eventAt,
          content = content,
          formUrl = formUrl,
          segmentation = UpdateMessageRequest.LearnedSocietyInvitation.Segmentation(
            medicalProfessionIds = segmentation.medicalProfessions,
            medicalSpecialityIds = segmentation.medicalSpecialities,
            medicalInterestIds = segmentation.medicalInterests,
            professionalCategoryCodes = segmentation.professionalCategories,
            professionalStatusCodes = segmentation.professionalStatuses,
            medicalCardTypeCodes = segmentation.medicalCardTypes,
            departmentCodes = segmentation.departments
          )
        ),
        banner = banner,
        document = document
      )

  override suspend fun deleteMessage(id: MessageId): ApiResponse<Unit> =
    apiClient.messages
      .deleteMessage(id)
}
