package fr.labodoc.webapp.pages.admin.partner.form

import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.partner.PartnerCode
import fr.labodoc.domain.labodoc.partner.PartnerName
import fr.labodoc.webapp.components.labodocText
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class AdminPartnerForm(
  codeRequired: Boolean,
  data: Data? = null
) : FormPanel<AdminPartnerForm.Data>(
  serializer = Data.serializer(),
  className = "admin-partner-form"
) {
  @Serializable
  data class Data(
    val name: String? = null,
    val code: String? = null
  ) {
    companion object {
      fun validateName(name: String?): String? =
        name?.let {
          PartnerName(name).fold(
            { error: Errors.Partner.Name.Invalid ->
              when (error) {
                Errors.Partner.Name.Invalid.Blank -> "Ne peut pas être vide"
                Errors.Partner.Name.Invalid.TooLong -> "Trop long, taille maximum ${PartnerName.MAX_LENGTH}"
              }
            },
            {
              null
            }
          )
        }

      fun validateCode(code: String?): String? =
        code?.let {
          PartnerCode(code).fold(
            { error: Errors.Partner.Code.Invalid ->
              when (error) {
                Errors.Partner.Code.Invalid.Blank -> "Ne peut pas être vide si renseigné"
                Errors.Partner.Code.Invalid.TooLong -> "Trop long, taille maximum ${PartnerCode.MAX_LENGTH}"
              }
            },
            {
              null
            }
          )
        }
    }
  }

  init {
    labodocText {
      label = "Nom"
      value = data?.name
    }.bind(
      Data::name,
      required = true,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateName(it.value) == null },
      validatorMessage = { Data.validateName(it.value) }
    )

    labodocText {
      label = "Code"
      value = data?.code
    }.bind(
      Data::code,
      required = codeRequired,
      requiredMessage = I18n.tr("Field.Required"),
      validator = { Data.validateCode(it.value) == null },
      validatorMessage = { Data.validateCode(it.value) }
    )
  }
}

fun Container.adminPartnerForm(
  codeRequired: Boolean,
  data: AdminPartnerForm.Data? = null
): AdminPartnerForm {
  val adminPartnerForm = AdminPartnerForm(
    codeRequired = codeRequired,
    data = data
  )

  this.add(adminPartnerForm)
  return adminPartnerForm
}
