package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.EmailAddress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object EmailAddressAsStringSerializer : KSerializer<EmailAddress> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("EmailAddressAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(emailAddress: String): Either<Errors.EmailAddress.Invalid, EmailAddress> = EmailAddress(emailAddress)

  override fun deserialize(decoder: Decoder): EmailAddress {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(emailAddress: EmailAddress): String = emailAddress.value

  override fun serialize(encoder: Encoder, value: EmailAddress) {
    return encoder.encodeString(serialize(value))
  }
}

typealias EmailAddressAsString = @Serializable(EmailAddressAsStringSerializer::class) EmailAddress
