package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.HealthProfessionalMedicineWithInformationResponse
import fr.labodoc.api.payloads.responses.HealthProfessionalMedicinesSummaryResponse
import fr.labodoc.app.data.healthprofessional.model.AtcClassificationModel
import fr.labodoc.app.data.healthprofessional.model.MedicineModel
import fr.labodoc.app.data.healthprofessional.model.MedicineSummaryModel
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId

class MedicineRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : MedicinesRepository {
  override suspend fun getMedicines(): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getMedicines()
      .map { response: HealthProfessionalMedicinesSummaryResponse ->
        response.toModel()
      }

  override suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<MedicineModel> =
    apiClient.medicines
      .getMedicine(id)
      .map { response: HealthProfessionalMedicineWithInformationResponse ->
        val medicine = response.medicine

        MedicineModel(
          id = medicine.id,
          laboratory = medicine.laboratory?.let { laboratory ->
            MedicineModel.Laboratory(
              id = laboratory.id,
              name = laboratory.name,
              website = laboratory.website,
              pharmacovigilance = laboratory.pharmacovigilance
            )
          },
          name = medicine.name,
          atcClassification = medicine.atcClassification.toModel(),
          dosages = medicine.dosages
            .map { dosage ->
              MedicineModel.Dosage(
                cisCode = dosage.cisCode,
                name = dosage.name
              )
            }
            .toSet(),
          logoUrl = medicine.logoURL,
          marketingDocuments = medicine.marketingDocuments
            .map { marketingDocument ->
              MedicineModel.MarketingDocument(
                id = marketingDocument.id,
                name = marketingDocument.name,
                url = marketingDocument.documentUrl,
                version = marketingDocument.version,
                latestVersionSeen = marketingDocument.lastVersionSeen
              )
            }
            .toSet(),
        )

      }

  override suspend fun tagDocumentAsSeen(id: MarketingDocumentId, forMedicine: MedicineId): ApiResponse<Unit> =
    apiClient.medicines
      .getMarketingDocument(id, forMedicine)
      .map { }
}

private fun HealthProfessionalMedicinesSummaryResponse.toModel(): Set<MedicineSummaryModel> {
  return medicines
    .map { medicine: HealthProfessionalMedicinesSummaryResponse.Medicine ->
      MedicineSummaryModel(
        id = medicine.id,
        laboratory = medicine.laboratory?.let { laboratory ->
          MedicineSummaryModel.Laboratory(
            id = laboratory.id,
            name = laboratory.name
          )
        },
        name = medicine.name,
        logoUrl = medicine.logoUrl,
        atcClassification = medicine.atcClassification.toModel(),
        isPartner = medicine.isPartner
      )
    }
    .toSet()
}

private fun HealthProfessionalMedicinesSummaryResponse.Medicine.AtcClassification.toModel(): AtcClassificationModel {
  // 1) Collect the chain from bottom to top
  val chainBottomToTop = generateSequence(this) { current -> current.parent }.toList()

  // 2) Reverse it so that index 0 is the top node,
  //    and the last item is the bottom node
  val chainTopToBottom = chainBottomToTop.asReversed()

  // 3) Convert each DTO item into an empty AtcClassificationModel
  //    (we will fill parent/children relationships below)
  val models = chainTopToBottom.map {
    AtcClassificationModel(
      code = it.code,
      name = it.name,
      parent = null,
      children = emptySet()
    )
  }.toMutableList()

  // 4) Link each parent to its child
  for (i in 0 until (models.size - 1)) {
    val parentModel = models[i]
    val childModel = models[i + 1]

    // Set child's parent
    val updatedChild = childModel.copy(parent = parentModel)
    models[i + 1] = updatedChild

    // Add child into parent's children
    val updatedParent = parentModel.copy(children = parentModel.children + updatedChild)
    models[i] = updatedParent
  }

  // The first item in "models" is now the topmost classification
  // with fully linked descendants down to the bottom node.
  return models.last()
}

private fun HealthProfessionalMedicineWithInformationResponse.Medicine.AtcClassification.toModel(
): AtcClassificationModel {
  // 1) Collect the chain from bottom to top
  val chainBottomToTop = generateSequence(this) { current -> current.parent }.toList()

  // 2) Reverse it so that index 0 is the top node,
  //    and the last item is the bottom node
  val chainTopToBottom = chainBottomToTop.asReversed()

  // 3) Convert each DTO item into an empty AtcClassificationModel
  //    (we will fill parent/children relationships below)
  val models = chainTopToBottom.map {
    AtcClassificationModel(
      code = it.code,
      name = it.name,
      parent = null,
      children = emptySet()
    )
  }.toMutableList()

  // 4) Link each parent to its child
  for (i in 0 until (models.size - 1)) {
    val parentModel = models[i]
    val childModel = models[i + 1]

    // Set child's parent
    val updatedChild = childModel.copy(parent = parentModel)
    models[i + 1] = updatedChild

    // Add child into parent's children
    val updatedParent = parentModel.copy(children = parentModel.children + updatedChild)
    models[i] = updatedParent
  }

  // The first item in "models" is now the topmost classification
  // with fully linked descendants down to the bottom node.
  return models.last()
}
