package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.MedicalCardCPFRegistrationRequest
import fr.labodoc.api.payloads.requests.MedicalCardCPSRegistrationRequest
import fr.labodoc.api.payloads.requests.NoMedicalCardRegistration
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.HealthProfessionalResponse
import fr.labodoc.api.resources.Applications
import fr.labodoc.api.routes.HealthProfessionalApplicationsRoutes
import fr.labodoc.domain.healthdirectory.MedicalCardNumber
import fr.labodoc.domain.labodoc.InputFile
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class HealthProfessionalApplicationsRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalApplicationsRoutes {
  override suspend fun getHealthProfessionalByCardNumber(
    cardNumber: MedicalCardNumber
  ): ApiResponse<HealthProfessionalResponse> {
    val response = httpClient.get(Applications.WithMedicalCard.Number(cardNumber = cardNumber))

    return if (response.status.isSuccess())
      response.body<HealthProfessionalResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun registerWithMedicalCardCPS(
    payload: MedicalCardCPSRegistrationRequest,
  ): ApiResponse<Unit> {
    val response = httpClient.post(Applications.WithMedicalCard.CPS()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun registerWithMedicalCardCPF(
    payload: MedicalCardCPFRegistrationRequest
  ): ApiResponse<Unit> {
    val response = httpClient.post(Applications.WithMedicalCard.CPF()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun registerWithoutMedicalCard(
    payload: NoMedicalCardRegistration,
    supportingDocument: InputFile
  ): ApiResponse<Unit> {
    val response = httpClient.post(Applications.WithoutMedicalCard()) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          append("supporting-document", supportingDocument.content, Headers.build {
            append(HttpHeaders.ContentType, supportingDocument.mime)
            append(HttpHeaders.ContentDisposition, "filename=\"${supportingDocument.name}\"")
          })
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun validateApplicationEmail(
    token: String
  ): ApiResponse<Unit> {
    val response = httpClient.post(Applications.ValidateEmail(token))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
