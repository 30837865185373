package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.PartnerCodeAsString
import fr.labodoc.api.payloads.serializers.PartnerNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class PartnersResponse(
  val partners: Set<Partner>
) {
  @Serializable
  data class Partner(
    val code: PartnerCodeAsString,
    val name: PartnerNameAsString
  )
}
