package fr.labodoc.webapp.pages.notFound

import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import io.kvision.core.Container
import io.kvision.html.p
import io.kvision.panel.SimplePanel

class NotFoundPage: SimplePanel()  {
  init {
    p("NOT FOUND")
  }
}

fun Container.notFoundPage() {
  add(LabodocHeader())
  add(NotFoundPage())
  add(LabodocFooter())
}
