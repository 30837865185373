package fr.labodoc.domain.labodoc.common

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.healthdirectory.NationalIdentifier
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

/**
 * [Definition](https://esante.gouv.fr/produits-services/repertoire-rpps)
 */
@JvmInline
value class RPPSNumber private constructor(val value: String) {
  companion object {
    val regex: Regex = Regex("^\\d{11}\$")

    fun isFormatValid(value: String): Boolean = regex.containsMatchIn(value)

    operator fun invoke(value: String): Either<Errors.RPPSNumber.Invalid, RPPSNumber> =
      either {
        ensure(isFormatValid(value)) { Errors.RPPSNumber.Invalid.Format }

        RPPSNumber(value)
      }
  }

  fun toNationalIdentifier(): NationalIdentifier =
    NationalIdentifier("8${value}")
}
