package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateId
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateName

data class SegmentationTemplateModel(
  val id: SegmentationTemplateId,
  val name: SegmentationTemplateName,
  val segmentation: Segmentation
)
