package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
sealed class MessageResponse {
  @Serializable
  data class Laboratory(
    val id: LaboratoryIdAsString,
    val name: LaboratoryNameAsString
  )

  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val name: MedicineNameAsString
  )

  @Serializable
  data class LearnedSociety(
    val id: LearnedSocietyIdAsString,
    val name: LearnedSocietyNameAsString
  )

  abstract val id: MessageIdAsString
  abstract val publishedAt: Instant
  abstract val title: MessageTitleAsString
  abstract val content: MessageContentAsString
  abstract val bannerUrl: UrlAsString
  abstract val documentUrl: UrlAsString?
  abstract val seen: Boolean
  abstract val favorite: Boolean
}

@Serializable
data class LabodocFlashInfoResponse(
  override val id: MessageIdAsString,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()

@Serializable
data class LaboratoryFlashInfoResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()

@Serializable
data class LaboratoryInvitationResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  val eventAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  val formUrl: UrlAsString?,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()

@Serializable
data class MedicineFlashInfoResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()

@Serializable
data class MedicineInvitationResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  val medicine: Medicine,
  override val publishedAt: Instant,
  val eventAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  val formUrl: UrlAsString?,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()

@Serializable
data class LearnedSocietyFlashInfoResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()

@Serializable
data class LearnedSocietyInvitationResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  val eventAt: Instant,
  override val title: MessageTitleAsString,
  override val content: MessageContentAsString,
  val formUrl: UrlAsString?,
  override val bannerUrl: UrlAsString,
  override val documentUrl: UrlAsString?,
  override val seen: Boolean,
  override val favorite: Boolean
) : MessageResponse()
