package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class FreestandingApplicationResponse(
  val id: ApplicationIdAsString,
  val isEmailValidated: Boolean,
  val email: EmailAddressAsString,
  val medicalSpeciality: MedicalSpecialityNameAsString,
  val medicalProfession: MedicalProfessionNameAsString,
  val phone: PhoneNumberAsString,
  val firstName: FirstNameAsString,
  val lastName: LastNameAsString,
  val supportingDocumentUrl: UrlAsString,
  val facility: Facility,
  val comment: String?,
  val rppsNumber: RPPSNumberAsString?,
  val partnerCode: PartnerCodeAsString?
) {
  @Serializable
  data class Facility(
    val name: String,
    val unit: String,
    val departmentCode: DepartmentCodeAsString,
    val city: String
  )
}
