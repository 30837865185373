package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class AdminLaboratoryResponse(
  val laboratory: Laboratory
) {
  @Serializable
  data class Laboratory(
    val id: LaboratoryIdAsString,
    val name: LaboratoryNameAsString,
    val website: LaboratoryWebsiteAsString,
    val pharmacovigilance: LaboratoryPharmacovigilanceAsString?,
    val logoUrl: UrlAsString?,
    val activated: Boolean
  )
}
