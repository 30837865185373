package fr.labodoc.webapp.pages.admin.laboratories

import arrow.core.Either
import arrow.core.Nel
import arrow.core.nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZipOrAccumulate
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.LaboratoryModel
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.laboratories.components.AdminLaboratoryForm
import fr.labodoc.webapp.pages.admin.laboratories.components.adminLaboratoryForm
import fr.labodoc.webapp.utils.toInputFile
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.form.getDataWithFileContent
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminLaboratoryUpdatePage(
  laboratoryId: LaboratoryId
) : SimplePanel() {
  private class ViewModel(val laboratoryId: LaboratoryId): KoinComponent {
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))

    private val _data: ObservableValue<ApiResponse<LaboratoryModel>?> by lazy {
      val observableValue: ObservableValue<ApiResponse<LaboratoryModel>?> = ObservableValue(null)

      App.scope.launch {
        laboratoriesRepository.getLaboratory(laboratoryId)
          .also { observableValue.setState(it) }
      }

      observableValue
    }

    val data: ObservableState<ApiResponse<LaboratoryModel>?>
      get() = _data

    suspend fun updateLaboratory(
      laboratory: AdminLaboratoryForm.Data
    ): Either<Nel<String>, Unit> =
      either {
        parZipOrAccumulate(
          {
            ensureNotNull(laboratory.name) { "Le nom du laboratoire est obligatoire" }
            LaboratoryName(laboratory.name)
              .mapLeft { error: Errors.Laboratory.Name.Invalid ->
                when (error) {
                  Errors.Laboratory.Name.Invalid.Blank -> "Le nom du laboratoire est vide"
                  Errors.Laboratory.Name.Invalid.TooLong -> "Le nom du laboratoire est trop long"
                }
              }
              .bind()
          },
          {
            ensureNotNull(laboratory.website) { "Le site du laboratoire est obligatoire" }
            LaboratoryWebsite(Url(laboratory.website))
          },
          {
            laboratory.pharmacovigilance?.let {
              LaboratoryPharmacovigilance(Url(laboratory.pharmacovigilance))
            }
          },
          {
            laboratory.logo?.firstOrNull()?.toInputFile()
          }
        ) { name, website, pharmacovigilance, logo ->
          laboratoriesRepository
            .updateLaboratory(
              laboratoryId,
              name,
              website,
              pharmacovigilance,
              logo
            )
            .mapLeft {
              "Une erreur est survenue".nel()
            }
            .bind()
        }
      }
  }

  private val viewModel = ViewModel(laboratoryId)

  init {
    id = "page-admin-laboratory-update"
    require("./css/pages/admin/laboratories/update.css")

    div(className = "page-width").bind(viewModel.data) { data ->
      h1 {
        content = "Mettre à jour un laboratoire"
      }

      when (data) {
        is Either.Left -> {
          Toast.danger("Impossible de charger le laboratoire")
          window.history.back()
        }

        is Either.Right -> {
          val laboratory = data.value

          val form = adminLaboratoryForm(
           laboratory.logoUrl
          ).apply {
            setData(
              AdminLaboratoryForm.Data(
                name = laboratory.name.value,
                website = laboratory.website.value.toString(),
                pharmacovigilance = laboratory.pharmacovigilance?.value?.toString(),
                logo = null
              )
            )
          }

          labodocButton("Sauvegarder", className = "save") {
            onClick {
              if (form.validate()) {
                disabled = true
                App.scope.launch {
                  val formData = form.getDataWithFileContent()
                  viewModel
                    .updateLaboratory(formData)
                    .onLeft { disabled = false }
                    .onRight {
                      Toast.success("Laboratoire mis à jour avec succés")
                      App.routing.navigate(Page.AdminBackOfficeLaboratorySheet(laboratoryId))
                    }
                }
              }
            }
          }
        }

        null -> labodocSpinner()
      }
    }
  }
}

fun Container.adminLaboratoryUpdatePage(laboratoryId: LaboratoryId): AdminLaboratoryUpdatePage {
  val adminLaboratoryUpdatePage = AdminLaboratoryUpdatePage(laboratoryId)
  this.add(adminLaboratoryUpdatePage)
  return adminLaboratoryUpdatePage
}
