package fr.labodoc.webapp.pages.admin.message.form

import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.LaboratorySummaryModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.MedicineSummaryModel
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.*
import io.ktor.http.*
import io.kvision.form.time.dateTime
import io.kvision.html.div
import io.kvision.html.h3
import io.kvision.html.image
import io.kvision.i18n.I18n
import io.kvision.state.ObservableList
import io.kvision.state.ObservableListWrapper
import io.kvision.toast.Toast
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named
import kotlin.js.Date

class AdminMedicineFlashInfoMessageForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalCategories: Set<Pair<ProfessionalCategoryCode, ProfessionalCategoryName>>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
  departments: Set<Pair<DepartmentCode, DepartmentName>>,
  laboratories: Set<LaboratorySummaryModel>? = null,
  bannerLogoUrl: Url? = null,
  initialData: Data? = null
) : AdminMessageForm<AdminMedicineFlashInfoMessageForm.Data>(
  serializer = Data.serializer(),
  className = "medicine-flashinfo-message-form"
) {
  @Serializable
  data class Data(
    val laboratoryId: String? = null,
    val medicineId: String? = null,
    @Contextual override val publishedAt: Date? = null,
    override val title: String? = null,
    override val text: String? = null,
    override val segmentation: SegmentationAsString? = null,
    override val document: List<KFile>? = null,
    override val banner: List<KFile>? = null
  ) : AdminMessageFormData

  private interface ViewModel {
    val laboratories: ObservableList<LaboratorySummaryModel>
    val laboratoryMedicines: ObservableList<MedicineSummaryModel>

    fun selectLaboratory(laboratoryId: LaboratoryId)
  }

  private class ViewModelImpl(
    laboratories: Set<LaboratorySummaryModel>?
  ) : ViewModel, KoinComponent {
    private val laboratoryRepository: LaboratoriesRepository by inject(named("admin"))
    private val medicineRepository: MedicinesRepository by inject(named("admin"))

    override val laboratories: ObservableListWrapper<LaboratorySummaryModel> by lazy {
      val observableListWrapper: ObservableListWrapper<LaboratorySummaryModel> = ObservableListWrapper()

      if (laboratories != null) {
        observableListWrapper.addAll(laboratories)
      } else {
        App.scope.launch {
          laboratoryRepository.getLaboratories()
            .onRight { laboratories ->
              observableListWrapper.addAll(laboratories)
            }
        }
      }

      observableListWrapper
    }

    override val laboratoryMedicines: ObservableList<MedicineSummaryModel> =
      ObservableListWrapper()

    override fun selectLaboratory(laboratoryId: LaboratoryId) {
      App.scope.launch {
        laboratoryMedicines.clear()

        medicineRepository.getMedicinesForLaboratory(laboratoryId)
          .onLeft {
            Toast.danger("Une erreur est survenue lors du chargement du laboratoire")
          }
          .onRight { medicines ->
            laboratoryMedicines.addAll(medicines)
          }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(laboratories)

  init {
    require("./css/pages/admin/message/form/medicine-flashinfo.css")

    div(className = "laboratory") {
      h3("Informations du laboratoire et du médicament associé : ")

      div(className = "fields") {
        labodocSelect {
          label = "Laboratoire"
          value = initialData?.laboratoryId

          viewModel.laboratories.subscribe { laboratories ->
            value = laboratories.find { it.id.value.toString() == value || it.id.value.toString() == initialData?.laboratoryId }?.id?.value?.toString()

            options = laboratories
              .map { laboratory ->
                laboratory.id.value.toString() to laboratory.name.value
              }
          }

          subscribe { laboratoryIdInput: String? ->
            laboratoryIdInput?.let { viewModel.selectLaboratory(LaboratoryId(uuidFrom(it))) }
          }
        }.bindCustom(
          key = Data::laboratoryId,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )

        labodocSelect {
          label = "Médicament"
          value = initialData?.medicineId

          viewModel.laboratoryMedicines.subscribe { medicines ->
            value = medicines.find { it.id.value.toString() == value || it.id.value.toString() == initialData?.medicineId }?.id?.value?.toString()

            options = medicines
              .map { medicine ->
                medicine.id.value.toString() to medicine.name.value
              }
          }
        }.bindCustom(
          key = Data::medicineId,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )
      }
    }

    div(className = "message") {
      h3("Information du message :")

      div(className = "fields") {
        dateTime {
          addCssClass("published-at")
          label = "Publié le"
          format = "DD/MM/YYYY HH:mm"
          value = initialData?.publishedAt
        }.bind(
          key = Data::publishedAt,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )

        labodocText {
          addCssClass("title")
          label = "Titre"
          value = initialData?.title
        }.bindCustom(
          key = Data::title,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { AdminMessageFormData.validateTitle(it) == null },
          validatorMessage = { AdminMessageFormData.validateTitle(it) }
        )

        labodocTextArea {
          addCssClass("text")
          label = "Text"
          value = initialData?.text
        }.bindCustom(
          key = Data::text,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )

        labodocSegmentation(
          medicalProfessions,
          professionalCategories,
          professionalStatuses,
          medicalCardTypes,
          departments
        ) {
          addCssClass("segmentation")
          label = "Segmentation"
          initialData?.segmentation?.let { data = it }
        }.bindCustom(
          key = Data::segmentation,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { AdminMessageFormData.validateSegmentation(it) == null },
          validatorMessage = { AdminMessageFormData.validateSegmentation(it) }
        )


        div(className = "document") {
          labodocUpload {
            label = "Document"
            accept = null
            multiple = false
          }.bind(
            Data::document,
            required = false,
            requiredMessage = I18n.tr("Field.Required"),
            validator = null,
            validatorMessage = null
          )
        }
      }


      div(className = "banner") {
        val bannerPreview = image(null)

        labodocUpload {
          label = "Bannière"
          accept = listOf("image/*")
          multiple = false

          subscribe { files ->
            App.scope.launch {
              bannerPreview.src =
                files?.firstOrNull()?.let { getNativeFile(it)?.getContent() } ?: bannerLogoUrl?.toString()
            }
          }
        }.bind(
          Data::banner,
          required = false,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )
      }
    }
  }
}
