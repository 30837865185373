package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateUniversityHospitalRequest
import fr.labodoc.api.payloads.requests.UpdateUniversityHospitalRequest
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.UniversityHospitalIdResponse
import fr.labodoc.api.payloads.responses.UniversityHospitalResponse
import fr.labodoc.api.resources.UniversityHospitals
import fr.labodoc.api.routes.AdminUniversityHospitalRoutes
import fr.labodoc.api.routes.HealthProfessionalUniversityHospitalRoutes
import fr.labodoc.domain.labodoc.universityhospital.UniversityHospitalId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminUniversityHospitalsRemoteDataSource(
  private val httpClient: HttpClient
) : AdminUniversityHospitalRoutes {
  override suspend fun getUniversityHospitals(): ApiResponse<Set<UniversityHospitalResponse>> {
    val response = httpClient.get(UniversityHospitals())

    return if (response.status.isSuccess())
      response.body<Set<UniversityHospitalResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createUniversityHospital(
    payload: CreateUniversityHospitalRequest
  ): ApiResponse<UniversityHospitalIdResponse> {
    val response = httpClient.post(UniversityHospitals()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<UniversityHospitalIdResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateUniversityHospital(
    id: UniversityHospitalId,
    payload: UpdateUniversityHospitalRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(UniversityHospitals.UniversityHospital(id)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteUniversityHospital(
    id: UniversityHospitalId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(UniversityHospitals.UniversityHospital(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
