package fr.labodoc.webapp.pages.login

import arrow.core.flatMap
import fr.labodoc.Cookie
import fr.labodoc.Cookies
import fr.labodoc.app.data.healthprofessional.model.AdminUserModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import fr.labodoc.webapp.pages.login.sections.LoginForm
import fr.labodoc.webapp.pages.login.sections.PasswordForgottenForm
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.i18n.I18n
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class LoginPage(redirectTo: String?) : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      abstract val errorMessage: String?

      data class LoginForm(
        val form: fr.labodoc.webapp.pages.login.sections.LoginForm,
        val processing: Boolean,
        override val errorMessage: String?
      ) : UiState()

      data class LoginSuccessful(
        val redirectTo: String,
        override val errorMessage: String?
      ) : UiState()

      data class AccountDisabled(
        override val errorMessage: String?
      ) : UiState()

      data class PasswordForgottenForm(
        val form: fr.labodoc.webapp.pages.login.sections.PasswordForgottenForm,
        val processing: Boolean,
        override val errorMessage: String?
      ) : UiState()

      data class PasswordForgottenSuccess(
        override val errorMessage: String?
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun displayLoginForm()

    fun displayPasswordForgottenForm()

    fun login()

    fun sendPasswordResetRequest()
  }

  private class ViewModelImpl(
    private val redirectTo: String?
  ) : ViewModel, KoinComponent {
    private val usersRepository: UsersRepository by inject()

    override val uiState: ObservableValue<ViewModel.UiState> =
      ObservableValue(
        ViewModel.UiState.LoginForm(
          form = LoginForm(
            emailAddress = null,
            password = null,
            rememberMe = false,
            onForgottenPasswordClick = this::displayPasswordForgottenForm
          ),
          processing = false,
          errorMessage = null
        )
      )

    override fun displayLoginForm() {
      App.scope.launch {
        uiState.value = ViewModel.UiState.LoginForm(
          form = LoginForm(
            emailAddress = null,
            password = null,
            rememberMe = false,
            onForgottenPasswordClick = this@ViewModelImpl::displayPasswordForgottenForm
          ),
          processing = false,
          errorMessage = null
        )
      }
    }

    override fun displayPasswordForgottenForm() {
      App.scope.launch {
        uiState.value = ViewModel.UiState.PasswordForgottenForm(
          form = PasswordForgottenForm(
            emailAddress = null
          ),
          processing = false,
          errorMessage = null
        )
      }
    }

    override fun login() {
      App.scope.launch {
        when (val currentUiState = uiState.value) {
          is ViewModel.UiState.LoginForm -> {
            uiState.value = currentUiState
              .copy(
                processing = true,
                errorMessage = null
              )

            val newUiState = currentUiState.form.getValidatedData()
              .mapLeft {
                currentUiState
                  .copy(
                    processing = false,
                    errorMessage = "Le formulaire est invalide"
                  )
              }
              .flatMap { (emailAddress, password, rememberMe) ->
                usersRepository.login(emailAddress, password, rememberMe)
                  .mapLeft { error ->
                    when (error.code) {
                      Errors.Authentication.AccountDisabled.code -> {
                        ViewModel.UiState.AccountDisabled(
                          errorMessage = null
                        )
                      }

                      else -> {
                        currentUiState
                          .copy(
                            processing = false,
                            errorMessage = when (error.code) {
                              Errors.Authentication.InvalidCredentials.code -> "Indentifiants invalides ou inconnus"
                              else -> "Une erreur est survenue, veuillez réessayer"
                            }
                          )
                      }
                    }
                  }
                  .map { user  ->
                    App.user.setState(user)

                    ViewModel.UiState.LoginSuccessful(
                      redirectTo = redirectTo ?: when (user) {
                        is AdminUserModel -> Page.AdminBackOfficeLaboratoriesList().url
                        is HealthProfessionalUserModel -> Page.HealthProfessionalDashboard().url
                      },
                      errorMessage = null
                    )
                  }
              }
              .fold(
                {
                  it
                },
                {
                  it
                }
              )

            uiState.value = newUiState
          }

          else -> {}
        }
      }
    }

    override fun sendPasswordResetRequest() {
      App.scope.launch {
        when (val currentUiState = uiState.value) {
          is ViewModel.UiState.PasswordForgottenForm -> {
            uiState.value = currentUiState
              .copy(
                processing = true,
                errorMessage = null
              )

            val newUiState = currentUiState.form.getValidatedData()
              .mapLeft {
                currentUiState
                  .copy(
                    processing = false,
                    errorMessage = "Le formulaire est invalide"
                  )
              }
              .flatMap { (emailAddress) ->
                usersRepository.sendPasswordResetRequest(emailAddress)
                  .mapLeft { _ ->
                    currentUiState
                      .copy(
                        processing = false,
                        errorMessage = "Une erreur est survenue, veuillez réessayer"
                      )
                  }
                  .map {
                    ViewModel.UiState.PasswordForgottenSuccess(
                      errorMessage = null
                    )
                  }
              }
              .fold(
                {
                  it
                },
                {
                  it
                }
              )

            uiState.value = newUiState
          }

          else -> {}
        }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl(
    redirectTo = redirectTo
  )

  init {
    require("./css/pages/login/login.css")

    id = "page-login"

    div(className = "page-width") {
      i(className = "icon fa-solid fa-user")

      div(className = "container").bind(viewModel.uiState) { uiState ->
        uiState.errorMessage?.let { Toast.danger(it) }

        h1 {
          content = "Connexion"
        }

        hr()

        when (uiState) {
          is ViewModel.UiState.LoginForm -> {
            p(rich = true) {
              content = "Vous n'avez pas encore de compte ? "
              navigoLink("Inscrivez-vous gratuitement", Page.Register())
            }

            add(uiState.form)

            labodocButton("", className = "login labodoc-background-yellow") {
              if (uiState.processing) {
                disabled = true
                text = ""
                icon = "fa fa-spinner fa-spin"
              } else {
                disabled = false
                text = I18n.tr("LoginForm.Login")
                icon = null

                onClick {
                  viewModel.login()
                }
              }
            }
          }

          is ViewModel.UiState.LoginSuccessful -> {
            App.routing.navigate(uiState.redirectTo)
          }

          is ViewModel.UiState.AccountDisabled -> {
            p {
              content = """
                  Votre compte a été désactivé et vous ne pouvez plus accéder à la plateforme.
                """.trimIndent()
            }

            p {
              rich = true
              content = """
                  Si vous pensez qu'il s'agit d'une erreur ou pour tout information, <a href="${Page.Contact().url}">contactez-nous.</a>
                """.trimIndent()
            }

            labodocButton("Retour", className = "return") {
              onClick {
                viewModel.displayLoginForm()
              }
            }
          }

          is ViewModel.UiState.PasswordForgottenForm -> {
            p {
              strong("Pour réinitialiser votre mot de passe :")
            }

            ol {
              li {
                content = "Saisissez votre adresse e-mail"
              }

              li {
                content = "Ouvrez le lien dans l'e-mail que vous recevez"
              }

              li {
                content = "Choisissez un nouveau mot de passe"
              }
            }

            add(uiState.form)

            labodocButton("", className = "labodoc-background-yellow") {
              if (uiState.processing) {
                disabled = true
                text = ""
                icon = "fa fa-spinner fa-spin"
              } else {
                disabled = false
                text = "Envoyer l'email"
                icon = null

                onClick {
                  viewModel.sendPasswordResetRequest()
                }
              }
            }

            labodocButton("Retour", className = "return") {
              onClick {
                viewModel.displayLoginForm()
              }
            }
          }

          is ViewModel.UiState.PasswordForgottenSuccess -> {
            p {
              content = "Ouvrez le lien dans l'e-mail pour réinitialiser votre mot de passe"
            }

            p(className = "notice") {
              rich = true
              content = """
                La réception de l'email peut prendre quelques minutes. Vous ne le recevrez que si vous avez saisi une adresse e-mail liée à un utilisateur existant.<br>
                Si vous ne recevez rien, vérifiez vos spams.
              """.trimIndent()
            }

            navigoLink("", Page.Home()) {
              labodocButton("Retourner à l'accueil", className = "labodoc-background-yellow")
            }
          }
        }
      }
    }
  }
}

fun Container.loginPage(redirectTo: String?) {
  add(LabodocHeader())
  add(LoginPage(redirectTo))
  add(LabodocFooter())
}
