package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.LoginResponse
import fr.labodoc.api.payloads.responses.SelfResponse
import fr.labodoc.api.resources.Users
import fr.labodoc.api.routes.HealthProfessionalUsersRoutes
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class HealthProfessionalUsersRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalUsersRoutes {
  override suspend fun login(
    payload: LoginRequest
  ): ApiResponse<LoginResponse> {
    val response = httpClient.post(Users.Login()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<LoginResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun logout(
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.Self.Logout())

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun changePassword(
    payload: ChangePasswordRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(Users.Self.Password()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun sendPasswordResetRequest(
    payload: PasswordResetRequest
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.Password.Reset()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun finalizePasswordResetRequest(
    token: String,
    payload: FinalizePasswordResetRequest
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.Password.Reset.Validate(token)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getSelf(
  ): ApiResponse<SelfResponse> {
    val response = httpClient.get(Users.Self())

    return if (response.status.isSuccess())
      response.body<SelfResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMedicalInterests(
    payload: UpdateSelfMedicalInterestsRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(Users.Self.MedicalInterests()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateNotificationsSettings(
    payload: UpdateSelfNotificationsSettingsRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(Users.Self.NotificationSettings()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteAccount(): ApiResponse<Unit> {
    val response = httpClient.delete(Users.Self())

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}

