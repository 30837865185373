package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.CipCode
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object CipCodeAsStringSerializer : KSerializer<CipCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("CipCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(cipCode: String): Either<Errors.CIPCode.Invalid, CipCode> = CipCode(cipCode)

  override fun deserialize(decoder: Decoder): CipCode {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(cipCode: CipCode): String = cipCode.value

  override fun serialize(encoder: Encoder, value: CipCode) {
    return encoder.encodeString(serialize(value))
  }
}

typealias CipCodeAsString = @Serializable(CipCodeAsStringSerializer::class) CipCode
