package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.MedicineModel
import fr.labodoc.app.data.healthprofessional.model.MedicinesSummaryModel
import fr.labodoc.app.data.healthprofessional.source.remote.MedicinesRemoteDataSource
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId

class MedicineRepositoryImpl(
  private val medicinesRemoteDataSource: MedicinesRemoteDataSource
) : MedicinesRepository {
  override suspend fun getMedicines(
  ): Either<RepositoryError.DataSource, MedicinesSummaryModel> =
    medicinesRemoteDataSource
      .getMedicines()
      .mapLeft(RepositoryError::DataSource)

  override suspend fun getMedicine(
    id: MedicineId
  ): Either<RepositoryError, MedicineModel> =
    medicinesRemoteDataSource
      .getMedicine(id)
      .mapLeft(RepositoryError::DataSource) // Fixme not found

  override suspend fun tagDocumentAsSeen(
    id: MarketingDocumentId,
    forMedicine: MedicineId
  ): Either<RepositoryError, Unit> =
    medicinesRemoteDataSource
      .tagDocumentAsSeen(id, forMedicine)
      .mapLeft(RepositoryError::DataSource) // Fixme not found
}
