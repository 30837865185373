package fr.labodoc.webapp.di

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.client.httpClient
import fr.labodoc.app.data.healthprofessional.source.remote.*
import fr.labodoc.process
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.cookies.*
import io.ktor.client.plugins.resources.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json
import org.koin.dsl.module

val dataSourceModule = module {
  single<HttpClient> {
    httpClient {
      defaultRequest {
        url(process.env.API_URL)
      }

      engine {
        js(
          """
            window.originalFetch = window.fetch;
            window.fetch = function (resource, init) {
                init = Object.assign({}, init);
                init.credentials = init.credentials !== undefined ? init.credentials : 'include';
                return window.originalFetch(resource, init);
            };
          """
        )
      }

      install(HttpCookies)

      install(Resources)

      install(ContentNegotiation) {
        json(Json {
          ignoreUnknownKeys = true
        })
      }
    }
  }

  single<AdminApiClient> {
    AdminApiClient(get())
  }

  single<HealthProfessionalApiClient> {
    HealthProfessionalApiClient(get())
  }
}

val healthProfessionalRemoteDataSourceModule = module {
  single<AtcClassificationsRemoteDataSource> {
    AtcClassificationsRemoteDataSourceImpl(
      apiClient = get()
    )
  }

  single<DepartmentsRemoteDataSource> {
    DepartmentsRemoteDataSourceImpl(
      apiClient = get()
    )
  }

  single<LaboratoriesRemoteDataSource> {
    LaboratoriesRemoteDataSourceImpl(
      apiClient = get()
    )
  }

  single<LearnedSocietiesRemoteDataSource> {
    LearnedSocietiesRemoteDataSourceImpl(
      apiClient = get()
    )
  }

  single<MedicinesRemoteDataSource> {
    MedicinesRemoteDataSourceImpl(
      apiClient = get()
    )
  }

  single<MessagesRemoteDataSource> {
    MessagesRemoteDataSourceImpl(
      apiClient = get()
    )
  }

  single<UniversityHospitalsRemoteDataSource> {
    UniversityHospitalsRemoteDataSourceImpl(
      apiClient = get()
    )
  }
}
