package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.api.resources.MedicalProfessions
import fr.labodoc.api.routes.AdminMedicalProfessionsRoutes
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminMedicalProfessionsRemoteDataSource(
  private val httpClient: HttpClient
) : AdminMedicalProfessionsRoutes {
  override suspend fun getMedicalProfessions(
  ): ApiResponse<Set<MedicalProfessionResponse>> {
    val response = httpClient.get(MedicalProfessions())

    return if (response.status.isSuccess())
      response.body<Set<MedicalProfessionResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMedicalProfession(
    id: MedicalProfessionId
  ): ApiResponse<MedicalProfessionResponse> {
    val response = httpClient.get(MedicalProfessions.MedicalProfession(id))

    return if (response.status.isSuccess())
      response.body<MedicalProfessionResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createMedicalProfession(
    payload: CreateMedicalProfessionRequest
  ): ApiResponse<MedicalProfessionCreatedResponse> {
    val response = httpClient.post(MedicalProfessions()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<MedicalProfessionCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMedicalProfession(
    id: MedicalProfessionId,
    payload: UpdateMedicalProfessionRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(MedicalProfessions.MedicalProfession(id)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createMedicalSpeciality(
    medicalProfessionId: MedicalProfessionId,
    payload: CreateMedicalSpecialityRequest
  ): ApiResponse<MedicalSpecialityCreatedResponse> {
    val response = httpClient.post(MedicalProfessions.MedicalProfession.MedicalSpecialities(medicalProfessionId)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<MedicalSpecialityCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMedicalSpeciality(
    medicalProfessionId: MedicalProfessionId,
    id: MedicalSpecialityId,
    payload: UpdateMedicalSpecialityRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(
      MedicalProfessions.MedicalProfession.MedicalSpecialities.MedicalSpeciality(
        medicalProfessionId,
        id
      )
    ) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createMedicalInterest(
    medicalProfessionId: MedicalProfessionId,
    medicalSpecialityId: MedicalSpecialityId,
    payload: CreateMedicalInterestRequest
  ): ApiResponse<MedicalInterestCreatedResponse> {
    val response = httpClient.post(
      MedicalProfessions.MedicalProfession.MedicalSpecialities.MedicalSpeciality.MedicalInterests(
        medicalProfessionId,
        medicalSpecialityId
      )
    ) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<MedicalInterestCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateMedicalInterest(
    medicalProfessionId: MedicalProfessionId,
    medicalSpecialityId: MedicalSpecialityId,
    id: MedicalInterestId,
    payload: UpdateMedicalInterestRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(
      MedicalProfessions.MedicalProfession.MedicalSpecialities.MedicalSpeciality.MedicalInterests.MedicalInterest(
        medicalProfessionId,
        medicalSpecialityId,
        id
      )
    ) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
