package fr.labodoc.webapp.pages.admin.partnership

import fr.labodoc.app.data.admin.repository.PartnershipsRepository
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.partnership.form.adminPartnershipForm
import io.kvision.core.Container
import io.kvision.core.onClickLaunch
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminPartnershipCreatePage : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data class Form(
        val createProcessing: ObservableState<Boolean>,
        val error: ObservableState<String?>,
      ) : UiState()

      data class Created(
        val id: PartnershipId
      ) : UiState()
    }

    val uiState: ObservableState<UiState>

    fun createPartnership(
      name: PartnershipName,
      logo: InputFile,
      website: PartnershipWebsite
    )
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val partnershipsRepository: PartnershipsRepository by inject(named("admin"))

    private val createProcessing: ObservableValue<Boolean> =
      ObservableValue(false)

    private val error: ObservableValue<String?> =
      ObservableValue(null)

    override val uiState: ObservableValue<ViewModel.UiState> =
      ObservableValue(ViewModel.UiState.Form(createProcessing, error))

    override fun createPartnership(
      name: PartnershipName,
      logo: InputFile,
      website: PartnershipWebsite
    ) {
      App.scope.launch {
        createProcessing.value = true

        partnershipsRepository
          .createPartnership(
            name = name,
            logo = logo,
            website = website
          )
          .onLeft {
            error.value = "Une erreur est survenue"
          }
          .onRight { id ->
            uiState.value = ViewModel.UiState.Created(
              id = id
            )
          }

        createProcessing.value = false
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-admin-partnership-create"
    require("./css/pages/admin/partnership/create.css")

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1 {
        content = "Créer un partenariat"
      }


      when (uiState) {
        is ViewModel.UiState.Form -> {
          uiState.error.subscribe { error ->
            error?.also { Toast.danger(it) }
          }

          val partnershipForm = adminPartnershipForm(
            currentLogoUrl = null
          )

          labodocButton("Sauvegarder") {
            uiState.createProcessing.subscribe { processing ->
              if (processing) {
                disabled = true
                text = "Traitement"
                icon = "fa fa-spinner fa-spin"
              } else {
                disabled = false
                text = "Sauvegarder"
                icon = null
              }
            }

            onClickLaunch {
              if (partnershipForm.validate()) {
                partnershipForm.getValidatedData()
                  .onLeft {
                    Toast.danger("Le formulaire est invalide")
                  }
                  .onRight { data ->
                    if (data.logo == null)
                      Toast.danger("Le logo est obligatoire")
                    else {
                      viewModel
                        .createPartnership(
                          name = data.name,
                          logo = data.logo,
                          website = data.website
                        )
                    }
                  }
              }
            }
          }
        }

        is ViewModel.UiState.Created -> {
          Toast.success("Partenariat correctement créé")
          App.routing.navigate(Page.AdminBackOfficePartnershipList())
        }
      }
    }
  }
}

fun Container.adminPartnershipCreatePage(): AdminPartnershipCreatePage {
  val adminPartnershipCreatePage = AdminPartnershipCreatePage()

  this.add(adminPartnershipCreatePage)
  return adminPartnershipCreatePage
}
