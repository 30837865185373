package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateLaboratoryRequest
import fr.labodoc.api.payloads.requests.UpdateLaboratoryRequest
import fr.labodoc.api.payloads.responses.LaboratoryCreatedResponse
import fr.labodoc.app.data.admin.model.LaboratoryModel
import fr.labodoc.app.data.admin.model.LaboratorySummaryModel
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite

class LaboratoriesRepositoryImpl(
  private val apiClient: AdminApiClient
) : LaboratoriesRepository {
  override suspend fun getLaboratories(
  ): ApiResponse<Set<LaboratorySummaryModel>> =
    apiClient.laboratories
      .getLaboratories()
      .map { laboratoriesResponse ->
        laboratoriesResponse.laboratories
          .map {
            LaboratorySummaryModel(
              id = it.id,
              name = it.name,
              logoUrl = it.logoUrl,
              activated = it.activated
            )
          }
          .toSet()
      }

  override suspend fun createLaboratory(
    name: LaboratoryName,
    website: LaboratoryWebsite,
    pharmacovigilance: LaboratoryPharmacovigilance?,
    logo: InputFile?
  ): ApiResponse<LaboratoryId> =
    apiClient.laboratories
      .createLaboratory(
        payload = CreateLaboratoryRequest(
          name = name,
          website = website,
          pharmacovigilance = pharmacovigilance
        ),
        logo = logo
      )
      .map { laboratoryCreatedResponse: LaboratoryCreatedResponse ->
        laboratoryCreatedResponse.id
      }

  override suspend fun getLaboratory(
    id: LaboratoryId
  ): ApiResponse<LaboratoryModel> =
    apiClient.laboratories
      .getLaboratory(id)
      .map { laboratoryResponse ->
        val laboratory = laboratoryResponse.laboratory

        LaboratoryModel(
          id = laboratory.id,
          name = laboratory.name,
          website = laboratory.website,
          pharmacovigilance = laboratory.pharmacovigilance,
          logoUrl = laboratory.logoUrl,
          activated = laboratory.activated
        )
      }


  override suspend fun updateLaboratory(
    id: LaboratoryId,
    name: LaboratoryName,
    website: LaboratoryWebsite,
    pharmacovigilance: LaboratoryPharmacovigilance?,
    logo: InputFile?
  ): ApiResponse<Unit> =
    apiClient.laboratories
      .updateLaboratory(
        id = id,
        payload = UpdateLaboratoryRequest(
          name = name,
          website = website,
          pharmacovigilance = pharmacovigilance
        ),
        logo = logo
      )

  override suspend fun activateLaboratory(
    laboratoryId: LaboratoryId
  ): ApiResponse<Unit> =
    apiClient.laboratories
      .activateLaboratory(laboratoryId)

  override suspend fun deactivateLaboratory(
    laboratoryId: LaboratoryId
  ): ApiResponse<Unit> =
    apiClient.laboratories
      .deactivateLaboratory(laboratoryId)
}
