package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.DepartmentResponse
import fr.labodoc.app.data.admin.model.DepartmentModel

class DepartmentsRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : DepartmentsRepository {
  override suspend fun getDepartments(): ApiResponse<Set<DepartmentModel>> =
    apiClient.departments
      .getDepartments()
      .map { departments: Set<DepartmentResponse> ->
        departments
          .map { department: DepartmentResponse ->
            DepartmentModel(
              code = department.code,
              name = department.name
            )
          }
          .toSet()
      }
}
