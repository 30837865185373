package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalMedicineWithInformationResponse(
  val medicine: Medicine
) {
  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val laboratory: Laboratory,
    val cipCode: CipCodeAsString,
    val atcCode: AtcCode,
    val governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseIdAsString?,
    val name: MedicineNameAsString,
    val mainComposition: MedicineMainCompositionAsString,
    val website: MedicineWebsiteAsString?,
    val logoURL: UrlAsString,
    val favorite: Boolean
  ) {
    @Serializable
    data class Laboratory(
      val id: LaboratoryIdAsString,
      val name: LaboratoryNameAsString,
      val website: LaboratoryWebsiteAsString,
      val pharmacovigilance: LaboratoryPharmacovigilanceAsString
    )

    @Serializable
    data class AtcCode(
      val code: AtcCodeAsString,
      val name: AtcNameAsString
    )
  }
}
