package fr.labodoc.webapp.utils

import doist.x.normalize.Form
import doist.x.normalize.normalize

fun String.ellipsis(maxLength: Int): String {
  if (this.length <= maxLength) return this

  return "${this.take(maxLength)}..."
}

fun String.middleEllipsis(maxLength: Int): String {
  // Check if the text needs to be shortened
  if (this.length <= maxLength) return this

  val partLength = (maxLength - 3) / 2 // Calculate length of each part around the ellipsis
  val startPart = this.take(partLength) // Get the starting part of the text
  val endPart = this.takeLast(partLength) // Get the ending part of the text

  return "$startPart...$endPart" // Combine with ellipsis in the middle
}

fun String.containsNormalized(other: String, ignoreCase: Boolean = false): Boolean {
  fun normalize(string: String): String =
    string.normalize(Form.NFD).replace(Regex("[\\u0300-\\u036f]"), "")

  return normalize(this).contains(normalize(other), ignoreCase)
}
