package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyAcronym
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyWebsite
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import io.ktor.http.*

data class LearnedSocietyModel(
  val id: LearnedSocietyId,
  val name: LearnedSocietyName,
  val acronym: LearnedSocietyAcronym?,
  val website: LearnedSocietyWebsite,
  val logoUrl: Url,
  val medicalSpecialities: Set<MedicalSpecialityId>,
  val favorite: Boolean
)
