package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.*
import io.ktor.http.*

data class MedicineModel(
  val id: MedicineId,
  val laboratory: LaboratoryId,
  val cipCode: CipCode,
  val atcCode: AtcCode.Code,
  val governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
  val name: MedicineName,
  val mainComposition: MedicineMainComposition,
  val website: MedicineWebsite?,
  val logoUrl: Url,
  val segmentation: Segmentation,
  val activated: Boolean
)
