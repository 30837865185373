package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.ApplicationIdAsString
import fr.labodoc.api.payloads.serializers.MedicalCardNumberAsString
import io.ktor.resources.*

@Resource("applications")
class Applications {
  @Resource("{applicationId}")
  class Application(val applicationId: ApplicationIdAsString, val applications: Applications = Applications()) {
    @Resource("validate")
    class Validate(val application: Application) {
      constructor(applicationId: ApplicationIdAsString): this(Application(applicationId))
    }

    @Resource("reject")
    class Reject(val application: Application) {
      constructor(applicationId: ApplicationIdAsString): this(Application(applicationId))
    }
  }

  @Resource("email/{emailToken}/validate")
  class ValidateEmail(val emailToken: String, val applications: Applications = Applications())

  @Resource("with-medical-card")
  class WithMedicalCard(val applications: Applications = Applications()) {
    @Resource("cps")
    class CPS(val withMedicalCard: WithMedicalCard = WithMedicalCard())

    @Resource("cpf")
    class CPF(val withMedicalCard: WithMedicalCard = WithMedicalCard())

    @Resource("{cardNumber}")
    class Number(val cardNumber: MedicalCardNumberAsString, val withMedicalCard: WithMedicalCard = WithMedicalCard())
  }

  @Resource("without-medical-card")
  class WithoutMedicalCard(val applications: Applications = Applications())
}
