package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.FreestandingApplicationResponse
import fr.labodoc.app.data.admin.model.FreestandingApplicationModel
import fr.labodoc.domain.labodoc.application.ApplicationId

class ApplicationsRepositoryImpl(
  private val apiClient: AdminApiClient
) : ApplicationsRepository {
  override suspend fun getPendingFreestandingApplications(
  ): ApiResponse<Set<FreestandingApplicationModel>> =
    apiClient.applications
      .getPendingFreestandingApplications()
      .map { freestandingApplications: Set<FreestandingApplicationResponse> ->
        freestandingApplications
          .map { freestandingApplication: FreestandingApplicationResponse ->
            FreestandingApplicationModel(
              id = freestandingApplication.id,
              isEmailValidated = freestandingApplication.isEmailValidated,
              email = freestandingApplication.email,
              medicalSpeciality = freestandingApplication.medicalSpeciality,
              medicalProfession = freestandingApplication.medicalProfession,
              phone = freestandingApplication.phone,
              firstName = freestandingApplication.firstName,
              lastName = freestandingApplication.lastName,
              supportingDocumentUrl = freestandingApplication.supportingDocumentUrl,
              facility = FreestandingApplicationModel.Facility(
                name = freestandingApplication.facility.name,
                unit = freestandingApplication.facility.unit,
                city = freestandingApplication.facility.city,
                departmentCode = freestandingApplication.facility.departmentCode
              ),
              comment = freestandingApplication.comment,
              rppsNumber = freestandingApplication.rppsNumber,
              partnerCode = freestandingApplication.partnerCode
            )
          }
          .toSet()
      }

  override suspend fun validateFreestandingPendingApplication(
    id: ApplicationId
  ): ApiResponse<Unit> =
    apiClient.applications
      .validateFreestandingPendingApplication(id)

  override suspend fun rejectFreestandingPendingApplication(
    id: ApplicationId
  ): ApiResponse<Unit> =
    apiClient.applications
      .rejectFreestandingPendingApplication(id)
}
