package fr.labodoc.app.di

import fr.labodoc.app.data.healthprofessional.repository.*
import org.koin.dsl.module

val healthProfessionalRepositoryModule = module {
  single<AtcCodesRepository> { AtcCodesRepositoryImpl(get()) }
  single<ContactRepository> { ContactRepositoryImpl(get()) }
  single<DepartmentsRepository> { DepartmentsRepositoryImpl(get()) }
  single<HealthDirectoryRepository> { HealthDirectoryRepositoryImpl(get()) }
  single<LaboratoriesRepository> { LaboratoriesRepositoryImpl(get()) }
  single<LearnedSocietiesRepository> { LearnedSocietiesRepositoryImpl(get()) }
  single<MedicalDiplomasRepository> { MedicalDiplomasRepositoryImpl(get()) }
  single<MedicalProfessionsRepository> { MedicalProfessionsRepositoryImpl(get()) }
  single<MedicinesRepository> { MedicineRepositoryImpl(get()) }
  single<MessagesRepository> { MessagesRepositoryImpl(get()) }
  single<UniversityHospitalsRepository> { UniversityHospitalsRepositoryImpl(get()) }
  single<UsersRepository> { UsersRepositoryImpl(get()) }
}
