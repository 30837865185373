package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.MedicalDiplomaResponse
import fr.labodoc.api.resources.MedicalDiplomas
import fr.labodoc.api.routes.HealthProfessionalMedicalDiplomasRoutes
import fr.labodoc.domain.healthdirectory.ProfessionCode
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalMedicalDiplomasRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalMedicalDiplomasRoutes {
  override suspend fun getMedicalDiplomas(
    forHealthDirectoryProfessionCode: ProfessionCode?
  ): ApiResponse<Set<MedicalDiplomaResponse>> {
    val response = httpClient.get(MedicalDiplomas(forHealthDirectoryProfessionCode))

    return if (response.status.isSuccess())
      response.body<Set<MedicalDiplomaResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
