package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class HealthDirectoryHealthProfessionalResponse(
  val nationalIdentifier: HealthProfessionalNationalIdentifierAsString,
  val lastName: String,
  val firstName: String,
  val practices: Set<Practice>,
  val medicalCards: Set<MedicalCard>
) {
  @Serializable
  data class Practice(
    val professionCode: ProfessionCodeAsString,
    val professionalCategoryCode: ProfessionalCategoryCodeAsString,
    val situations: Set<Situation>,
    val expertises: Set<ExpertiseCodeAsString>
  ) {
    @Serializable
    data class Situation(
      val statusCode: ProfessionalStatusCodeAsString?,
      val pharmacistTableSectionCode: PharmacistTableSectionCodeAsString?,
      val facility: Facility?
    ) {
      @Serializable
      data class Facility(
        val id: String,
        val name: String?,
        val siret: String?,
        val siren: String?,
        val finess: String?,
        val phoneNumber: String?,
        val email: String?,
        val street: String?,
        val zipcode: String?,
        val department: DepartmentCodeAsString?,
        val city: String?
      )
    }
  }

  @Serializable
  data class MedicalCard(
    val number: MedicalCardNumberAsString,
    val typeCode: MedicalCardTypeCodeAsString
  )
}
