package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.*
import io.ktor.resources.*
import kotlinx.serialization.SerialName

@Resource("health-directory")
class HealthDirectory {
  @Resource("health-professional")
  class HealthProfessional(val healthDirectory: HealthDirectory = HealthDirectory()) {
    @Resource("per-card/{cardNumber}")
    class PerCard(
      val healthProfessional: HealthProfessional = HealthProfessional(),
      val cardNumber: MedicalCardNumberAsString
    )
  }

  @Resource("health-professionals")
  class HealthProfessionals(
    @SerialName("page[size]")
    val pageSize: Int = 100,
    @SerialName("page[number]")
    val pageNumber: Int = 1,
    val nationalIdentifier: HealthProfessionalNationalIdentifierAsString? = null,
    val name: String? = null,
    val healthRepositoryProfessionCodes: Set<ProfessionCodeAsString>? = null,
    val healthRepositoryProfessionalCategoryCodes: Set<ProfessionalCategoryCodeAsString>? = null,
    val healthRepositoryProfessionalStatusCodes: Set<ProfessionalStatusCodeAsString>? = null,
    val healthRepositoryPharmacistTableSectionCodes: Set<PharmacistTableSectionCodeAsString>? = null,
    val healthRepositoryExpertiseCodes: Set<ExpertiseCodeAsString>? = null,
    val medicalCardNumber: MedicalCardNumberAsString? = null,
    val medicalCardTypeCodes: Set<MedicalCardTypeCodeAsString?>? = null,
    val labodocMedicalProfessionIds: Set<MedicalProfessionIdAsString>? = null,
    val labodocMedicalSpecialitiesIds: Set<MedicalSpecialityIdAsString>? = null,
    val departmentsCodes: Set<DepartmentCodeAsString>? = null,
    val healthDirectory: HealthDirectory = HealthDirectory()
  )

  @Resource("expertises")
  class Expertises(val healthDirectory: HealthDirectory = HealthDirectory())

  @Resource("professions")
  class Professions(val healthDirectory: HealthDirectory = HealthDirectory())

  @Resource("labodoc-medical-profession-speciality")
  class LabodocMedicalProfessionAndSpeciality(
    val professionCode: ProfessionCodeAsString,
    val professionalCategoryCode: ProfessionalCategoryCodeAsString,
    val professionalStatusCode: ProfessionalStatusCodeAsString? = null,
    val expertiseCode: ExpertiseCodeAsString? = null,
    val pharmacistTableSectionCode: PharmacistTableSectionCodeAsString? = null,
    val healthDirectory: HealthDirectory = HealthDirectory()
  )
}
