package fr.labodoc.domain.labodoc.medicine

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.models.AggregateRoot
import kotlin.jvm.JvmInline

/**
 * [Definition](https://fr.wikipedia.org/wiki/Classification_ATC)
 */
class AtcCategory(
  val code: Code,
  val name: Name
) : AggregateRoot<AtcCategory.Code>(code) {
  @JvmInline
  value class Code private constructor(val value: String) {
    companion object {
      val regex: Regex = Regex("^[ABCDGHJLMNPRSV]?\$")

      fun isFormatValid(value: String): Boolean = regex.containsMatchIn(value)

      operator fun invoke(value: String): Either<Errors.AtcCategory.Code.Invalid, Code> =
        either {
          ensure(isFormatValid(value)) { Errors.AtcCategory.Code.Invalid.Format }

          Code(value)
        }
    }
  }

  @JvmInline
  value class Name private constructor(val value: String) {
    companion object {
      const val MAX_LENGTH: Int = 100

      fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH

      fun isNotBlank(value: String): Boolean = value.isNotBlank()

      operator fun invoke(value: String): Either<Errors.AtcCategory.Label.Invalid, Name> =
        either {
          ensure(isNotTooLong(value)) { Errors.AtcCategory.Label.Invalid.TooLong }
          ensure(isNotBlank(value)) { Errors.AtcCategory.Label.Invalid.Blank }

          Name(value)
        }
    }
  }
}
