package fr.labodoc.app.data.healthprofessional.source.remote

import arrow.core.Either
import arrow.core.raise.catch
import arrow.core.raise.either
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.DepartmentResponse
import fr.labodoc.app.data.error.RemoteDataSourceError
import fr.labodoc.app.data.healthprofessional.model.DepartmentModel

class DepartmentsRemoteDataSourceImpl(
  private val apiClient: HealthProfessionalApiClient
) : DepartmentsRemoteDataSource {
  override suspend fun getDepartments(
  ): Either<RemoteDataSourceError, Set<DepartmentModel>> =
    either {
      catch({
        apiClient.departments
          .getDepartments()
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map { response: Set<DepartmentResponse> ->
            response.toModel()
          }
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }
}

private fun Set<DepartmentResponse>.toModel(): Set<DepartmentModel> =
  this
    .map { department: DepartmentResponse ->
      DepartmentModel(
        code = department.code,
        name = department.name,
      )
    }
    .toSet()
