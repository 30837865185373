package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.app.data.healthprofessional.model.*
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageId

class MessagesRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : MessagesRepository {
  override suspend fun getMessages(
    onlyFavorite: Boolean,
    flashInfo: Boolean,
    invitation: Boolean
  ): ApiResponse<Set<MessageSummaryModel>> =
    apiClient.messages
      .getMessages(
        favorite = onlyFavorite,
        flashInfo = flashInfo,
        invitation = invitation
      )
      .map { messagesSummaryResponse: MessagesSummaryResponse ->
        messagesSummaryResponse.messages
          .map { message: MessageSummaryResponse ->
            when (message) {
              is LabodocFlashInfoSummaryResponse -> LabodocFlashInfoSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                title = message.title,
                seen = message.seen,
                favorite = message.favorite
              )

              is LaboratoryFlashInfoSummaryResponse -> LaboratoryFlashInfoSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                laboratory = LaboratoryMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite
              )

              is LaboratoryInvitationSummaryResponse -> LaboratoryInvitationSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                laboratory = LaboratoryMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite,
              )

              is MedicineFlashInfoSummaryResponse -> MedicineFlashInfoSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                laboratory = MedicineMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite
              )

              is MedicineInvitationSummaryResponse -> MedicineInvitationSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                laboratory = MedicineMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite,
              )

              is LearnedSocietyFlashInfoSummaryResponse -> LearnedSocietyFlashInfoSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
                  id = message.learnedSociety.id,
                  name = message.learnedSociety.name,
                  acronym = message.learnedSociety.acronym
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite
              )

              is LearnedSocietyInvitationSummaryResponse -> LearnedSocietyInvitationSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
                  id = message.learnedSociety.id,
                  name = message.learnedSociety.name,
                  acronym = message.learnedSociety.acronym
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite,
              )
            }
          }
          .toSet()
      }

  override suspend fun getMessage(
    id: MessageId
  ): ApiResponse<MessageModel> =
    apiClient.messages
      .getMessage(id)
      .map { messageResponse: MessageResponse ->
        when (messageResponse) {
          is LabodocFlashInfoResponse -> LabodocFlashInfoModel(
            id = messageResponse.id,
            publishedAt = messageResponse.publishedAt,
            title = messageResponse.title,
            content = messageResponse.content,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is LaboratoryFlashInfoResponse -> LaboratoryFlashInfoModel(
            id = messageResponse.id,
            laboratory = LaboratoryMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            publishedAt = messageResponse.publishedAt,
            title = messageResponse.title,
            content = messageResponse.content,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is LaboratoryInvitationResponse -> LaboratoryInvitationModel(
            id = id,
            laboratory = LaboratoryMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            publishedAt = messageResponse.publishedAt,
            eventAt = messageResponse.eventAt,
            title = messageResponse.title,
            content = messageResponse.content,
            formUrl = messageResponse.formUrl,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is MedicineFlashInfoResponse -> MedicineFlashInfoModel(
            id = messageResponse.id,
            laboratory = MedicineMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            medicine = MedicineMessageModel.Medicine(
              id = messageResponse.medicine.id,
              name = messageResponse.medicine.name
            ),
            publishedAt = messageResponse.publishedAt,
            title = messageResponse.title,
            content = messageResponse.content,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is MedicineInvitationResponse -> MedicineInvitationModel(
            id = id,
            laboratory = MedicineMessageModel.Laboratory(
              id = messageResponse.laboratory.id,
              name = messageResponse.laboratory.name
            ),
            medicine = MedicineMessageModel.Medicine(
              id = messageResponse.medicine.id,
              name = messageResponse.medicine.name
            ),
            publishedAt = messageResponse.publishedAt,
            eventAt = messageResponse.eventAt,
            title = messageResponse.title,
            content = messageResponse.content,
            formUrl = messageResponse.formUrl,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is LearnedSocietyFlashInfoResponse -> LearnedSocietyFlashInfoModel(
            id = messageResponse.id,
            learnedSociety = LearnedSocietyMessageModel.LearnedSociety(
              id = messageResponse.learnedSociety.id,
              name = messageResponse.learnedSociety.name
            ),
            publishedAt = messageResponse.publishedAt,
            title = messageResponse.title,
            content = messageResponse.content,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )

          is LearnedSocietyInvitationResponse -> LearnedSocietyInvitationModel(
            id = id,
            learnedSociety = LearnedSocietyMessageModel.LearnedSociety(
              id = messageResponse.learnedSociety.id,
              name = messageResponse.learnedSociety.name
            ),
            publishedAt = messageResponse.publishedAt,
            eventAt = messageResponse.eventAt,
            title = messageResponse.title,
            content = messageResponse.content,
            formUrl = messageResponse.formUrl,
            seen = messageResponse.seen,
            favorite = messageResponse.favorite,
            bannerUrl = messageResponse.bannerUrl,
            documentUrl = messageResponse.documentUrl
          )
        }
      }

  override suspend fun getMessagesForMedicine(
    medicineId: MedicineId
  ): ApiResponse<Set<MedicineMessageSummaryModel>> =
    apiClient.medicines
      .getMessages(medicineId)
      .map { response: Set<MessageSummaryResponse> ->
        response
          .mapNotNull { message: MessageSummaryResponse ->
            when (message) {
              is MedicineFlashInfoSummaryResponse -> MedicineFlashInfoSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                laboratory = MedicineMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite
              )

              is MedicineInvitationSummaryResponse -> MedicineInvitationSummaryModel(
                id = message.id,
                publishedAt = message.publishedAt,
                laboratory = MedicineMessageSummaryModel.Laboratory(
                  id = message.laboratory.id,
                  name = message.laboratory.name
                ),
                title = message.title,
                seen = message.seen,
                favorite = message.favorite,
              )

              else -> null
            }
          }
          .toSet()
      }

  override suspend fun favorite(
    id: MessageId
  ): ApiResponse<Unit> =
    apiClient.messages
      .favoriteMessage(id)

  override suspend fun unfavorite(
    id: MessageId
  ): ApiResponse<Unit> =
    apiClient.messages
      .unfavoriteMessage(id)
}
