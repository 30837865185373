package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.UrlAsString
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalMedicineWithInformationResponse(
  val medicine: Medicine,
  val laboratory: Laboratory?,
  val dosages: Set<Dosage>,
  val marketingDocuments: Set<MarketingDocument>
) {
  @Serializable
  data class Medicine(
    val id: String,
    val name: String,
    val atcClassification: AtcClassification,
    val logoURL: UrlAsString
  )

  @Serializable
  data class AtcClassification(
    val code: String,
    val name: String,
    val shortName: String?,
    val parent: AtcClassification?
  )

  @Serializable
  data class Laboratory(
    val id: String,
    val name: String,
    val website: UrlAsString,
    val pharmacovigilance: UrlAsString
  )

  @Serializable
  data class Dosage(
    val cisCode: String,
    val name: String
  )

  @Serializable
  data class MarketingDocument(
    val id: String,
    val name: String,
    val documentUrl: UrlAsString,
    val version: Int,
    val lastVersionSeen: Int?
  )
}
