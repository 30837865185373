package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.HealthDirectoryExpertiseResponse
import fr.labodoc.api.payloads.responses.HealthDirectoryHealthProfessionalResponse
import fr.labodoc.api.payloads.responses.HealthDirectoryProfessionResponse
import fr.labodoc.api.payloads.responses.PaginatedEntities
import fr.labodoc.app.data.admin.model.HealthDirectoryExpertiseModel
import fr.labodoc.app.data.admin.model.HealthDirectoryProfessionModel
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId

class HealthDirectoryRepositoryImpl(
  private val apiClient: AdminApiClient
) : HealthDirectoryRepository {
  override suspend fun getProfessions(
  ): ApiResponse<Set<HealthDirectoryProfessionModel>> =
    apiClient.healthDirectory
      .getProfessions()
      .map { professions: Set<HealthDirectoryProfessionResponse> ->
        professions
          .map { profession: HealthDirectoryProfessionResponse ->
            HealthDirectoryProfessionModel(
              code = profession.code,
              name = profession.name
            )
          }
          .toSet()
      }

  override suspend fun getExpertises(
  ): ApiResponse<Set<HealthDirectoryExpertiseModel>> =
    apiClient.healthDirectory
      .getExpertises()
      .map { expertises: Set<HealthDirectoryExpertiseResponse> ->
        expertises
          .map { expertise: HealthDirectoryExpertiseResponse ->
            HealthDirectoryExpertiseModel(
              code = expertise.code,
              name = expertise.name
            )
          }
          .toSet()
      }

  override suspend fun getHealthProfessionals(
    pageSize: Int,
    pageNumber: Int,
    nationalIdentifier: NationalIdentifier?,
    name: String?,
    healthRepositoryProfessionCodes: Set<ProfessionCode>?,
    healthRepositoryProfessionalCategoryCodes: Set<ProfessionalCategoryCode>?,
    healthRepositoryProfessionalStatusCodes: Set<ProfessionalStatusCode>?,
    healthRepositoryPharmacistTableSectionCodes: Set<PharmacistTableSection.Code>?,
    healthRepositoryExpertiseCodes: Set<ExpertiseCode>?,
    medicalCardNumber: MedicalCardNumber?,
    medicalCardTypeCodes: Set<MedicalCardTypeCode?>?,
    labodocMedicalProfessionIds: Set<MedicalProfessionId>?,
    labodocMedicalSpecialitiesIds: Set<MedicalSpecialityId>?,
    departmentsCodes: Set<DepartmentCode>?
  ): ApiResponse<PaginatedEntities<HealthDirectoryHealthProfessionalResponse>> =
    apiClient.healthDirectory
      .getHealthProfessionals(
        pageSize = pageSize,
        pageNumber = pageNumber,
        nationalIdentifier = nationalIdentifier,
        name = name,
        healthRepositoryProfessionCodes = healthRepositoryProfessionCodes,
        healthRepositoryProfessionalCategoryCodes = healthRepositoryProfessionalCategoryCodes,
        healthRepositoryProfessionalStatusCodes = healthRepositoryProfessionalStatusCodes,
        healthRepositoryPharmacistTableSectionCodes = healthRepositoryPharmacistTableSectionCodes,
        healthRepositoryExpertiseCodes = healthRepositoryExpertiseCodes,
        medicalCardNumber = medicalCardNumber,
        medicalCardTypeCodes = medicalCardTypeCodes,
        labodocMedicalProfessionIds = labodocMedicalProfessionIds,
        labodocMedicalSpecialitiesIds = labodocMedicalSpecialitiesIds,
        departmentsCodes = departmentsCodes
      )
}
