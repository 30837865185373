package fr.labodoc.app.data.admin.repository

import arrow.core.raise.either
import arrow.core.toNonEmptySetOrNull
import arrow.fx.coroutines.parZip
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.app.data.admin.model.MedicineModel
import fr.labodoc.app.data.admin.model.MedicineModelWithDocuments
import fr.labodoc.app.data.admin.model.MedicineSummaryModel
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.medicine.*
import kotlinx.datetime.Instant

class MedicinesRepositoryImpl(
  private val apiClient: AdminApiClient
) : MedicinesRepository {
  override suspend fun getMedicines(
  ): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getMedicines()
      .map { medicinesResponse ->
        medicinesResponse.medicines
          .map { medicine ->
            MedicineSummaryModel(
              id = medicine.id,
              cipCode = medicine.cipCode,
              atcCode = medicine.atcCode,
              name = medicine.name,
              mainComposition = medicine.mainComposition,
              logoUrl = medicine.logoUrl,
              activated = medicine.activated
            )
          }
          .toSet()
      }

  override suspend fun getMedicinesForLaboratory(
    laboratoryId: LaboratoryId
  ): ApiResponse<Set<MedicineSummaryModel>> =
    apiClient.medicines
      .getMedicines(laboratoryId)
      .map { medicinesResponse ->
        medicinesResponse.medicines
          .map { medicine ->
            MedicineSummaryModel(
              id = medicine.id,
              cipCode = medicine.cipCode,
              atcCode = medicine.atcCode,
              name = medicine.name,
              mainComposition = medicine.mainComposition,
              logoUrl = medicine.logoUrl,
              activated = medicine.activated
            )
          }
          .toSet()
      }

  override suspend fun createMedicine(
    laboratory: LaboratoryId,
    cipCode: CipCode,
    atcCode: AtcCode.Code,
    governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
    name: MedicineName,
    mainComposition: MedicineMainComposition,
    website: MedicineWebsite?,
    segmentation: Segmentation,
    logo: InputFile
  ): ApiResponse<MedicineId> =
    apiClient.medicines
      .createMedicine(
        payload = CreateMedicineRequest(
          laboratory = laboratory,
          cipCode = cipCode,
          atcCode = atcCode,
          governmentPublicDatabaseId = governmentPublicDatabaseId,
          name = name,
          mainComposition = mainComposition,
          website = website,
          segmentation = CreateMedicineRequest.Segmentation(
            medicalProfessions = segmentation.medicalProfessions,
            medicalSpecialities = segmentation.medicalSpecialities,
            medicalInterests = segmentation.medicalInterests,
            professionalCategories = segmentation.professionalCategories,
            professionalStatuses = segmentation.professionalStatuses,
            medicalCardTypes = segmentation.medicalCardTypes
          )
        ),
        logo = logo
      )
      .map { medicineCreatedResponse: MedicineCreatedResponse ->
        medicineCreatedResponse.id
      }

  override suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<MedicineModel> =
    apiClient.medicines
      .getMedicine(id)
      .map { medicineResponse: AdminMedicineResponse ->
        val medicine = medicineResponse.medicine

        MedicineModel(
          id = medicine.id,
          laboratory = medicine.laboratory,
          cipCode = medicine.cipCode,
          atcCode = medicine.atcCode,
          governmentPublicDatabaseId = medicine.governmentPublicDatabaseId,
          name = medicine.name,
          mainComposition = medicine.mainComposition,
          website = medicine.website,
          logoUrl = medicine.logoUrl,
          segmentation = Segmentation(
            medicalProfessions = medicine.segmentation.medicalProfessions?.toNonEmptySetOrNull(),
            medicalSpecialities = medicine.segmentation.medicalSpecialities?.toNonEmptySetOrNull(),
            medicalInterests = medicine.segmentation.medicalInterests?.toNonEmptySetOrNull(),
            professionalCategories = medicine.segmentation.professionalCategories?.toNonEmptySetOrNull(),
            professionalStatuses = medicine.segmentation.professionalStatuses?.toNonEmptySetOrNull(),
            medicalCardTypes = medicine.segmentation.medicalCardTypes?.toNonEmptySetOrNull(),
            departments = null
          ),
          activated = medicine.activated
        )
      }

  override suspend fun getMedicineWithDocuments(id: MedicineId): ApiResponse<MedicineModelWithDocuments> =
    either {
      parZip(
        {
          apiClient.medicines.getMedicine(id).bind()
        },
        {
          apiClient.medicines.getMarketingDocuments(id).bind()
        }
      ) { medicineResponse, marketingDocumentsResponse ->
        val medicine = medicineResponse.medicine
        val marketingDocuments = marketingDocumentsResponse.marketingDocuments

        MedicineModelWithDocuments(
          id = medicine.id,
          laboratory = medicine.laboratory,
          cipCode = medicine.cipCode,
          atcCode = medicine.atcCode,
          name = medicine.name,
          mainComposition = medicine.mainComposition,
          website = medicine.website,
          logoUrl = medicine.logoUrl,
          segmentation = Segmentation(
            medicalProfessions = medicine.segmentation.medicalProfessions?.toNonEmptySetOrNull(),
            medicalSpecialities = medicine.segmentation.medicalSpecialities?.toNonEmptySetOrNull(),
            medicalInterests = medicine.segmentation.medicalInterests?.toNonEmptySetOrNull(),
            professionalCategories = medicine.segmentation.professionalCategories?.toNonEmptySetOrNull(),
            professionalStatuses = medicine.segmentation.professionalStatuses?.toNonEmptySetOrNull(),
            medicalCardTypes = medicine.segmentation.medicalCardTypes?.toNonEmptySetOrNull(),
            departments = null
          ),
          marketingDocuments = marketingDocuments
            .map { marketingDocument ->
              MedicineModelWithDocuments.MarketingDocument(
                id = marketingDocument.id,
                name = marketingDocument.name,
                segmentation = Segmentation(
                  medicalProfessions = marketingDocument.segmentation.medicalProfessions?.toNonEmptySetOrNull(),
                  medicalSpecialities = marketingDocument.segmentation.medicalSpecialities?.toNonEmptySetOrNull(),
                  medicalInterests = marketingDocument.segmentation.medicalInterests?.toNonEmptySetOrNull(),
                  professionalCategories = null,
                  professionalStatuses = marketingDocument.segmentation.professionalStatuses?.toNonEmptySetOrNull(),
                  medicalCardTypes = marketingDocument.segmentation.medicalCardTypes?.toNonEmptySetOrNull(),
                  departments = null
                ),
                documentUrl = marketingDocument.documentUrl,
                expireAt = marketingDocument.expireAt
              )
            }
            .toSet(),
          activated = medicine.activated
        )
      }
    }

  override suspend fun updateMedicine(
    id: MedicineId,
    laboratory: LaboratoryId,
    cipCode: CipCode,
    atcCode: AtcCode.Code,
    governmentPublicDatabaseId: MedicineGovernmentPublicDatabaseId?,
    name: MedicineName,
    mainComposition: MedicineMainComposition,
    website: MedicineWebsite?,
    segmentation: Segmentation,
    logo: InputFile?
  ): ApiResponse<Unit> =
    apiClient.medicines
      .updateMedicine(
        id = id,
        payload = UpdateMedicineRequest(
          laboratory = laboratory,
          cipCode = cipCode,
          atcCode = atcCode,
          governmentPublicDatabaseId = governmentPublicDatabaseId,
          name = name,
          mainComposition = mainComposition,
          website = website,
          segmentation = UpdateMedicineRequest.Segmentation(
            medicalProfessions = segmentation.medicalProfessions,
            medicalSpecialities = segmentation.medicalSpecialities,
            medicalInterests = segmentation.medicalInterests,
            professionalCategories = segmentation.professionalCategories,
            professionalStatuses = segmentation.professionalStatuses,
            medicalCardTypes = segmentation.medicalCardTypes
          )
        ),
        logo = logo
      )

  override suspend fun deleteMedicine(
    id: MedicineId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deleteMedicine(id)

  override suspend fun addMarketingDocument(
    medicine: MedicineId,
    name: MarketingDocumentName,
    segmentation: Segmentation,
    document: InputFile,
    expireAt: Instant?
  ): ApiResponse<MarketingDocumentId> =
    apiClient.medicines
      .addMarketingDocument(
        forMedicine = medicine,
        payload = CreateMarketingDocumentRequest(
          name = name,
          segmentation = CreateMarketingDocumentRequest.Segmentation(
            medicalProfessions = segmentation.medicalProfessions,
            medicalSpecialities = segmentation.medicalSpecialities,
            medicalInterests = segmentation.medicalInterests,
            professionalStatuses = segmentation.professionalStatuses,
            medicalCardTypes = segmentation.medicalCardTypes
          ),
          expireAt = expireAt
        ),
        document = document
      )
      .map { marketingDocumentCreatedResponse: MarketingDocumentCreatedResponse ->
        marketingDocumentCreatedResponse.id
      }

  override suspend fun updateMarketingDocument(
    medicine: MedicineId,
    id: MarketingDocumentId,
    name: MarketingDocumentName,
    segmentation: Segmentation,
    document: InputFile?,
    expireAt: Instant?
  ): ApiResponse<Unit> =
    apiClient.medicines
      .updateMarketingDocument(
        forMedicine = medicine,
        id = id,
        payload = UpdateMarketingDocumentRequest(
          name = name,
          segmentation = UpdateMarketingDocumentRequest.Segmentation(
            medicalProfessions = segmentation.medicalProfessions,
            medicalSpecialities = segmentation.medicalSpecialities,
            medicalInterests = segmentation.medicalInterests,
            professionalStatuses = segmentation.professionalStatuses,
            medicalCardTypes = segmentation.medicalCardTypes
          ),
          expireAt = expireAt
        ),
        document = document
      )

  override suspend fun deleteMarketingDocument(
    medicine: MedicineId,
    id: MarketingDocumentId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deleteMarketingDocument(medicine, id)

  override suspend fun activateMedicine(
    id: MedicineId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .activate(id)

  override suspend fun deactivateMedicine(
    id: MedicineId
  ): ApiResponse<Unit> =
    apiClient.medicines
      .deactivate(id)
}
