package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.MessageIdAsString
import io.ktor.resources.*

@Resource("messages")
class Messages(
  val onlyFavorite: Boolean? = null,
  val flashInfo: Boolean? = null,
  val invitation: Boolean? = null
) {
  @Resource("{messageId}")
  class Message(val messageId: MessageIdAsString, val messages: Messages = Messages()) {
    @Resource("favorite")
    class Favorite(val message: Message) {
      constructor(messageId: MessageIdAsString) : this(Message(messageId))
    }
  }
}
