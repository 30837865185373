package fr.labodoc.webapp.components

import arrow.core.nonEmptySetOf
import arrow.core.toNonEmptySetOrNull
import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.repository.SegmentationTemplatesRepository
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalprofession.MedicalProfessionId
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateId
import fr.labodoc.domain.labodoc.segmentationtemplate.SegmentationTemplateName
import fr.labodoc.require
import fr.labodoc.webapp.App
import io.kvision.core.*
import io.kvision.form.*
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.MutableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import io.kvision.utils.SnOn
import kotlinx.coroutines.launch
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named
import org.w3c.dom.Node

object MySegmentationAsStringSerializer: KSerializer<Segmentation> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("SegmentationAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(mySegmentation: String): Segmentation {
    val parts = mySegmentation.split("|")

    return Segmentation(
      medicalProfessions = parts[0].ifBlank { null }?.split(",")?.map { MedicalProfessionId(uuidFrom(it)) }?.toNonEmptySetOrNull(),
      medicalSpecialities = parts[1].ifBlank { null }?.split(",")?.map { MedicalSpecialityId(uuidFrom(it)) }?.toNonEmptySetOrNull(),
      medicalInterests = parts[2].ifBlank { null }?.split(",")?.map { MedicalInterestId(uuidFrom(it)) }?.toNonEmptySetOrNull(),
      professionalCategories = parts[3].ifBlank { null }?.split(",")?.map { ProfessionalCategoryCode(it) }?.toNonEmptySetOrNull(),
      professionalStatuses = parts[4].ifBlank { null }?.split(",")?.map { ProfessionalStatusCode(it) }?.toNonEmptySetOrNull(),
      medicalCardTypes = parts[5].ifBlank { null }?.split(",")?.map { MedicalCardTypeCode(it) }?.toNonEmptySetOrNull(),
      departments = parts[6].ifBlank { null }?.split(",")?.mapNotNull { DepartmentCode(it).getOrNull() }?.toNonEmptySetOrNull()
    )
  }

  override fun deserialize(decoder: Decoder): Segmentation =
    deserialize(decoder.decodeString())

  fun serialize(mySegmentation: Segmentation): String = buildString {
    append(mySegmentation.medicalProfessions?.joinToString(",") { it.value.toString() } ?: "")
    append("|")
    append(mySegmentation.medicalSpecialities?.joinToString(",") { it.value.toString() } ?: "")
    append("|")
    append(mySegmentation.medicalInterests?.joinToString(",") { it.value.toString() } ?: "")
    append("|")
    append(mySegmentation.professionalCategories?.joinToString(",") { it.value } ?: "")
    append("|")
    append(mySegmentation.professionalStatuses?.joinToString(",") { it.value } ?: "")
    append("|")
    append(mySegmentation.medicalCardTypes?.joinToString(",") { it.value } ?: "")
    append("|")
    append(mySegmentation.departments?.joinToString(",") { it.value } ?: "")
  }

  override fun serialize(encoder: Encoder, value: Segmentation) =
    encoder.encodeString(serialize(value))
}

typealias SegmentationAsString = @Serializable(with = MySegmentationAsStringSerializer::class) Segmentation

class LabodocSegmentationInput(
  medicalProfessions: Set<MedicalProfessionModel>?,
  professionalCategories: Set<Pair<ProfessionalCategoryCode, ProfessionalCategoryName>>?,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>?,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>?,
  departments: Set<Pair<DepartmentCode, DepartmentName>>?,
  readOnly: Boolean?,
  init: (LabodocSegmentationInput.() -> Unit)? = null
) : SimplePanel("labodoc-segmentation-input"), FormInput, GenericFormComponent<String?>, MutableState<String?>, KoinComponent {
  private val segmentationTemplatesRepository: SegmentationTemplatesRepository by inject(named("admin"))

  private val observers = mutableListOf<(String?) -> Unit>()

  override var disabled: Boolean by refreshOnUpdate(false)
  override var name: String? by refreshOnUpdate()
  override var size: InputSize? by refreshOnUpdate()
  override var validationStatus: ValidationStatus? by refreshOnUpdate()
  override var value: String? by refreshOnUpdate {
    observers.forEach { ob -> ob(it) }
  }

  var data: Segmentation
    set(value) {
      this.value = if (value.isEmpty)
        null
      else
        MySegmentationAsStringSerializer.serialize(value)
    }
    get() = value?.let { MySegmentationAsStringSerializer.deserialize(it) } ?: Segmentation(null, null, null, null, null, null, null)

  @Serializable
  data class SegmentationTemplateDataFormData(
    val name: String
  )

  private val modal = Div(className = "modal") Modal@ {
    val container = div(className = "container") {
      if (readOnly == true) {
        div(className = "header") {
          h4("Voir la segmentation")
        }
        div(className = "content") {
          this@LabodocSegmentationInput.subscribe {

            div {
                medicalProfessions?.forEach { medicalProfession ->
                  medicalProfession.medicalSpecialities.forEach { medicalSpeciality ->
                    if (data.medicalSpecialities?.contains(medicalSpeciality.id) == true) {
                      span(medicalSpeciality.name.value, className = "segmentation-tag")
                    }
                  }
                }
            }

            div {
              data.professionalCategories?.map { span(professionalCategories?.firstOrNull{category -> category.first.value == it.value}?.second?.value, className = "segmentation-tag") }
            }

            div {
              data.professionalStatuses?.map { span(professionalStatuses?.firstOrNull{status -> status.first.value == it.value}?.second?.value, className = "segmentation-tag") }
            }

            div {
              data.medicalCardTypes?.map { span(medicalCardTypes?.firstOrNull{status -> status.first.value == it.value}?.second?.value, className = "segmentation-tag") }
            }
          }
        }
      } else {
        div(className = "header") {
          h4("Modifier la segmentation")
        }
        div(className = "content") {
          val segmentationTemplateOptions: ObservableValue<List<StringPair>> = ObservableValue(listOf())
          val selectedSegmentationTemplate: ObservableValue<Uuid?> = ObservableValue(null)
          div (className = "segmentation-template-select-container") {
              labodocSelect {
              label = "Charger une segmentation sauvegardée"
                segmentationTemplateOptions.subscribe {
                  options = it
                }
              subscribe {
                if (it != null) {
                  selectedSegmentationTemplate.setState(uuidFrom(it))
                }
              }
              onClick {
                App.scope.launch {
                  segmentationTemplatesRepository.getSegmentationTemplates(null).map { segmentationTemplateResponse -> segmentationTemplateOptions.setState(segmentationTemplateResponse.map { it.id.value.toString() to it.name.value})  }
                }
              }
            }

            labodocButton("Importer") {
              onClick {
                if (selectedSegmentationTemplate.getState() != null) {
                  App.scope.launch {
                    segmentationTemplatesRepository
                      .getSegmentationTemplate(SegmentationTemplateId(selectedSegmentationTemplate.getState()!!))
                      .onLeft { Toast.danger("Une erreur est survenue") }
                      .onRight {
                        Toast.success("Segmentation importée")
                          data = data.copy(
                            medicalProfessions = it.segmentation.medicalProfessions,
                            medicalSpecialities = it.segmentation.medicalSpecialities,
                            medicalInterests = it.segmentation.medicalInterests,
                            professionalCategories = it.segmentation.professionalCategories,
                            professionalStatuses = it.segmentation.professionalStatuses,
                            medicalCardTypes = it.segmentation.medicalCardTypes
                          )
                      }
                  }
                }
              }
            }
          }

          hr {  }

          medicalProfessions?.let { medicalProfessions ->
            labodocSelectCheckbox(label = "Spécialités") {
              options = medicalProfessions.fold(mutableMapOf()) { acc, medicalProfession ->
                acc[medicalProfession.name.value] = medicalProfession.medicalSpecialities.map { medicalSpeciality ->
                  medicalSpeciality.id.value.toString() to medicalSpeciality.name.value
                }
                acc
              }

              this@LabodocSegmentationInput.subscribe {
                values = data.medicalSpecialities?.map { it.value.toString() }
              }

              subscribe {
                val selectedMedicalSpecialities = values?.mapNotNull {
                  try {
                    MedicalSpecialityId(uuidFrom(it))
                  } catch (e: Exception) {
                    null
                  }
                }.orEmpty().toSet()

                val selectedMedicalProfessions = mutableSetOf<MedicalProfessionId>()
                medicalProfessions.forEach { medicalProfession ->
                  medicalProfession.medicalSpecialities.forEach { medicalSpeciality ->
                    if (medicalSpeciality.id in selectedMedicalSpecialities) {
                      selectedMedicalProfessions.add(medicalProfession.id)
                    }
                  }
                }

                data = data.copy(
                  medicalProfessions = selectedMedicalProfessions.toNonEmptySetOrNull(),
                  medicalSpecialities = selectedMedicalSpecialities.toNonEmptySetOrNull()
                )
              }
            }

            labodocSelectCheckbox(label = "Centres d'intérets") {
              this@LabodocSegmentationInput.subscribe {
                val checkedMedicalSpecialitiesId = data.medicalSpecialities

                val medicalSpecialities = medicalProfessions.flatMap { medicalProfession ->
                  medicalProfession.medicalSpecialities.map { medicalSpeciality ->
                    medicalProfession to medicalSpeciality
                  }
                }.toSet()

                val medicalInterestsOptions: Map<String?, List<StringPair>> = checkedMedicalSpecialitiesId?.mapNotNull { checkedMedicalSpecialityId ->
                  val match = medicalSpecialities.find { it.second.id == checkedMedicalSpecialityId }

                  if (match != null && match.second.medicalInterests.isNotEmpty()) {
                    "${match.first.name.value} - ${match.second.name.value}" to match.second.medicalInterests.map { it.id.value.toString() to it.name.value }
                  } else
                    null
                }?.toMap<String?, List<StringPair>>().orEmpty()

                if (medicalInterestsOptions.isEmpty())
                  hide()
                else
                  show()

                options = medicalInterestsOptions

                values = data.medicalInterests?.map { it.value.toString() }
              }

              subscribe {
                val selectedMedicalInterests = values?.mapNotNull {
                  try {
                    MedicalInterestId(uuidFrom(it))
                  } catch (e: Exception) {
                    null
                  }
                }.orEmpty().toSet()

                data = data.copy(
                  medicalInterests = selectedMedicalInterests.toNonEmptySetOrNull()
                )
              }
            }
          }

          professionalCategories?.let { professionalCategories ->
            div(className = "form-group") {
              p("Catégories professionel", className = "form-label")
              professionalCategories.map { (professionalCategoryCode, professionalCategoryName) ->
                labodocCheckbox {
                  label = professionalCategoryName.value

                  this@LabodocSegmentationInput.subscribe {
                    value = data.professionalCategories?.contains(professionalCategoryCode) ?: false
                  }

                  subscribe {
                    val newProfessionalCategories = if (it)
                      data.professionalCategories?.plus(professionalCategoryCode)?.toNonEmptySetOrNull()
                        ?: nonEmptySetOf(professionalCategoryCode)
                    else
                      data.professionalCategories?.minus(professionalCategoryCode)?.toNonEmptySetOrNull()

                    data = data.copy(professionalCategories = newProfessionalCategories)
                  }
                }
              }
            }
          }

          professionalStatuses?.let { professionalStatuses ->
            div(className = "form-group") {
              p("Situations professionel", className = "form-label")
              professionalStatuses.map { (professionalStatusCode, professionalStatusName) ->
                labodocCheckbox {
                  label = professionalStatusName.value

                  this@LabodocSegmentationInput.subscribe {
                    value = data.professionalStatuses?.contains(professionalStatusCode) ?: false
                  }

                  subscribe {
                    val newProfessionalStatuses = if (it)
                      data.professionalStatuses?.plus(professionalStatusCode)?.toNonEmptySetOrNull() ?: nonEmptySetOf(
                        professionalStatusCode
                      )
                    else
                      data.professionalStatuses?.minus(professionalStatusCode)?.toNonEmptySetOrNull()

                    data = data.copy(professionalStatuses = newProfessionalStatuses)
                  }
                }
              }
            }
          }

          medicalCardTypes?.let { medicalCardTypes ->
            div(className = "form-group") {
              p("Types de carte", className = "form-label")
              medicalCardTypes.map { (medicalCardTypeCode, medicalCardTypeName) ->
                labodocCheckbox {
                  label = medicalCardTypeName.value

                  this@LabodocSegmentationInput.subscribe {
                    value = data.medicalCardTypes?.contains(medicalCardTypeCode) ?: false
                  }

                  subscribe {
                    val newMedicalCardTypes = if (it)
                      data.medicalCardTypes?.plus(medicalCardTypeCode)?.toNonEmptySetOrNull() ?: nonEmptySetOf(
                        medicalCardTypeCode
                      )
                    else
                      data.medicalCardTypes?.minus(medicalCardTypeCode)?.toNonEmptySetOrNull()

                    data = data.copy(medicalCardTypes = newMedicalCardTypes)
                  }
                }
              }
            }
          }

          departments?.let { departments ->
            labodocSelectCheckbox(label = "Départments") {
              options = mapOf(null to departments.sortedBy { it.first.value }.map { it.first.value to "${it.first.value} - ${it.second.value}" })

              this@LabodocSegmentationInput.subscribe {
                values = data.departments?.map { it.value }
              }

              subscribe {
                data = data.copy(departments = values?.mapNotNull { DepartmentCode(it).getOrNull() }?.toNonEmptySetOrNull())
              }
            }
          }

          labodocButton("Enregistrer cette segmentation", icon= "fa-solid fa-floppy-disk", className= "save-this-segmentation-btn") {
            onClick { closeModal(); openSaveModal() }
          }

          labodocButton("Sauvegarder") {
            onClick { closeModal() }
          }
        }
      }
    }

    onClick { event ->
      val target = event.target

      if ((target !is Node || container.getElement()?.contains(target) == false) && this@Modal.hasCssClass("open"))
        closeModal()
    }
  }

  private val saveModal = Div(className = "modal") Modal@ {
    val container = div(className = "container") {
      div(className = "header") {
        h4("Sauvegarder la segmentation selectionée")
      }

      div(className = "content") {
        val segmentationSaveForm = formPanel<SegmentationTemplateDataFormData> {

          labodocText {
            label = "Nom de la segmentation"
          }.bindCustom(
            SegmentationTemplateDataFormData::name
          )
        }

        this@LabodocSegmentationInput.subscribe {

          div {
            medicalProfessions?.forEach { medicalProfession ->
              medicalProfession.medicalSpecialities.forEach { medicalSpeciality ->
                if (data.medicalSpecialities?.contains(medicalSpeciality.id) == true) {
                  span(medicalSpeciality.name.value, className = "segmentation-tag")
                }
              }
            }
          }

          div {
            data.professionalCategories?.map { span(professionalCategories?.firstOrNull{category -> category.first.value == it.value}?.second?.value, className = "segmentation-tag") }
          }

          div {
            data.professionalStatuses?.map { span(professionalStatuses?.firstOrNull{status -> status.first.value == it.value}?.second?.value, className = "segmentation-tag") }
          }

          div {
            data.medicalCardTypes?.map { span(medicalCardTypes?.firstOrNull{status -> status.first.value == it.value}?.second?.value, className = "segmentation-tag") }
          }
        }
        labodocButton("Sauvegarder") {
          onClick { saveSegmentationTemplate(segmentationSaveForm) }
        }
      }
    }

    onClick { event ->
      val target = event.target

      if ((target !is Node || container.getElement()?.contains(target) == false) && this@Modal.hasCssClass("open"))
        closeSaveModal()
    }
  }

  private val cssOpenClass = "open"

  fun openModal() {
    modal.addCssClass(cssOpenClass)
  }

  fun closeModal() {
    modal.removeCssClass(cssOpenClass)
  }

  fun openSaveModal() {
    saveModal.addCssClass(cssOpenClass)
  }

  fun closeSaveModal() {
    saveModal.removeCssClass(cssOpenClass)
  }

  fun saveSegmentationTemplate(segmentationSaveForm: FormPanel<SegmentationTemplateDataFormData>) {
    val formData = segmentationSaveForm.form.getData()
    App.scope.launch {
      segmentationTemplatesRepository
        .createSegmentationTemplate(
          name = SegmentationTemplateName(formData.name),
          segmentation = data
        )
        .onLeft { Toast.danger("Une erreur est survenue") }
        .onRight { Toast.success("Segmentation sauvegardée"); closeSaveModal() }
    }
  }

  init {
    require("./css/components/labodoc-segmentation.css")

    add(modal)
    add(saveModal)

    div (className = "segmentation-button-container") {
      labodocButton("Voir segmentation") {
        onClick { openModal() }
      }
    }

    div (className = "selected-information").bind(this) {
      medicalProfessions?.let {
        p {
          content = "Specialité(s) sélectionné(s) : ${data.medicalSpecialities?.size ?: 0}"
        }

        p {
          content = "Centre(s) d'intérêt(s) sélectionné(s): ${data.medicalInterests?.size ?: 0}"
        }
      }

      professionalCategories?.let {
        p {
          content = "Catégorie(s) professionnelle(s) sélectionnée(s) : ${data.professionalCategories?.size ?: 0}"
        }
      }

      professionalStatuses?.let {
        p {
          content = "Situation(s) professionnelle(s) sélectionnée(s) : ${data.professionalStatuses?.size ?: 0}"
        }
      }

      medicalCardTypes?.let {
        p {
          content = "Type(s) de carte sélectionné(s) : ${data.medicalCardTypes?.size ?: 0}"
        }
      }

      departments?.let {
        p {
          content = "Départment(s) sélectionné(s) : ${data.departments?.size ?: 0}"
        }
      }
    }

    init?.invoke(this)
  }

  override fun subscribe(observer: (String?) -> Unit): () -> Unit {
    observers += observer
    observer(value)
    return {
      observers -= observer
    }
  }

  override fun getState(): String? = value

  override fun setState(state: String?) {
    value = state
  }
}

fun Container.labodocSegmentationInput(
  medicalProfessions: Set<MedicalProfessionModel>?,
  professionalCategories: Set<Pair<ProfessionalCategoryCode, ProfessionalCategoryName>>?,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>?,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>?,
  departments: Set<Pair<DepartmentCode, DepartmentName>>?,
  readOnly: Boolean? = false,
  init: (LabodocSegmentationInput.() -> Unit)? = null
): LabodocSegmentationInput {
  val labodocSegmentationInput = LabodocSegmentationInput(medicalProfessions, professionalCategories, professionalStatuses, medicalCardTypes, departments, readOnly, init)
  this.add(labodocSegmentationInput)
  return labodocSegmentationInput
}


class LabodocSegmentation(
  val medicalProfessions: Set<MedicalProfessionModel>?,
  val professionalCategories: Set<Pair<ProfessionalCategoryCode, ProfessionalCategoryName>>?,
  val professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>?,
  val medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>?,
  val departments: Set<Pair<DepartmentCode, DepartmentName>>?,
  name: String? = null,
  label: String? = null, rich: Boolean = false,
  readOnly: Boolean? = false,
  init: (LabodocSegmentation.() -> Unit)? = null
): SimplePanel("form-group labodoc-segmentation"), StringFormControl, MutableState<String?> {
  private val idc = "labodoc_segmentation_$counter"

  override var value: String?
    get() = input.value
    set(value) {
      input.value = value
    }

  var data
    get() = input.data
    set(value) {
      input.data = value
    }

  var label
    get() = flabel.content
    set(value) {
      flabel.content = value
    }

  var rich
    get() = flabel.rich
    set(value) {
      flabel.rich = value
    }


  override val input: LabodocSegmentationInput = LabodocSegmentationInput(
    medicalProfessions, professionalCategories, professionalStatuses, medicalCardTypes, departments, readOnly).apply {
    this.id = this@LabodocSegmentation.idc
    this.name = name
  }
  override val flabel: FieldLabel = FieldLabel(idc, label, rich, "form-label")
  override val invalidFeedback: InvalidFeedback = InvalidFeedback().apply { visible = false }

  init {
    input.eventTarget = this
    this.addPrivate(flabel)
    this.addPrivate(input)
    this.addPrivate(invalidFeedback)
    counter++

    init?.invoke(this)
  }


  override fun buildClassSet(classSetBuilder: ClassSetBuilder) {
    super.buildClassSet(classSetBuilder)
    if (validatorError != null) {
      classSetBuilder.add("text-danger")
    }
  }

  override fun <T : Widget> setEventListener(block: SnOn<T>.() -> Unit): Int {
    return input.setEventListener(block)
  }

  override fun removeEventListener(id: Int) {
    input.removeEventListener(id)
  }

  override fun removeEventListeners() {
    input.removeEventListeners()
  }

  override fun add(child: Component) {
    input.add(child)
  }

  override fun add(position: Int, child: Component) {
    input.add(position, child)
  }

  override fun addAll(children: List<Component>) {
    input.addAll(children)
  }

  override fun remove(child: Component) {
    input.remove(child)
  }

  override fun removeAt(position: Int) {
    input.removeAt(position)
  }

  override fun removeAll() {
    input.removeAll()
  }

  override fun disposeAll() {
    input.disposeAll()
  }

  override fun getChildren(): List<Component> {
    return input.getChildren()
  }

  override fun focus() {
    input.focus()
  }

  override fun blur() {
    input.blur()
  }


  override fun subscribe(observer: (String?) -> Unit): () -> Unit {
    return input.subscribe(observer)
  }

  override fun getState(): String? = input.getState()

  override fun setState(state: String?) {
    input.setState(state)
  }

  companion object {
    internal var counter = 0
  }
}

fun Container.labodocSegmentation(
  medicalProfessions: Set<MedicalProfessionModel>?,
  professionalCategories: Set<Pair<ProfessionalCategoryCode, ProfessionalCategoryName>>?,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>?,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>?,
  departments: Set<Pair<DepartmentCode, DepartmentName>>?,
  name: String? = null,
  label: String? = null, rich: Boolean = false,
  readOnly: Boolean? = false,
  init: (LabodocSegmentation.() -> Unit)? = null
): LabodocSegmentation {
  val labodocSegmentation = LabodocSegmentation(
    medicalProfessions, professionalCategories, professionalStatuses, medicalCardTypes, departments, name, label, rich, readOnly, init
  )
  this.add(labodocSegmentation)
  return labodocSegmentation
}
