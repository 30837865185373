package fr.labodoc.webapp.pages.admin.medicalProfessions.form

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestName
import fr.labodoc.webapp.components.LabodocText
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class AdminMedicalInterestForm : FormPanel<AdminMedicalInterestForm.Data>(
  serializer = Data.serializer(),
  className = "admin-medical-interest-form"
) {
  @Serializable
  data class Data(
    val name: String? = null
  )

  data class ValidatedData(
    val name: MedicalInterestName
  )

  private val nameInput = LabodocText {
    label = "Nom"
  }.bind(
    key = Data::name,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validateName(it.value).isRight() },
    validatorMessage = { validateName(it.value).leftOrNull() }
  )

  private fun validateName(input: String?): Either<String, MedicalInterestName> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      MedicalInterestName(input)
    }

  init {
    add(nameInput)
  }

  suspend fun getValidatedData(): Either<Nel<String>, ValidatedData> =
    either {
      val inputs = getDataWithFileContent()

      zipOrAccumulate(
        {
          validateName(inputs.name)
            .onLeft { nameInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${nameInput.label}: $it" }
            .bind()
        },
        {
        }
      ) { name, _ ->
        ValidatedData(
          name = name
        )
      }
    }
}
