package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.*
import fr.labodoc.api.payloads.responses.AdminUsersResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.LoginResponse
import fr.labodoc.api.payloads.responses.SelfResponse
import fr.labodoc.api.resources.Users
import fr.labodoc.api.routes.AdminUsersRoutes
import fr.labodoc.domain.labodoc.user.UserId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.http.*

class AdminUsersRemoteDataSource(
  private val httpClient: HttpClient
) : AdminUsersRoutes {
  override suspend fun getUsers(
  ): ApiResponse<AdminUsersResponse> {
    val response = httpClient.get(Users())

    return if (response.status.isSuccess())
      response.body<AdminUsersResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun enableUser(
    id: UserId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.User.Activate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun disableUser(
    id: UserId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.User.Deactivate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deleteUser(
    id: UserId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Users.User(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun login(
    payload: LoginRequest
  ): ApiResponse<LoginResponse> {
    val response = httpClient.post(Users.Login()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      response.body<LoginResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun logout(
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.Self.Logout())

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun changePassword(
    payload: ChangePasswordRequest
  ): ApiResponse<Unit> {
    val response = httpClient.put(Users.Self.Password()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun sendPasswordResetRequest(
    payload: PasswordResetRequest
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.Password.Reset()) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun finalizePasswordResetRequest(
    token: String,
    payload: FinalizePasswordResetRequest
  ): ApiResponse<Unit> {
    val response = httpClient.post(Users.Password.Reset.Validate(token)) {
      contentType(ContentType.Application.Json)
      setBody(payload)
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getSelf(
  ): ApiResponse<SelfResponse> {
    val response = httpClient.get(Users.Self())

    return if (response.status.isSuccess())
      response.body<SelfResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }
}

