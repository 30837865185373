package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.app.data.healthprofessional.model.MedicalDiplomaModel
import fr.labodoc.domain.healthdirectory.ProfessionCode

class MedicalDiplomasRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : MedicalDiplomasRepository {
  override suspend fun getMedicalDiplomas(
    forHealthDirectoryProfession: ProfessionCode?
  ): ApiResponse<Set<MedicalDiplomaModel>> =
    apiClient.medicalDiplomas
      .getMedicalDiplomas(forHealthDirectoryProfession)
      .map { medicalDiplomas ->
        medicalDiplomas
          .map { medicalDiploma ->
            MedicalDiplomaModel(
              id = medicalDiploma.id,
              name = medicalDiploma.name,
              healthDirectoryProfessionCode = medicalDiploma.healthDirectoryProfessionCode,
              labodocMedicalSpeciality = MedicalDiplomaModel.MedicalSpeciality(
                id = medicalDiploma.labodocMedicalSpeciality.id,
                medicalProfession = MedicalDiplomaModel.MedicalSpeciality.MedicalProfession(
                  id = medicalDiploma.labodocMedicalSpeciality.medicalProfession.id,
                  name = medicalDiploma.labodocMedicalSpeciality.medicalProfession.name
                ),
                name = medicalDiploma.labodocMedicalSpeciality.name
              )
            )
          }
          .toSet()
      }
}
