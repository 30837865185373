package fr.labodoc.webapp.pages.admin.message.form

import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.healthdirectory.*
import fr.labodoc.domain.labodoc.department.DepartmentCode
import fr.labodoc.domain.labodoc.department.DepartmentName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.*
import io.ktor.http.*
import io.kvision.form.time.dateTime
import io.kvision.html.div
import io.kvision.html.h3
import io.kvision.html.image
import io.kvision.i18n.I18n
import io.kvision.types.KFile
import io.kvision.utils.getContent
import kotlinx.coroutines.launch
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlin.js.Date

class AdminLabodocFlashInfoMessageForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalCategories: Set<Pair<ProfessionalCategoryCode, ProfessionalCategoryName>>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
  departments: Set<Pair<DepartmentCode, DepartmentName>>,
  bannerLogoUrl: Url? = null,
  initialData: Data? = null
) : AdminMessageForm<AdminLabodocFlashInfoMessageForm.Data>(
  serializer = Data.serializer(),
  className = "labodoc-flashinfo-message-form"
) {
  @Serializable
  class Data(
    @Contextual override val publishedAt: Date? = null,
    override val title: String? = null,
    override val text: String? = null,
    override val segmentation: SegmentationAsString? = null,
    override val document: List<KFile>? = null,
    override val banner: List<KFile>? = null
  ): AdminMessageFormData

  init {
    require("./css/pages/admin/message/form/labodoc-flashinfo.css")

    div(className = "message") {
      h3("Information du message :")

      div(className = "fields") {
        dateTime {
          addCssClass("published-at")
          label = "Publié le"
          format = "DD/MM/YYYY HH:mm"
          value = initialData?.publishedAt
        }.bind(
          key = Data::publishedAt,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )

        labodocText {
          addCssClass("title")
          label = "Titre"
          value = initialData?.title
        }.bindCustom(
          key = Data::title,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { AdminMessageFormData.validateTitle(it) == null },
          validatorMessage = { AdminMessageFormData.validateTitle(it) }
        )

        labodocTextArea {
          addCssClass("text")
          label = "Text"
          value = initialData?.text
        }.bindCustom(
          key = Data::text,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )

        labodocSegmentation(
          medicalProfessions,
          professionalCategories,
          professionalStatuses,
          medicalCardTypes,
          departments
        ) {
          addCssClass("segmentation")
          label = "Segmentation"
          initialData?.segmentation?.let { data = it }
        }.bindCustom(
          key = Data::segmentation,
          required = true,
          requiredMessage = I18n.tr("Field.Required"),
          validator = { AdminMessageFormData.validateSegmentation(it) == null },
          validatorMessage = { AdminMessageFormData.validateSegmentation(it) }
        )

        div(className = "document") {
          labodocUpload {
            label = "Document"
            accept = null
            multiple = false
          }.bind(
            Data::document,
            required = false,
            requiredMessage = I18n.tr("Field.Required"),
            validator = null,
            validatorMessage = null
          )
        }
      }

      div(className = "banner") {
        val bannerPreview = image(null)

        labodocUpload {
          label = "Bannière"
          accept = listOf("image/*")
          multiple = false

          subscribe { files ->
            App.scope.launch {
              bannerPreview.src =
                files?.firstOrNull()?.let { getNativeFile(it)?.getContent() } ?: bannerLogoUrl?.toString()
            }
          }
        }.bind(
          Data::banner,
          required = false,
          requiredMessage = I18n.tr("Field.Required"),
          validator = null,
          validatorMessage = null
        )
      }
    }
  }
}
