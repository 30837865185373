package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.DepartmentModel
import fr.labodoc.app.data.healthprofessional.source.remote.DepartmentsRemoteDataSource

class DepartmentsRepositoryImpl(
  private val departmentsRemoteDataSource: DepartmentsRemoteDataSource
) : DepartmentsRepository {
  override suspend fun getDepartments(
  ): Either<RepositoryError.DataSource, Set<DepartmentModel>> =
    departmentsRemoteDataSource
      .getDepartments()
      .mapLeft(RepositoryError::DataSource)
}
