package fr.labodoc.webapp.pages.healthProfessional.profile

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.core.raise.ensureNotNull
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestId
import fr.labodoc.domain.labodoc.medicalinterest.MedicalInterestName
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocCheckbox
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocCheckbox
import fr.labodoc.webapp.components.labodocSpinner
import io.kvision.core.Container
import io.kvision.core.onInput
import io.kvision.html.div
import io.kvision.html.p
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class HealthProfessionalProfileMedicalInterestsPage : SimplePanel() {

  private sealed class ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data class Loaded(
        val medicalInterests: List<MedicalInterestData>
      ) : UiState()

      data object Error : UiState()
    }

    data class MedicalInterestData(
      val id: MedicalInterestId,
      val name: MedicalInterestName,
      val checked: Boolean
    )

    abstract val uiState: ObservableState<UiState>

    abstract val updateMedicalInterestResponse: ObservableState<ApiResponse<Unit>?>

    abstract fun updateMedicalInterests(medicalInterests: Set<MedicalInterestId>)
  }

  private class ViewModelImpl : ViewModel(), KoinComponent {
    private val userRepository: UsersRepository by inject()
    private val medicalProfessionRepository: MedicalProfessionsRepository by inject()

    override val uiState: ObservableState<UiState> by lazy {
      val observableValue: ObservableValue<UiState> = ObservableValue(UiState.Loading)

      App.scope.launch {
        val uiState = either {
          parZip(
            {
              val user = userRepository.getSelf()
                .mapLeft { UiState.Error }
                .bind()

              ensure(user is HealthProfessionalUserModel) { UiState.Error }
              user
            },
            {
              medicalProfessionRepository
                .getMedicalProfessions()
                .mapLeft { UiState.Error }
                .bind()
            }
          ) { self, medicalProfessions ->
            val medicalProfession =
              ensureNotNull(medicalProfessions.find { it.id == self.medicalProfession.id }) { UiState.Error }
            val medicalSpeciality =
              ensureNotNull(medicalProfession.medicalSpecialities.find { it.id == self.medicalSpeciality.id }) { UiState.Error }

            val medicalInterests = medicalSpeciality.medicalInterests
              .map { medicalInterest ->
                MedicalInterestData(
                  id = medicalInterest.id,
                  name = medicalInterest.name,
                  checked = medicalInterest.id in self.medicalInterests.map { it.id }
                )
              }

            UiState.Loaded(medicalInterests)
          }
        }.fold(
          { it },
          { it }
        )

        observableValue.setState(uiState)
      }

      observableValue
    }

    override val updateMedicalInterestResponse: ObservableValue<ApiResponse<Unit>?> =
      ObservableValue(null)

    override fun updateMedicalInterests(medicalInterests: Set<MedicalInterestId>) {
      App.scope.launch {
        val response = userRepository.updateMedicalInterests(medicalInterests)

        updateMedicalInterestResponse.setState(response)
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    viewModel.updateMedicalInterestResponse.subscribe {
      when (it) {
        is Either.Left -> {
          Toast.danger("Une erreur est survenue, veuillez réessayer")
        }

        is Either.Right -> {
          Toast.success("Centres d'intérêts correctement mis à jour")
        }

        null -> {}
      }
    }

    id = "page-health-professional-profile-medical-interests"
    require("./css/pages/healthProfessional/profile/medical-interests.css")

    div(className = "page-width") {
      div().bind(viewModel.uiState) { uiState ->
        when (uiState) {
          ViewModel.UiState.Loading -> labodocSpinner()

          ViewModel.UiState.Error -> {
            Toast.danger("Une erreur est survenue")
            //window.history.back()
          }

          is ViewModel.UiState.Loaded -> {
            val medicalInterestsCheckboxes = uiState.medicalInterests
              .map { medicalInterest ->
                medicalInterest.id to LabodocCheckbox(
                  value = medicalInterest.checked,
                  label = medicalInterest.name.value
                )
              }

            p(className = "title") {
              content = "Gérer vos centres d'intérêts"
            }

            p(className = "text") {
              content =
                "Choisissez les thématiques qui vous intéressent et vous aurez accès à toutes les communications et propositions de formation."
            }

            labodocCheckbox(
              label = "Tout cocher",
              value = medicalInterestsCheckboxes.all { it.second.value }
            ) {
              addCssClass("all")

              onInput {
                medicalInterestsCheckboxes
                  .forEach { it.second.value = value }
              }
            }

            div(className = "choices") {
              medicalInterestsCheckboxes.forEach {
                add(it.second)
              }
            }

            labodocButton("Enregistrer mes préférences", className = "labodoc-background-yellow") {
              onClick {
                val checkedMedicalInterests = medicalInterestsCheckboxes
                  .filter { it.second.value }
                  .map { it.first }
                  .toSet()

                viewModel.updateMedicalInterests(checkedMedicalInterests)
              }
            }
          }
        }
      }
    }
  }
}

fun Container.healthProfessionalMedicalInterestsPage(): HealthProfessionalProfileMedicalInterestsPage {
  val healthProfessionalProfileMedicalInterestsPage = HealthProfessionalProfileMedicalInterestsPage()
  this.add(healthProfessionalProfileMedicalInterestsPage)
  return healthProfessionalProfileMedicalInterestsPage
}
