package fr.labodoc.webapp.pages.login.sections

import arrow.core.Either
import arrow.core.EitherNel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.require
import fr.labodoc.webapp.components.LabodocCheckbox
import fr.labodoc.webapp.components.LabodocText
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.html.Autocomplete
import io.kvision.html.InputType
import io.kvision.html.button
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class LoginForm(
  val emailAddress: EmailAddress?,
  val password: Password?,
  val rememberMe: Boolean,
  val onForgottenPasswordClick: () -> Unit
) : FormPanel<LoginForm.Data>(
  className = "login",
  serializer = Data.serializer()
) {
  @Serializable
  data class Data(
    val emailAddress: String? = null,
    val password: String? = null,
    val rememberMe: Boolean = false
  )

  data class ValidatedData(
    val emailAddress: EmailAddress,
    val password: Password,
    val rememberMe: Boolean
  )

  private val emailAddressInput = LabodocText {
    addCssClass("email")
    input.autocomplete = Autocomplete.USERNAME
    label = I18n.tr("LoginForm.Email")
    value = emailAddress?.value
  }.bind(
    key = Data::emailAddress,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validateEmailAddress(it.value).isRight() },
    validatorMessage = { validateEmailAddress(it.value).leftOrNull() }
  )

  private val passwordInput = LabodocText {
    type = InputType.PASSWORD
    label = I18n.tr("LoginForm.Password")
    addCssClass("password")
    input.autocomplete = Autocomplete.CURRENT_PASSWORD

    button(I18n.tr("LoginForm.ForgottenPassword"), className = "forgot-password") {
      onClick {
        onForgottenPasswordClick()
      }
    }
  }.bind(
    key = Data::password,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validatePassword(it.value).isRight() },
    validatorMessage = { validatePassword(it.value).leftOrNull() }
  )

  private val rememberMeInput = LabodocCheckbox {
    addCssClass("remember-me")
    label = I18n.tr("LoginForm.RememberMe")
    value = rememberMe
  }.bind(
    key = Data::rememberMe,
    required = false,
    requiredMessage = I18n.tr("Field.Required"),
    validator = null,
    validatorMessage = null
  )

  override fun dispose() {}


  init {
    require("./css/pages/login/sections/login-form.css")

    add(emailAddressInput)
    add(passwordInput)
    add(rememberMeInput)
  }

  private fun validateEmailAddress(input: String?): Either<String, EmailAddress> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      EmailAddress(input)
        .mapLeft { error ->
          when (error) {
            Errors.EmailAddress.Invalid.Format -> I18n.tr("Errors.EmailAddress.Invalid")
            Errors.EmailAddress.Invalid.TooLong -> I18n.tr("Errors.EmailAddress.Invalid")
          }
        }
        .bind()
    }

  private fun validatePassword(input: String?): Either<String, Password> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      Password(input)
        .mapLeft { error ->
          when (error) {
            Errors.Password.Invalid.NoDigit ->I18n.tr("Errors.Password.Invalid")
            Errors.Password.Invalid.NoLowercaseLetter -> I18n.tr("Errors.Password.Invalid")
            Errors.Password.Invalid.NoSpecialCharacter -> I18n.tr("Errors.Password.Invalid")
            Errors.Password.Invalid.NoUppercaseLetter -> I18n.tr("Errors.Password.Invalid")
            Errors.Password.Invalid.TooLong -> I18n.tr("Errors.Password.Invalid")
            Errors.Password.Invalid.TooShort -> I18n.tr("Errors.Password.Invalid")
          }
        }
        .bind()
    }

  suspend fun getValidatedData(): EitherNel<String, ValidatedData> =
    either {
      val inputs = getDataWithFileContent()

      zipOrAccumulate(
        {
          validateEmailAddress(inputs.emailAddress)
            .onLeft { emailAddressInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${emailAddressInput.label}: $it" }
            .onRight { emailAddressInput.validatorError = null }
            .bind()
        },
        {
          validatePassword(inputs.password)
            .onLeft { passwordInput.validatorError = it }
            .mapLeft { "Erreur sur le champ ${passwordInput.label}: $it" }
            .onRight { passwordInput.validatorError = null }
            .bind()
        },
        {
          inputs.rememberMe
        }
      ) { emailAddress, password, rememberMe ->
        ValidatedData(
          emailAddress = emailAddress,
          password = password,
          rememberMe = rememberMe
        )
      }
    }
}

fun Container.loginForm(
  emailAddress: EmailAddress?,
  password: Password?,
  rememberMe: Boolean,
  onForgottenPasswordClick: () -> Unit
): LoginForm {
  val loginForm = LoginForm(
    emailAddress = emailAddress,
    password = password,
    rememberMe = rememberMe,
    onForgottenPasswordClick = onForgottenPasswordClick
  )

  this.add(loginForm)
  return loginForm
}
