package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.DepartmentCodeAsString
import fr.labodoc.api.payloads.serializers.DepartmentNameAsString
import fr.labodoc.api.payloads.serializers.UniversityHospitalIdAsString
import fr.labodoc.api.payloads.serializers.UniversityHospitalNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class UniversityHospitalResponse(
  val id: UniversityHospitalIdAsString,
  val name: UniversityHospitalNameAsString,
  val department: Department
) {
  @Serializable
  data class Department(
    val code: DepartmentCodeAsString,
    val name: DepartmentNameAsString
  )
}
