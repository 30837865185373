package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.form.select.Select
import io.kvision.form.select.SelectInput
import io.kvision.html.span

class LabodocSelect(
  options: List<StringPair>? = null, value: String? = null, emptyOption: Boolean = false,
  multiple: Boolean = false,
  selectSize: Int? = null,
  name: String? = null, label: String? = null, rich: Boolean = false, notice: String? = null,
  init: (LabodocSelect.() -> Unit)? = null
) : Select(options, value, emptyOption, multiple, selectSize, name, label, rich, false, null) {

  var notice by refreshOnUpdate(notice) {
    spanNotice.content = it
  }

  private val spanNotice = span(className = "notice")

  init {
    require("./css/components/labodoc-select.css")

    addCssClass("labodoc-select")

    add(spanNotice)

    init?.invoke(this)
  }
}


/**
 * DSL builder extension function.
 *
 * It takes the same parameters as the constructor of the built component.
 */
fun Container.labodocSelect(
  options: List<StringPair>? = null,
  value: String? = null,
  emptyOption: Boolean = false,
  multiple: Boolean = false,
  selectSize: Int? = null,
  name: String? = null,
  label: String? = null,
  rich: Boolean = false,
  notice: String? = null,
  init: (LabodocSelect.() -> Unit)? = null
): LabodocSelect {
  val select = LabodocSelect(options, value, emptyOption, multiple, selectSize, name, label, rich, notice, init)
  this.add(select)
  return select
}

class LabodocSelectInput(
  options: List<StringPair>? = null, value: String? = null, emptyOption: Boolean = false,
  multiple: Boolean = false,
  selectSize: Int? = null,
  className: String? = null, init: (LabodocSelectInput.() -> Unit)? = null
) : SelectInput(options, value, emptyOption, multiple, selectSize, className, null) {
  init {
    require("./css/components/labodoc-select.css")

    addCssClass("labodoc-select")

    init?.invoke(this)
  }
}

fun Container.labodocSelectInput(
  options: List<StringPair>? = null, value: String? = null, emptyOption: Boolean = false,
  multiple: Boolean = false,
  selectSize: Int? = null,
  className: String? = null,
  init: (LabodocSelectInput.() -> Unit)? = null
): LabodocSelectInput {
  val selectInput = LabodocSelectInput(options, value, emptyOption, multiple, selectSize, className, init)
  this.add(selectInput)
  return selectInput
}

