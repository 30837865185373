package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.MessageResponse
import fr.labodoc.api.payloads.responses.MessagesSummaryResponse
import fr.labodoc.api.resources.Messages
import fr.labodoc.api.routes.HealthProfessionalMessagesRoutes
import fr.labodoc.domain.labodoc.message.MessageId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalMessagesRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalMessagesRoutes {
  override suspend fun getMessages(
    favorite: Boolean,
    flashInfo: Boolean,
    invitation: Boolean
  ): ApiResponse<MessagesSummaryResponse> {
    val response = httpClient.get(Messages(onlyFavorite = favorite, flashInfo = flashInfo, invitation = invitation))

    return if (response.status.isSuccess())
      response.body<MessagesSummaryResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMessage(
    id: MessageId
  ): ApiResponse<MessageResponse> {
    val response = httpClient.get(Messages.Message(id))

    return if (response.status.isSuccess())
      response.body<MessageResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun favoriteMessage(
    id: MessageId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Messages.Message.Favorite(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun unfavoriteMessage(
    id: MessageId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Messages.Message.Favorite(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
