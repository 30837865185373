package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
sealed class AdminMessageSummaryResponse {
  abstract val id: MessageIdAsString
  abstract val publishedAt: Instant
  abstract val title: MessageTitleAsString

  @Serializable
  data class Laboratory(
    val id: LaboratoryIdAsString,
    val name: LaboratoryNameAsString
  )

  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val name: MedicineNameAsString
  )

  @Serializable
  data class LearnedSociety(
    val id: LearnedSocietyIdAsString,
    val name: LearnedSocietyNameAsString
  )
}

@Serializable
data class AdminLabodocFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

@Serializable
data class AdminLaboratoryFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

@Serializable
data class AdminLaboratoryInvitationSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

@Serializable
data class AdminMedicineFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

@Serializable
data class AdminMedicineInvitationSummaryResponse(
  override val id: MessageIdAsString,
  val laboratory: Laboratory,
  val medicine: Medicine,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

@Serializable
data class AdminLearnedSocietyFlashInfoSummaryResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

@Serializable
data class AdminLearnedSocietyInvitationSummaryResponse(
  override val id: MessageIdAsString,
  val learnedSociety: LearnedSociety,
  override val publishedAt: Instant,
  override val title: MessageTitleAsString
) : AdminMessageSummaryResponse()

