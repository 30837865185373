package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.AtcClassificationCodeAsString
import fr.labodoc.api.payloads.serializers.LaboratoryIdAsString
import fr.labodoc.api.payloads.serializers.MedicineNameAsString
import kotlinx.serialization.Serializable

@Serializable
data class CreateMedicineRequest(
  val laboratory: LaboratoryIdAsString?,
  val name: MedicineNameAsString,
  val atcClassificationCode: AtcClassificationCodeAsString,
)
