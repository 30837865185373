package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class AdminMarketingDocumentsResponse(
  val marketingDocuments: Set<MarketingDocument>
) {
  @Serializable
  data class MarketingDocument(
    val id: MarketingDocumentIdAsString,
    val name: MarketingDocumentNameAsString,
    val segmentation: Segmentation,
    val documentUrl: UrlAsString,
    val expireAt: Instant?
  ) {
    @Serializable
    data class Segmentation(
      val medicalProfessions: Set<MedicalProfessionIdAsString>?,
      val medicalSpecialities: Set<MedicalSpecialityIdAsString>?,
      val medicalInterests: Set<MedicalInterestIdAsString>?,
      val professionalStatuses: Set<ProfessionalStatusCodeAsString>?,
      val medicalCardTypes: Set<MedicalCardTypeCodeAsString>?
    )
  }
}
