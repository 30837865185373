package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.PartnerCodeAsString
import io.ktor.resources.*

@Resource("partners")
class Partners {
  @Resource("{partnerCode}")
  class Partner(
    val partnerCode: PartnerCodeAsString,
    val partners: Partners = Partners()
  )
}
