package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.LearnedSocietyAcronymAsString
import fr.labodoc.api.payloads.serializers.LearnedSocietyNameAsString
import fr.labodoc.api.payloads.serializers.LearnedSocietyWebsiteAsString
import fr.labodoc.api.payloads.serializers.MedicalSpecialityIdAsString
import kotlinx.serialization.Serializable

@Serializable
data class UpdateLearnedSocietyRequest(
  val name: LearnedSocietyNameAsString,
  val acronym: LearnedSocietyAcronymAsString?,
  val website: LearnedSocietyWebsiteAsString,
  val medicalSpecialities: Set<MedicalSpecialityIdAsString>
)
