package fr.labodoc.app.data.admin.repository

import arrow.core.right
import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.MedicalCardTypeModel
import fr.labodoc.domain.healthdirectory.MedicalCardTypeCode
import fr.labodoc.domain.healthdirectory.MedicalCardTypeLabel

class MedicalCardTypesRepositoryImpl(
  private val apiClient: AdminApiClient
) : MedicalCardTypesRepository {
  //TODO
  override suspend fun getMedicalCardTypes(
  ): ApiResponse<Set<MedicalCardTypeModel>> =
    setOf(
      MedicalCardTypeModel(
        code = MedicalCardTypeCode("CPS"),
        label = MedicalCardTypeLabel("CPS")
      ),
      MedicalCardTypeModel(
        code = MedicalCardTypeCode("CPF"),
        label = MedicalCardTypeLabel("CPF")
      ),
      MedicalCardTypeModel(
        code = MedicalCardTypeCode("NONE"),
        label = MedicalCardTypeLabel("Pas de carte")
      )
    ).right()
}
