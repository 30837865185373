package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import arrow.core.right
import fr.labodoc.domain.healthdirectory.ProfessionalStatusCode
import fr.labodoc.domain.healthdirectory.ProfessionalStatusName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object ProfessionalStatusCodeAsStringSerializer : KSerializer<ProfessionalStatusCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ProfessionalStatusCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(professionalStatusCode: String): Either<Nothing, ProfessionalStatusCode> =
    ProfessionalStatusCode(professionalStatusCode).right()

  override fun deserialize(decoder: Decoder): ProfessionalStatusCode =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(professionalStatusCode: ProfessionalStatusCode): String =
    professionalStatusCode.value

  override fun serialize(encoder: Encoder, value: ProfessionalStatusCode) =
    encoder.encodeString(serialize(value))
}

typealias ProfessionalStatusCodeAsString = @Serializable(ProfessionalStatusCodeAsStringSerializer::class) ProfessionalStatusCode


object ProfessionalStatusNameAsStringSerializer : KSerializer<ProfessionalStatusName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("ProfessionalStatusNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(professionalStatusLabel: String): Either<Nothing, ProfessionalStatusName> =
    ProfessionalStatusName(professionalStatusLabel).right()

  override fun deserialize(decoder: Decoder): ProfessionalStatusName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(professionalStatusName: ProfessionalStatusName): String =
    professionalStatusName.value

  override fun serialize(encoder: Encoder, value: ProfessionalStatusName) =
    encoder.encodeString(serialize(value))
}

typealias ProfessionalStatusNameAsString = @Serializable(ProfessionalStatusNameAsStringSerializer::class) ProfessionalStatusName
