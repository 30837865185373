package fr.labodoc.api.routes

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateMedicalDiplomaRequest
import fr.labodoc.api.payloads.requests.UpdateMedicalDiplomaRequest
import fr.labodoc.api.payloads.responses.MedicalDiplomaIdResponse
import fr.labodoc.api.payloads.responses.MedicalDiplomaResponse
import fr.labodoc.domain.healthdirectory.ProfessionCode
import fr.labodoc.domain.labodoc.medicaldiploma.MedicalDiplomaId

interface AdminMedicalDiplomasRoutes {
  suspend fun getMedicalDiplomas(
    forHealthDirectoryProfessionCode: ProfessionCode? = null
  ): ApiResponse<Set<MedicalDiplomaResponse>>

  suspend fun createMedicalDiploma(
    payload: CreateMedicalDiplomaRequest
  ): ApiResponse<MedicalDiplomaIdResponse>

  suspend fun updateMedicalDiploma(
    id: MedicalDiplomaId,
    payload: UpdateMedicalDiplomaRequest
  ): ApiResponse<Unit>

  suspend fun deleteMedicalDiploma(
    id: MedicalDiplomaId
  ): ApiResponse<Unit>
}
