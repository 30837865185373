package fr.labodoc.app.data.healthprofessional.source.remote

import arrow.core.Either
import arrow.core.raise.catch
import arrow.core.raise.either
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.app.data.error.RemoteDataSourceError
import fr.labodoc.app.data.healthprofessional.model.*
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageId

class MessagesRemoteDataSourceImpl(
  private val apiClient: HealthProfessionalApiClient
) : MessagesRemoteDataSource {
  override suspend fun getMessages(
    onlyFavorite: Boolean,
    flashInfo: Boolean,
    invitation: Boolean
  ): Either<RemoteDataSourceError, Set<MessageSummaryModel>> =
    either {
      catch({
        apiClient.messages
          .getMessages(
            favorite = onlyFavorite,
            flashInfo = flashInfo,
            invitation = invitation
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map(MessagesSummaryResponse::toModel)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun getMessagesForMedicine(
    medicineId: MedicineId
  ): Either<RemoteDataSourceError, Set<MedicineMessageSummaryModel>> =
    either {
      catch({
        apiClient.medicines
          .getMessages(
            id = medicineId
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map { response: Set<MessageSummaryResponse> ->
            response
              .mapNotNull { messageSummaryResponse: MessageSummaryResponse ->
                val model = messageSummaryResponse.toModel()
                if (model is MedicineFlashInfoSummaryModel || model is MedicineInvitationSummaryModel)
                  model
                else
                  null
              }
              .toSet()
          }
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun getMessage(
    id: MessageId
  ): Either<RemoteDataSourceError, MessageModel> =
    either {
      catch({
        apiClient.messages
          .getMessage(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map(MessageResponse::toModel)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun favoriteMessage(
    id: MessageId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.messages
          .favoriteMessage(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun unfavoriteMessage(
    id: MessageId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.messages
          .unfavoriteMessage(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }
}

private fun MessagesSummaryResponse.toModel(): Set<MessageSummaryModel> =
  this.messages
    .map(MessageSummaryResponse::toModel)
    .toSet()

private fun MessageSummaryResponse.toModel(): MessageSummaryModel =
  when (this) {
    is LabodocFlashInfoSummaryResponse -> LabodocFlashInfoSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LaboratoryFlashInfoSummaryResponse -> LaboratoryFlashInfoSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      laboratory = LaboratoryMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LaboratoryInvitationSummaryResponse -> LaboratoryInvitationSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      laboratory = LaboratoryMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      title = this.title,
      seen = this.seen,
      favorite = this.favorite,
    )

    is MedicineFlashInfoSummaryResponse -> MedicineFlashInfoSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      laboratory = MedicineMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is MedicineInvitationSummaryResponse -> MedicineInvitationSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      laboratory = MedicineMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      title = this.title,
      seen = this.seen,
      favorite = this.favorite,
    )

    is LearnedSocietyFlashInfoSummaryResponse -> LearnedSocietyFlashInfoSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
        id = this.learnedSociety.id,
        name = this.learnedSociety.name,
        acronym = this.learnedSociety.acronym
      ),
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LearnedSocietyInvitationSummaryResponse -> LearnedSocietyInvitationSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
        id = this.learnedSociety.id,
        name = this.learnedSociety.name,
        acronym = this.learnedSociety.acronym
      ),
      title = this.title,
      seen = this.seen,
      favorite = this.favorite,
    )
  }

private fun MessageResponse.toModel(): MessageModel =
  when (this) {
    is LabodocFlashInfoResponse -> LabodocFlashInfoModel(
      id = this.id,
      publishedAt = this.publishedAt,
      title = this.title,
      content = this.content,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )

    is LaboratoryFlashInfoResponse -> LaboratoryFlashInfoModel(
      id = this.id,
      laboratory = LaboratoryMessageModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      content = this.content,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )

    is LaboratoryInvitationResponse -> LaboratoryInvitationModel(
      id = this.id,
      laboratory = LaboratoryMessageModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      publishedAt = this.publishedAt,
      eventAt = this.eventAt,
      title = this.title,
      content = this.content,
      formUrl = this.formUrl,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )

    is MedicineFlashInfoResponse -> MedicineFlashInfoModel(
      id = this.id,
      laboratory = MedicineMessageModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      medicine = MedicineMessageModel.Medicine(
        id = this.medicine.id,
        name = this.medicine.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      content = this.content,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )

    is MedicineInvitationResponse -> MedicineInvitationModel(
      id = this.id,
      laboratory = MedicineMessageModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      medicine = MedicineMessageModel.Medicine(
        id = this.medicine.id,
        name = this.medicine.name
      ),
      publishedAt = this.publishedAt,
      eventAt = this.eventAt,
      title = this.title,
      content = this.content,
      formUrl = this.formUrl,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )

    is LearnedSocietyFlashInfoResponse -> LearnedSocietyFlashInfoModel(
      id = this.id,
      learnedSociety = LearnedSocietyMessageModel.LearnedSociety(
        id = this.learnedSociety.id,
        name = this.learnedSociety.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      content = this.content,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )

    is LearnedSocietyInvitationResponse -> LearnedSocietyInvitationModel(
      id = this.id,
      learnedSociety = LearnedSocietyMessageModel.LearnedSociety(
        id = this.learnedSociety.id,
        name = this.learnedSociety.name
      ),
      publishedAt = this.publishedAt,
      eventAt = this.eventAt,
      title = this.title,
      content = this.content,
      formUrl = this.formUrl,
      seen = this.seen,
      favorite = this.favorite,
      bannerUrl = this.bannerUrl,
      documentUrl = this.documentUrl
    )
  }
