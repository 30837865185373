package fr.labodoc.domain.labodoc.common

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

/**
 * This type is mostly here to ensure that we manipulate an email address and not a random string.
 * It is quite mostly impossible to have a valid regex to have a valid email address.
 * The best way to be sure that the email address is valid is to verify the email address by sending an email.
 * However, this type will make some assumptions about the validity of the email address and assume that our users are French.
 *
 * https://codefool.tumblr.com/post/15288874550/list-of-valid-and-invalid-email-addresses
 * https://stackoverflow.com/a/2071250
 * https://www.regular-expressions.info/email.html
 */
@JvmInline
value class EmailAddress private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 50
    val regex: Regex = Regex("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}\$")

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH
    fun isFormatValid(value: String): Boolean = regex.containsMatchIn(value)

    operator fun invoke(value: String): Either<Errors.EmailAddress.Invalid, EmailAddress> =
      either {
        ensure(isNotTooLong(value)) { Errors.EmailAddress.Invalid.TooLong }
        ensure(isFormatValid(value)) { Errors.EmailAddress.Invalid.Format }

        EmailAddress(value.lowercase())
      }
  }
}
