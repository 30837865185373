package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.LaboratoriesResponse
import fr.labodoc.api.resources.Laboratories
import fr.labodoc.api.routes.HealthProfessionalLaboratoriesRoutes
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalLaboratoriesRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalLaboratoriesRoutes {
  override suspend fun getLaboratories(
  ): ApiResponse<LaboratoriesResponse> {
    val response = httpClient.get(Laboratories())

    return if (response.status.isSuccess())
      response.body<LaboratoriesResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun favoriteLaboratory(
    id: LaboratoryId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Laboratories.Laboratory.Favorite(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun unfavoriteLaboratory(
    id: LaboratoryId
  ): ApiResponse<Unit> {
    val response = httpClient.delete(Laboratories.Laboratory.Favorite(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
