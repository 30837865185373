package fr.labodoc.api.resources

import fr.labodoc.api.payloads.serializers.MedicalDiplomaIdAsString
import fr.labodoc.api.payloads.serializers.ProfessionCodeAsString
import io.ktor.resources.*

@Resource("medical-diplomas")
class MedicalDiplomas(
  val healthDirectoryProfessionCode: ProfessionCodeAsString? = null
) {
  @Resource("{medicalDiplomaId}")
  class MedicalDiploma(
    val medicalDiplomaId: MedicalDiplomaIdAsString,
    val medicalDiplomas: MedicalDiplomas = MedicalDiplomas(),
  )
}
