package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalMedicinesSummaryResponse(
  val medicines: Set<Medicine>
) {
  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val laboratory: Laboratory,
    val cipCode: CipCodeAsString,
    val atcCode: AtcCodeAsString,
    val name: MedicineNameAsString,
    val mainComposition: MedicineMainCompositionAsString,
    val logoUrl: UrlAsString,
    val favorite: Boolean,
    val unreadDocuments: Int
  ) {
    @Serializable
    data class Laboratory(
      val id: LaboratoryIdAsString,
      val name: LaboratoryNameAsString
    )
  }
}
