package fr.labodoc.webapp.pages.healthProfessional.dashboard

import fr.labodoc.require
import fr.labodoc.webapp.pages.healthProfessional.dashboard.components.MedicalItems
import fr.labodoc.webapp.pages.healthProfessional.dashboard.components.Messages
import io.kvision.core.Container
import io.kvision.html.div
import io.kvision.panel.SimplePanel

class HealthProfessionalDashboardPage : SimplePanel() {
  init {
    id = "page-health-professional-dashboard"
    require("./css/pages/healthProfessional/dashboard/dashboard.css")

    div(className = "medical-items-with-messages") {
      add(Messages())

      add(MedicalItems())
    }
  }
}

fun Container.healthProfessionalDashboardPage(): HealthProfessionalDashboardPage {
  val healthProfessionalDashboardPage = HealthProfessionalDashboardPage()

  this.add(healthProfessionalDashboardPage)
  return healthProfessionalDashboardPage
}

