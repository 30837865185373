package fr.labodoc.app.data.healthprofessional.repository

import fr.labodoc.api.ApiResponse
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.app.data.healthprofessional.model.HealthDirectoryExpertiseModel
import fr.labodoc.app.data.healthprofessional.model.HealthDirectoryProfessionModel
import fr.labodoc.app.data.healthprofessional.model.MedicalProfessionAndSpecialityModel
import fr.labodoc.domain.healthdirectory.*

class HealthDirectoryRepositoryImpl(
  private val apiClient: HealthProfessionalApiClient
) : HealthDirectoryRepository {
  override suspend fun getProfessions(
  ): ApiResponse<Set<HealthDirectoryProfessionModel>> =
    apiClient.healthDirectory
      .getProfessions()
      .map { professions ->
        professions
          .map { profession ->
            HealthDirectoryProfessionModel(
              code = profession.code,
              name = profession.name
            )
          }
          .toSet()
      }

  override suspend fun getExpertises(
  ): ApiResponse<Set<HealthDirectoryExpertiseModel>> =
    apiClient.healthDirectory
      .getExpertises()
      .map { expertises ->
        expertises
          .map { expertise ->
            HealthDirectoryExpertiseModel(
              code = expertise.code,
              name = expertise.name
            )
          }
          .toSet()
      }

  override suspend fun getMatchingLabodocMedicalSpeciality(
    profession: ProfessionCode,
    professionalCategory: ProfessionalCategoryCode,
    professionalStatus: ProfessionalStatusCode?,
    expertise: ExpertiseCode?,
    pharmacistTableSection: PharmacistTableSection.Code?
  ): ApiResponse<MedicalProfessionAndSpecialityModel> =
    apiClient.healthDirectory
      .getLabodocMedicalProfessionAndSpeciality(
        professionCode = profession,
        professionalCategoryCode = professionalCategory,
        professionalStatusCode = professionalStatus,
        expertiseCode = expertise,
        pharmacistTableSectionCode = pharmacistTableSection
      )
      .map { medicalProfessionAndSpeciality ->
        MedicalProfessionAndSpecialityModel(
          medicalSpecialityId = medicalProfessionAndSpeciality.medicalSpecialityId,
          medicalProfessionName = medicalProfessionAndSpeciality.medicalProfessionName,
          medicalSpecialityName = medicalProfessionAndSpeciality.medicalSpecialityName
        )
      }
}
