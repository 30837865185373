package fr.labodoc.webapp.pages.admin.message

import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.admin.model.MessageModel
import fr.labodoc.app.data.admin.repository.MessagesRepository
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.navigate
import io.kvision.core.Container
import io.kvision.form.text.textAreaInput
import io.kvision.html.div
import io.kvision.html.p
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminMessageSheetPage(
  messageId: MessageId
) : SimplePanel() {
  private interface ViewModel {
    sealed class UiState {
      data object Loading : UiState()

      data class Error(
        val errorResponse: ErrorResponse
      ) : UiState()

      data class Loaded(
        val message: MessageModel,
        val deleteButtonDisabled: Boolean,
      ) : UiState()

      data object MedecineDeleted : UiState()
    }

    val uiState: ObservableState<UiState>
    val deleteMessageErrorResponse: ObservableState<ErrorResponse?>

    fun deleteMessage(messageId: MessageId)
  }

  private class ViewModelImpl(
    messageId: MessageId
  ) : ViewModel, KoinComponent {
    private val messageRepository: MessagesRepository by inject(named("admin"))

    override val uiState: ObservableValue<ViewModel.UiState> by lazy {
      val observableValue: ObservableValue<ViewModel.UiState> = ObservableValue(ViewModel.UiState.Loading)

      App.scope.launch {
        messageRepository.getMessage(messageId)
          .fold(
            { errorResponse: ErrorResponse ->
              ViewModel.UiState.Error(errorResponse)
            },
            { message: MessageModel ->
              ViewModel.UiState.Loaded(
                message = message,
                deleteButtonDisabled = false
              )
            }
          )
          .also { uiState.setState(it) }
      }

      observableValue
    }

    override val deleteMessageErrorResponse: ObservableValue<ErrorResponse?> =
      ObservableValue(null)

    override fun deleteMessage(messageId: MessageId) {
      App.scope.launch {

        messageRepository.deleteMessage(messageId)
          .onLeft { errorResponse: ErrorResponse ->
            deleteMessageErrorResponse.setState(errorResponse)
          }
          .onRight {
            uiState.setState(ViewModel.UiState.MedecineDeleted)
          }
      }
    }
  }

  private val viewModel = ViewModelImpl(messageId)

  init {
    id = "page-admin-message"
    require("./css/pages/admin/message/message.css")

    viewModel.deleteMessageErrorResponse.subscribe { deleteMessageErrorResponse: ErrorResponse? ->
      if (deleteMessageErrorResponse != null)
        Toast.danger("Impossible de supprimer le médicament")
    }

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      when (uiState) {
        ViewModel.UiState.Loading -> labodocSpinner()

        is ViewModel.UiState.Error -> {
          val errorResponse = uiState.errorResponse

          val errorMessage = when (errorResponse.code) {
            "Message.NotFound" -> "Ce message n'existe pas"
            else -> "Une erreur est survenue"
          }

          Toast.danger(errorMessage)
        }

        is ViewModel.UiState.Loaded -> {
          val message = uiState.message

          div(className = "message") {
            p(message.title.value, className = "title")

            textAreaInput(value = message.content.value) {
              disabled = true
            }

            //title
            // text
            // publishedAt
            // segmentation
            // bannerUrl
            // DocumentUrl

            // laboratory
            // medicine
            // contact

            // eventDate
          }

          div(className = "actions") {
            navigoLink("", Page.AdminBackOfficeMessageUpdate(message.id)) {
              labodocButton("Mettre à jour", className = "edit")
            }

            labodocButton("Supprimer") {
              onClick {
                disabled = true
                viewModel.deleteMessage(message.id)
              }

              viewModel.deleteMessageErrorResponse.subscribe { _: ErrorResponse? ->
                disabled = false
              }
            }
          }
        }

        ViewModel.UiState.MedecineDeleted -> App.routing.navigate(Page.AdminBackOfficeMessagesList())
      }
    }
  }
}

fun Container.adminMessageSheetPage(
  messageId: MessageId
): AdminMessageSheetPage {
  val adminMessageSheetPage = AdminMessageSheetPage(
    messageId
  )

  this.add(adminMessageSheetPage)
  return adminMessageSheetPage
}
