package fr.labodoc.webapp.pages.aboutUs

import fr.labodoc.require
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.*
import fr.labodoc.webapp.layouts.LabodocFooter
import fr.labodoc.webapp.layouts.LabodocHeader
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import org.koin.core.component.KoinComponent
import io.kvision.i18n.I18n.tr as translation

class WhoAreWePage() : SimplePanel(), KoinComponent {

  init {
    id = "page-about-us"
    require("./css/pages/aboutUs/about-us.css")
    add(LabodocHeader())
    div(className = "labodoc-background-beige d-flex flex-row align-center pb-xs-6") {
      id = "page-about-header"

      div(className = "labodoc-background-beige col-xl-6 col-lg-6 col-12") {
        id = "page-about-presentation"

        div(className = "page-width d-flex flex-column pt-5 pt-lg-10 pt-xl-10 align-xs-center") {
          id = "page-about-presentation-content"

          h1 (className = "mb-8 text-xs-center mb-xs-4"){
            content = "Qui sommes-nous ?"
          }

          p (className= "mb-8 mb-xs-4") {
            rich = true
            content = "<p>L’équipe LaboDoc est composée de professionnels de l’information sur le médicament et d’experts du digital. Le fondateur, <b>Emmanuel Messer</b>, a travaillé quotidiennement pendant plus de 20 ans au contact des professionnels de santé dans le cadre de sa précédente activité de délégué médical.</p>"
          }
        }
      }

      div (className = "col-xl-3 col-lg-3 hidden-sm-and-down") {
        div(className = "page-width") {
          image(require("./img/page-whoarewe-header.png"))
        }
      }
    }

    div(className = "labodoc-background-white py-lg-14 py-xl-14") {
      id = "page-about-pros"

      div(className = "page-width mx-lg-14 mx-xl-16 hidden-xs hidden-sm") {
        h1 {
          content = "Le constat"
        }
        h4 {
          rich = true
          content = """
            Les professionnels de santé font face à des journées toujours plus chargées, un nombre croissant de patients et un besoin constant de mettre à jour leurs connaissances médicales.<br>
            En parallèle, ils doivent s'informer sur l'actualité des innovations thérapeutiques qui deviennent de plus en plus spécifiques et techniques.
          """.trimIndent()
        }

        h1 {
          content = "Notre mission"
        }
        h4 (className = "mb-xl-5 mb-lg-3") {
          rich = true
          content = "<p>Permettre aux professionnels de santé d'accéder <b>simplement et librement</b> aux informations que les laboratoires pharmaceutiques mettent à leur disposition.</p>"
        }
      }
    }

    div(className = "labodoc-background-light-blue") {
      id = "page-about-features"

      div(className = "page-width") {
        div (className = "order-md-last d-flex justify-center align-center") {
          image(require("./img/page-whoarewe-info.png"))
        }
        div (className = "d-flex flex-column justify-center") {
          h1 {
            content = "Ce que nous offrons avec LaboDoc"
          }
          hr {}
          span {
            rich = true
            content =
              "<p>Nous proposons une plateforme innovante qui ne centralise que des informations <b>conformes au Code de la Santé Publique</b> fournies par les Laboratoires partenaires de LaboDoc. Chaque professionnel de santé peut consulter <b>en toute indépendance des informations</b> pertinentes à ses besoins et à sa pratique.<br>" +
                "Cette solution unique de <b>veille informationnelle</b> permet au médecin de gagner du temps et d'être averti dès qu'une actualité publiée dans LaboDoc le concerne (documents, invitations, communiqués). <br> <br> Pour les laboratoires pharmaceutiques, notre plateforme représente une opportunité unique de communiquer directement et largement avec les professionnels de santé, en leur fournissant les informations certifiées les plus pertinentes et utiles au bon usage de leurs médicaments.</p>"
          }
        }
      }
    }

    div(className = "labodoc-background-white px-lg-12 px-xl-16") {
      id = "page-about-mission"

      div(className = "page-width mx-lg-12 mx-xl-16") {
        div (className = "d-flex flex-column justify-center align-center text-center my-8") {
          div(className = "quote-container") {
            p (className = "font-italic"){
              rich = true
              content = "\" Chers Professionnels de santé, nous sommes fiers de vous présenter LaboDoc, notre innovation collaborative MedTech, conçue pour vous faire gagner du temps et pour vous aider à offrir à chacun de vos patients une prise en charge des plus adaptées. \""
            }
          }

          b (className = "my-6") {
            content = "L'ÉQUIPE LABODOC"

          }
        }

        hr (className = "mt-6") {  }

        div (className = "d-flex flex-column justify-center align-center text-center") {
          p (className = "px-lg-10 px-xl-14") {
            rich = true
            content = "Professionnels de santé, partenaires de santé et laboratoires pharmaceutiques, ensemble, simplifions l’accès à l’information et au bon usage des médicaments !"
          }
        }

        navigoLink("", Page.Register()) {
          labodocButton("Je rejoins LaboDoc", className = "labodoc-background-middle-blue")
        }
      }
    }

    val swiper = require("swiper/element/bundle")

    swiper.register()
  }
}

fun Container.whoAreWePage() {
  add(WhoAreWePage())
  add(LabodocFooter())
}
