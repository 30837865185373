package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.UniversityHospitalResponse
import fr.labodoc.api.resources.UniversityHospitals
import fr.labodoc.api.routes.HealthProfessionalUniversityHospitalRoutes
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalUniversityHospitalsRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalUniversityHospitalRoutes {
  override suspend fun getUniversityHospitals(): ApiResponse<Set<UniversityHospitalResponse>> {
    val response = httpClient.get(UniversityHospitals())

    return if (response.status.isSuccess())
      response.body<Set<UniversityHospitalResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
