package fr.labodoc.webapp.pages.login.sections

import arrow.core.Either
import arrow.core.EitherNel
import arrow.core.nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.require
import fr.labodoc.webapp.components.LabodocText
import io.kvision.core.Container
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.html.Autocomplete
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class PasswordForgottenForm(
  emailAddress: EmailAddress?
) : FormPanel<PasswordForgottenForm.Data>(
  className = "password-forgotten",
  serializer = Data.serializer()
) {
  @Serializable
  data class Data(
    val emailAddress: String? = null
  )

  data class ValidatedData(
    val emailAddress: EmailAddress
  )

  private val emailAddressInput = LabodocText {
    input.autocomplete = Autocomplete.USERNAME
    label = I18n.tr("LoginForm.Email")
    value = emailAddress?.value
  }.bind(
    key = Data::emailAddress,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { validateEmailAddress(it.value).isRight() },
    validatorMessage = { validateEmailAddress(it.value).leftOrNull() }
  )

  override fun dispose() {}

  init {
    require("./css/pages/login/sections/password-forgotten-form.css")

    add(emailAddressInput)
  }

  private fun validateEmailAddress(input: String?): Either<String, EmailAddress> =
    either {
      ensureNotNull(input) { I18n.tr("Field.Required") }

      EmailAddress(input)
        .mapLeft { error ->
          when (error) {
            Errors.EmailAddress.Invalid.Format -> I18n.tr("Errors.EmailAddress.Invalid")
            Errors.EmailAddress.Invalid.TooLong -> I18n.tr("Errors.EmailAddress.Invalid")
          }
        }
        .bind()
    }

  suspend fun getValidatedData(): EitherNel<String, ValidatedData> =
    either {
      val inputs = getDataWithFileContent()

      val emailAddress = validateEmailAddress(inputs.emailAddress)
        .onLeft { emailAddressInput.validatorError = it }
        .mapLeft { "Erreur sur le champ ${emailAddressInput.label}: $it".nel() }
        .bind()

      ValidatedData(
        emailAddress = emailAddress
      )
    }
}

fun Container.passwordForgottenForm(
  emailAddress: EmailAddress?
): PasswordForgottenForm {
  val passwordForgottenForm = PasswordForgottenForm(
    emailAddress = emailAddress,
  )

  this.add(passwordForgottenForm)
  return passwordForgottenForm
}
