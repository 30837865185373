package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.user.UserId
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UserIdAsStringSerializer : KSerializer<UserId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("UserIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.User.Id.Invalid, UserId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.User.Id.Invalid.Format }
      .map { UserId(it) }

  override fun deserialize(decoder: Decoder): UserId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: UserId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: UserId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias UserIdAsString = @Serializable(UserIdAsStringSerializer::class) UserId
