package fr.labodoc.webapp.components

import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.html.Button
import io.kvision.html.ButtonStyle
import io.kvision.html.ButtonType

class LabodocButton(
  text: String, icon: String? = null, style: ButtonStyle = ButtonStyle.PRIMARY, type: ButtonType = ButtonType.BUTTON,
  disabled: Boolean = false, separator: String? = null, labelFirst: Boolean = true,
  className: String? = null, init: (LabodocButton.() -> Unit)? = null
) : Button(text, icon, style, type, disabled, separator, labelFirst, className, null) {
  init {
    require("./css/components/labodoc-button.css")

    addCssClass("labodoc-button")

    init?.invoke(this)
  }
}

/**
 * DSL builder extension function.
 *
 * It takes the same parameters as the constructor of the built component.
 */
fun Container.labodocButton(
  text: String,
  icon: String? = null,
  style: ButtonStyle = ButtonStyle.PRIMARY,
  type: ButtonType = ButtonType.BUTTON,
  disabled: Boolean = false,
  separator: String? = null,
  labelFirst: Boolean = true,
  className: String? = null,
  init: (LabodocButton.() -> Unit)? = null
): LabodocButton {
  val button = LabodocButton(text, icon, style, type, disabled, separator, labelFirst, className, init)
  this.add(button)
  return button
}
