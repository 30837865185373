package fr.labodoc.webapp.pages.admin.laboratories

import arrow.core.Either
import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.api.ApiResponse
import fr.labodoc.app.data.admin.model.LaboratoryModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.MedicineSummaryModel
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.LabodocDialog
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import io.kvision.core.Container
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.qualifier.named

class AdminLaboratoryPage(
  laboratoryId: LaboratoryId
) : SimplePanel() {
  private class ViewModel(val laboratoryId: LaboratoryId): KoinComponent {
    private val laboratoriesRepository: LaboratoriesRepository by inject(named("admin"))
    private val medicalProfessionsRepository: MedicalProfessionsRepository by inject(named("admin"))
    private val medicinesRepository: MedicinesRepository by inject(named("admin"))

    data class Data(
        val laboratory: LaboratoryModel,
        val medicalProfessions: Set<MedicalProfessionModel>,
        val laboratoryMedicines: Set<MedicineSummaryModel>
    )

    private val _data: ObservableValue<ApiResponse<Data>?> by lazy {
      val observableValue: ObservableValue<ApiResponse<Data>?> = ObservableValue(null)

      App.scope.launch {
        either {
          parZip(
            { laboratoriesRepository.getLaboratory(laboratoryId).bind() },
            { medicalProfessionsRepository.getMedicalProfessions().bind() },
            { medicinesRepository.getMedicinesForLaboratory(laboratoryId).bind() }
          ) { laboratory, medicalProfessions, laboratoryMedicines ->
            Data(
              laboratory,
              medicalProfessions,
              laboratoryMedicines
            )
          }
        }.also { observableValue.setState(it) }
      }

      observableValue
    }
    val data: ObservableState<ApiResponse<Data>?>
      get() = _data

    private val _laboratoryActivated: ObservableValue<Boolean?> = ObservableValue(null)
    val laboratoryActivated: ObservableState<Boolean?>
      get() = _laboratoryActivated

    init {
      data.subscribe { data ->
        data?.onRight { _laboratoryActivated.setState(it.laboratory.activated) }
      }
    }
    var currentTabDisplayed = ObservableValue(1);
    var activateDialogContainer = SimplePanel {}
    var activateDialogContent = LabodocDialog(SimplePanel {}, "")
    fun openActivateDialog() {
      activateDialogContainer.add(activateDialogContent)
      activateDialogContent.showModal()
    }

    fun closeActivateDialog() {
      activateDialogContent.closeModal()
    }

    suspend fun activateLaboratory() =
      laboratoriesRepository.activateLaboratory(laboratoryId)
        .onRight { _laboratoryActivated.setState(true); activateDialogContent.closeModal(); Toast.success("Laboratoire activé avec succés") }

    suspend fun deactivateLaboratory() =
      laboratoriesRepository.deactivateLaboratory(laboratoryId)
        .onRight { _laboratoryActivated.setState(false); activateDialogContent.closeModal(); Toast.success("Laboratoire désactivé avec succés") }

  }

  private val viewModel = ViewModel(laboratoryId)

  init {
    id = "page-admin-laboratory"
    require("./css/pages/admin/laboratories/laboratory.css")

    viewModel.activateDialogContainer = div {}

    div(className = "page-width").bind(viewModel.data) { data ->
      when (data) {
        is Either.Left -> {
          Toast.danger("Impossible de charger le laboratoire")
          window.history.back()
        }

        is Either.Right -> {
          val laboratory = data.value.laboratory
          val medicalProfessions = data.value.medicalProfessions

          viewModel.activateDialogContent = LabodocDialog(SimplePanel() {
            div(className = "dialog-inner").bind(viewModel.laboratoryActivated) {
              h3 {
                content = if (viewModel.laboratoryActivated.getState() == true) {
                  "Voulez vous vraiment désactiver le laboratoire " + laboratory.name.value  + " ? "
                } else {
                  "Voulez vous vraiment activer le laboratoire " + laboratory.name.value + " ? "
                }
              }
              div {
                if (viewModel.laboratoryActivated.getState() == true) {
                  labodocButton("") {
                    text = "Désactiver";
                    addCssClass("deactivate-btn")
                    onClick {
                      App.scope.launch {
                        viewModel.deactivateLaboratory()
                      }
                    }
                  }
                } else {
                  labodocButton("") {
                    text = "Activer";
                    addCssClass("activate-btn")
                    onClick {
                      App.scope.launch {
                        viewModel.activateLaboratory()
                      }
                    }
                  }
                }
                labodocButton("") {
                  text = "Annuler";
                  addCssClass("cancel-btn")
                  onClick {
                    viewModel.closeActivateDialog()
                  }
                }
              }
            }
          }, "Activation d'un laboratoire")

          div(className = "laboratory-information") {
            div {
              if (laboratory.logoUrl != null) {
                div(className = "image-container") {
                  image(laboratory.logoUrl.toString(), "Logo du laboratoire")
                }
              }

              h1(className = "name") {
                content = data.value.laboratory.name.value
              }

              span (className = "info-field") {
                icon ("fa-solid fa-globe")
                p { content = "Site web : " }
                link(
                  laboratory.website.value.toString(),
                  laboratory.website.value.toString(),
                  className = "website"
                )
              }

              div(className = "actions") {
                navigoLink("", Page.AdminBackOfficeLaboratoryUpdate(laboratory.id)) {
                  labodocButton("Mettre à jour", icon = "fa-solid fa-pencil", className = "edit")
                }

                labodocButton("").bind(viewModel.laboratoryActivated) { activated ->
                  removeEventListeners()
                  when (activated) {
                    true -> {
                      text = "Activé"
                      icon = "fa-solid fa-check"
                      removeCssClass("activate")
                      addCssClass("deactivate")

                      onClick {
                        viewModel.openActivateDialog()
                      }
                    }

                    false -> {
                      text ="Désactivé"
                      icon = "fa-solid fa-ban"
                      removeCssClass("deactivate")
                      addCssClass("activate")

                      onClick {
                        viewModel.openActivateDialog()
                      }
                    }

                    null -> Unit
                  }
                }
              }
            }
          }



          div(className = "contents").bind(viewModel.currentTabDisplayed) {
            nav {
              link("Médicaments") {onClick { viewModel.currentTabDisplayed.setState(1) }; if (viewModel.currentTabDisplayed.getState() == 1){addCssClass("active")}}
            }
            div(className = "container") {
              when (viewModel.currentTabDisplayed.getState()) {
                1 -> {
                  div(className = "medicines") {
                    if (data.value.laboratoryMedicines.isNotEmpty()) {
                      data.value.laboratoryMedicines.forEach {it ->
                        div(className = "medicine") {
                          div (className = "medicine-image") { if (it.logoUrl != null) {image(it.logoUrl.toString(), it.name.value)} }
                          navigoLink("", Page.AdminBackOfficeMedicineSheet(it.id)) {p("""<span class="medicine-name">${it.name.value}</span>""", rich = true, className = "name")}
                          div(className = "information") {
                            p("""<b>CIP: </b> ${it.cipCode.value}""", rich = true, className = "cip")
//                            if (it.website != null) {p("""<i class="fa-solid fa-globe"></i> <a href="${it.website}" target="_blank">${it.website}</a> """, rich = true, className = "website")}
//                            else {p("""<i class="fa-solid fa-globe"></i> Site web non renseigné""", rich = true, className = "website")}
                          }
                          div (className = "medicine-actions") {
                            navigoLink("", Page.AdminBackOfficeMedicineUpdate(it.id)) {
                              labodocButton("Mettre à jour", className = "edit-medicine")
                            }
                          }
                        }

                      }
                    } else {
                      div(className = "no-information") {
                        p("Aucun médicament renseigné")
                      }
                    }
                    div(className = "add-medicine-action") {
                      navigoLink("", Page.AdminBackOfficeLaboratoryMedicineCreate(laboratory.id)) {
                        labodocButton("Ajouter un Médicament", icon = "fa-solid fa-plus", className = "edit")
                      }
                    }
                  }
                }
              }
            }
          }
        }

        null -> labodocSpinner()
      }
    }
  }
}

fun Container.adminLaboratoryPage(laboratoryId: LaboratoryId): AdminLaboratoryPage {
  val adminLaboratoryPage = AdminLaboratoryPage(laboratoryId)
  this.add(adminLaboratoryPage)
  return adminLaboratoryPage
}
