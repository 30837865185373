package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.CreateLaboratoryRequest
import fr.labodoc.api.payloads.requests.UpdateLaboratoryRequest
import fr.labodoc.api.payloads.responses.AdminLaboratoriesResponse
import fr.labodoc.api.payloads.responses.AdminLaboratoryResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.payloads.responses.LaboratoryCreatedResponse
import fr.labodoc.api.resources.Laboratories
import fr.labodoc.api.routes.AdminLaboratoriesRoutes
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class AdminLaboratoriesRemoteDataSource(
  private val httpClient: HttpClient
) : AdminLaboratoriesRoutes {
  override suspend fun getLaboratories(
  ): ApiResponse<AdminLaboratoriesResponse> {
    val response = httpClient.get(Laboratories())

    return if (response.status.isSuccess())
      response.body<AdminLaboratoriesResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getLaboratory(
    id: LaboratoryId
  ): ApiResponse<AdminLaboratoryResponse> {
    val response = httpClient.get(Laboratories.Laboratory(id))

    return if (response.status.isSuccess())
      response.body<AdminLaboratoryResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun createLaboratory(
    payload: CreateLaboratoryRequest,
    logo: InputFile?
  ): ApiResponse<LaboratoryCreatedResponse> {
    val response = httpClient.post(Laboratories()) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          logo?.let {
            append("logo", logo.content, Headers.build {
              append(HttpHeaders.ContentType, logo.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      response.body<LaboratoryCreatedResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun updateLaboratory(
    id: LaboratoryId,
    payload: UpdateLaboratoryRequest,
    logo: InputFile?
  ): ApiResponse<Unit> {
    val response = httpClient.put(Laboratories.Laboratory(id)) {
      setBody(MultiPartFormDataContent(
        formData {
          append("payload", Json.encodeToString(payload), Headers.build {
            append(HttpHeaders.ContentType, "application/json")
          })
          logo?.let {
            append("logo", logo.content, Headers.build {
              append(HttpHeaders.ContentType, logo.mime)
              append(HttpHeaders.ContentDisposition, "filename=\"${logo.name}\"")
            })
          }
        }
      ))
    }

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun activateLaboratory(
    id: LaboratoryId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Laboratories.Laboratory.Activate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun deactivateLaboratory(
    id: LaboratoryId
  ): ApiResponse<Unit> {
    val response = httpClient.post(Laboratories.Laboratory.Deactivate(id))

    return if (response.status.isSuccess())
      Unit.right()
    else
      response.body<ErrorResponse>().left()
  }
}
