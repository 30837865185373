package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.domain.labodoc.partnership.PartnershipName
import fr.labodoc.domain.labodoc.partnership.PartnershipWebsite
import io.ktor.http.*

data class PartnershipModel(
  val id: PartnershipId,
  val name: PartnershipName,
  val logo: Url,
  val website: PartnershipWebsite
)
