package fr.labodoc.webapp.components

import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import io.kvision.core.Container
import io.kvision.core.ResString
import io.kvision.html.Link

class NavigoLink(
  label: String, url: String? = null, icon: String? = null, image: ResString? = null,
  separator: String? = null, labelFirst: Boolean = true, target: String? = null, dataNavigo: Boolean? = null,
  className: String? = null, init: (Link.() -> Unit)? = null
) : Link(label, url, icon, image, separator, labelFirst, target, dataNavigo, className, init) {
  init {
    url?.let {
      onClick { event ->
        event.preventDefault()
        App.routing.navigate(url)
      }
    }
  }
}

/**
 * DSL builder extension function.
 *
 * It takes the same parameters as the constructor of the built component.
 */
fun Container.navigoLink(
  label: String, url: String? = null, icon: String? = null, image: ResString? = null,
  separator: String? = null, labelFirst: Boolean = true, target: String? = null, dataNavigo: Boolean? = null,
  className: String? = null,
  init: (Link.() -> Unit)? = null
): NavigoLink {
  val link =
    NavigoLink(
      label,
      url,
      icon,
      image,
      separator,
      labelFirst,
      target,
      dataNavigo,
      className,
      init
    )
  this.add(link)
  return link
}


fun Container.navigoLink(
  label: String, page: Page? = null, icon: String? = null, image: ResString? = null,
  separator: String? = null, labelFirst: Boolean = true, target: String? = null, dataNavigo: Boolean? = null,
  className: String? = null,
  init: (Link.() -> Unit)? = null
): NavigoLink {
  val link =
    NavigoLink(
      label,
      page?.url,
      icon,
      image,
      separator,
      labelFirst,
      target,
      dataNavigo,
      className,
      init
    )
  this.add(link)
  return link
}
