package fr.labodoc.app.data.healthprofessional.repository

import arrow.core.Either
import fr.labodoc.app.data.error.RepositoryError
import fr.labodoc.app.data.healthprofessional.model.*
import fr.labodoc.app.data.healthprofessional.source.remote.MessagesRemoteDataSource
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageId

class MessagesRepositoryImpl(
  private val messagesRemoteDataSource: MessagesRemoteDataSource
) : MessagesRepository {
  override suspend fun getMessages(
    onlyFavorite: Boolean,
    flashInfo: Boolean,
    invitation: Boolean
  ): Either<RepositoryError.DataSource, Set<MessageSummaryModel>> =
    messagesRemoteDataSource
      .getMessages(
        onlyFavorite = onlyFavorite,
        flashInfo = flashInfo,
        invitation = invitation
      )
      .mapLeft(RepositoryError::DataSource)

  override suspend fun getMessagesForMedicine(
    medicineId: MedicineId
  ): Either<RepositoryError.DataSource, Set<MedicineMessageSummaryModel>> =
    messagesRemoteDataSource
      .getMessagesForMedicine(
        medicineId = medicineId
      )
      .mapLeft(RepositoryError::DataSource)

  override suspend fun getMessage(
    id: MessageId
  ): Either<RepositoryError, MessageModel> =
    messagesRemoteDataSource
      .getMessage(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)

  override suspend fun favorite(
    id: MessageId
  ): Either<RepositoryError, Unit> =
    messagesRemoteDataSource
      .favoriteMessage(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)

  override suspend fun unfavorite(
    id: MessageId
  ): Either<RepositoryError, Unit> =
    messagesRemoteDataSource
      .unfavoriteMessage(
        id = id
      )
      .mapLeft(RepositoryError::DataSource)
}



fun MessageModel.toSummaryModel(): MessageSummaryModel =
  when (this) {
    is LabodocFlashInfoModel -> LabodocFlashInfoSummaryModel(
      id = this.id,
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LaboratoryFlashInfoModel -> LaboratoryFlashInfoSummaryModel(
      id = this.id,
      laboratory = LaboratoryMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LaboratoryInvitationModel -> LaboratoryInvitationSummaryModel(
      id = this.id,
      laboratory = LaboratoryMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LearnedSocietyFlashInfoModel -> LearnedSocietyFlashInfoSummaryModel(
      id = this.id,
      learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
        id = this.learnedSociety.id,
        name = this.learnedSociety.name,
        acronym = TODO() // fixme
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is LearnedSocietyInvitationModel -> LearnedSocietyInvitationSummaryModel(
      id = this.id,
      learnedSociety = LearnedSocietyMessageSummaryModel.LearnedSociety(
        id = this.learnedSociety.id,
        name = this.learnedSociety.name,
        acronym = TODO() // fixme
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is MedicineFlashInfoModel -> MedicineFlashInfoSummaryModel(
      id = this.id,
      laboratory = MedicineMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )

    is MedicineInvitationModel -> MedicineInvitationSummaryModel(
      id = this.id,
      laboratory = MedicineMessageSummaryModel.Laboratory(
        id = this.laboratory.id,
        name = this.laboratory.name
      ),
      publishedAt = this.publishedAt,
      title = this.title,
      seen = this.seen,
      favorite = this.favorite
    )
  }
