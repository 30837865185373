package fr.labodoc.app.data.healthprofessional.source.remote

import arrow.core.Either
import arrow.core.raise.catch
import arrow.core.raise.either
import fr.labodoc.api.HealthProfessionalApiClient
import fr.labodoc.api.payloads.responses.HealthProfessionalLearnedSocietyResponse
import fr.labodoc.app.data.error.RemoteDataSourceError
import fr.labodoc.app.data.healthprofessional.model.LearnedSocietyModel
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId

class LearnedSocietiesRemoteDataSourceImpl(
  private val apiClient: HealthProfessionalApiClient
) : LearnedSocietiesRemoteDataSource {
  override suspend fun getLearnedSocieties(
  ): Either<RemoteDataSourceError, Set<LearnedSocietyModel>> =
    either {
      catch({
        apiClient.learnedSocieties
          .getLearnedSocieties()
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .map { response: Set<HealthProfessionalLearnedSocietyResponse> ->
            response.toModel()
          }
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun favoriteLearnedSociety(
    id: LearnedSocietyId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.learnedSocieties
          .favoriteLearnedSociety(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }

  override suspend fun unfavoriteLearnedSociety(
    id: LearnedSocietyId
  ): Either<RemoteDataSourceError, Unit> =
    either {
      catch({
        apiClient.learnedSocieties
          .unfavoriteLearnedSociety(
            id = id
          )
          .mapLeft(RemoteDataSourceError::SomethingWentWrong)
          .bind()
      }) { exception: Exception ->
        raise(RemoteDataSourceError.UnreachableServer(exception))
      }
    }
}

private fun Set<HealthProfessionalLearnedSocietyResponse>.toModel(): Set<LearnedSocietyModel> =
  this
    .map { learnedSociety: HealthProfessionalLearnedSocietyResponse ->
      LearnedSocietyModel(
        id = learnedSociety.id,
        name = learnedSociety.name,
        acronym = learnedSociety.acronym,
        website = learnedSociety.website,
        logoUrl = learnedSociety.logo,
        medicalSpecialities = learnedSociety.medicalSpecialities,
        favorite = learnedSociety.favorite
      )
    }
    .toSet()
