package fr.labodoc.webapp.pages.admin.medicines.create

import arrow.core.raise.either
import fr.labodoc.app.data.admin.repository.LaboratoriesRepository
import fr.labodoc.app.data.admin.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.webapp.App
import fr.labodoc.webapp.pages.admin.medicines.AdminMedicineForm
import io.kvision.state.ObservableValue
import kotlinx.coroutines.launch

class AdminMedicineCreatePageViewModelImpl(
  private val laboratoryId: LaboratoryId?,
  private val laboratoriesRepository: LaboratoriesRepository,
  private val medicineRepository: MedicinesRepository
) : AdminMedicineCreatePageViewModel() {
  private val processing: ObservableValue<Boolean> = ObservableValue(false)

  override val uiState: ObservableValue<UiState> by lazy {
    refresh()
    ObservableValue(UiState.Loading)
  }
  override val errorMessage: ObservableValue<String?> = ObservableValue(null)
  override val successMessage: ObservableValue<String?> = ObservableValue(null)

  override fun refresh() {
    App.scope.launch {
      uiState.value = UiState.Loading

      uiState.value = either {
        UiState.Form(
          laboratories = laboratoriesRepository.getLaboratories().bind(),
          formData = AdminMedicineForm.Data(
            laboratory = laboratoryId?.value?.toString()
          ),
          processing = processing
        )
      }.mapLeft {
        UiState.Error
      }.fold({ it }, { it })
    }
  }

  override fun createMedicine(
    form: AdminMedicineForm
  ) {
    App.scope.launch {
      processing.value = true

      form.getValidatedData()
        .onLeft {
          errorMessage.value = "Le formulaire n'est pas valide"
        }
        .onRight { validatedData ->
          medicineRepository
            .createMedicine(
              laboratory = validatedData.laboratory,
              name = validatedData.name,
              atcClassificationCode = validatedData.atcClassificationCode,
              logo = validatedData.logo
            )
            .onLeft {
              errorMessage.value = "Une erreur est survenue, vérifiez les données et réessayez"
            }
            .onRight { id ->
              uiState.value = UiState.MedicineCreated(
                id = id
              )
            }
        }

      processing.value = false
    }
  }
}
