package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.PasswordAsString
import kotlinx.serialization.Serializable

@Serializable
class ChangePasswordRequest(
  val previousPassword: PasswordAsString,
  val newPassword: PasswordAsString
)
